import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

export const repeatEmail = async (seismiEventId: number) => {

	const headers = getHeaders()

	const response = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/utils/repeat_email_for_seismo_event`,
		headers,
		params: {
            seismic_event_id: seismiEventId
		},
	})

	return response
};

export default repeatEmail;
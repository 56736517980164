class DeviceParametersGroup {
    id: number = NaN;

    name: string = ''; 
    label: string = ''; 
    short_label: string = '';
    units: string = '';

    parameters: number[] = [];
    
    get description() {
        return `${this.label}, ${this.units} (${this.name})`
    }
}

export default DeviceParametersGroup;
import { DeviceParametersDataForPeriod, DeviceParametersDataInfo } from "../../api/devices/get.data.for.period"
import Device from "../../model/device"
import { PlayerTimelineItem } from "../components/PlayerTimeline"
import { LeafletHeatmapConfig, LeafletHeatmapData } from "../types/leaflet-heatmap-config"

export const deviceParamsDataForPeriodToPlayerTimelineWithHeatmap = (devices: Device[], paramsDataForPeriod: DeviceParametersDataForPeriod, paramNames: string[]): PlayerTimelineItem<LeafletHeatmapConfig>[] => {
    const dataByLabel: Record<string, LeafletHeatmapConfig['data']> = {};

    for (const [deviceId, paramData] of Object.entries(paramsDataForPeriod)) {
        const device = devices.find(d => d.device_id.toString() === deviceId);

        if (device) {
            const position: Pick<LeafletHeatmapData, 'lat' | 'lng'> = {
                lat: device.latitude,
                lng: device.longitude
            }

            paramNames.forEach(name => {
                const paramInfo = paramData.data[name];
                const labels = paramData.labels;

                if (paramInfo) {
                    paramInfo.data.forEach((d, index) => {
                        const value = d.raw;

                        if (value !== 0 && !value) return;

                        const label = labels[index];

                        if (dataByLabel[label]) {
                            dataByLabel[label].push({ ...position, value })
                        } else {
                            dataByLabel[label] = [{ ...position, value }];
                        }
                    });
                }
            });
        }
    }

    return Object.entries(dataByLabel).map(([label, data], index): PlayerTimelineItem<LeafletHeatmapConfig> => {
        const max = data.length ? Math.max(...data.map(i => i.value)) : 0;
        const min = data.length ? Math.min(...data.map(i => i.value)) : 0;

        return {
            label,
            value: { data, min, max },
            id: index,
        }
    }).filter(item => item.value.data.length > 0);
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function TooMuchDevicesDialog(props) {
    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                className="z-[9999999]"
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Слишком много устройств"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Для сравнения добавлено слишком много устройств. Удалите одно или более устройств из 
                        списка для сравнения и тогда добавление опять будет доступно.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Понятно</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
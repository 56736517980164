import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import 'echarts/i18n/langRU';

const ChartEcharts = (props) => {
    const [option, setOption] = useState(undefined)

    useEffect(() => {
        let series = []
        Object.keys(props.data_checked).forEach((key) => {
            if (props.data_checked[key] === true) {
                const dataset = props.datasets[key]
                if (!dataset) {
                    return
                }

                let newData = []
                if (dataset.data) {
                    dataset.data.forEach((data) => {
                        newData.push({
                            value: data,
                            unit: dataset.unit,
                            deviceName: dataset.device
                        })
                    })
                }                
                series.push({
                    itemStyle: { normal: { color: dataset.borderColor } },
                    name: dataset.label,
                    type: 'line',
                    data: newData,
                    connectNulls: false,
                })
            }
        })


        const option = {
            locale: 'DE',
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                show: false,
            },
            toolbox: {
                show: true,
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    dataView: { readOnly: false },
                    magicType: { type: ['line', 'bar'] },
                    restore: {},
                    saveAsImage: {}
                },
                top: -7,
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
                position: function (point, params, dom, rect, size) {
                    // fixed at top

                    const left = point[0]
                    const padding = left > 350 ? -350 : 20
                    return [left + padding, '10%'];
                },
                formatter: (params) => {
                    let rows = ''
                    let parasByDevices = {}
                    params.forEach((param) => {
                        if (!parasByDevices[param.data.deviceName]) {
                            parasByDevices[param.data.deviceName] = []
                        }
                        parasByDevices[param.data.deviceName].push(param)

                    })

                    Object.keys(parasByDevices).forEach((deviceName) => {
                        rows = rows + `<b>${deviceName}</b>: <br />`

                        const params = parasByDevices[deviceName]
                        params.forEach((param) => {
                            let value = '-'
                            if (param.value !== null) {
                                value = Math.round(param.value * 100) / 100
                            }

                            rows = rows + `${param.marker} ${param.seriesName}: ${value} ${param.data.unit}<br />`
                        })
                    })

                    return `
                            ${params[0].name} <br />
                            ${rows}
                            
                            `;
                },
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: props.data.labels,
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value}'
                },
                min: function (value) {
                    return value.min + value.min * 0.1 * (- Math.sign(value.min));
                }

            },
            series: series,
            grid: {
                left: 55,
                top: 20,
                right: 25,
                bottom: 30
            }
        };

        setOption(option)

    }, [props])


    return (
        <>
            {option &&
                <ReactEcharts
                    style={{ 'height': '28vh' }}
                    option={option}
                    opts={{ locale: 'RU' }}

                    key={`react-echart-component-${option.series.length}`} />
            }
        </>
    )
}
export default ChartEcharts;
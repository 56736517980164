import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { AiCameraRoi } from "../../../model/ai.camera.roi";


interface RoisTableProps {
    areas: AiCameraRoi[],
    clickEditRoi: (area: AiCameraRoi) => void,
}

export default function RoisTable({ areas, clickEditRoi }: RoisTableProps) {
    
    
    return (
        <>
            <TableContainer className="mx-auto" component={Paper}>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell key="th-x1">X1</TableCell>
                            <TableCell key="th-y1">Y1</TableCell>
                            <TableCell key="th-x2">X2</TableCell>
                            <TableCell key="th-y2">Y2</TableCell>
                            <TableCell key="th-actions"></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody className="min-h-[275px]">
                        {areas.map((area: AiCameraRoi) => {
                            return (
                                <TableRow key={`${area.id}`}>

                                    <TableCell key={`x1-${area.id}`}>
                                        {area.area_x1}
                                    </TableCell>

                                    <TableCell key={`x2-${area.id}`}>
                                        {area.area_y1}
                                    </TableCell>

                                    <TableCell key={`x1-${area.id}`}>
                                        {area.area_x2}
                                    </TableCell>

                                    <TableCell key={`x1-${area.id}`}>
                                        {area.area_y2}
                                    </TableCell>
                                    
                                    
                                    <TableCell key={`actions-${area.id}`}>
                                        <Tooltip title="Редактировать информацию о параметра">
                                            <div className="inline-block">
                                                <FaEdit className="text-2xl inline cursor-pointer" onClick={() => clickEditRoi(area)}/>
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}
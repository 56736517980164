import { TabContext } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDeviceTypeInfo } from '../../../api/devices/get.device.type.info';
import DeviceParameter from '../../../model/device.parameter';
import DeviceTypeIcon from '../../../model/device.type.icon';
import Nav from '../../nav';
import AdminHeader from '../header';
import DeviceParatersTab from './DeviceParametersTab';
import DeviceTypeIconsTab from './DeviceTypeIconsTab';
import NotAccessPage from '../../lk/NotAccessPage';


export enum DeviceTypeTab {
    Parameters = 'Parameters',
    Icons = 'Icons',
}


const DeviceTypePage = () => {

    const [activeTab, setActiveTab] = useState<DeviceTypeTab>(DeviceTypeTab.Parameters);
    const [deviceParameters, setDeviceParameters] = useState<DeviceParameter[] | null>([])
    const [deviceTypeIcons, setDeviceTypeIcons] = useState<DeviceTypeIcon[]>([])

    const params = useParams()
    const [headerTitle, setHeaderTitle] = useState('Тип устройства...')

    useEffect(() => {
        const fetchData = async () => {
            const deviceInfo = await getDeviceTypeInfo(params.device_type_id, params.module_type_id)
            if (deviceInfo) {
                setDeviceParameters(deviceInfo.device_parameters)
                setDeviceTypeIcons(deviceInfo.device_type_icons)
                setHeaderTitle(`Тип устройства ${deviceInfo.device_type.description}`)
            } else {
                setDeviceParameters(null)
            }
        }

        fetchData()

    }, [])


    const onChangeTab = (value: DeviceTypeTab) => {
        setActiveTab(value)
    }

    if (deviceParameters === null) {
        return <NotAccessPage />
    }

    return (
        <>
            <Nav />

            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AdminHeader headerText={`${headerTitle}`} backUrl={`/admin/devices/types`} />

                    <Tabs className="mb-2" centered={true} value={activeTab} onChange={(_, value) => onChangeTab(value)}>
                        <Tab label="Параметры устройства" value={DeviceTypeTab.Parameters} />
                        <Tab label="Иконки на виджетах" value={DeviceTypeTab.Icons} />

                    </Tabs>

                    <TabContext value={activeTab}>
                        <TabPanel className="p-0" value={DeviceTypeTab.Parameters}>
                            <DeviceParatersTab deviceParamters={deviceParameters} />
                        </TabPanel>
                        <TabPanel className="p-0" value={DeviceTypeTab.Icons}>
                            <DeviceTypeIconsTab
                                deviceParameters={deviceParameters}
                                deviceTypeIcons={deviceTypeIcons}
                                deviceTypeId={params.device_type_id}
                                moduleTypeId={params.module_type_id}
                            />
                        </TabPanel>

                    </TabContext>

                </div>
            </section>

        </>
    );
}

export default DeviceTypePage
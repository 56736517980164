import { useState } from 'react';
import Device from '../../../model/device';
import { Form } from 'react-bootstrap';
import Box from '@mui/material/Box';
import saveDevicesForItem from '../../../api/devices/save.devices.for.request';
import { TextField, Typography } from '@mui/material';

interface ChangedRequestFormProps {
    allDevices: Device[],
    checkedDevices: any,
    id: number,
    url: string,
    handleClose?: () => void,
    afterSaveSuccess?: () => void
}


export default function ChangedItemDeviceListForm({ allDevices, checkedDevices, id, url, handleClose, afterSaveSuccess }: ChangedRequestFormProps) {

    const [checkedDevicesForEdit, setCheckedDevicesForEdit] = useState<any>(Object.assign({}, checkedDevices))

    const [searchText, setSearchText] = useState<string>('')

    const [saving, setSaving] = useState<boolean>(false)

    const clickSaveProfile = async () => {
        setSaving(true)
        const data = await saveDevicesForItem(checkedDevicesForEdit, id, url)
        setSaving(false)
        if (data && afterSaveSuccess) {
            afterSaveSuccess()
        }
    }

    const changeRequestListDevices = (checked: boolean, device_id: number) => {
        let _checkedDevices = checkedDevicesForEdit
        _checkedDevices[device_id] = checked
        setCheckedDevicesForEdit(Object.assign({}, _checkedDevices))
    }

    const selectAll = () => {
        let _checkedDevices = checkedDevicesForEdit
        allDevices.map( (device) => {
            _checkedDevices[device.id_device] = true
        })
        setCheckedDevicesForEdit(Object.assign({}, _checkedDevices))
    }

    const  deSelectAll = () => {
        setCheckedDevicesForEdit(Object.assign({}, {}))
    }

    const invertAll = () => {
        let _checkedDevices = checkedDevicesForEdit
        allDevices.map( (device) => {
            _checkedDevices[device.id_device] = !_checkedDevices[device.id_device]
        })
        setCheckedDevicesForEdit(Object.assign({}, _checkedDevices))
    }

    return (
        <>
            <TextField
                className="my-5 shadow-none"
                fullWidth
                value={searchText}
                size="small"
                placeholder='Текст для поиска'
                onChange={(event) => {
                    setSearchText(event.target.value)
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <div className="pb-5">
                <Typography component='a' onClick={selectAll} className="text-xs cursor-pointer text-lr-green-primary">
                    Выбрать все
                </Typography> |&nbsp;
                <Typography component='a' onClick={deSelectAll} className="text-xs cursor-pointer text-lr-green-primary">
                    Снять выделение у всех
                </Typography>  |&nbsp;
                <Typography component='a' onClick={invertAll} className="text-xs cursor-pointer text-lr-green-primary">
                    Инвертировать
                </Typography>
            </div>
            <Box
                sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 560,
                    overflow: "hidden",
                    overflowY: "scroll",
                }}
            >
                {allDevices.map((device, i) => {
                    const name = device.name.toLocaleLowerCase()
                    const sText = searchText.toLocaleLowerCase()
                    const isVisible = name.includes(sText)

                    if (!isVisible) return null

                    return <div className="mb-3" key={`form-device-${i}`}>
                        <Form.Check
                            type="checkbox"
                            label={`${device.name}`}
                            checked={checkedDevicesForEdit[device.id_device]}
                            onChange={(evnt) => changeRequestListDevices(evnt.target.checked, device.id_device)}

                        />
                    </div>
                })}
            </Box>
            <hr className="border-dashed my-2" />
            <p className='text-right'>
                <button className="lr-secondary-button" onClick={handleClose}>
                    Закрыть
                </button>
                <button className="ml-5 lr-primary-button" onClick={clickSaveProfile}
                    disabled={saving}

                >
                    {saving ? 'Сохранение...' : 'Сохранить'}
                </button>
            </p>
        </>
    );
}
import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import AccountSettings from '../../model/account.settings';

class AccountSettingsParams {
    account_id: number = -1;
}

const defaultLayouts = {
	[consts.DEVICE_TYPES.METEO]: [],
	[consts.DEVICE_TYPES.CAMERA]: [],
	[consts.DEVICE_TYPES.AUDIO]: [],
	[consts.DEVICE_TYPES.MODULE]: [],
}

export const getAccountSettings = async (accountId?: number): Promise<AccountSettings> => {

	const headers = getHeaders()

	console.log(accountId)
	const params = new AccountSettingsParams
	if (accountId != null) {
		params.account_id = accountId
	}
	console.log(params)
	const response = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/get_account_settings`,
		headers,
		params,
	})
	const accountSettings = response.data.settings as AccountSettings
	if (!accountSettings.mpanel_layouts) {
		accountSettings.mpanel_layouts = JSON.stringify(defaultLayouts);
	}


	return accountSettings
};

export default getAccountSettings;

import { Snackbar } from "@mui/material";
import { useEffect, useState } from 'react';


interface EcoCustomNotificationProps {
    message?: string,
    errorMessage?: string,
    successMessage?: string,
}

const ERROR_PROPS = { background: "red" }

export default function EcoCustomNotification({ message, errorMessage, successMessage }: EcoCustomNotificationProps) {

    const [alertContentProps, setAlertContentProps] = useState({})
    
    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string, props: any) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
        setAlertContentProps(props);
    };

    useEffect( () => {
        if (message) {
            notify(message, {})
        }
    }, [message])


    useEffect( () => {
        if (errorMessage) {
            notify(errorMessage, ERROR_PROPS)
        }
    }, [errorMessage])
    
    return (
        <>

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
                ContentProps={{
                    sx: alertContentProps
                }}
            />
        </>
    );
}
import Nav from '../../../nav';
import AdminHeader from "../../header";
import MisstionsTable from "./MissionsTable";

export default function MissionsMain() {

    return (
        <>
            <Nav />
            <section className="mt-0 min-h-[600px]">
                <div className="container px-0 admin-section">

                    <AdminHeader headerText={`Проект 'По ботаническому саду'`} backUrl={`/admin/devices/types`} />

                    <button className="lr-primary-button m-10 align-bottom">
                        Добавить миссию
                    </button>


                    <MisstionsTable />


                </div>
            </section>

        </>
    );
}



import { useMemo } from 'react';
import { HeatmapGradient } from '../types/leaflet-heatmap-config';

type Props = {
  gradient: HeatmapGradient;
  min: number;
  max: number;
  widthInPx: number;
  className?: string;
}

const calculateGradientStyle = (gradient: Props['gradient']) => {
  if (!gradient) return '';

  const direction = 'to right';

  const colors: string[] = [];

  Object.entries(gradient).sort().forEach(([percentage, color]) => {
    const percent = Number(percentage) * 100;

    colors.push(`${color} ${percent}%`);
  });

  return `linear-gradient(${[direction, ...colors].join(',')})`;
}

const UnitScale = (props: Props) => {
  const scaleValuesArray = useMemo(() => {
    const delta = props.max - props.min;
    const stepCount = 5;
    const stepSize = delta / stepCount;

    const array = [props.min];

    for (let i = array.length; i < stepCount - 1; i++) {
      array.push(array[i - 1] + stepSize);
    }

    array.push(props.max);

    return array.map(value => value.toFixed(1));
  }, [props.min, props.max])

  return <div className={props.className}>
    <div className='flex items-center justify-between mb-0.5 font-light' style={{ width: props.widthInPx }}>
      {scaleValuesArray.map((val, index) => {
        return <div key={index} className='text-black'>{val}</div>
      })}
    </div>
    <div style={{ width: props.widthInPx, height: 4, background: calculateGradientStyle(props.gradient) }}></div>
  </div>;
}

export default UnitScale;

import { Tooltip } from '@mui/material';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import { Component, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Form from 'react-bootstrap/Form';
import { isMobile } from 'react-device-detect';
import { BsCheck } from "react-icons/bs";
import { FaSave } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import loadDataForPeriod from '../../api/data/load.data.for.period';
import * as utils from '../../utils';
import ChartEcharts from '../map/cc/chart_echarts';
import chartOptions from '../map/cc/chart_options.js';
import ChooserAgType from '../map/cc/chooser_ag_type';
import ChooserSaverFile from '../map/cc/chooser_saver_file';
import ParameterChooser from './ParameterChooser';


const defaultCurrentDateType = localStorage.getItem('current_date_type') || 'day';

const periodTypes = {
    'hour': 'Час',
    'day': 'Сутки',
    'week': 'Неделя',
    'month': 'Месяц',
    'year': 'Год',
    'custom': 'Свой интервал',
}

const Charts = (props) => {

    const [data, setData] = useState({ labels: [], datasets: [] })
    const [datasets, setDatasets] = useState({})
    const [allData, setAllData] = useState({})
    const [deviceParameters, setDeviceParameters] = useState({})
    const [dataChecked, setDataChecked] = useState([])
    const [firstKey, setFirstKey] = useState('')
    const [dtStart, setDtStart] = useState(utils.getDtStartForTypePeriod(defaultCurrentDateType).unix())
    const [dtEnd, setDtEnd] = useState(moment().unix())
    const [currentAgType, setCurrentAgType] = useState(localStorage.getItem('current_ag_type') || 'auto')
    const [currentDateType, setCurrentDateType] = useState(defaultCurrentDateType)
    const [dataLoaded, setDataLoaded] = useState(true)
    const [isChartNormal, setIsChartNormal] = useState(false)
    const [showChartPreviewModal, setShowChartPreviewModal] = useState(false)
    const [parameterChooserCounter, setParameterChooserCounter] = useState(0)

    useEffect(() => {
        // Load info in case device id is changed.
        if (props.selectedDevices.length == 0) {
            setData({ labels: [], datasets: [] })
            setDatasets({})
            setAllData({})
            setDeviceParameters({})
            return
        }

        let data_checked = [];

        props.selectedDevices.forEach((device) => {

            for (let dataCheckedIndex in dataChecked) {
                if (dataCheckedIndex.startsWith(device.device_id + '_')) {
                    data_checked[dataCheckedIndex] = dataChecked[dataCheckedIndex];
                }
            }
        })

        let datasets = [];
        const suffix = isChartNormal ? 'normal' : 'raw'
        Object.keys(data_checked).forEach((k) => {
            if (data_checked[k] && datasets[k]) {
                let dataset = datasets[k];
                dataset.data = allData[`${k}_${suffix}`]
                datasets.push(dataset)
            }
        })

        let _data = data;
        _data.datasets = datasets;
        const _firstKey = Object.keys(data_checked).length > 0 ? firstKey : undefined;
        console.log('before set first key, new:', _firstKey)

        setDataChecked(data_checked)
        setFirstKey(_firstKey)
        setData(_data)

        chartOptions.plugins.tooltip.callbacks.label = chartLabel


    }, [props.selectedDevices])


    const changeChartParameters = (v, key, device_id) => {
        // Updates list of checked parameters.
        let _dataChecked = dataChecked;
        _dataChecked[`${device_id}_${key}`] = v.target.checked;

        let datasets = [];
        const suffix = isChartNormal ? 'normal' : 'raw'
        Object.keys(_dataChecked).forEach((k) => {
            if (_dataChecked[k] && datasets[k]) {
                let dataset = datasets[k];
                dataset.data = allData[`${k}_${suffix}`]
                datasets.push(dataset)
            }
        })

        let _data = data;
        _data.datasets = datasets;

        setData(_data)
        setDataChecked(_dataChecked)
        setParameterChooserCounter(c => c + 1)
    }


    const loadDeviceData = async (device, dt_start, dt_end) => {
        setDataLoaded(false)
        const response = await loadDataForPeriod(device.device_id, dt_start, dt_end, currentAgType)

        let _deviceParameters = deviceParameters;
        _deviceParameters[device.device_id] = response.device_parameters
        setDeviceParameters(_deviceParameters)


        let labels = response.labels;
        let _datasets = datasets
        let _firstKey = firstKey
        let _allData = allData;

        const rdata = response.data;

        response.device_parameters.forEach((info) => {
            if (info.chart === false) {
                return;
            }
            let data = [];
            let data_normal = [];

            if (!rdata[info.name]) {
                return;
            }

            rdata[info.name].data.forEach((v) => {
                data.push(v.raw);
                data_normal.push(v.normal);
            })
            _allData[`${device.device_id}_${info.name}_normal`] = data_normal;
            _allData[`${device.device_id}_${info.name}_raw`] = data;
            _datasets[`${device.device_id}_${info.name}`] = {
                label: info.label,
                data: isChartNormal ? data_normal : data,
                borderColor: info.color,
                backgroundColor: info.color + '40',
                borderWidth: 1,
                pointRadius: 2,
                diff: rdata[info.name].diff,
                min: rdata[info.name].min,
                unit: info.units,
                device: `${device.name} ${device.serial_number}`,
            };
            if (!_firstKey) {
                _firstKey = `${device.device_id}_${info.name}`;
            }
        })

        showChart(_firstKey, _allData, labels, _datasets)
    }


    const showChart = (_firstKey, all_data, labels, _datasets) => {
        console.log('from show chart', firstKey, _firstKey)
        if (!firstKey) {
            console.log('from set first key')
            const data = {
                labels,
                datasets: [
                    _datasets[_firstKey],
                ],
            };
            let data_checked = {};
            data_checked[_firstKey] = true
            setData(data)
            setDataChecked(data_checked)
            console.log('before set first key, new:', _firstKey)

            setFirstKey(_firstKey)
        } else {
            const newDatasets = [];
            Object.keys(dataChecked).forEach((k) => {
                if (dataChecked[k]) {
                    newDatasets.push(_datasets[k])
                }
            });

            const data = {
                labels,
                datasets: newDatasets,
            };
            setData(data)

        }


        setDataLoaded(true)
        setAllData(all_data)
        setDatasets(_datasets)
    }


    const applyPeriod = (event, picker) => {
        setDtStart(picker.startDate.unix())
        setDtEnd(picker.endDate.unix())
        setCurrentDateType('custom')

        loadDataForAllDevices(picker.startDate.unix(), picker.endDate.unix());
    }

    const loadDataForTypePeriod = (type) => {
        const _dtEnd = moment();
        const _dtStart = utils.getDtStartForTypePeriod(type);

        setDtStart(_dtStart.unix())
        setDtEnd(_dtEnd.unix())
        setCurrentDateType(type)
        localStorage.setItem('current_date_type', type);

        loadDataForAllDevices(_dtStart.unix(), _dtEnd.unix());
    }


    /**
     * dt_start, dt_end - unix timestamp
     */
    const loadDataForAllDevices = (dt_start, dt_end) => {
        props.selectedDevices.forEach((device) => {
            loadDeviceData(device, dt_start, dt_end);
        })
    }

    const deleteDatasets = (device) => {
        let newDatasets = []
        let newDataChecked = {}
        let _data = data;

        Object.keys(dataChecked).forEach((k) => {
            if (!k.startsWith(device.device_id)) {
                newDatasets.push(datasets[k])
                newDataChecked[k] = true
            }
        })

        _data.datasets = newDatasets;
        setData(_data)
        setDataChecked(newDataChecked)
    }


    const getDatePickerParameters = () => {
        return {
            startDate: moment(dtStart * 1000).format('DD-MM-YYYY HH:mm'),
            endDate: moment(dtEnd * 1000).format('DD-MM-YYYY HH:mm'),
            timePicker24Hour: true,
            timePicker: true,
            drops: 'up',
            locale: {
                format: 'DD-MM-YYYY HH:mm',
            },
        }
    }

    const changeUnits = () => {
        const _isChartNormal = !isChartNormal
        chartOptions.scales.y.ticks.display = !isChartNormal

        let _data = data;
        let newDatasets = []

        Object.keys(dataChecked).forEach((k) => {
            let dataset = datasets[k];
            const suffix = _isChartNormal ? 'normal' : 'raw'
            dataset.data = allData[`${k}_${suffix}`]
            newDatasets.push(dataset)
        })

        _data.datasets = newDatasets;
        setData(_data)
        setIsChartNormal(_isChartNormal)
    }

    const chartLabel = (v) => {
        let value = v.raw;
        if (isChartNormal) {
            value = v.raw * v.dataset.diff + v.dataset.min;
        }
        return `${Math.round(value * 10) / 10} ${v.dataset.unit}`;
    }


    const getContainterClass = () => {
        let suffix = props.compareMode ? '66' : 'full';

        if (isMobile) {
            suffix = 'full';
        } else {
            if (!props.compareMode) {
                suffix = props.classShowDeviceInfo === '' ? '70' : 'full';
            } else {
                suffix = props.classShowMonitoringPanel === '' ? '66' : 'full';
            }
        }
        return `device-compares-container-${suffix}`;
    }


    const changeAggregationType = (atype) => {
        setCurrentAgType(atype)
    }

    useEffect(() => {
        localStorage.setItem('current_ag_type', currentAgType);
        loadDataForAllDevices(dtStart, dtEnd);
    }, [currentAgType])


    const containerClass = getContainterClass();


    return (
        <>
            <div>

                <div className={`mb-3 w-full flow-root`}>

                    {props.selectedDevices.map((device, i) => {

                        return <div className="float-left">
                            <ParameterChooser
                                key={`parameter_chooser_${i}_${dataChecked}`}
                                chooser_class={``}
                                deviceParameters={deviceParameters[device.device_id]}

                                show={''}
                                deleteDeviceFromCompare={() => {
                                    deleteDatasets(device)
                                    props.deleteDeviceFromCompare(device);
                                }}
                                device={device}
                                changeChartParameters={(v, key) => changeChartParameters(v, key, device.device_id)}
                                data_checked={dataChecked}
                            />
                        </div>
                    })}

                    <div data-tip data-for='choose-aggregation-type' className={`float-right`} data-event='click'>
                        <Button className="agro2-button">
                            Усреднение <img className="inline" src="/static_map/triangle_up.png" />
                        </Button>
                    </div>
                    <div data-tip data-for='choose-interval' className={`float-right`} data-event='click'>
                        <Button className="agro2-button">
                            Задать интервал <img className="inline" src="/static_map/triangle_up.png" />
                        </Button>
                    </div>
                </div>
                <div className={`flex mb-3`}>
                    <span className="mx-2">123</span>
                    <Tooltip title="Позволяет переключать режим просмотра графика между абсолютными и относительными значениями">
                        <Form.Check
                            onClick={changeUnits}
                            className="cursor-pointer"
                            type="switch"
                            id="custom-switch"
                            label=""
                        />
                    </Tooltip>
                    %
                    {!dataLoaded &&
                        <Spinner animation="border" size="sm" />
                    }
                </div>
                <div className={`${containerClass}`} key={`chart-div-echarts-${dataChecked}`}>
                    <ChartEcharts
                        key={`chart-echarts-${dataChecked}`}
                        data={data}
                        data_checked={dataChecked}
                        datasets={datasets}
                    />
                </div>

                <ReactTooltip
                    id='choose-interval'
                    aria-haspopup='true' effect='solid'
                    backgroundColor='#fff'
                    className="cc-toolip-dates"
                    clickable={true}
                    padding="1px 10px"
                    offset={{ top: -10, left: 0 }}
                    globalEventOff='click'
                >
                    {Object.keys(periodTypes).map((ptype, i) => {
                        if (ptype === 'custom') {
                            return (
                                <DateRangePicker
                                    key={`date-range-picker-${ptype}-${i}`}
                                    initialSettings={getDatePickerParameters()}
                                    onApply={applyPeriod}
                                >
                                    <div className="btn-date-period">
                                        {periodTypes[ptype]}
                                        {currentDateType == ptype &&
                                            <>&nbsp;<BsCheck /></>
                                        }
                                    </div>
                                </DateRangePicker>
                            )
                        }
                        return (
                            <div
                                key={`btn-${ptype}-${i}`}
                                className="btn-date-period" onClick={() => loadDataForTypePeriod(ptype)}>
                                {periodTypes[ptype]}
                                {currentDateType == ptype &&
                                    <>&nbsp;<BsCheck className='inline' /></>
                                }
                            </div>
                        )
                    })}
                </ReactTooltip>
                <ChooserAgType
                    className="chooser-ag-type"
                    changeAggregationType={changeAggregationType}
                    current_ag_type={currentAgType}
                />

            </div>
        </>
    )
}


export default Charts;

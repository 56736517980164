import { Col, Row } from 'react-bootstrap';
import * as consts from '../../consts';
import SpectrTable from '../common/SpectrTable';
import LastDataPanel from '../map/devices/last_data_panel';
import NdviComponent from '../map/device_info/plant/NDVI';

export interface AgroInfoProps {
    showDeviceById: () => {},
    data: any,
    deviceParameters: any
}

export default function AgroMainInfo({ showDeviceById, data, deviceParameters }: AgroInfoProps) {

    const parameters: any = consts.MODULE_INFO[consts.MODULE_TYPES.AGRO].parameters
    const waves: number[] = [610, 680, 730, 760, 810, 860];

    const info = data.info
    if (!info) {
        return <></>
    }

    info['last_parameter_values'] = data.data
    const dataForPanel = { info };
    return (
        <>
            <Row>
                <Col xs={6} md={6}>

                    <LastDataPanel
                        deviceParameters={deviceParameters}
                        parameters={parameters}
                        data={dataForPanel}
                    />
                </Col>

                <Col xs={6} md={6}>

                    <NdviComponent
                        data={data.data}
                    />
                    <hr className='my-5' />

                    <SpectrTable
                        data={data.data}
                        waves={waves}
                        prefix={'f'}
                    />
                </Col>

            </Row>
        </>
    );
}
import { Grid, Tooltip } from '@mui/material';
import DeviceTypeIcon from '../../../../model/device.type.icon';
import DeviceParameter from '../../../../model/device.parameter';
import { weatherIcons } from '../../../../consts'


export interface IconsProps {
    info: any,
    deviceParameters: Map<string, DeviceParameter>
}


export default function IconsWidget({ info, deviceParameters }: IconsProps) {
    return (
        <>
            <Grid gap={1} container className="pt-2 px-2 font-montserrat font-extrabold">
                {info.device_type_icons.map((devTypeIcon: DeviceTypeIcon) => {
                    if (!devTypeIcon.device_parameter_id || !deviceParameters) {
                        return null
                    }

                    let devParam: DeviceParameter = new DeviceParameter();
                    for (let entry of Array.from(Object.entries(deviceParameters))) {
                        let param = entry[1];
                        if (param.id === devTypeIcon.device_parameter_id) {
                            devParam = param
                            break
                        }
                    }

                    if (!devParam) {
                        return null
                    }


                    let isShowMax = true
                    if (devTypeIcon.condition_max_value !== null && devTypeIcon.condition_max_value !== undefined) {
                        isShowMax = devTypeIcon.condition_max_value > info['last_parameter_values'][devParam.name]
                    }
                    let isShowMin = true
                    if (devTypeIcon.condition_min_value !== null && devTypeIcon.condition_min_value !== undefined) {
                        isShowMin = devTypeIcon.condition_min_value < info['last_parameter_values'][devParam.name]
                    }

                    if (!isShowMin || !isShowMax) {
                        if (devTypeIcon.condition_min_value !== null && devTypeIcon.condition_min_value !== undefined
                            && devTypeIcon.condition_max_value !== null && devTypeIcon.condition_max_value !== undefined) {
                            const isEqual = devTypeIcon.condition_min_value === info['last_parameter_values'][devParam.name] &&
                                devTypeIcon.condition_max_value === info['last_parameter_values'][devParam.name]
                            if (!isEqual) {
                                return null
                            }
                        } else {
                            return null
                        }

                    }

                    let tooltipText = ''
                    weatherIcons.forEach((iconInfo) => {
                        if (iconInfo.icon === devTypeIcon.icon_file) {
                            tooltipText = iconInfo.description
                        }
                    })

                    return (
                        <Grid item xs={2} className="" key={`icon-${devTypeIcon.id}`}>
                            <Tooltip title={tooltipText}>
                                <div className='flex flex-col items-center gap-0.5'>
                                    <div className='w-4 h-4'>
                                        <img className='object-cover' src={`/weather_icons/${devTypeIcon.icon_file}`} />
                                    </div>

                                    <div className='max-w-[36px] overflow-hidden'>
                                        <div className='text-[7px] truncate font-light leading-none'>{tooltipText}</div>
                                    </div>
                                </div>
                            </Tooltip>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    );
}
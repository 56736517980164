import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

export const checkDeviceAccess = async (device_uuid) => {
    const headers = getHeaders()
    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/devices/check_access`,
		headers,
        params: {device_uuid},
	})

    return result.data.result
};

export default checkDeviceAccess
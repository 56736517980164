import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { BsXCircle } from "react-icons/bs";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 600,
    maxHeight: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '25px',
    zIndex: '99999999999999999 !important',
};

const EcoModal = (props) => {

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                disableScrollLock
            >
                <Box sx={{ ...style }}
                >
                    <p className="text-2xl mb-8">
                        {props.title}
                    </p>
                    <hr className="border-dashed my-6" />
                    <props.content
                        {...props}
                    />
                </Box>
            </Modal>
        </div>
    );
};

export default EcoModal;

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { BsCardImage } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";



export interface SaveExcelDialogProps {
	isCheckingAccess: boolean;
	excelDownloading: boolean;
	allNotAccessed: boolean;
	allAccessed: boolean;
	dtStart: number;
	dtEnd: number;
	saveDataToFile: () => void;
	saveDataToPngFile: () => void;
	onClose: () => void;
}


const SaveExcelDialog = ({ 
			isCheckingAccess, 
			excelDownloading, 
			allNotAccessed, 
			allAccessed, 
			dtStart, 
			dtEnd, 
			saveDataToFile, 
			saveDataToPngFile, 
			onClose }: SaveExcelDialogProps) => {

	if (isCheckingAccess || excelDownloading) {
		return (
			<p className="w-full flex justify-center items-center">
				<CircularProgress />
			</p>
		)
	}


	if (allNotAccessed) {
		return (
			<p className="w-full flex justify-center items-center font-montserrat">
				<Alert severity="error">Владельцы выбранных устройств не предоставили доступ к скачиванию данных.</Alert>
			</p>
		)
	}



	return (
		<>
			{!allAccessed &&
				<Alert severity="warning" className="mb-5">Некоторые из выбранных устройств не доступны для скачивания данных!</Alert>
			}
			<p className="font-montserrat text-base font-semibold">
				Период сохранения:
			</p>
			<p className="font-montserrat text-xl">
				{moment(dtStart * 1000).format('YYYY-MM-DD HH:mm:SS')} -
				{moment(dtEnd * 1000).format('YYYY-MM-DD HH:mm:SS')}
			</p>
			<p className="mt-4 font-montserrat text-base font-semibold">
				Выберите тип файла:
			</p>

			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
						m: 1,
						width: 128,
						height: 128,
					},
				}}
			>
				<Tooltip title={'Файл в формате xlsx'}>
					<Paper elevation={3}
						className="flex justify-center items-center cursor-pointer"
						onClick={() => saveDataToFile()}
					>
						<SiMicrosoftexcel className="text-5xl" />
					</Paper>
				</Tooltip>

				<Tooltip title={'Изоборажение в формет png.'}>
					<Paper elevation={3}
						className="flex justify-center items-center cursor-pointer"
						onClick={() => saveDataToPngFile()}
					>
						<BsCardImage className="text-5xl" />
					</Paper>
				</Tooltip>


			</Box>

			<hr className="border-dashed my-6" />
			<p className="w-full flex justify-end">
				<button className="ml-5 lr-secondary-button" onClick={onClose}>
					Закрыть
				</button>
			</p>
		</>
	)
}

export default SaveExcelDialog;


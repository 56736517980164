import { io } from 'socket.io-client';
import loadLastBirdObservation from '../../../../api/devices/load.last.bird.observation';
import ReactEcharts from "echarts-for-react";
import 'echarts/i18n/langRU';
import { useEffect, useState, useRef } from 'react';
import getBarChartOptions from './get_chart_bar_options';

const BirdLastRecognitionRows = (props) => {

    const [birdNames, setBirdNames] = useState([])
    const [birdConfidence, setBirdConfidence] = useState([])
    const [chartOption, setChartOption] = useState(undefined)
    const eChartsRef = useRef(null);

    useEffect(() => {
        const socket = io.connect('/');

        socket.on("new_bird_observation", (message) => {
            updateData(message)
        })
    }, [])


    useEffect(() => {
        loadLastRows(props.device_id)
    }, [props.device_id])


    const updateData = (message) => {
        birdNames.unshift(`${message.name} (${message.mstime})`)
        birdConfidence.unshift(message.confidence)

        if (birdNames.length > 5) {
            birdNames.pop()
            birdConfidence.pop()
        }
        if (eChartsRef && eChartsRef.current) {
            eChartsRef.current?.getEchartsInstance().setOption(getBarChartOptions(birdNames, birdConfidence));
        }
    }


    const loadLastRows = async (device_id) => {
        const lastRows = await loadLastBirdObservation(device_id)
        const birdNames = lastRows.map((row) => `${row.name} (${row.mstime})`)
        const birdConfidence = lastRows.map((row) => row.confidence)
        setBirdNames(birdNames)
        setBirdConfidence(birdConfidence)
        setChartOption(getBarChartOptions(birdNames, birdConfidence))

    }

    return (
        <div>
            {chartOption &&
                <ReactEcharts
                    option={chartOption}
                    opts={{ locale: 'RU' }}
                    key={`react-echart-bird-confidence`}
                    ref={eChartsRef}
                />
            }
        </div>
    )
}

export default BirdLastRecognitionRows;

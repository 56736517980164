import ReactEcharts from 'echarts-for-react';
import { useEffect, useState, useRef } from "react";
import moment from 'moment';
import { rgbToHex } from '@material-ui/core';


export interface CrownSpectrChartProps {
    rawData: number[][]
}

const CrownSpectrChart = ({ rawData }: CrownSpectrChartProps) => {

    let xData: number[] = [];
    let yData: number[] = [];

    let max = -100;
    let min = 100

    function generateData() {
        let data: number[][] = [];
        for (let i = 0; i < rawData.length; i++) {
            for (let j = 0; j < rawData[0].length; j++) {
                data.push([i, j, rawData[i][j]]);
                max = Math.max(max, rawData[i][j])
                min = Math.min(min, rawData[i][j])
            }
            xData.push(i);
        }
        for (let j = 0; j < rawData[0].length; j++) {
            yData.push(j);
        }

        return data;
    }
    let data = generateData();

    const [options, setOptions] = useState<any>()


    useEffect(() => {
        const options = createChartOptins()
        setOptions(options)
    }, [])


    const createChartOptins = () => {

        return {
            title: {
                text: 'Распределение температуры тепловизионной камеры',
                textStyle: {
                    fontSize: 12,
                },
                itemGap: 10
            },
            tooltip: {},
            xAxis: {
                type: 'category',
                data: xData
            },
            yAxis: {
                type: 'category',
                data: yData
            },
            visualMap: {
                min: min,
                max: max,
                calculable: true,
                realtime: false,
                inRange: {
                    color: [
                        '#00003c',
                        '#0025ef',
                        '#008cff',
                        '#09ff7f',                        
                        '#3eff00',
                        '#2cff1a',
                        '#d8ff00',
                        '#ffa500',
                        '#fe3500'
                    ]
                },
                orient: 'horizontal',
                right: '25%',
                left: '20%',
            },
            series: [
                {
                    name: 'Температура',
                    type: 'heatmap',
                    data: data,
                    emphasis: {
                        itemStyle: {
                            borderColor: '#333',
                            borderWidth: 1
                        }
                    },
                    progressive: 1000,
                    animation: false
                }
            ]

        };


    }

    return (
        <>
            {options &&
                <ReactEcharts
                    key={`seismic-echart`}
                    option={options}
                    style={{ height: `500px`, width: '70%' }}
                />
            }
        </>
    );
}

export default CrownSpectrChart;
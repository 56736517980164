import { DataGrid, gridClasses, GridToolbar, ruRU } from '@mui/x-data-grid';
import Badge from 'react-bootstrap/Badge';
import { useNavigate } from "react-router-dom";
import { withRouter } from '../../withRouter';

const columns = [
	{
		field: 'device',
		headerName: 'Название',
		flex: 1,
		renderCell: (param) => {
			const device = param.value
			const sn = device.serial_number ? device.serial_number : '--';
			return (
				<p>
					{device.name} <br />
					s/n: {sn}<br />
					{device.is_public == 0 &&
						<Badge bg="secondary">не публично</Badge>
					}
					{device.is_public == 1 &&
						<Badge bg="success">публично</Badge>
					}
				</p>
			)
		},
	},
	{
		field: 'description',
		headerName: 'Описание',
		flex: 1,
	},
	{
		field: 'location',
		headerName: 'Местоположение',
		flex: 1,
	},
	{
		field: 'name',
	},
	{
		field: 'sn',
	}
];

const DevicesTable = (props) => {
	const navigate = useNavigate();

	const rows = []
	props.devices.forEach((device) => {
		const sn = device.serial_number ? device.serial_number : '--';

		const row = {
			'id': device.device_id,
			'device': device,
			'description': device.description,
			'location': device.location_description,
			'name': device.name,
			'sn': sn,
			'uuid': device.uuid,
		}
		rows.push(row)
	})

	const onRowClick = (row) => {
		console.log('from row click', row.row.uuid)
		navigate(`/admin/device/${row.row.uuid}/`);
	}


	return (
		<>
			<DataGrid
				localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
				getRowHeight={() => 'auto'}
				className='h-full cursor-pointer'
				rows={rows}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 100,
						},
					},
					filter: {
						filterModel: {
							items: [],
							quickFilterValues: [''],
						},
					},
					columns: {
						columnVisibilityModel: {
							name: false,
							sn: false,
						},
					},
				}}
				pageSizeOptions={[5, 10, 50, 100]}
				disableRowSelectionOnClick
				disableColumnFilter
				disableDensitySelector
                disableColumnSelector
                disableColumnMenu
                
				autoHeight={true}
				onRowClick={onRowClick}
				sx={{
					[`& .${gridClasses.cell}`]: {
						py: 1,
					},
				}}
				slots={{
					toolbar: GridToolbar
				}}
				slotProps={{
					toolbar: {
						csvOptions: { disableToolbarButton: true },
						printOptions: { disableToolbarButton: true },

						showQuickFilter: true,
						quickFilterProps: { debounceMs: 500 },
						disableRowSelectionOnClick: true,

					},
				}}
			/>
		</>
	);

}

export default withRouter(DevicesTable);

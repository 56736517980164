import { TextField } from '@mui/material';
import { Component } from 'react';
import { Form } from 'react-bootstrap';
import saveCameraSettings from '../../../api/devices/save.camera.settings';
import { toast } from 'react-toastify';


class UpdateAISettingsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            ...props.device,
        }
    }

    saveSettings = async () => {
        // Save data.
        this.setState({ isSaving: true })
        const httpCode = await saveCameraSettings({ ...this.state })

        if (httpCode === 200) {
            toast.success('Сохранение прошло успешно')
        }
        this.setState({ isSaving: false })
        
    }

    render() {
        return (
            <>
                <Form>
                    <TextField
                        className="mt-5"
                        fullWidth
                        name="name"
                        value={this.state.rtmp_server_stream}
                        size="small"
                        label="RTMP url для трансляции"
                        onChange={(event) => this.setState({ rtmp_server_stream: event.target.value })}
                        inputProps={{ style: { boxShadow: '0 0 #0000' } }}

                    />
                </Form>

                <hr className="border-dashed my-6" />
                <p className='text-right'>
                    <button className="lr-secondary-button" onClick={this.props.handleClose}>
                        Закрыть
                    </button>
                    <button className="ml-5 lr-primary-button" onClick={this.saveSettings}
                        disabled={this.state.isSaving}

                    >
                        {this.state.isSaving ? 'Сохранение...' : 'Сохранить'}
                    </button>
                </p>
            </>
        );
    }
}

export default UpdateAISettingsForm;

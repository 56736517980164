import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

export const getCameraRoiSettings = async (device_id) => {

	const headers = getHeaders()
    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/devices/get_camera_roi_settings`,
		headers,
        params: {device_id},
	})

    return result.data.settings
};

export default getCameraRoiSettings;
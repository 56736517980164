import { Grid } from '@mui/material';
import Param from './city_parameter';


export interface GasWidgetProps {
    showDeviceById: () => {},
    info: any,
}

export default function GasWidget({ showDeviceById, info }: GasWidgetProps) {

    if (!info.last_parameter_values) {
        return <></>
    }

    return (
        <>
            <Grid container spacing={2}>
                <Param
                    icon="/widgets_icons/temperature.svg"
                    value={info.last_parameter_values.temp}
                    units="°"
                    divClass="ecosystem-center-data"
                    unitClass="city-parameter-units-lg"
                    data_key="temp"
                />
                <Param
                    icon="/widgets_icons/hum.svg"
                    value={info.last_parameter_values.hum}
                    units="%"
                    divClass="ecosystem-right-data"
                    data_key="hum"
                />
            </Grid>
        </>
    );
}
import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

export const refreshSeismicImagesForDay = async (day: string, statioinId: number) => {

	const headers = getHeaders()

	const response = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/seismicevents/refresh_seismic_images`,
		headers,
		params: {
            day,
            station_id: statioinId
		},
	})

	return response
};

export default refreshSeismicImagesForDay;
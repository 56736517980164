import { useEffect, useRef, useState } from "react";
import getCameraRoiSettings from '../../../api/devices/get.camera.roi.settings';
import * as consts from '../../../consts';

function AiCanvas(props) {

    const [img, setImg] = useState('');
    const [box, setBox] = useState({ x: 10, y: 10, w: 100, h: 100 });

    const canvasRef = useRef();
    let ctx = null;

    let isDown = false;
    let dragTarget = null;
    let startX = null;
    let startY = null;
    let isResize = false;
    let isDownMouse = false;
    let isResizeY = false;

    useEffect(() => {

        const fetchSettings = async () => {
            const settings = await getCameraRoiSettings(props.device_id)

            
            if (settings.settings_roi_file) {
                const url = `${consts.BASE_API_URL}/devices/get_camera_roi_settings_file?device_id=${props.device_id}`

                var img = document.createElement('img');
                img.src = url;

                setImg(url)
                img.onload = () => {
                    const ctx = canvasRef.current.getContext("2d");
                    ctx.canvas.width = img.width;
                    ctx.canvas.height = img.height;
                    drawRectangle()
                    if (settings.area_x1) {
                        setBox({ x: settings.area_x1, y: settings.area_y1, w: settings.area_x2, h: settings.area_y2 })
                    }
        
                }
            }

        }
        fetchSettings()

    }, [])


    useEffect(() => {
        if (props.img) {
            setImg(props.img)
        }
    }, [props.img])


    useEffect(() => {
        drawRectangle()
    }, [box])

    // identify the click event in the rectangle
    const hitBox = (x, y) => {
        if (x >= box.x && x <= box.x + box.w && y >= box.y && y <= box.y + box.h) {
            dragTarget = box;
            return true;
        }
        return false;
    }

    const handleMouseDown = e => {
        isDownMouse = true
        startX = parseInt(e.nativeEvent.offsetX - canvasRef.current.clientLeft);
        startY = parseInt(e.nativeEvent.offsetY - canvasRef.current.clientTop);


        isResize = checkRightBundle(e)
        if (!isResize) {
            isResizeY = checkBottomBundle(e)

            if (!isResizeY) {
                isDown = hitBox(startX, startY);
            }
        }
    }


    const handleMouseMove = e => {
        const onBottomRigth = checkRightBundle(e)
        if (!onBottomRigth)
            checkBottomBundle(e)


        if (isResize) {
            const mouseX = parseInt(e.nativeEvent.offsetX - canvasRef.current.clientLeft);
            const mouseY = parseInt(e.nativeEvent.offsetY - canvasRef.current.clientTop);
            const dx = mouseX - startX;
            const dy = mouseY - startY;
            startX = mouseX;
            startY = mouseY;
            dragTarget.w += dx;
            drawRectangle();
            return
        }

        if (isResizeY) {
            const mouseX = parseInt(e.nativeEvent.offsetX - canvasRef.current.clientLeft);
            const mouseY = parseInt(e.nativeEvent.offsetY - canvasRef.current.clientTop);
            const dx = mouseX - startX;
            const dy = mouseY - startY;
            startX = mouseX;
            startY = mouseY;
            dragTarget.h += dy;
            drawRectangle();
            return
        }

        if (isDown) {
            const mouseX = parseInt(e.nativeEvent.offsetX - canvasRef.current.clientLeft);
            const mouseY = parseInt(e.nativeEvent.offsetY - canvasRef.current.clientTop);
            const dx = mouseX - startX;
            const dy = mouseY - startY;
            startX = mouseX;
            startY = mouseY;
            dragTarget.x += dx;
            dragTarget.y += dy;
            drawRectangle();
        }

    }


    const checkRightBundle = (e) => {
        const x = parseInt(e.nativeEvent.offsetX - canvasRef.current.clientLeft);
        const y = parseInt(e.nativeEvent.offsetY - canvasRef.current.clientTop);

        const rightBundle = box.x + box.w
        if ((rightBundle - 10) <= x && (rightBundle - 5) >= x) {
            dragTarget = box;
            canvasRef.current.style.cursor = 'w-resize';
            return true;
        } else {
            canvasRef.current.style.cursor = 'default';
            return false
        }

    }

    const checkBottomBundle = (e) => {
        const x = parseInt(e.nativeEvent.offsetX - canvasRef.current.clientLeft);
        const y = parseInt(e.nativeEvent.offsetY - canvasRef.current.clientTop);

        const bottomBundle = box.y + box.h
        if ((bottomBundle - 10) <= y && (bottomBundle - 5) >= y) {
            dragTarget = box;
            canvasRef.current.style.cursor = 'n-resize';
            return true;
        } else {
            canvasRef.current.style.cursor = 'default';
            return false
        }

    }

    const handleMouseUp = e => {
        dragTarget = null;
        isDown = false;
        isDownMouse = false

        isResize = false;
        isResizeY = false;
        canvasRef.current.style.cursor = 'default';
    }


    const handleMouseOut = e => {
        handleMouseUp(e);
    }


    const drawRectangle = () => {
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.clientWidth, canvasRef.current.clientHeight);

        context.strokeStyle = "white";
        context.lineWidth = 3;

        context.strokeRect(box.x, box.y, box.w, box.h);
        props.onUpdateBox(box)
    };


    useEffect(() => {
        const ctx = canvasRef.current.getContext("2d");
        ctx.canvas.width = props.width;
        ctx.canvas.height = props.height;

        drawRectangle();
    }, [props.width, props.height]);


    return (
        <div>
            <canvas
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseOut={handleMouseOut}
                ref={canvasRef}
                style={{
                    background: `no-repeat url('${img}')`,
                }}
            />
        </div>
    );
}

export default AiCanvas
import { Snackbar } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { User } from "../../../model/profile";
import EcoModal from '../../eco_modal';
import EditProfileForm from './EditProfileForm';

export interface ProfileInfoProps {
    user: User;
}


export default function ProfileInfo({ user }: ProfileInfoProps) {
    const [showUpdateProfileForm, setShowUpdateProfileForm] = useState<boolean>(false)

    const openProfileChangedForm = () => {
        console.log('from open')
        setShowUpdateProfileForm(true)
    }

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };

    const afterSaveSuccess = () => {
        notify('Сохранение прошло успешно.')
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }


    return (
        <>
            <List component="nav" className="pl-16">
                <ListItemButton>
                    <ListItemText primary={`Имя`} secondary={`${user.name}`} />
                </ListItemButton>
                <ListItemButton>
                    <ListItemText primary={`Фамилия`} secondary={`${user.second_name}`} />
                </ListItemButton>
                <ListItemButton>
                    <ListItemText primary={`EMail`} secondary={`${user.email}`} />
                </ListItemButton>
                <ListItemButton>
                    <ListItemText primary={`Телефон`} secondary={`${user.phone}`} />
                </ListItemButton>
                <ListItemButton>
                    <ListItemText primary={`Организация`} secondary={`${user.organization}`} />
                </ListItemButton>
            </List>


            <hr className="border-dashed my-6" />
            <p className='text-right'>
                <button className="ml-5 lr-primary-button" onClick={openProfileChangedForm}
                >
                    Изменить
                </button>
            </p>



            <EcoModal
                key="addupdate-modal"
                open={showUpdateProfileForm}
                userForEdit={user}
                handleClose={() => setShowUpdateProfileForm(false)}
                content={EditProfileForm}
                title={`Редактирование профиля`}
                afterSaveSuccess={afterSaveSuccess}
            />

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />

        </>
    );
}
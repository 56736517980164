import DeviceParameter from "./device.parameter";

class DeviceAlertType {
    id: number = NaN;
    device_id: number = NaN;
    parameter_id: number = NaN;
    value: string = '';
    type: number = NaN;

    name: string = '';
    description: string = '';

    parameter: DeviceParameter = new DeviceParameter();
    is_visible_for_all: number = 0;
    emails: string = '';

}

export default DeviceAlertType;

export enum AlertContidionType {
    Less = 0,
    Greater = 1,
    Equal = 2,
}

export const AlertConditionLabels = {
    0: '<',
    1: '>',
    2: '=',
    3: '<=',
    4: '>=',
}
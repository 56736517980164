import { Grid } from "@mui/material";

const LeftPart = () => {


    return (

        <Grid item xs={12} sm={3}  md={3}>
            <div className="ml-0">
                <p className="cover-header">
                    УМНЫЕ ЭКОСИСТЕМЫ
                </p>
                <p className="cover-text">
                    Сервис мониторинга <br />
                    среды и природы
                </p>
                <p className="cover-text">
                    Environment & Wildlife<br /> Smart Monitoring Service
                </p>
            </div>
        </Grid>

    );
}

export default LeftPart
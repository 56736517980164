import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import DeviceParametersGroup from '../../model/device.parameters.group';


export const getDeviceParametersGroups = async (): Promise<DeviceParametersGroup[] | null> => {

	try {

		const headers = getHeaders()

		const result = await axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/devices/get_device_parameters_groups`,
			headers,
		})

		return result.data.groups as DeviceParametersGroup[]
	} catch (error: any) {
		console.error(error);

		if (error.response.status === 401) {
			return null
		}

	}
	return null

};

import { Grid } from "@mui/material";
import LeftPart from "./left_part";

const CoverPage4 = () => {


    return (

        <Grid container spacing={2}>
            <Grid item xs={1}></Grid>

            <LeftPart />

            <Grid xs={4}>
                <div className="relative">

                    <img src="/landing/assets/img/copter.png" className="copter-image-header" />
                    <div className="rc-header">
                        <video src="/landing/assets/video/video_04.mp4" autoPlay width="870" height="870"
                            className="video-circle" />
                    </div>
                </div>
            </Grid>
            <Grid xs={3}>
                <div className="mr-0 text-right">

                    <p className="cover-header">
                        04 | Аэромониторинг
                    </p>
                    <p className="cover-text">
                        Выполняем <br />
                        профессиональную<br />
                        аэрофотосъемку с БПЛА<br />
                    </p>
                    <p className="more-link-text">
                        <a className="more-link-text" href="#part04">Узнать подробнее</a>
                    </p>
                </div>
            </Grid>
            <Grid item xs={1}></Grid>

        </Grid>
    );
}

export default CoverPage4
import React from 'react';
import axios from "axios";
import * as consts from '../../consts';
import Token from '../token_v2';
import { BsXCircle } from "react-icons/bs";
import EcoModal from '../eco_modal';
import RegistrationForm from '../registrationform/RegistrationForm';
import EcoCustomNotification from '../common/EcoCustomNotification';

interface LoginFormProps {
	close: () => void,
	afterLogin?: () => void,
}

export default function LoginForm({ close, afterLogin }: LoginFormProps) {

	const [login, setLogin] = React.useState<string>('')
	const [password, setPassword] = React.useState<string>('')
	const [token, setToken] = React.useState<Token>(new Token())
    const [showRegistrationForm, setShowRegistrationForm] = React.useState(false);
	const [notifyMessage, setNotifyMessage] = React.useState<string>('')
	const [errorMessage, setErrorMessage] = React.useState<string>('')

	

	const clickLogin = () => {

		axios({
			method: "POST",
			url: `${consts.BASE_API_URL}/token`,
			data: {
				login: login,
				password: password
			}
		})
			.then((response) => {
				token.saveToken(response.data.access_token)
				localStorage.setItem('user.login', login)

				setNotifyMessage("Вход в систему выполнен!")
				setTimeout(() => {
					close()
					if (afterLogin) {
						afterLogin()
					}
				}, 1000);
				

			}).catch((error) => {
				if (error.response) {
					console.log(error.response)
					console.log(error.response.status)
					console.log(error.response.headers)
				}
				setErrorMessage("Неверный логин или пароль!")
			})

	}

	const clickShowRegistrationForm = () => {
		setShowRegistrationForm(true)
	}

    const RegistrationModalForm = () => {
        return (
            <EcoModal
                key="registration-modal"
                open={showRegistrationForm}
                handleClose={() => setShowRegistrationForm(false)}
                content={RegistrationForm}
                title={``}
            />
        )
    }


	return (
		<>
			<div className="login-header">
				<BsXCircle onClick={close} className="close-icon" />
				<img src="/brand_image_dark.svg" alt="" width="50%" />
			</div>
			<div className="login-header">
				Добро пожаловать
			</div>
			<div className="self-modal-content">
				<input
					type="input" name="login"
					onChange={(event) => setLogin(event.target.value) }
					value={login}
					className="input-field"
					placeholder="Введите логин"
				/>
				<input
					type="password" name="password"
					onChange={(event) => setPassword(event.target.value) }
					value={password}
					className="input-field"
					placeholder="Введите пароль"
				/>
			</div>
			<div className="self-modal-content">
				<div className="self-modal-content-buttons">
					<button className="f-button" onClick={clickLogin}>Войти</button>
				</div>
			</div>
			<div className="login-footer">
				<div className="login-footer-q">
					Ещё не зарегистрированы?
				</div>
				<div className="login-footer-link" onClick={clickShowRegistrationForm}>
					Зарегистрироваться
				</div>
			</div>

            <RegistrationModalForm />

			<EcoCustomNotification 
				message={notifyMessage}
				errorMessage={errorMessage}
			/>
		</>
	);
}


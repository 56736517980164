import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import DeviceAdditionFields from '../../model/device.addition.fields';


export const alertToArchive = async (alertId: number): Promise<number> => {

    const headers = getHeaders()

    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/devices/device_alert_to_archive`,
		headers,
        params: {
            alert_id: alertId,
		},
	})

    return result.data.result
};

import React from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";

class MapComponent extends React.Component {
    helpDiv;

    createButtonControl() {
	const button = L.Control.extend({
	    onAdd: (map) => {
		this.helpDiv = this.props.element()

		this.helpDiv.addEventListener(this.props.evnt, () => {
		    this.props.onClick()
		});

		return this.helpDiv;
	    }
	});
	const position = this.props.position ? this.props.position : "verticalcenterright"
	return new button({ position });
    }

    componentDidMount() {
	const { map } = this.props;
	const control = this.createButtonControl();
	control.addTo(map);
    }

    componentWillUnmount() {
	this.helpDiv.remove();
    }

    render() {
	return null;
    }
}


function withMap(Component) {
    return function WrappedComponent(props) {
	const map = useMap();
	return <Component {...props} map={map} />;
    };
}

export default withMap(MapComponent);


import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import { User } from '../../model/profile';

export const getProfile = async (): Promise<User> => {

	const headers = getHeaders()

    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/profile`,
		headers
	})

    return result.data.user as User;
};

export default getProfile;
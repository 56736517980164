import { Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { FaEdit } from "react-icons/fa";
import DeviceParameter from '../../../model/device.parameter';
import DeviceTypeIcon from "../../../model/device.type.icon";
import EcoModal from '../../eco_modal';
import EditIconForm from './EditIconForm';
import RemoveForm from '../resources/remove_modal';
import { BsFillTrashFill } from "react-icons/bs";

interface DeviceTypeIconsTabProps {
    deviceTypeIcons: DeviceTypeIcon[]
    deviceParameters: DeviceParameter[]
    deviceTypeId?: string | undefined
    moduleTypeId?: string | undefined
}

export default function DeviceTypeIconsTab({ deviceTypeIcons, deviceParameters, deviceTypeId, moduleTypeId }: DeviceTypeIconsTabProps) {

    const [showAddUpdateForm, setShowAddUpdateForm] = useState<boolean>(false)
    const [iconForEdit, setIconForEdit] = useState<DeviceTypeIcon>(new DeviceTypeIcon)
    const [_deviceTypeIcons, setDeviceTypeIcons] = useState<DeviceTypeIcon[]>(deviceTypeIcons)
    const initialState = useRef<boolean>(false)
    const [showRemoveForm, setShowRemoveForm] = useState(false)
    const [eventIdForRemove, setEventIdForRemove] = useState<number>(0)

    const showAddForm = () => {
        setIconForEdit(new DeviceTypeIcon)
    }

    const showEditForm = (icon: DeviceTypeIcon) => {
        setIconForEdit(Object.assign({}, icon))
    }

    useEffect(() => {
        if (!initialState.current) {
            initialState.current = true;
            return
        }
        setShowAddUpdateForm(true)
    }, [iconForEdit])


    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };



    const afterSaveSuccess = (icon: DeviceTypeIcon) => {
        const newDeviceTypeIcons: DeviceTypeIcon[] = []
        let isInserted = false
        deviceTypeIcons.forEach((typeIcon: DeviceTypeIcon) => {
            if (typeIcon.id == icon.id) {
                newDeviceTypeIcons.push(icon)
                isInserted = true
                return
            }
            newDeviceTypeIcons.push(typeIcon)
        })
        if (!isInserted) {
            newDeviceTypeIcons.push(icon)
        }
        setDeviceTypeIcons(newDeviceTypeIcons)
        setShowAddUpdateForm(false)
        notify('Информация о размещении иконки сохранена успешно.')

    }


    const showModalRemoveResource = (event_id: number) => {
        setEventIdForRemove(event_id)
        setShowRemoveForm(true)
    }

    return (
        <>
            <button className="lr-primary-button mb-3" onClick={() => showAddForm()}>Добавить новую иконку</button>

            <EcoModal
                key="addupdate-modal"
                open={showAddUpdateForm}
                iconForEdit={iconForEdit}
                deviceParameters={deviceParameters}
                handleClose={() => setShowAddUpdateForm(false)}
                content={EditIconForm}
                title={`Редактирование информации об иконке на виджета`}
                deviceTypeId={deviceTypeId}
                moduleTypeId={moduleTypeId}
                afterSaveSuccess={afterSaveSuccess}
            />

            <TableContainer className="mx-auto" component={Paper}>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell key="th-ifonfile">Иконка</TableCell>
                            <TableCell key="th-parameter">Параметр</TableCell>
                            <TableCell key="th-conditions">Условия</TableCell>
                            <TableCell key="th-actions"></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody className="min-h-[275px]">
                        {_deviceTypeIcons.map((devIcon: DeviceTypeIcon) => {
                            return (
                                <TableRow key={`${devIcon.id}`}>

                                    <TableCell key={`ifonfile-${devIcon.id}`}>
                                        <img src={`/weather_icons/${devIcon.icon_file}`} />
                                    </TableCell>

                                    <TableCell key={`parameter-${devIcon.id}`}>
                                        {deviceParameters.map((devParameter) => {
                                            if (devParameter.id !== devIcon.device_parameter_id) {
                                                return null
                                            }
                                            return (<>
                                                {devParameter.name} ({devParameter.label}, {devParameter.units})
                                            </>)
                                        })}
                                    </TableCell>

                                    <TableCell key={`conditions-${devIcon.id}`}>
                                        {devIcon.condition_min_value !== null && devIcon.condition_min_value !== undefined &&
                                            <>
                                                &gt; {devIcon.condition_min_value}
                                            </>
                                        }
                                        <br />
                                        {devIcon.condition_max_value !== null && devIcon.condition_max_value !== undefined &&
                                            <>
                                                &lt; {devIcon.condition_max_value}
                                            </>
                                        }
                                    </TableCell>

                                    <TableCell key={`actions-${devIcon.id}`}>
                                        <Tooltip title="Редактировать иконку на виджете">
                                            <div className="inline-block">
                                                <FaEdit className="text-2xl inline cursor-pointer" onClick={() => showEditForm(devIcon)} />
                                            </div>
                                        </Tooltip>

                                        <Tooltip title="Удалить событие">
                                            <div className="inline-block">
                                                <BsFillTrashFill onClick={() => showModalRemoveResource(devIcon.id)} className="text-2xl inline cursor-pointer text-red-500" />
                                            </div>
                                        </Tooltip>


                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />

            <RemoveForm
                show={showRemoveForm}
                handleClose={() => setShowRemoveForm(false)}
                id={eventIdForRemove}
                endpoint="devices/remove_device_type_icon"
                successLabel="Информация об иконке успешно удалена!"
            />

        </>
    );
}
import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';


export const checkUserIsAdmin = async (): Promise<boolean> => {

	const headers = getHeaders()

    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/check_user_is_admin`,
		headers
	})

    return result.data.is_admin as boolean;
};

export default checkUserIsAdmin;
import ReactEcharts from 'echarts-for-react';
import { useEffect, useState, useRef } from "react";
import moment from 'moment';

const directions = {
    0: 'N',
    1: 'E',
    2: 'Z',
}

const SeismicHourChart = (props) => {

    const chartRef = useRef();

    const [options, setOptions] = useState(undefined)

    const [pointsInfo, setPointsInfo] = useState([])
    const [zoomStart, setZoomStart] = useState(0)
    const [zoomEnd, setZoomEnd] = useState(100)

    const chartHeight = 400;

    useEffect(() => {
        if (!chartRef || !chartRef.current) {
            return
        }
        const instance = chartRef.current.getEchartsInstance();
        const zr = instance.getZr();

        if (zr) {
            console.log('ffom change point 2:', props.pointForChanged)

            if (props.pointForChanged) {
                zr.on('click', (params) => {
                    console.log('ffom change point:')
                    const pointInPixel = [params.offsetX, params.offsetY];
                    const pointInGrid = instance.convertFromPixel('grid', pointInPixel);
                    if (pointInGrid.length == 0) {
                        return
                    }

                    let newSTimestamp = props.date.startOf('hour').unix() * 1000 + 20 * pointInGrid[0]
                    const seriesIndex = Math.floor(params.offsetY / (chartHeight / 3))

                    const _pointsInfo = JSON.parse(JSON.stringify(pointsInfo))
                    if (_pointsInfo.length == 0) {
                        _pointsInfo.push({
                            station_id: props.device_id,
                        })
                    }
                    _pointsInfo[0][`timestamp_${props.pointForChanged}`] = newSTimestamp
                    _pointsInfo[0][`direction_${props.pointForChanged}`] = directions[seriesIndex]
                    const options = createChartOptins(props.chartData, _pointsInfo)
                    setOptions(options)
                    setPointsInfo(_pointsInfo)
                    if (props.updatePointsInfo) {
                        props.updatePointsInfo(_pointsInfo)
                    }

                });
            } else {
                zr.off('click')
            }
        }
    }, [props.pointForChanged]);


    useEffect(() => {
        const pointsInfo = props.seismicEvent ? getPointsInfo(props.seismicEvent.points[props.device_id]) : []
        const options = createChartOptins(props.chartData, pointsInfo)
        setOptions(options)
        setPointsInfo(pointsInfo)
    }, [props.chartData]);


    const getPointsInfo = (points) => {
        const pointsInfo = []
        if (!points) {
            return pointsInfo
        }
        points.forEach((point) => {
            const info = {
                direction_p: point.point_p_direction,
                direction_s: point.point_s_direction,
                timestamp_p: point.timestamp_point_p,
                timestamp_s: point.timestamp_point_s,
                station_id: props.device_id,
                id: point.id,
            }
            pointsInfo.push(info)
        })

        return pointsInfo
    }


    const createChartOptins = (chartData, points) => {
        let labels = [], n = [], e = [], z = [];


        if (chartData) {
            let dtStart = props.date.startOf('hour').unix() * 1000

            const data = chartData.split(',');
            let index = 0;
            data.forEach((el) => {
                if (index % 3 == 0) {
                    n.push(el)
                    labels.push(dtStart)
                    dtStart += 20
                }
                if (index % 3 == 1) e.push(el)
                if (index % 3 == 2) z.push(el)

                index++;
            })
        }

        const markLinesN = []
        const markLinesE = []
        const markLinesZ = []
        if (points) {
            points.forEach((point) => {
                const markLineP = createMarkLine(point.timestamp_p, 'P', 'red')
                const markLineS = createMarkLine(point.timestamp_s, 'S', 'blue')

                switch (point.direction_p) {
                    case "Z":
                        markLinesZ.push(markLineP)
                        break;
                    case "N":
                        markLinesN.push(markLineP)
                        break;
                    case "E":
                        markLinesE.push(markLineP)
                        break;
                }

                switch (point.direction_s) {
                    case "Z":
                        markLinesZ.push(markLineS)
                        break;
                    case "N":
                        markLinesN.push(markLineS)
                        break;
                    case "E":
                        markLinesE.push(markLineS)
                        break;
                }

            })
        }

        return {
            option: {
                dataZoom: [
                    {
                        show: true,
                        xAxisIndex: [0, 1, 2],
                        realtime: true,
                        //start: zoomStart,
                        //end: zoomEnd
                        start: 0,
                        end: 100
                    },
                    {
                        type: 'inside',
                        xAxisIndex: [0, 1, 2],
                        realtime: true,
                        //start: zoomStart,
                        //end: zoomEnd
                        start: 0,
                        end: 100
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        animation: false
                    },
                    formatter: (params) => {
                        const time = moment(params[0].name, "x").format("DD MMM YYYY hh:mm:ss.SSS")
                        const values = params.reduce((result, param) => result + param.seriesName + ": " + param.value + "<br />", "")

                        return (
                            time + '<br />' + values
                        );
                    },
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: 'all'
                        }
                    ]
                },
                xAxis: [
                    {
                        type: 'category',
                        data: labels,
                        gridIndex: 0,
                        show: false,
                    },
                    {
                        type: 'category',
                        data: labels,
                        gridIndex: 1,
                        show: false,
                    },
                    {
                        type: 'category',
                        data: labels,
                        gridIndex: 2,
                        axisLabel: {
                            formatter: (function (value) {
                                return moment(value, "x").format("DD MMM YYYY hh:mm a");
                            })
                        }
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        gridIndex: 0,
                        show: false,
                        name: "N22",
                        nameLocation: 'middle',
                        nameGap: 50
                    },
                    {
                        type: 'value',
                        gridIndex: 1,
                        show: false,
                    },
                    {
                        type: 'value',
                        gridIndex: 2,
                        show: false,

                    }
                ],
                grid: [
                    {
                        top: '0%',
                        left: '5%',
                        right: '5%',
                        bottom: '70%'
                    },
                    {
                        top: '33%',
                        left: '5%',
                        right: '5%',
                        bottom: '35%'
                    },
                    {
                        top: '66%',
                        left: '5%',
                        right: '5%',
                        bottom: '5%'
                    }
                ],
                series: [
                    {
                        name: 'N',
                        type: 'line',
                        data: n,
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        gridIndex: 0,
                        lineStyle: { color: '#000000' },
                        itemStyle: { color: '#000000' },
                        markLine: {
                            symbol: 'none',
                            data: markLinesN,
                        },
                        endLabel: createEndLabel('N'),
                    },
                    {
                        name: 'E',
                        type: 'line',
                        data: e,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        gridIndex: 1,
                        lineStyle: { color: '#000000' },
                        itemStyle: { color: '#000000' },
                        markLine: {
                            symbol: 'none',
                            data: markLinesE,
                        },
                        endLabel: createEndLabel('E'),

                    },
                    {
                        name: 'Z',
                        type: 'line',
                        data: z,
                        xAxisIndex: 2,
                        yAxisIndex: 2,
                        gridIndex: 2,
                        lineStyle: { color: '#000000' },
                        itemStyle: { color: '#000000' },
                        markLine: {
                            symbol: 'none',
                            data: markLinesZ,
                        },
                        endLabel: createEndLabel('Z'),

                    },
                ],
            }
        };


    }


    const createMarkLine = (value, label, color) => {
        return {
            xAxis: value + "",
            label: {
                normal: {
                    show: true,
                    position: 'insideEndBottom',
                    rotate: 0,
                    color: color,
                    fontSize: 18,
                    fontWeight: 'bold',
                    formatter: (param) => {
                        return label
                    },
                }
            },
            lineStyle: {
                normal: {
                    type: 'solid',
                    color: color,
                    width: 2,
                    rotate: 90,
                }
            },
        }
    }

    const createEndLabel = (name) => {
        return {
            show: true,
            formatter: function (params) {
                return `{label|${name}}`;
            },
            rich: {
                label: {
                    fontWeight: 700,
                    fontSize: 18,
                }
            }
        }
    }

    const dataZoom = (e) => {
        if (e && e.batch && e.batch.length > 0) {
            setZoomEnd(e.batch[0].end)
            setZoomStart(e.batch[0].start)
        }
    }


    const click = (params) => {
        console.log('from click')
        if (!props.pointForChanged) {
            console.log('from click 1')
            return
        }

        if (!chartRef || !chartRef.current) {
            console.log('from click 2')
            return
        }
        const instance = chartRef.current.getEchartsInstance();
        const event = params.event;

        const pointInPixel = [event.offsetX, event.offsetY];
        const pointInGrid = instance.convertFromPixel('grid', pointInPixel);
        if (pointInGrid.length == 0) {
            return
        }

        let newSTimestamp = props.date.startOf('hour').unix() * 1000 + 20 * pointInGrid[0]
        const seriesIndex = Math.floor(event.offsetY / (chartHeight / 3))

        const _pointsInfo = JSON.parse(JSON.stringify(pointsInfo))
        if (_pointsInfo.length == 0) {
            _pointsInfo.push({
                station_id: props.device_id,
            })
        }
        _pointsInfo[0][`timestamp_${props.pointForChanged}`] = newSTimestamp
        _pointsInfo[0][`direction_${props.pointForChanged}`] = directions[seriesIndex]
        const options = createChartOptins(props.chartData, _pointsInfo)
        setOptions(options)
        setPointsInfo(_pointsInfo)
        if (props.updatePointsInfo) {
            props.updatePointsInfo(_pointsInfo)
        }

    }

    const onEvents = {
        'datazoom': dataZoom,
        'click': click,
    }

    return (
        <>
            {options &&
                <ReactEcharts
                    key={`seismic-echart`}
                    ref={chartRef}
                    option={options.option}
                    style={{ height: `${chartHeight}px`, width: '100%' }}
                    // onEvents={onEvents}
                />
            }
        </>
    );
}

export default SeismicHourChart;
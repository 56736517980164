import { Spinner } from 'react-bootstrap';
import { BsXCircle } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";
import { ImSpinner11 } from "react-icons/im";
import { Component } from 'react';
import axios from "axios";
import * as consts from '../../../consts';
import checkDeviceAccess from '../../../api/devices/check.device.access';
import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';
import Link from '@mui/material/Link';
import { isMobile } from 'react-device-detect';
import LinkIcon from '@mui/icons-material/Link';
import { Snackbar } from '@mui/material';
import Battery from '../panel/widgets/battery'
import UpdateIcon from '@mui/icons-material/Update';
import Notifications from '../panel/Notifications';
import moment from 'moment';

const defaultState = {
	data_loaded: false,
	msdata: {},
	deviceParameters: {},
	info: undefined,
	data: undefined,
	adminAccessToDevice: false,
	actionNotificationOpen: false,
}

class DeviceInfoDiv extends Component {
	constructor(props) {
		super(props);
		this.state = defaultState
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// Load info in case device id is changed.
		if (this.state.device_id !== nextProps.device_id && nextProps.device_type) {
			this.loadDeviceInfo(nextProps.device_id, nextProps.device_type);
			this.checkAccessForDevice(nextProps.device.uuid)
		}
	}

	async checkAccessForDevice(uuid) {
		const adminAccessToDevice = await checkDeviceAccess(uuid)
		this.setState({ adminAccessToDevice })
	}

	loadDeviceInfo(device_id, device_type) {
		this.setState({ ...defaultState, device_type, device_id })
		axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/${consts.DEVICE_INFO[device_type].endpointInfo}`,
			params: {
				device_id, device_type,
				timeoffset: new Date().getTimezoneOffset() / 60
			}
		})
			.then((response) => {
				console.log(response.data)
				let deviceParameters = {}
				if (response.data.info && response.data.info.device_parameters) {
					Object.keys(response.data.info.device_parameters).forEach( (paramKey) => {
						const param = response.data.info.device_parameters[paramKey]
						deviceParameters[param.name] = param
					})
				}
				
				this.setState({
					data: response.data,
					data_loaded: true,
					info: response.data.info,
					last_parameter_values: response.data.last_parameter_values,
					resources: response.data.resources,
					deviceParameters
				})

			}).catch((error) => {
				if (error.response) {
					console.log(error.response)
				}
			})
	}

	updateData = () => {
		this.setState({
			data_loaded: false,
			info: undefined,
		})
		this.loadDeviceInfo(this.state.device_id, this.state.device_type);
	}

	getDevParameters = (device_id, module_type_id) => {
		let devParameters = consts.DEVICE_INFO[this.state.device_type]?.parameters;
		if (module_type_id !== undefined && module_type_id in consts.MODULE_INFO) {
			devParameters = consts.MODULE_INFO[module_type_id].parameters
		}
		return devParameters ? devParameters : {}
	}

	getCssContainer = () => {
		let containter = 'device-info-container-desktop'
		if (isMobile) {
			containter = this.props.showCompareComponent === '' ? 'device-info-container-mobile' : 'device-info-container-mobile-0'
		}

		return containter
	}

	copyLink = () => {
		const url = `${window.location.protocol}://${window.location.hostname}/map/device/${this.props.device.uuid}`
		navigator.clipboard.writeText(url)
		this.setState({actionNotificationOpen: true})
	}

	getDevice = (module_type_id) => {
		const info = consts.DEVICE_INFO[this.state.device_type]
		if (module_type_id !== undefined && module_type_id in consts.MODULE_INFO && consts.MODULE_INFO[module_type_id].infoComponent) {
			return consts.MODULE_INFO[module_type_id].infoComponent
		}
		return info && info.infoComponent ? info.infoComponent : 'span';
	}

	getMstime = () => {		
		const timeoffset = new Date().getTimezoneOffset() / 60
		if (!this.state.last_parameter_values) {
			return ''
		}
		
		if (!moment(this.state.last_parameter_values?.mstime, "YYYY-MM-DD hh:mm:ss").isValid()) {
			return ''
		}

		const m_mstime = moment(this.state.last_parameter_values?.mstime, "YYYY-MM-DD HH:mm:SS")
		const mstime = m_mstime.subtract(timeoffset, 'hours').add(this.state.info?.tz, 'hours')

		return mstime.format("YYYY-MM-DD HH:mm:SS")
	}


	render() {
		const cssContainer = this.getCssContainer();
		const Device = this.getDevice(this.state.info?.module_type_id)
		return (
			<div className={`device-info-container ${cssContainer} ${this.props.show}`}>
				<BiArrowBack onClick={this.props.backToMonitorPanel} className="back-icon" />
				<BsXCircle onClick={this.props.hideDeviceInfoPanel} className="absolute cursor-pointer right-3 top-5 text-xl" />
				<div className="header-device-info">
					Информация об устройстве
					<Tooltip title="Скопировать ссылку">
						<LinkIcon className="pl-2 cursor-pointer" onClick={this.copyLink.bind(this)}/>
					</Tooltip>

					{this.state.adminAccessToDevice &&
						<Link href={`/admin/device/${this.props.device.uuid}`} color="inherit" target={'_blank'}>
							<Tooltip title="Перейти к редактированию устройства">
								<EditIcon className="pl-2 cursor-pointer" />
							</Tooltip>
						</Link>
					}
					
				</div>
				{!this.state.data_loaded &&
					<div className="spinner-div">
						<Spinner animation="border" />
					</div>
				}
				<div className={this.state.info ? '' : 'hidden'}>
					
					{this.state.info &&
						<div className="di-header">
							<span className="di-type">
								
								{consts.DEVICE_INFO[this.state.device_type].name}
								{this.state.device_type == consts.DEVICE_TYPES.MODULE &&
									<>
										&nbsp;{consts.MODULE_INFO[this.state.info.module_type_id].name},
									</>
								}
								

							</span>
							<span className="di-name"> {this.state.info.name}
								{this.state.info.serial_number &&
									<>
										, s/n {this.state.info.serial_number}
									</>
								}
							</span>
							<Notifications deviceUUID={this.props.device_uuid} />
							<br />

							{/* Location info. */}
							{this.state.info.location.description &&
							<>
								<span className="di-location">{this.state.info.location.description}</span>
								<br />
							</>
							}
							
							
							<Battery info={this.state.info} />
							<Tooltip title="Обновить данные">
								<UpdateIcon onClick={this.updateData} className="text-xl inline mr-1 ml-0 cursor-pointer" />
							</Tooltip>
							<span className="di-last-date">Данные на {this.getMstime()}</span>
							<br />

							{this.state.device_type === consts.DEVICE_TYPES.KARELIAMETEO_STATION && !this.state.info.meteoinfo_url &&
								<div className="text-xs mt-3 text-blue-500">
									<a href="https://kareliameteo.ru/" target="_blank">Официальный сайт Карельского ЦГМС</a>
								</div>
							}
							<hr className='mt-3' />
						</div>
					}
					<Device
						data={this.state.data}
						device_id={this.props.device_id}
						device_uuid={this.props.device_uuid}
						parameters={this.getDevParameters(this.props.device_id, this.state.info?.module_type_id)}
						resources={this.state.resources}
						deviceParameters={this.props.deviceParameters}
					/>
					<Snackbar
                		open={this.state.actionNotificationOpen}
                		autoHideDuration={2500}
                		onClose={() => this.setState({actionNotificationOpen: false})}
                		message={'Ссылка скопирована успешно'}
            		/>
				</div>
			</div>
		);
	}
}
export default DeviceInfoDiv;



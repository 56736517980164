import Device from "./device";

class DevicesGroup {
    id: number = NaN;

    name: string = ''; 
    description: string = ''; 
    slug: string = ''; 

    devices: Device[] = [];
    access_type: string = 'all';


    map_zoom: number = NaN;
    map_center_lat: number = NaN;
    map_center_lng: number = NaN;
    map_layer: string = '';
    mpanel_layouts: string = '';
}

export default DevicesGroup;
import { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Param from './city_parameter';
import { Grid } from '@mui/material';

class City extends Component {
	constructor(props) {
		super(props);
		this.state = {
			info: props.info
		}
	}

	render() {
		return (
			<Grid container spacing={0}>
				<Param
					icon="/widgets_icons/city_dust.png"
					value={this.props.info.last_parameter_values.pm1}
					units=" ppm"
					data_key="pm1"
				/>
				<Param
					icon="/widgets_icons/city_noise.svg"
					value={this.props.info.last_parameter_values.db_avg}
					units=" дБ"
					divClass="ecosystem-center-data"
					data_key="db_avg"
				/>
				<Param
					icon="/widgets_icons/temperature.svg"
					value={this.props.info.last_parameter_values.temp}
					units="°"
					unitClass="city-parameter-units-lg"
					divClass="ecosystem-right-data"
					data_key="temp"
				/>
			</Grid>
		);
	}
}
export default City;



import { useEffect, useState } from 'react';
import getDevice from '../../api/devices/get.device';
import { getDeviceParameters } from '../../api/devices/get.device.parameters';
import Device from '../../model/device';
import DeviceParameter from '../../model/device.parameter';
import AdminHeader from '../admin/header';
import AgroMainInfo from './AgroMainInfo';
import Header from './Header';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ControlPanel from './ControlPanel';
import Charts from './Charts';
import * as consts from '../../consts';

const UUID = '158d23ea-c3ea-4391-9a43-265c790162f8'
const MODULE_TYPE_ID = 5;
const DEVICE_TYPE_ID = 3

export default function Agro2Main() {
    const [headerTitle, setHeaderTitle] = useState('Модуль Agro')
    const [device, setDevice] = useState<Device | null>(null)
    const [deviceParameters, setDeviceParameters] = useState<any>({})

    useEffect(() => {
        const fetchData = async () => {
            const device = await getDevice(UUID)
            setDevice(device)

            const deviceParameters = await getDeviceParameters(UUID)
            const devParams: { [id: string]: DeviceParameter; } = {};

            deviceParameters.forEach((devParam: DeviceParameter) => {
                const paramKey = `${DEVICE_TYPE_ID}_${MODULE_TYPE_ID}_${devParam.name}`
                devParams[paramKey] = devParam;
            })

            setDeviceParameters(devParams)
        }

        fetchData()

    }, [])


    return (
        <>
            <section className="mt-0 min-h-[600px]">
                
                <img src="/brand_image_dark.svg" className="absolute right-0 top-0"/>
                <div className="container px-5 admin-section">
                     
                    <AdminHeader headerText={`${headerTitle}`} backUrl={`/map`} />
                    {device &&
                        <Row>
                            <Col md={6} xs={6}>
                                <Header device={device} />
                            </Col>
                            <Col md={6} xs={6}>
                                <ControlPanel device={device} />
                            </Col>
                        </Row>

                    }
                    <hr className='mt-3' />

                    <AgroMainInfo
                        showDeviceById={() => { return {} }}
                        data={{ info: device, data: device?.last_parameter_values }}
                        deviceParameters={deviceParameters}
                    />

                    {device &&
                        <Charts
                            key={`compare-component-1`}
                            placement="bottom"
                            name="comparecomponent"
                            show={true}
                            deleteDeviceFromCompare={(device: Device) => void 0}
                            selectedDevices={[device]}
                            classShowMonitoringPanel={false}
                            classShowDeviceInfo={'device-compares-container-full'}
                            compareMode={consts.COMPARE_MODE.OFF}
                            deviceTypeParameters={deviceParameters}
                        />
                    }
                </div>
            </section>
        </>
    );
}
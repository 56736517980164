import { IconButton, ImageListItemBar, Snackbar } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import deletePlantPhenologyImage from '../../../api/data/delete.plant.phenology.image';
import { useState } from 'react';

class ImageDataItem {
    original: string = '';
    thumbnail: string = '';
    description: string = '';
    id: string = '';
}


export interface PhenologyProps {
    items: ImageDataItem[];
    update: () => void;
}

const PhenologyImageList = ({ items, update }: PhenologyProps) => {

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };

    const deleteImage = async (item: ImageDataItem) => {
        const result = await deletePlantPhenologyImage(item.id)
        if (result) {
            notify('Изображение удалено')
            setTimeout(update, 1000)
        }
    }

    return (
        <>
            <ImageList sx={{ maxHeight: 2500 }} cols={4}>
                {items.map((item) => (
                    <ImageListItem key={item.original}>
                        <img
                            src={`${item.original}`}
                            alt={item.description}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            sx={{
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                            }}
                            position="top"
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'white' }}
                                    aria-label={`star ${item.description}`}
                                    onClick={() => deleteImage(item)}
                                >
                                    <DeleteIcon className='fill-red-600' />
                                </IconButton>
                            }
                            actionPosition="right"
                            title={item.description}
                        />
                        
                    </ImageListItem>
                    
                ))}
            </ImageList>

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />


        </>
    );
}

export default PhenologyImageList;
import { Grid } from "@mui/material";
import LeftPart from "./left_part";

const CoverPage2 = () => {


    return (

        <Grid container spacing={2}>
            <Grid item xs={1}></Grid>

            <LeftPart />

            <Grid item xs={12} sm={4} md={4}>
                <img src="/landing/assets/img/header_02.png" className="w-full" />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
                <div className="mr-0 text-right">
                    <p className="cover-header">
                        02 | Подключение к сети
                    </p>
                    <p className="cover-text">
                        Подключаем к IoT-сети <br />
                        существующие посты<br />
                        мониторинга<br />
                    </p>
                    <p className="more-link-text">
                        <a className="more-link-text" href="#part02">Узнать подробнее</a>
                    </p>
                </div>
            </Grid>
            <Grid item xs={1}></Grid>

        </Grid>
    );
}

export default CoverPage2
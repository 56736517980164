import 'leaflet/dist/leaflet.css';
import { useEffect, useMemo, useState } from "react";
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getDevicesLite from '../../api/devices/get.devices.lite';
import getAccountSettings from '../../api/user/get.account.settings';
import getGroup from '../../api/devices/get.group';

import * as consts from '../../consts';
import Device from '../../model/device';
import CompareComponent from './cc/compare_component';
import TooMuchDevicesDialog from './cc/too_much_devices_dialog';
import DeviceInfo from './device_info/device_info';
import Map from './map';
import './map.css';
import Menu from './menu/menu';
import MonitoringPanel from './panel/main';
import TermOfUseDialog from './TerfOfUseDialog';
import ParametersSelector from '../../leaflet-heatmap/components/ParametersSelector';
import { getDeviceParametersGroups } from '../../api/devices/get.device.parameters.groups';
import { Box, CircularProgress, IconButton, MenuItem, Modal, Select } from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getDevicesHeatmapDataByParameters, getParametersByIds } from '../../leaflet-heatmap/utils/devices-utils';
import UnitScale from '../../leaflet-heatmap/components/UnitScale';
import { defaultHeatmapGradient } from '../../leaflet-heatmap/types/leaflet-heatmap-config';
import { getDeviceParametersDataForPeriod } from '../../api/devices/get.data.for.period';
import { getDateDaysAgo } from '../../leaflet-heatmap/utils/date-utils';
import PalyerTimeline from '../../leaflet-heatmap/components/PlayerTimeline';
import { deviceParamsDataForPeriodToPlayerTimelineWithHeatmap } from '../../leaflet-heatmap/utils/player-timeline-utils';
import ConcentrationMapConfig, { defaultLeafletConcentrationMapConfig } from '../../leaflet-heatmap/components/ConcentrationMapConfig';
import SettingsIcon from '@mui/icons-material/Settings';

const getInitialConcentrationMapConfig = () => {
	const paramString = localStorage.getItem('concentration-map-config');
	return paramString ? { ...defaultLeafletConcentrationMapConfig, ...JSON.parse(paramString) } : defaultLeafletConcentrationMapConfig;
}

const getInitialParamsSelectorOpen = () => {
	const paramString = localStorage.getItem('params-selector-open');
	return paramString ? JSON.parse(paramString) : false;
}

const getInitialAgType = () => {
	const paramString = localStorage.getItem('saved-ag-type');
	return paramString ?? 'auto';
}

const getInitialTimelineDaysAgo = () => {
	const paramString = localStorage.getItem('saved-timeline-days-ago');
	return paramString ? Number(paramString) : 0;
}

function MapScreen() {
	const [classShowDeviceInfo, setClassShowDeviceInfo] = useState('hidden');
	const [currentDeviceId, setCurrentDeviceId] = useState(undefined);
	const [currentDeviceUUID, setCurrentDeviceUUID] = useState(undefined);
	const [currentDeviceType, setCurrentDeviceType] = useState(undefined);
	const [currentDevice, setCurrentDevice] = useState(undefined);

	const [concentrationModelParams, setConcentrationModelParams] = useState(getInitialConcentrationMapConfig());

	const updateConcentrationModelParams = (config) => {
		localStorage.setItem('concentration-map-config', JSON.stringify(config));
		setConcentrationModelParams(config);
	}

	const [concentrationModelConfigOpen, setConcentrationModelConfigOpen] = useState(false);

	const [devices, setDevices] = useState([]);
	const [deviceParametersGroups, setDeviceParametersGroups] = useState([]);
	const [deviceTypeParameters, setDeviceTypeParameters] = useState({});

	const [selectedDevicesForComparing, setSelectedDevicesForComparing] = useState([]);
	const [currentModuleType, setCurrentModuleType] = useState(undefined);
	const [showCompareComponent, setShowCompareComponent] = useState('hidden');
	const [compareMode, setCompareMode] = useState(consts.COMPARE_MODE.OFF);
	const [classShowMonitoringPanel, setClassShowMonitoringPanel] = useState('hidden');
	const [deviceOnMap, setDeviceOnMap] = useState(undefined);
	const [currentLayer, setCurrentLayer] = useState(undefined);
	const [deviceToMapCenter, setDeviceToMapCenter] = useState(false)
	const [accountSettings, setAccountSettings] = useState(undefined)
	const [open, setOpen] = useState(false);
	const params = useParams()
	const [openTermOfUseDialog, setOpenTermOfUseDialog] = useState(localStorage.getItem('terms_of_use_approved') === null);

	const [timelineDaysAgo, setTimelineDaysAgo] = useState(getInitialTimelineDaysAgo());
	const [agType, setAgType] = useState(getInitialAgType());

	const [paramsSelectorOpen, setParamsSelectorOpen] = useState(getInitialParamsSelectorOpen());

	const [deviceParamsActiveGroupId, setDeviceParamsActiveGroupId] = useState(undefined);

	const [playerTimelineItems, setPlayerTimelineItems] = useState([]);
	const [selectedPlayerTimelineItemId, setSelectedPlayerTimelineItemId] = useState(null);


	const onChangeActiveGroupId = (groupId) => {
		setDeviceParamsActiveGroupId(groupId);
	}

	const activeHeatmapConfig = useMemo(() => {
		const activePlayerTimelineItem = playerTimelineItems.find(p => p.id === selectedPlayerTimelineItemId);
		return activePlayerTimelineItem?.value;
	}, [selectedPlayerTimelineItemId, playerTimelineItems]);

	const activeDeviceParams = useMemo(() => {
		const group = deviceParametersGroups?.find?.(g => g.id === deviceParamsActiveGroupId);
		if (!group) return [];

		const paramNames = getParametersByIds(deviceTypeParameters, group.parameters).map(p => p.name);

		return paramNames;
	}, [deviceParametersGroups, deviceParamsActiveGroupId, deviceTypeParameters])


	const hideDeviceInfoPanel = () => {
		setClassShowDeviceInfo('hidden')
		setShowCompareComponent('hidden')
		setSelectedDevicesForComparing([])
	}

	const backToMonitorPanel = () => {
		setDeviceOnMap(undefined)
		hideDeviceInfoPanel()
		openMonitoringPanel()
	}

	const changeCompareMode = () => {
		// In case is compare mode, then remove all device.
		if (compareMode) {
			if (selectedDevicesForComparing.length > 0) {
				setShowCompareComponent('hidden')
				setSelectedDevicesForComparing([])
			}
		} else {
			setClassShowDeviceInfo('hidden')
		}
		setCompareMode(!compareMode)
	}



	const [deviceParametersDataForPeriodLoading, setDeviceParametersDataForPeriodLoading] = useState(false);
	const [deviceParametersDataForPeriod, setDeviceParametersDataForPeriod] = useState(null);

	useEffect(() => {
		setDeviceParametersDataForPeriod(null);

		if (deviceParamsActiveGroupId) {
			setDeviceParametersDataForPeriodLoading(true);
			getDeviceParametersDataForPeriod({ deviceParametersGroupId: deviceParamsActiveGroupId, dateFrom: getDateDaysAgo(timelineDaysAgo), ag_type: agType })
				.then(res => setDeviceParametersDataForPeriod(res))
				.finally(() => setDeviceParametersDataForPeriodLoading(false));
		}
	}, [deviceParamsActiveGroupId, timelineDaysAgo, agType])


	useEffect(() => {
		if (devices?.length && activeDeviceParams?.length) {
			const nowHeatmap = getDevicesHeatmapDataByParameters(devices, activeDeviceParams);
			const nowplayerItem = { label: 'Сейчас', value: nowHeatmap, id: '__now__' };

			if (deviceParametersDataForPeriod) {
				setPlayerTimelineItems(
					deviceParamsDataForPeriodToPlayerTimelineWithHeatmap(devices, deviceParametersDataForPeriod, activeDeviceParams)
						.concat([nowplayerItem])
				);
			} else {
				setPlayerTimelineItems([nowplayerItem]);
			}

			setSelectedPlayerTimelineItemId(nowplayerItem.id);
		} else {
			setPlayerTimelineItems([]);
			setSelectedPlayerTimelineItemId(undefined);
		}
	}, [deviceParametersDataForPeriod, devices, activeDeviceParams])

	// Gets list of all devices from api.
	useEffect(() => {

		if (params.group) {
			console.log('from group.')
		}
		const group = params.group ? params.group : '';

		if (!params.device_uuid) {
			openMonitoringPanel();
		}

		async function fetchDevices() {
			const devicesResponse = await getDevicesLite(NaN, group)
			const allDevices = Device.fromArray(devicesResponse.devices)
			allDevices.sort((a, b) => {
				let r = (a.device_type_id > b.device_type_id) ? 1 : ((b.device_type_id > a.device_type_id) ? -1 : 0);
				if (r == 0 && a.device_type_id === consts.DEVICE_TYPES.MODULE) {
					r = (a.module_type_id > b.module_type_id) ? 1 : ((b.module_type_id > a.module_type_id) ? -1 : 0);
				}
				return r;
			})

			setDevices(allDevices)
			if (params.device_uuid) {
				allDevices.map((device) => {
					if (device.uuid == params.device_uuid) {
						showDeviceInfoPanel(device)
					}
				})
			}


			if (group) {
				const groupInfo = await getGroup(group)
				setAccountSettings(groupInfo)
			} else {
				const accountSettings = await getAccountSettings()
				setAccountSettings(accountSettings)
			}

			setDeviceTypeParameters(devicesResponse.deviceParameters)
		}

		async function fetchDeviceParametersGroups() {
			const params = await getDeviceParametersGroups();
			setDeviceParametersGroups(params ?? []);
		}

		fetchDevices();
		fetchDeviceParametersGroups();
	}, [])


	const openMonitoringPanel = () => {
		setClassShowMonitoringPanel('')

		if (!params.group) {
			window.history.replaceState(null, ``, `/map`)
		}
	}


	const toggleMonitoringPanel = () => {
		let clazz = 'hidden';
		if (classShowMonitoringPanel == 'hidden') {
			clazz = ''
			setClassShowDeviceInfo('hidden')
			window.history.replaceState(null, ``, `/map`)

		}
		setClassShowMonitoringPanel(clazz)
	}

	const showDeviceInfoPanel = (device) => {
		window.history.replaceState(null, `${device.getFullName()}`, `/map/device/${device?.uuid}`)

		setCurrentDeviceId(device.device_id)
		setCurrentDeviceType(device.device_type_id)
		setCurrentModuleType(device.module_type_id)
		setCurrentDeviceUUID(device.uuid)
		setCurrentDevice(device)
		setClassShowDeviceInfo('')

		if (device.isComparable()) {
			setShowCompareComponent('')
			setSelectedDevicesForComparing([device])
		} else {
			setShowCompareComponent('hidden')
			setSelectedDevicesForComparing([])
		}
	}


	const addDeviceToCompare = (device) => {
		let alreadyExist = false;
		selectedDevicesForComparing.forEach((d) => {
			if (d.device_id === device.device_id) {
				alreadyExist = true;
			}
		})
		if (alreadyExist) {
			deleteDeviceFromCompare(device)
			return;
		}

		if (selectedDevicesForComparing.length == 10) {
			setOpen(true)
			return
		}

		if (device.isComparable()) {
			setCurrentDeviceId(device.device_id)
			setCurrentDevice(device)
			setShowCompareComponent('')

			let selectedDevices = selectedDevicesForComparing;
			selectedDevices.push(device)
			setSelectedDevicesForComparing(selectedDevices)
		} else {
			toast.error('Устройство не поддерживает режим сравнения.')
		}
	}

	const deleteDeviceFromCompare = (device) => {
		let newSelectedDevices = []
		selectedDevicesForComparing.forEach((d) => {
			if (d.device_id !== device.device_id) {
				newSelectedDevices.push(d)
			}
		});
		setSelectedDevicesForComparing(newSelectedDevices)
		if (newSelectedDevices.length === 0) {
			setShowCompareComponent('hidden')
		}
	}

	const deviceClick = (device, deviceToMapCenter = false) => {
		setDeviceToMapCenter(deviceToMapCenter)
		setDeviceOnMap(device)
		if (!compareMode) {
			hideMonitoringPanel()
		}
		compareMode ? addDeviceToCompare(device) : showDeviceInfoPanel(device)
	}

	const showDeviceById = (device_id) => {
		devices.forEach((device) => {
			if (device.device_id == device_id) {
				deviceClick(device, true)
				return false;
			}
		})
	}

	const hideMonitoringPanel = () => {
		setClassShowMonitoringPanel('hidden')
	}

	const toggleLayer = (layer) => {
		setCurrentLayer(layer)
	}

	const closeTermOfUseDialog = () => {
		setOpenTermOfUseDialog(false)

		const userToken = localStorage.getItem('token')
		if (userToken) {

		}
	}

	const toggleParamsSelector = () => {
		localStorage.setItem('params-selector-open', !paramsSelectorOpen);
		setParamsSelectorOpen(!paramsSelectorOpen);
	}

	const updateTimelineDaysAgo = (value) => {
		localStorage.setItem('saved-timeline-days-ago', value);
		setTimelineDaysAgo(value);
	}

	const updateAgType = (value) => {
		localStorage.setItem('saved-ag-type', value);
		setAgType(value);
	}

	return (
		<div>
			<TermOfUseDialog open={openTermOfUseDialog} handleClose={closeTermOfUseDialog} />

			<ToastContainer autoClose={2000} hideProgressBar={false} />
			<Menu
				toggleLayer={toggleLayer}
				toggleCompare={changeCompareMode}
				compareMode={compareMode}
			/>
			<MonitoringPanel
				show={classShowMonitoringPanel}
				hideMonitoringPanel={hideMonitoringPanel}
				showDeviceById={showDeviceById}
				compareMode={compareMode}
				devices={devices}
				selectedDevices={selectedDevicesForComparing}
				accountSettings={accountSettings}
				deviceParameters={deviceTypeParameters}

			/>
			<DeviceInfo
				show={classShowDeviceInfo}
				hideDeviceInfoPanel={hideDeviceInfoPanel}
				backToMonitorPanel={backToMonitorPanel}
				device_id={currentDeviceId}
				device_type={currentDeviceType}
				device_uuid={currentDeviceUUID}
				module_type={currentModuleType}
				showCompareComponent={showCompareComponent}
				device={currentDevice}
				deviceParameters={deviceTypeParameters}

			/>
			<CompareComponent
				key={`compare-component-${selectedDevicesForComparing.length}`}
				placement="bottom"
				name="comparecomponent"
				show={showCompareComponent}
				deleteDeviceFromCompare={(device) => deleteDeviceFromCompare(device)}
				selectedDevices={selectedDevicesForComparing}
				classShowMonitoringPanel={classShowMonitoringPanel}
				classShowDeviceInfo={classShowDeviceInfo}
				changeCompareMode={changeCompareMode}
				compareMode={compareMode}
				deviceTypeParameters={deviceTypeParameters}
			/>

			{
				<div className={`absolute z-[510] ${concentrationModelConfigOpen && 'p-1.5'} bg-white rounded-md top-20 right-2`}>
					<div className='w-full h-full relative min-h-[330px]'>
						<IconButton
							color='secondary' size='small' className='absolute top-1/2 -translate-y-1/2 -left-7 bg-[#508104]'
							onClick={() => setConcentrationModelConfigOpen(!concentrationModelConfigOpen)}>
							{concentrationModelConfigOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
						</IconButton>

						<div className={`w-[400px] max-w-[400px] ${!concentrationModelConfigOpen && 'hidden'}`}>
							<ConcentrationMapConfig config={concentrationModelParams} onConfigChange={updateConcentrationModelParams} />
						</div>
					</div>
				</div>
			}

			{devices.length &&
				<div className={`absolute transition-all z-[510] right-2 bottom-16`}>
					<div className='h-full w-full relative min-h-[98px]'>
						<IconButton
							color='secondary' size='small' className='absolute top-1/2 -translate-y-1/2 -left-7 bg-[#508104]'
							onClick={toggleParamsSelector}>
							{paramsSelectorOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
						</IconButton>

						<div className={`bg-white/80 pb-3 pt-1.5 px-3 rounded-md overflow-hidden w-80 ${paramsSelectorOpen ? '' : 'hidden'}`}>
							{activeHeatmapConfig &&
								<UnitScale
									className='mb-4'
									min={activeHeatmapConfig.min}
									max={activeHeatmapConfig.max}
									gradient={defaultHeatmapGradient}
									heightInPx={200} />}

							<div className='flex items-center justify-between'>
								<div>Тепловая карта:</div>
							</div>

							<div>
								<ParametersSelector
									devices={devices}
									deviceParameters={deviceTypeParameters}
									paramsGroups={deviceParametersGroups}
									onChangeActiveGroupId={onChangeActiveGroupId} />
							</div>

							{
								deviceParamsActiveGroupId &&
								<div className='flex gap-1 mt-1.5'>
									<div className='flex-auto'>
										<div className='text-[10px]'>Период:</div>
										<Select
											className='text-xs w-full'
											label={'За какой период'}
											variant='standard' value={timelineDaysAgo}
											onChange={e => updateTimelineDaysAgo(e.target.value)}>
											<MenuItem value={0}>Сегодня</MenuItem>
											<MenuItem value={1}>Последние 2 дня</MenuItem>
											<MenuItem value={7}>Последняя неделя</MenuItem>
											<MenuItem value={30}>Последний месяц</MenuItem>
											<MenuItem value={365}>Последний год</MenuItem>
										</Select>
									</div>

									<div className='flex-auto'>
										<div className='text-[10px]'>Агрегация:</div>
										<Select
											className='text-xs w-full'
											variant='standard'
											value={agType}
											onChange={e => updateAgType(e.target.value)}>
											<MenuItem value={'auto'}>Автоматически</MenuItem>
											<MenuItem value={'hour'}>По часам</MenuItem>
											<MenuItem value={'day'}>По дням</MenuItem>
											<MenuItem value={'month'}>По месяцам</MenuItem>
											<MenuItem value={'year'}>По годам</MenuItem>
										</Select>
									</div>
								</div>
							}

						</div>
					</div>
				</div>
			}

			{deviceParametersDataForPeriodLoading
				? <div className='bg-gray-100 absolute bottom-0 inset-x-0 z-[505] flex h items-center justify-center h-12'>
					<CircularProgress size={20} />
				</div>
				: playerTimelineItems?.length &&
				<div className='absolute bottom-0 inset-x-0 z-[505]'>
					<PalyerTimeline
						activeItemId={selectedPlayerTimelineItemId}
						items={playerTimelineItems}
						onItemChange={item => setSelectedPlayerTimelineItemId(item.id)} />
				</div>}

			<Map
				heatmapConfig={activeHeatmapConfig}
				concentrationModelParams={concentrationModelParams}
				toggleLayer={toggleLayer}
				toggleMonitoringPanel={toggleMonitoringPanel}
				deviceClick={deviceClick}
				devices={devices}
				compareMode={compareMode}
				classShowMonitoringPanel={classShowMonitoringPanel}
				selectedDevices={selectedDevicesForComparing}
				deviceOnMap={deviceOnMap}
				currentLayer={currentLayer}
				deviceToMapCenter={deviceToMapCenter}
				accountSettings={accountSettings}
				deviceTypeParameters={deviceTypeParameters}
			/>
			<TooMuchDevicesDialog open={open} handleClose={() => setOpen(false)} />
		</div>
	);
}

export default MapScreen;

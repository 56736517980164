import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import getDevices from '../../../api/devices/get.devices';
import createSeismicEvent from '../../../api/seismic/create.seismic.event';
import { DEVICE_TYPES } from '../../../consts';
import Device from '../../../model/device';
import EcoModal from '../../eco_modal';
import SeismicDevice from '../../map/devices/seismic/seismic';
import Nav from '../../nav';
import AdminHeader from '../header';
import SeismicStationsSelectingForm from './SeismicStationsSelectingForm';

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss"

export default function FindSeismicEvents() {
    const navigate = useNavigate()

    const [mstime, setMstime] = useState(moment().format(DATE_FORMAT))
    const [mstimeDate, setMstimeDate] = useState<Date>()
    
    const [showSelectingStationsForm, setShowSelectingStationsForm] = useState<boolean>(false)
    const [stations, setStations] = useState<Device[]>([])
    const [selectedStations, setSelectedStations] = useState<Device[]>([])
    const [selectedIndex, setSelectedIndex] = useState<number>(0)

    useEffect(() => {
        const fetchData = async () => {
            const devices = await getDevices(DEVICE_TYPES.SEISMICSTATION)
            setStations(devices)
        }
        fetchData()

    }, [])

    const clickCreateSeismicEvent = async () => {
        const station_ids = selectedStations.map( (device) => device.device_id)
        const event_id = await createSeismicEvent(mstime, station_ids)
        if (event_id) {
            navigate(`/admin/seismic/event/${event_id}`)
        }
    }

    const updateEventDate = (mstime: Moment) => {
        if (mstime) {
            const _mstime = moment(mstime)
            setMstime(_mstime.format(DATE_FORMAT))
            setMstimeDate(_mstime.toDate())
        }
    }

    const addSeismicStation = () => {
        setShowSelectingStationsForm(true)
    }

    const afterSelectedSuccess = (_selectedStations: Device[]) => {
        setSelectedStations(_selectedStations)
        setSelectedIndex(selectedIndex + 1)
    }

    return (
        <>
            <Nav />

            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AdminHeader headerText={`Поиск событий`} backUrl={`/admin/seismic`} />
                    <button className="lr-primary-button" onClick={clickCreateSeismicEvent}>Создать событие</button>

                    <div className="mt-5">
                        Выбранные станции: -,
                        <Tooltip title='Изменить список станций для поиска'>
                            <IconButton aria-label="add" size="small" color="success" onClick={addSeismicStation}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <div key={`selected_stations_div_${selectedIndex}`}>
                        {selectedStations.map((station: Device, i) => {
                            return <SeismicDevice
                                key={`seismic_device_${i}`}
                                device_id={station.device_id}
                                updateEventDate={updateEventDate}
                                headerLabel={`${station.name}, s/n ${station.serial_number}`}
                                startDate={mstimeDate}
                            />

                        })}
                    </div>

                </div>
            </section>

            <EcoModal
                key="addupdate-modal"
                open={showSelectingStationsForm}
                handleClose={() => setShowSelectingStationsForm(false)}
                content={SeismicStationsSelectingForm}
                stations={stations}
                title={`Список сейсмостанций для поиска`}
                afterSaveSuccess={afterSelectedSuccess}
                checkedStations={selectedStations}
            />

        </>
    );
}
import L from 'leaflet';

const createIcon = (icon) => {
    return new L.Icon({
	iconUrl: require('' + icon),
	iconRetinaUrl: require('' + icon),
	iconAnchor: null,
	popupAnchor: null,
	shadowUrl: null,
	shadowSize: null,
	shadowAnchor: null,
	iconSize: new L.Point(28, 35),
	className: 'leaflet-div-icon'
    });
}

const iconPrefix = 'icons/devices/usual'
const iconCamera = createIcon(`./${iconPrefix}/camera.png`)
const iconMeteo = createIcon(`./${iconPrefix}/meteo.png`)
const iconAudio = createIcon(`./${iconPrefix}/sound.png`)
const iconSoil = createIcon(`./${iconPrefix}/soil.png`)
const iconCity = createIcon(`./${iconPrefix}/city.png`)
const iconPlant = createIcon(`./${iconPrefix}/plant.png`)
const iconSeismic = createIcon(`./${iconPrefix}/seismic.png`)

const iconComparePrefix = 'icons/devices/compare'
const iconCompareMeteo = createIcon(`./${iconComparePrefix}/meteo.png`)
const iconCompareCity = createIcon(`./${iconComparePrefix}/city.png`)
const iconCompareSoil = createIcon(`./${iconComparePrefix}/soil.png`)
const iconComparePlant = createIcon(`./${iconComparePrefix}/plant.png`)
const iconCompareSeismic = createIcon(`./${iconComparePrefix}/seismic.png`)

const iconSelectedPrefix = 'icons/devices/compare_selected'
const iconSelectedMeteo = createIcon(`./${iconSelectedPrefix}/meteo.png`)
const iconSelectedCity = createIcon(`./${iconSelectedPrefix}/city.png`)
const iconSelectedSoil = createIcon(`./${iconSelectedPrefix}/soil.png`)
const iconSelectedPlant = createIcon(`./${iconSelectedPrefix}/plant.png`)
const iconSelectedSeismic = createIcon(`./${iconSelectedPrefix}/seismic.png`)

export { 
    iconCamera, iconMeteo, iconAudio, iconCity, iconSoil, iconPlant, iconSeismic,
    iconSelectedMeteo, iconSelectedCity, iconSelectedSoil, iconSelectedPlant, iconCompareSeismic, 
    iconCompareMeteo, iconCompareCity, iconCompareSoil, iconComparePlant, iconSelectedSeismic,
};

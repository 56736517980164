import { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { BsCheck } from "react-icons/bs";


const aggregationTypes = {
	'without': 'Без',
	'minute': 'За минуту',
	'minute5': 'За 5 минут',
	'hour': 'За час',
	'day': 'За сутки',
	'month': 'За месяц',
	'auto': 'Авто',
}

class ChooserAgType extends Component {
	render() {
		return (
			<ReactTooltip
				id='choose-aggregation-type'
				aria-haspopup='true' effect='solid'
				backgroundColor='#fff'
				className="cc-toolip-dates"
				clickable={true}
				padding="1px 10px"
				offset={{ top: -10, left: 0 }}
				globalEventOff='click'
			>
				{Object.keys(aggregationTypes).map((atype, i) => {
					return (
						<div 
								key={`at-type-${atype}-${i}`}
								className="btn-date-period" onClick={() => this.props.changeAggregationType(atype)}>
							{aggregationTypes[atype]}
							{this.props.current_ag_type == atype &&
								<>&nbsp;<BsCheck className='inline' /></>
							}
						</div>
					)
				})}
				<div></div>
			</ReactTooltip>
		);
	}
}
export default ChooserAgType;


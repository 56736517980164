import { TextField } from "@mui/material";
import DeviceAdditionFields from "../../../../model/device.addition.fields";


interface SimpleTextFieldProps {
    field: DeviceAdditionFields; 
    onChangeField: any;
}


const SimpleTextField = ( {field, onChangeField} : SimpleTextFieldProps) => {
    return (
        <TextField
            key={`addition-fields-${field.name}`}
            className="mt-5"
            fullWidth
            name={`${field.name}`}
            value={field.value}
            size="small"
            label={`${field.description}`}
            onChange={(event) => onChangeField(event.target.value, field)}
            inputProps={{ style: { boxShadow: '0 0 #0000' } }}
        />
    )
}

export default SimpleTextField;
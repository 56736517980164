import RefreshIcon from '@mui/icons-material/Refresh';
import { Snackbar, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import refreshSeismicImagesForDay from '../../../../api/seismic/refresh.seismic.images.for.date';
import CircularProgress from '@mui/material/CircularProgress';
import checkUserIsAdmin from '../../../../api/user/check.user.isadmin';

export interface RefreshImagesProps {
    startDate: Date;
    stationId: number;
    afterRefreshImages: () => void
}

const RefreshImages = ({ startDate, stationId, afterRefreshImages }: RefreshImagesProps) => {
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false)
    const [isAdmin, setIsAdmin] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            const isAdmin = await checkUserIsAdmin()
            setIsAdmin(isAdmin)
        }
        fetchData()

    }, [])


    const sendRefreshingCommand = async () => {
        setIsRefreshing(true)
        const result = await refreshSeismicImagesForDay(startDate.toLocaleDateString('ru-Ru'), stationId)
        if (result) {
            notify('Обновление выполнено.')
            afterRefreshImages()
        }
        setIsRefreshing(false)
    }

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };

    if (!isAdmin) {
        return null
    }


    return (
        <div className='ml-2'>
            {isRefreshing &&
                <CircularProgress size="1.2rem" className='mt-2'/>
            }
            {!isRefreshing &&
                <Tooltip title={`Пересоздать изображения за ${startDate.toLocaleDateString('ru-Ru')}`}>
                    <RefreshIcon className="cursor-pointer" onClick={sendRefreshingCommand} />
                </Tooltip>
            }

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />
        </div>
    );
}
export default RefreshImages;



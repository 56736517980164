import ru from 'date-fns/locale/ru';
import DatePicker, { registerLocale } from "react-datepicker";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';
import moment from 'moment';

registerLocale('ru', ru)


export default function ResponsiveDatePickers(props) {
    const [selectedDate, setSelectedDate] = useState(props.date);

    const setPrevDayButtonClick = () => {
        const prevDate = moment(selectedDate).subtract(1, 'days')
        setSelectedDate(prevDate.toDate())
    };

    const setNextDayButtonClick = () => {
        const prevDate = moment(selectedDate).add(1, 'days')
        setSelectedDate(prevDate.toDate())
    };

    useEffect(() => {
        if (selectedDate && props.date && selectedDate.getTime() != props.date.getTime()) {
            props.onChange(selectedDate)
        }
    }, [selectedDate])

    const changeDateSelected = (date) => {
        setSelectedDate(date)
    }
    
    
    useEffect(() => {
        if (selectedDate && props.date && selectedDate.getTime() != props.date.getTime()) {
            setSelectedDate(props.date)
        }
    }, [props.date])


    return (
        <div className="flex justify-center items-center">
            <Tooltip title="Предыдущий день">
                <IconButton onClick={setPrevDayButtonClick}>
                    <ArrowBackIcon />
                </IconButton>
            </Tooltip>
            <div className="w-full px-2">
                <Tooltip title="Выбор дня" placement='top'>
                    <div>
                        <DatePicker
                            className=" w-full"
                            dateFormat={'dd.MM.yyyy'}
                            selected={selectedDate}
                            onChange={changeDateSelected}
                            locale="ru"
                        />
                    </div>
                </Tooltip>
            </div>
            <Tooltip title="Следующий день">
                <IconButton onClick={setNextDayButtonClick}>
                    <ArrowForwardIcon />
                </IconButton>
            </Tooltip>
        </div >
    );
}
import PhenologyGallery from "../../map/device_info/plant/PhenologyGallery";
import PhenologyImageList from "./PhenologyImageList";

export interface PhenologyProps {
    deviceId: number;
}

const PhenologyTab = ({ deviceId }: PhenologyProps) => {

    return (
        <>
            {deviceId &&
                <>
                    <PhenologyGallery 
                        deviceId={deviceId} 
                        Component={PhenologyImageList}
                    />
                </>
            }
        </>
    );
}

export default PhenologyTab;
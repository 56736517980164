import { Component } from 'react';
import DATA_CELLS from '../../../../data_cells';
import OverlayTriggerWrapper from './overlay_trigger_wrapper'
import { Grid } from '@mui/material';
import { Tooltip } from '@mui/material';

class CityParameter extends Component {
	render() {
		let value = this.props.value;
		if (this.props.paramType != 'string') {
			value = parseInt(value)
		}
		const label = this.props.data_key ? DATA_CELLS[this.props.data_key].label : '';

		return (
			<Grid item xs={4} className="">

				<Tooltip
					title={label}
				>
					<Grid container className="ml-2">
						<Grid item xs={3} className="flex items-center justify-center">
							{this.props.icon &&
								<img src={this.props.icon} className="max-w-24 min-w-full align-middle" />
							}&nbsp;
						</Grid>
						<Grid item xs={9}  className="">
							
							<span className="font-medium text-2xl ml-1">
								{!isNaN(value) &&
									<>{value}</>
								}
								{isNaN(value) &&
									<>--</>
								}
							</span>
							
							<span className="font-light text-xs">{this.props.units}</span>
						</Grid>
					</Grid>

				</Tooltip >
			</Grid>

		);
	}
}
export default CityParameter;




import { Grid } from '@mui/material';
import { Component } from 'react';
import Param from './city_parameter';

class SoilModule extends Component {

	render() {
		if (!this.props.info || !this.props.info.last_parameter_values) {
			return null
		}
		
		return (
			<Grid container spacing={2}>
				<Param
					icon="/widgets_icons/temperature.svg"
					value={this.props.info.last_parameter_values.air_temp}
					units="°"
					unitClass="city-parameter-units-lg"
					data_key="air_temp"
				/>
				<Param
					icon="/widgets_icons/temperature.svg"
					value={this.props.info.last_parameter_values.soil_temp}
					units="°"
					divClass="ecosystem-center-data"
					unitClass="city-parameter-units-lg"
					data_key="soil_temp"
				/>
				<Param
					icon="/widgets_icons/hum.svg"
					value={this.props.info.last_parameter_values.soil_hum}
					units=""
					divClass="ecosystem-right-data"
					data_key="soil_hum"
				/>
			</Grid>
		);
	}
}
export default SoilModule;



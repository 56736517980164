import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SEISMO_DATA_SOURCES } from '../../../../consts';


export interface SeismicDeviceProps {
	currentValue: number;
	setSelectedDataSource: (newValue: number | string) => void;

}


const SeismicDataSource = ({ currentValue, setSelectedDataSource }: SeismicDeviceProps) => {
    
    return (
        <div className="pb-5">
            <FormControl fullWidth className="">
                <InputLabel id="module_type_id-select-label">Источник данных</InputLabel>

                <Select
                    size='small'
                    labelId="module_type_id-select-label"
                    name="module_type_id"
                    value={currentValue}
                    label="Выбранный фильтр"
                    onChange={ (event) => setSelectedDataSource(event.target.value) }
                >
                    <MenuItem value={SEISMO_DATA_SOURCES.seismostation}>Сейсмостанция</MenuItem>
                    <MenuItem value={SEISMO_DATA_SOURCES.smartecosystemsmodule}>Модуль SmartEcoSystems</MenuItem>
                    
                </Select>
            </FormControl>
        </div>
    );
}


export default SeismicDataSource;

import { useEffect, useState } from 'react';
import Device from '../../model/device';
import * as consts from '../../consts';
import axios from 'axios';
import { DEVICE_INFO } from '../../consts';

type WeatherStationsLastParams = {
  windDirection: number;
  windSpeed: number;
};

const handleWeatherStationInfoResponse = (data: { last_parameter_values: Record<string, number> }): WeatherStationsLastParams => {
  return {
    windDirection: data.last_parameter_values['ms_winddir'],
    windSpeed: data.last_parameter_values['ms_windspeed'],
  }
}

export const useWeatherStationLastValues = (deviceId: Device['id_device']): WeatherStationsLastParams | null => {
  const [values, setValues] = useState<null | WeatherStationsLastParams>(null);

  useEffect(() => {
    if (deviceId) {
      // Обработку в реальном времени (по веб сокетам) нужно добавить здесь,
      // UI будет обновляться автоматически, так как зависит от этого хука (при каждом обновлении setValues).

      axios({
        method: "GET",
        url: `${consts.BASE_API_URL}/${DEVICE_INFO[consts.DEVICE_TYPES.METEO].endpointInfo}`,
        params: { device_id: deviceId, deviceType: consts.DEVICE_TYPES.METEO },
      }).then((response) => {
        setValues(handleWeatherStationInfoResponse(response.data));
        })
    } else {
      setValues(null);
    }
  }, [deviceId])

  return values;
}

import { TextField } from "@mui/material";
import { useState } from "react";
import saveDeviceParametersGroup from "../../../api/devices/save.device.parameter.group";
import DeviceParametersGroup from "../../../model/device.parameters.group";

interface EditGroupFormProps {
    groupForEdit: DeviceParametersGroup
    handleClose?: () => void,
    afterSaveSuccess?: () => void
}


export default function AddUpdateForm({ groupForEdit, handleClose, afterSaveSuccess }: EditGroupFormProps) {

    const [group, setGroup] = useState<DeviceParametersGroup>(Object.assign({}, groupForEdit))
    const [saving, setSaving] = useState<boolean>(false)

    const clickSaveGroup = async () => {
        const result = await saveDeviceParametersGroup(group)
        if (result && afterSaveSuccess) {
            afterSaveSuccess()
        }
    }
    return (
        <>
            <>
                {group.name}
            </>

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="name"
                value={group.name}
                size="small"
                label="Название"
                onChange={(event) => {
                    group.name = event.target.value
                    setGroup(Object.assign({}, group))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="label"
                value={group.label}
                size="small"
                label="Описание"
                onChange={(event) => {
                    group.label = event.target.value
                    setGroup(Object.assign({}, group))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="short_label"
                value={group.short_label}
                size="small"
                label="Краткое обозначение"
                onChange={(event) => {
                    group.short_label = event.target.value
                    setGroup(Object.assign({}, group))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="units"
                value={group.units}
                size="small"
                label="Единицы измерения"
                onChange={(event) => {
                    group.units = event.target.value
                    setGroup(Object.assign({}, group))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <hr className="border-dashed my-6" />
            <p className='text-right'>
                <button className="lr-secondary-button" onClick={handleClose}>
                    Закрыть
                </button>
                <button className="ml-5 lr-primary-button" onClick={clickSaveGroup}
                    disabled={saving}

                >
                    {saving ? 'Сохранение...' : 'Сохранить'}
                </button>
            </p>
        </>
    );
}
import { DEFAULT_MIN_VISIBLE_CONCENTRATION } from "../components/LeafletConcentrationMap";
import { linspace, meshgrid } from "../utils/array.utils";

export type ConcentrationModelParameters = {
    Q: number; // source emission rate (kg/s)
    U: number // wind speed (m/s)
    H: number // source height (m)
    K: number // eddy diffusion coefficient (m^2 / s)

    rmax: number // size of domain in r (m)
    ymax: number;  //size of domain in y (m)
    N: number // number of plotting points
}

export type ConcentrationLeafletPoint = {
    lat: number;
    lng: number;
    value: number;
};

export const defaultModelParameters: ConcentrationModelParameters = {
    Q: 1, // source emission rate (kg/s)
    U: 1,  // wind speed (m/s)
    H: 1, // source height (m)
    K: 0.5,  // eddy diffusion coefficient (m^2 / s)

    rmax: 4500, // size of domain in r (m)
    ymax: 2000, //size of domain in y (m)
    N: 400,  // number of plotting points
}

export function convertModelPointToLatLng(x: number, y: number, lat0: number, lng0: number, windDirection: number): Pick<ConcentrationLeafletPoint, 'lat' | 'lng'> {
    const earthRadius = 6371000; // радиус Земли в метрах
    const latCorrection = y / earthRadius; // Коррекция широты, зависящая от y
    const lngCorrection = x / (earthRadius * Math.cos(lat0 * Math.PI / 180)); // Коррекция долготы, зависящая от x и широты

    // Рассчитываем угол между севером и направлением ветра
    const angleFromNorth = (Math.PI / 2) - windDirection;

    // Поворачиваем координаты ветра относительно севера
    const rotatedX = x * Math.cos(angleFromNorth) - y * Math.sin(angleFromNorth);
    const rotatedY = x * Math.sin(angleFromNorth) + y * Math.cos(angleFromNorth);

    const lat = lat0 + (rotatedY / earthRadius) * (180 / Math.PI) + latCorrection;
    const lng = lng0 + (rotatedX / (earthRadius * Math.cos(lat0 * Math.PI / 180))) * (180 / Math.PI) + lngCorrection;

    return { lat: lat, lng: lng };
}

export const calculateConcentrationModelData = ({ Q, U, H, K, rmax, ymax, N }: ConcentrationModelParameters, minVisibleConcentration: number): [number[][], number[][], (number | null)[][]] => {
    const [rr, yy] = meshgrid(linspace(0.005, rmax / U * K, N), linspace(-ymax, ymax, 2 * N));
    const xx = rr.map(val => val.map(v => v * U / K));

    const cc = rr.map((r, index) => {
        const a = r.map(v => 2 * Q / (4 * Math.PI * Math.abs(U) * v));
        const b = r.map((v, i) => Math.exp((-(yy[index][i] ** 2)) / (4 * v)));
        const c = r.map(v => Math.exp(((-H) ** 2) / (4 * v)));

        return a.map((_, i) => a[i] * b[i] * c[i]).map(val => val <= minVisibleConcentration ? null : val);
    });

    return [xx, yy, cc];
}

const MobileCoverPage5 = () => {


    return (
        <>
            <div className="text-center mt-5 flex justify-center items-center">
                <img src="/landing/assets/img/header_05.png" className="w-[80%]" />
            </div>
            <div className="mb-5">
                <div className="text-center">
                    <p className="text-xl font-montserrat">
                        Анализ данных
                    </p>
                    <p className="text-xl font-montserrat mt-5">
                        Проводим научный<br />
                        анализ данных<br />
                        и полевые исследования<br />
                    </p>
                    <p className="more-link-text">
                        <a className="more-link-text" href="#part05">Узнать подробнее</a>
                    </p>
                </div>
            </div>
        </>
    );
}

export default MobileCoverPage5
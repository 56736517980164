import { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
class DevicesListBlock extends Component {
  
    render() {
	return (
	    <div className="devices-list-block">
		<div className="devices-list-block-name">
		    {this.props.block_name} 
		</div>
		<hr />
		<div>
		    {this.props.devices.map((device, i) => {
			return (
			    <Row key={`device_${i}`}>
				<Col md={8} xs={8} onClick={() => this.props.deviceClick(device)}>
				    <div className="devices-list-device-type-name">
					{this.props.device_type_name}
				    </div>
				    <div className="devices-list-device-name">
					{device.name}
				    </div>
				</Col>
				<Col md={2} xs={2} onClick={() => this.props.showDeviceOnMap(device)}>
				    <img className="devices-list-icon-show" src="/static_map/show_device_on_map_s.png" />
				</Col>
				<Col md={2} xs={2} onClick={() => this.props.deviceClick(device)}>
				    <img className="devices-list-icon-show" src="/static_map/show_device_info_s.png" />
				</Col>
			    </Row>
			)
		    })}
		    
		</div>
	    </div>
	);
    }
}

export default DevicesListBlock;





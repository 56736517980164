import * as consts from '../../consts';
import Token from '../../components/token_v2';
import axios from "axios";

export default function logout(callback) {
    axios({
        method: "POST",
        url: `${consts.BASE_API_URL}/logout`,
    })
        .then((response) => {
            const token = new Token()
            token.removeToken()
            if (callback) {
                callback()
            }
        }).catch((error) => {
            if (error.response) {
                console.log(error.response)
                console.log(error.response.status)
                console.log(error.response.headers)
            }
        })
}


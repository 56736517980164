import React, { Component } from 'react';
import axios from "axios";
import * as consts from '../../../consts';
import { Form, Modal, InputGroup } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Button } from '@mui/material';

class LoginForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			login: "",
			password: "",
			request_id: 0,
			isSaving: false,
			showPassword: false,
		}
	}

	saveLogin = () => {
		this.setState({ isSaving: true })
		const formData = new FormData();
		formData.append("login", this.state.login);
		formData.append("password", this.state.password);
		formData.append("request_id", this.state.request_id);
		try {
			axios({
				method: "post",
				url: `${consts.BASE_API_URL}/update_request_account`,
				data: formData,
			})
				.then((response) => {
					if (response.data.result === 'ok') {
						toast.success("Данные аккаунта успешно обновлены!")
						this.props.handleClose()
						this.setState({ isSaving: false })
						setTimeout(() => {
							window.location.reload()
						}, 1000);
					} else if (response.data.result === 'error') {
						this.setState({ isSaving: false })
						let error = response.data.description
						if (!error) {
							error = 'Неизвестная ошибка'
						}
						toast.error(error)
					}
				}).catch((error) => {
					if (error.response) {
						console.log(error.response)
						console.log(error.response.status)
						console.log(error.response.headers)
					}
				});
		} catch (error) {
			console.log(error)
		}
	}

	handleChangeLogin = (event) => {
		this.setState({ login: event.target.value })
	}

	handleChangePassword = (event) => {
		this.setState({ password: event.target.value })
	}

	changeShowPassword = () => {
		this.setState({ showPassword: !this.state.showPassword })
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({ login: nextProps.login, request_id: nextProps.request_id })
	}


	render() {
		return (
			<>
				<ToastContainer autoClose={2000} hideProgressBar={false} />
				<Modal show={this.props.show} onHide={this.props.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Изменение параметров доступа</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group className="mb-3" controlId="login">
								<Form.Label>Логин:</Form.Label>
								<Form.Control type="input" name="login" onChange={this.handleChangeLogin} value={this.state.login} />
							</Form.Group>
							<Form.Group className="mb-3" controlId="password">
								<Form.Label>Пароль:</Form.Label>
								{!this.state.showPassword && <InputGroup className="mb-3">
									<Form.Control type="password" name="password" onChange={this.handleChangePassword} value={this.state.password} />
									<Button variant="outline-secondary">
										<BsEyeFill onClick={this.changeShowPassword} />
									</Button>
								</InputGroup>}
								{this.state.showPassword && <InputGroup className="mb-3">
									<Form.Control type="text" name="password" onChange={this.handleChangePassword} value={this.state.password} />
									<Button variant="outline-secondary">
										<BsEyeSlashFill onClick={this.changeShowPassword} />
									</Button>
								</InputGroup>}
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<button className='lr-secondary-button' onClick={this.props.handleClose}>
							Закрыть
						</button>
						<button className='lr-primary-button' onClick={this.saveLogin}
							disabled={this.state.isSaving}
						>
							{this.state.isSaving ? 'Сохранение...' : 'Сохранить'}
						</button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}

export default LoginForm;


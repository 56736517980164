import SquareIcon from '@mui/icons-material/Square';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import MaterialTable, { MTableBodyRow } from "material-table";
import { useEffect, useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import reorderDeviceParameters from "../../../api/devices/reorder.device.parameters";
import DeviceParameter from "../../../model/device.parameter";
import EcoModal from "../../eco_modal";
import EditDeviceParameterForm from "./EditDeviceParameterForm";

interface DeviceParatersTabProps {
    deviceParamters: DeviceParameter[]
}

export default function DeviceParatersTab({ deviceParamters }: DeviceParatersTabProps) {

    const [showAddUpdateForm, setShowAddUpdateForm] = useState<boolean>(false)
    const [deviceParameterForEdit, setDeviceParameterForEdit] = useState<DeviceParameter>(new DeviceParameter)
    const initialState = useRef<boolean>(false)
    const [tableData, setTableData] = useState<DeviceParameter[]>([]);

    const shortEditDeviceParamterForm = (devParameter: DeviceParameter) => {
        setDeviceParameterForEdit(devParameter)
    }

    useEffect(() => {
        if (!initialState.current) {
            initialState.current = true;
            return
        }
        setShowAddUpdateForm(true)
    }, [deviceParameterForEdit])


    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };

    const afterSaveSuccess = () => {
        notify('Сохранение прошло успешно.')
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }


    const renderColor = (rowData: any) => {
        return (
            <>
                <SquareIcon htmlColor={rowData.color} />{rowData.color}
            </>
        )
    }


    const renderActions = (rowData: any) => {
        return (
            <>
                <FaEdit className="text-2xl inline cursor-pointer" onClick={() => shortEditDeviceParamterForm(rowData)} />
            </>
        )
    }

    const renderLimits = (rowData: DeviceParameter) => {
        const isMaxValue = !isNaN(Number(rowData.max_limit)) && rowData.max_limit != null
        const isMinValue = !isNaN(Number(rowData.min_limit)) && rowData.min_limit != null
        if (isMaxValue && isMinValue) {
            return (
                <>
                    [{rowData.min_limit}...{rowData.max_limit}]
                </>
            )
        }

        if (isMinValue) {
            return (
                <>
                    &gt;{rowData.min_limit}
                </>
            )
        }
        if (isMaxValue) {
            return (
                <>
                    &lt;{rowData.max_limit}
                </>
            )
        }
    }


    const columns = [
        { title: "Название", field: "name" },
        { title: "Описание", field: "label" },
        { title: "Краткое обозначение", field: "short_label" },
        { title: "Единицы измерения", field: "units" },
        { title: "Диапазон", field: "max_limit", render: renderLimits },
        { title: "Цвет", field: "color", render: renderColor },
        { title: "", field: "", render: renderActions },
    ]

    useEffect(() => {
        setTableData(deviceParamters)
    }, [deviceParamters])


    const DrageState = {
        row: -1,
        dropIndex: -1, // drag target
    };

    const offsetIndex = (from: number, to: number, arr: DeviceParameter[]) => {
        if (from < to) {
            let start = arr.slice(0, from),
                between = arr.slice(from + 1, to + 1),
                end = arr.slice(to + 1);
            return [...start, ...between, arr[from], ...end];
        }
        if (from > to) {
            let start = arr.slice(0, to),
                between = arr.slice(to, from),
                end = arr.slice(from + 1);
            return [...start, arr[from], ...between, ...end];
        }
        return arr;
    };
    const reOrderRow = (from: number, to: number) => {
        const newtableData = offsetIndex(from, to, tableData);
        setTableData(newtableData);
        saveNewOrder(newtableData)
    };

    const saveNewOrder = async (newOrder: DeviceParameter[]) => {
        const newOrderIds: number[] = []
        newOrder.map((deviceParameter) => {
            console.log('id:', deviceParameter.id)
            newOrderIds.push(deviceParameter.id)
        })

        const result = await reorderDeviceParameters(newOrderIds)
        if (result) {
            notify('Новый порядок сохранен')
        }
    }


    return (
        <>
            <Alert className="mb-3" severity="info">Для изменения порядка параметров перетаскивайте строки таблицы.</Alert>

            <MaterialTable
                key={`table-dp-${deviceParamters}`}
                columns={columns}
                data={tableData}
                title=""
                options={{
                    paging: false,
                    search: false,
                }}
                

                components={{
                    Row: (props) => (
                        <MTableBodyRow
                            {...props}
                            draggable="true"
                            onDragStart={(e: any) => {
                                console.log("onDragStart");
                                DrageState.row = props.data.tableData.id;
                            }}
                            onDragEnter={(e: any) => {
                                e.preventDefault();
                                if (props.data.tableData.id !== DrageState.row) {
                                    DrageState.dropIndex = props.data.tableData.id;
                                }
                            }}
                            onDragEnd={(e: any) => {
                                console.log(`onDragEnd`);
                                if (DrageState.dropIndex !== -1) {
                                    reOrderRow(DrageState.row, DrageState.dropIndex);
                                }
                                DrageState.row = -1;
                                DrageState.dropIndex = -1;
                            }}
                        />
                    ),
                }} // components
            />

            <EcoModal
                key="addupdate-modal"
                open={showAddUpdateForm}
                deviceParameterForEdit={deviceParameterForEdit}
                handleClose={() => setShowAddUpdateForm(false)}
                content={EditDeviceParameterForm}
                title={`Редактирование информации о параметре`}
                afterSaveSuccess={afterSaveSuccess}
            />

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />

        </>
    );
}
import { Component } from 'react';
import LastData from '../last_data';
import VideoStreamPlayer from '../../videostreamplayer';
import LastDataPanel from './last_data_panel';

const defaultState = {
	data: {},
	parameters: {},
	stream_url: '',
	device_id: 0,
}

class AICameraCarDevice extends Component {
	constructor(props) {
		super(props);
		this.state = defaultState
	}


	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.data) {
			this.handleData(nextProps.data, nextProps.parameters);
		}
	}

	handleData(data, parameters) {
		this.setState({
			info: data.info,
			parameters,
			stream_url: data.info.rtmp_server_stream,
			device_id: data.info.id
		})
	}


	render() {
		if (!this.state.info) {
			return null
		}

		return (
			<div>
				<LastDataPanel 
					deviceParameters={this.props.deviceParameters}
					parameters={this.state.parameters}
					data={{ info: this.state.info }}
				/>

				<hr className="border-dashed my-6" />
				<div className='h4'>Видеопоток</div>
				<div className="mt-5">
					{this.state.stream_url &&
						<VideoStreamPlayer
							file={this.state.stream_url} 
							device_id={this.state.device_id}
						/>
					}
				</div>

			</div>
		);
	}
}
export default AICameraCarDevice;

class Token {

    getToken() {
        const userToken = localStorage.getItem('token');
        return userToken
    }

    saveToken(userToken) {
        localStorage.setItem('token', userToken);
    };

    removeToken() {
        localStorage.removeItem("token");
    }
}

export default Token;


import { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Grid } from '@mui/material';

class Contacts extends Component {
	render() {
		const margin = isMobile ? 'px-5' : 'offset-md-1';
		const hidden = isMobile ? 'hidden' : '';
		const cols = isMobile ? 4 : 3;
		
		return (
			<div className={`contacts`} id="contacts">
				<div className={`row  contacts ${margin}`}>
					<div className="row">
						<div className="col-md-7">
							<div className="c-brand">
								<img src="/brand_image.svg" alt="" />
							</div>
							<div className="text-2xl text-white font-montserrat font-extrabold">
								+7 (953) 529-38-17
							</div>
							<div className="text-2xl text-white font-montserrat font-extrabold">
								smartecosystems@mail.ru
							</div>

							<div className="c-text">
								Республика Карелия, г. Петрозаводск, пр. Ленина 31, пом. 202 <br />
								Петрозаводский государственный университет,<br />
								Центр цифрового мониторинга северных и арктических экосистем <br />
								Малое инновационное предприятие ООО "Умные Экосистемы" <br />
							</div>

							<Grid container spacing={0} className={`pt-10`}>
								<Grid xs={cols}>
									<img src="/landing/contacts/petrsu.png" alt=""
										className="c-brands-link"
										onClick={() => window.open("https://petrsu.ru/structure/8506/tsentr-tsifrovogo-mo", "_blank")}
									/>
								</Grid>
								<Grid xs={cols}>
									<img src="/landing/contacts/innovations.png" alt=""
										className="c-brands-link"
										onClick={() => window.open("https://startup.karelia.ru/", "_blank")}
									/>
								</Grid>
								<Grid xs={cols}>
									<img src="/landing/contacts/fond.png"
										className="c-brands-link" alt=""
										onClick={() => window.open("https://fasie.ru/", "_blank")}
									/>
								</Grid>
							</Grid>
							<div className="c-text">
								Научные исследования осуществляются при грантовой поддержке <br />
								Фонда венчурных инвестиций РК и Фонда содействия инновациям <br />
								в рамках федерального проекта «Платформа университетского технологического <br />предпринимательства»
							</div>
							<div className="mt-5 text-white text-xs font-montserrat">
								<a href="/agreement" target="_blank">Условия пользовательского соглашения</a>
							</div>
							<div className="c-text">
							</div>

						</div>
						<div className="col-md-5  mb-10">
							<div className={`c-sections-list ${hidden}`}>
								<div className="c-text c-text-sections">
									<a href="#part01" className="c-text-link">01 | Сети и сервисы</a>
								</div>
								<div className="c-text c-text-sections">
									<a href="#part02" className="c-text-link">02 | Подключение к сети</a>
								</div>
								<div className="c-text c-text-sections">
									<a href="#part03" className="c-text-link">03 | Оборудование</a>
								</div>
								<div className="c-text c-text-sections">
									<a href="#part04" className="c-text-link">04 | Аэромониторинг</a>
								</div>
								<div className="c-text c-text-sections">
									<a href="#part05" className="c-text-link">05 | Научные исследования</a>
								</div>
								<div className="c-text c-text-sections">

								</div>
							</div>
							<div className="q-button-div">
								<button className="f-button" onClick={() => window.open("/map", "_blank")}>Платформа Мониторинг 4.0</button>
							</div>
							<div>
								<button className="f-button" onClick={this.props.showRequestForm}>Оставить заявку</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Contacts;


import { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WidgetCell from './widget_cell'

class WsMeteo extends Component {
	render() {
		return (
			<Row className="msstation-widget-row-data">
				<WidgetCell
					value={Math.round(this.props.info.last_parameter_values.temp)}
					data_key={'temp'}
					clazz="msstation-widget-row-data-temp"
				/>
				<WidgetCell
					value={Math.round(this.props.info.last_parameter_values.hum)}
					data_key={'hum'}
					clazz="msstation-widget-row-data-hum"
				/>
			</Row>
		);
	}
}
export default WsMeteo;

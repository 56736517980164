import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';


const loadSeismicData = async (station_id, dt_start, dt_end, timeoffset, filter, data_source) => {
    console.log('d:', data_source)
    const headers = getHeaders()
    const result = await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/data/load_seicmic_data`,
		headers,
        data: {station_id, dt_start, dt_end, timeoffset, filter, data_source},
	})

    return result.data
};


export default loadSeismicData
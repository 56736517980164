import { LatLng } from "leaflet";
import { useState } from "react";
import Nav from '../../../nav';
import ProjectsTable from "./ProjectsTable";

export default function ProjectsMain() {
    
    return (
        <>
            <Nav />

            <button className="lr-primary-button m-10 align-bottom">
                Создать проект
            </button>
            

            <section className="m-10 min-h-[600px]">
                <ProjectsTable />
            </section>
        </>
    );
}



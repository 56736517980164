import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SearchDevicesComponent(props) {

    const onTagsChange = (event, value) => {
        props.showDeviceById(value.id)
    }

    return (
        <Autocomplete
            className='absolute right-24 top-2'
            size='small'
            disablePortal
            options={props.deviceNames}
            sx={{ 
                width: 300, 
            }}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    label="Поиск устройства..."
                    sx={{border: 'none',}}
                     
                />
            }
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        {option.label}
                    </li>
                );
            }}
            onChange={onTagsChange}
            
        />
    );
}
import { Component } from 'react';
import * as consts from '../../../consts';
import WidgetModule from '../../map/panel/widgets/widget_module';
import Camera from '../../map/panel/widgets/camera';
import Audio from '../../map/panel/widgets/audio';
import Mstation from '../../map/panel/widgets/msstation';

import Seismic from '../../map/panel/widgets/seismic';
import './widgets.css'
import AICameraCars from './widgets/ai_camera_cars';
import AICameraFairs from './widgets/ai_camera_fires';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const deviceWidgets = {
    [consts.DEVICE_TYPES.METEO]: Mstation,
    [consts.DEVICE_TYPES.METEO_SOKOL]: Mstation,
    [consts.DEVICE_TYPES.METEO_WIFI_STATION]: Mstation,
    [consts.DEVICE_TYPES.KARELIAMETEO_STATION]: Mstation,
    [consts.DEVICE_TYPES.METEO_M]: Mstation,
    [consts.DEVICE_TYPES.PULSE_STATION]: Mstation,
    [consts.DEVICE_TYPES.PULSE_STATION_V2]: Mstation,
    [consts.DEVICE_TYPES.CAMERA]: Camera,
    [consts.DEVICE_TYPES.AUDIO]: Audio,
    [consts.DEVICE_TYPES.MODULE]: WidgetModule,
    [consts.DEVICE_TYPES.SEISMICSTATION]: Seismic,
    [consts.DEVICE_TYPES.AI_CAMERA_CARS]: AICameraCars,
    [consts.DEVICE_TYPES.AI_CAMERA_FIRES]: AICameraFairs,

}

class WidgetsGridMobile extends Component {

    render() {
        if (!this.props.type) {
            return null;
        }

        if (this.props.devices.length == 0) {
            return null;
        }

        return (
            <div className="widgets-grid">
                <Swiper
                    spaceBetween={30}

                    modules={[Navigation]}
                    className="mySwiper w-full py-5"
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 2.1,
                            spaceBetween: 5,
                            slideToClickedSlide: true,
                        },
                    }}
                >
                    {this.props.devices.map((device) => {
                        const Widget = deviceWidgets[device.device_type_id]
                        if (device.device_type_id === consts.DEVICE_TYPES.KARELIAMETEO_STATION && device.meteoinfo_url) {
                            return null
                        }


                        return (
                            <SwiperSlide key={`swiper_slide_${device.device_id}`}>
                                <div key={`eco_widget_${device.device_id}`}>
                                    <Widget
                                        device={device}
                                        info={device}
                                        showDeviceById={this.props.showDeviceById}
                                        file="wss://smartecosystems.petrsu.ru:3334/app/stream"
                                    />
                                </div>
                            </SwiperSlide>)
                    })}
                </Swiper>
                
            </div>
        )
    }
}

export default WidgetsGridMobile;



import { useEffect, useState } from 'react';
import Nav from '../components/nav'
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import getTeddingData from './TeddingData';
import CompareComponent from '../components/map/cc/compare_component';
import Device from '../model/device';
import getDevice from '../api/devices/get.device';



export default function TeddingEchart() {
    const [device, setDevice] = useState<Device | null>(null)

    useEffect(() => {
        const fetchData = async () => {
            const device = await getDevice('d8e75388-fe88-4526-9cce-65fee2eb7119')
            setDevice(device)
        }

        fetchData()

    }, [])

    const data: any[] = getTeddingData();
    const chartHeight = 400;

    var startTime = 1690405200;
    var categories = ['№9', '№8', '№7', '№6', '№5', '№4', '№3', '№2'];

    function renderItem(params: any, api: any) {
        var categoryIndex = api.value(0);
        var start = api.coord([api.value(1), categoryIndex]);
        var end = api.coord([api.value(2), categoryIndex]);
        var height = api.size([0, 1])[1] * 0.6;
        var rectShape = echarts.graphic.clipRectByRect(
            {
                x: start[0],
                y: start[1] - height / 2,
                width: end[0] - start[0],
                height: height
            },
            {
                x: params.coordSys.x,
                y: params.coordSys.y,
                width: params.coordSys.width,
                height: params.coordSys.height
            }
        );
        return (
            rectShape && {
                type: 'rect',
                transition: ['shape'],
                shape: rectShape,
                style: api.style()
            }
        );
    }

    const option = {
        tooltip: {
            formatter: function (params: any) {
                const start = moment(params.value[1] * 1000).format('MM-DD HH:mm')
                const end = moment(params.value[2] * 1000).format('HH:mm')
                const period = start + '-' + end
                return params.marker + params.name + ': ' + period;
            }
        },
        title: {
            text: 'Работы на иловых картах за период 27.07.2023-04.08.2023',
            left: 'center'
        },
        dataZoom: [
            {
                type: 'slider',
                filterMode: 'weakFilter',
                showDataShadow: false,
                top: 400,
                labelFormatter: ''
            },
            {
                type: 'inside',
                filterMode: 'weakFilter'
            }
        ],
        grid: {
            height: 300
        },
        xAxis: {
            min: startTime,
            scale: true,
            axisLabel: {
                formatter: function (val: number) {
                    return moment(val * 1000).format('MM-DD HH:mm')

                }
            }
        },
        yAxis: {
            data: categories
        },
        series: [
            {
                type: 'custom',
                renderItem: renderItem,
                itemStyle: {
                    opacity: 0.8
                },
                encode: {
                    x: [1, 2],
                    y: 0
                },
                data: data
            }
        ]
    };
    return (
        <>
            <Nav />
            <div className='h-full'>

                <ReactEcharts
                    key={`seismic-echart`}
                    option={option}
                    style={{ height: `${chartHeight}px`, width: '100%' }}
                />

                {device &&
                    <CompareComponent
                        placement="bottom"
                        name="comparecomponent"
                        show={true}
                        deleteDeviceFromCompare={(device: Device) => void 0}
                        selectedDevices={[device]}
                        classShowMonitoringPanel={'full'}
                        classShowDeviceInfo={'full'}
                        changeCompareMode={() => void 0}
                        compareMode={false}
                    />
                }
            </div>
        </>
    );
}
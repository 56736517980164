import React, { Component } from 'react';
import axios from "axios";
import * as consts from '../../consts';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip as MuiTooltip } from '@mui/material';

import {
	Chart as ChartJS,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend
);

const options = {
	scale: {
		ticks: {
			display: false,
		}
	}
}

class WindRose extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.getDataForRadar([]),
			period: 'month',
			loading: false,

		}
		this.loadWindRoseData();
	}

	getDataForRadar(data) {
		const labels = this.props.labels ? this.props.labels : consts.WIND_LABELS
		return data = {
			labels,
			datasets: [{
				label: 'Роза ветров',
				data: data,
				backgroundColor: 'rgba(255, 99, 132, 0.2)',
				borderColor: 'rgba(255, 99, 132, 1)',
				borderWidth: 1,
			},
			],
		};
	}


	loadWindRoseData() {
		this.setState({ loading: true })

		axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/load_wind_rose_data`,
			params: { device_id: this.props.device_id, period: this.state.period, second_version: this.props.second }
		})
			.then((response) => {
				this.setState({
					loading: false,
					data: this.getDataForRadar(response.data.data),
				})

			}).catch((error) => {
				if (error.response) {
					console.log(error.response)
				}
			})
	}


	changePeriod(event, newPeriod) {
		this.setState({ period: newPeriod }, () => {
			this.loadWindRoseData()
		})
	};

	render() {
		return (
			<div>
				<ToggleButtonGroup
					color="primary"
					value={this.state.period}
					exclusive
					onChange={this.changePeriod.bind(this)}
					aria-label="Platform"
					size="small"
					className="pt-3 w-full justify-center items-center flex"
				>
					<ToggleButton value="day">
						<MuiTooltip title="Роза ветров за последние 24 часа">
							<p>сутки</p>
						</MuiTooltip>
					</ToggleButton>
					<ToggleButton value="week">
						<MuiTooltip title="Роза ветров за последние 7 дней">
							<p>неделя</p>
						</MuiTooltip>
					</ToggleButton>
					<ToggleButton value="month">
						<MuiTooltip title="Роза ветров за последние 30 дней">
							<p>месяц</p>
						</MuiTooltip>
					</ToggleButton>
					<ToggleButton value="year">
						<MuiTooltip title="Роза ветров за последний год">
							<p>год</p>
						</MuiTooltip>
					</ToggleButton>
				</ToggleButtonGroup>
				{this.state.loading &&
					<div className="mt-5 flex justify-center items-center">
						<CircularProgress />
					</div>
				}
					<Radar options={options} data={this.state.data} />
			</div>
		);
	}
}
export default WindRose





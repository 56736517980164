import axios from 'axios';
import * as consts from '../../consts';
import { AiCameraRoi } from '../../model/ai.camera.roi';
import getHeaders from '../headers';

const loadRoiForDevice = async (device_id: string): Promise<AiCameraRoi[]> => {
    const headers = getHeaders()
    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/devices/load_roi_for_device`,
		headers,
        params: {device_id},
	})

    return result.data.areas as AiCameraRoi[]
};


export default loadRoiForDevice
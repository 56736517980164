import { FormControlLabel, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import getAgroStatus from '../../api/devices/get.agro.status';
import sendAgroControlAction from '../../api/devices/send_agro_control_action';
import Device from '../../model/device';


export interface ControlPanelProps {
    device: Device;
}


export default function ControlPanel({ device }: ControlPanelProps) {

    const [lightState, setLightState] = useState<boolean>(false)
    const [pumpState, setPumpState] = useState<boolean>(false)


    useEffect(() => {
        const fetchData = async () => {
            const response = await getAgroStatus(device.uuid)
            setLightState(response.light_state !== 0)
            setPumpState(response.pump_state !== 0)
        }

        fetchData()

    }, [])

    const clickSendAgroControlAction = async (deviceType: string, action: string) => {
        await sendAgroControlAction(deviceType, action, device.uuid)
    }


    const handleChangeLight = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLightState(event.target.checked);
        const newStatus = event.target.checked ? 'on' : 'off';
        clickSendAgroControlAction('light', newStatus)
    };


    const handleChangePump = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPumpState(event.target.checked);
        const newStatus = event.target.checked ? 'on' : 'off';
        clickSendAgroControlAction('pump', newStatus)
    };

    return (
        <>
            {device &&
                <>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={lightState}
                                onChange={handleChangeLight}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="свет"
                    />

                    <br />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={pumpState}
                                onChange={handleChangePump}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="насос"
                    />

                </>
            }

        </>
    );
}
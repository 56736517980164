import axios from 'axios';
import * as consts from '../../consts';
import SeismicEvent from '../../model/seismic.event';
import getHeaders from '../headers';

export const getSeismicEvent = async (seismic_event_id: number): Promise<SeismicEvent|null> => {

	try {


		const headers = getHeaders()

		const response = await axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/seismicevents/get_seismic_event`,
			headers,
			params: {
				seismic_event_id,
				timeoffset: new Date().getTimezoneOffset() / 60
			},
		})

		return response.data.event as SeismicEvent
	} catch (error: any) {
		console.error(error);

		if (error.response.status === 401) {
			return null
		}

	}
	return null


};

export default getSeismicEvent;
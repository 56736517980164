const MobileCoverPage1 = () => {


    return (
        <>
            <div className="text-center mt-5 flex justify-center items-center">
                <img src="/landing/assets/img/header_01.png" className="w-[80%]" />
            </div>

            <div className="mb-5">
                <div className="text-center">
                    <p className="text-xl font-montserrat">
                        Мониторинг 4.0
                    </p>
                    <p className="text-xl font-montserrat mt-5">
                        Создаем сети и сервисы <br />
                        для мониторинга состояния <br />
                        в режиме реального времени <br />
                    </p>
                    <p className="more-link-text">
                        <a className="more-link-text" href="#part01">Узнать подробнее</a>
                    </p>
                </div>
            </div>
        </>
    );
}

export default MobileCoverPage1
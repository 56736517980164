import { createTheme } from '@mui/material';

const appTheme = createTheme({
	palette: {
		primary: {
			light: '#81b13d',
			dark: '#205400',
			main: '#508104',
			contrastText: '#000',
		},
		secondary: {
			main: '#e1ffe4',
			light: '#fff',
			dark: '#afccb2',
			contrastText: '#000',
		},
		
	},
	
});

appTheme.typography.subtitle1 = {
	fontFamily: "Montserrat",
	fontSize: '0.2rem',
	'@media (min-width:600px)': {
		fontSize: '0.5rem',
	},
	[appTheme.breakpoints.up('md')]: {
		fontSize: '0.8rem',
	},
};

export default appTheme

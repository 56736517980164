import { Component } from 'react';
import { BiArrowBack } from "react-icons/bi";

class AdminHeader extends Component {

  render() {
    const url = this.props.backUrl ? this.props.backUrl : "/lk"
    return (
      <div className="admin-header">
        <BiArrowBack className="admin-back-button" onClick={() => window.location.href = url} />
        <h1 className="admin-header-text h1">{this.props.headerText}</h1>
      </div>
    );
  }
}

export default AdminHeader;


import { Grid, TextField, Tooltip, Typography } from '@mui/material';
import SeismicEvent from '../../../model/seismic.event';
import { FormControlLabel, Switch, FormGroup } from '@mui/material';
import DatePicker, { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { useState } from "react";
import moment from 'moment';
import LinkIcon from '@mui/icons-material/Link';

registerLocale('ru', ru)


export interface SeismicEventEditProps {
    seismicEvent: SeismicEvent;
    updateSeismicValue: (value: string, field: string) => void
    updateSeismicBooleanValue: (value: boolean, field: string) => void
}


export default function SeismicEventEditForm({ seismicEvent, updateSeismicValue, updateSeismicBooleanValue }: SeismicEventEditProps) {

    const [startDate, setStartDate] = useState<Date | null>(new Date(seismicEvent.mstime));

    const changeDate = (newDate: Date) => {
        setStartDate(newDate)
        const sTimeValue = moment(newDate).format('YYYY-MM-DD HH:mm:ss')
        updateSeismicValue(sTimeValue, 'mstime')
    }

    return (
        <>
            <div className="mt-3">
                Список сейсмостанций:&nbsp;
                <ul className="list-disc ml-10">
                    {seismicEvent.stations.map((station, i) => {
                        return <li key={`station-name-${i}`}>
                            {station.name} s/n {station.serial_number}
                            <Tooltip title="Перейти к устройству">
                                <Typography component="a" href={`/map/device/${station.uuid}`} target="_blank">
                                    &nbsp;<LinkIcon />
                                </Typography>
                            </Tooltip>
                        </li>
                    })}
                </ul>
            </div>

            <FormGroup className="my-3">
                <FormControlLabel control={
                    <Switch
                        onChange={(event) => updateSeismicBooleanValue(event.target.checked, 'is_approved')}
                        checked={seismicEvent.is_approved}
                    />}
                    label="Событие подверждено"
                />
            </FormGroup>

            <div className="w-full px-2">
                <Tooltip title="Выбор времени и даты" placement='top-start'>
                    <div className="">
                        <DatePicker
                            className=" w-full"
                            selected={startDate}
                            onChange={changeDate}
                            showTimeSelect
                            timeIntervals={1}
                            timeCaption="Время"
                            dateFormat="dd.MM.yyyy HH:mm:ss"
                            locale="ru"
                        />
                    </div>
                </Tooltip>
            </div>

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="name"
                value={seismicEvent.name}
                size="small"
                label="Название"
                onChange={(event) => updateSeismicValue(event.target.value, 'name')}
                required
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />


            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="notes"
                value={seismicEvent.notes}
                size="small"
                label="Описание"
                onChange={(event) => updateSeismicValue(event.target.value, 'notes')}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        className="mt-5 shadow-none"
                        fullWidth
                        name="epicenter_description"
                        value={seismicEvent.epicenter_description}
                        size="small"
                        label="Описание места"
                        onChange={(event) => updateSeismicValue(event.target.value, 'epicenter_description')}
                        inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        className="mt-5 shadow-none"
                        fullWidth
                        name="epicenter_lat"
                        value={seismicEvent.epicenter_lat}
                        size="small"
                        label="Широта"
                        onChange={(event) => updateSeismicValue(event.target.value, 'epicenter_lat')}
                        inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        className="mt-5 shadow-none"
                        fullWidth
                        name="epicenter_lon"
                        value={seismicEvent.epicenter_lon}
                        size="small"
                        label="Долгота"
                        onChange={(event) => updateSeismicValue(event.target.value, 'epicenter_lon')}
                        inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                    />
                </Grid>
            </Grid>

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="magnitude"
                value={seismicEvent.magnitude}
                size="small"
                label="Магнитуда"
                onChange={(event) => updateSeismicValue(event.target.value, 'magnitude')}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

        </>
    );
}
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function HelpDialog(props) {
    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                className="z-[9999999]"
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Настройка индивидуального отображения панели мониторинга"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Здесь можно настроить следующие параметры отображения панели мониторинга:
                        <ul className="list-disc ml-10">
                            <li>местоположение карты (передвигайте карту с помощью зажатого курсора мыши);</li>
                            <li>масштаб карты (используйте скролл мыши);</li>
                            <li>тип карты (из меню с помощью кнопки "Изменить тип карты");</li>
                            <li>размер и положение виджетов с данным с устройств (перетаскивайте виджеты или растягивайте их за правый нижний угол).</li>
                            
                        </ul>
                        <p className="mt-5">
                            После настройки необходимо сохранить параметры с помощью кнопки из меню "Сохранить настройки".
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Понятно</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
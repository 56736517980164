import { useEffect, useState } from 'react';
import loadPlantPhenologyImages from '../../../../api/data/load.plant.phenology.images';
import { PlantPhenologyImage, PlantPhenologyRequest } from '../../../../model/plant';
import { getUrlForPlantPhenologyImage } from '../../../../utils';
import ImageGallery from 'react-image-gallery';
import CircularProgress from '@mui/material/CircularProgress';
import ResponsiveDatePickers from '../../devices/seismic/datepicker';
import moment, { Moment } from 'moment';

export interface PhenologyGalleryProps {
    deviceId: number,
    Component?: any,
}

export default function PhenologyGallery({ deviceId, Component }: PhenologyGalleryProps) {

    const [images, setImages] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [labelInfo, setLabelInfo] = useState<string>('Последние 24 фото:')
    const [startDate, setStartDate] = useState<Moment | undefined>(moment());

    const fetchData = async (date: Moment | undefined) => {
        setLoading(true)
        const request = new PlantPhenologyRequest()
        request.device_id = deviceId
        if (date) {
            request.dt_start = moment(date).startOf('day').unix()
            request.dt_end = moment(date).endOf('day').unix()
        }

        const imagesInfo = await loadPlantPhenologyImages(request)

        const images = imagesInfo.map((image: PlantPhenologyImage) => {
            const url = getUrlForPlantPhenologyImage(deviceId, image.filename)
            return {
                original: url,
                thumbnail: url,
                description: `${image.mstime}`,
                id: image.id,
            }
        })
        if (imagesInfo.length > 0) {
            setStartDate(moment(imagesInfo[0].mstime))
        }
        setImages(images)
        setLoading(false)
    }

    useEffect(() => {
        fetchData(undefined)
    }, [deviceId])


    const updateDate = (date: any) => {
        const mDate = moment(date)
        setStartDate(mDate)
        fetchData(mDate)

        setLabelInfo(`Фото за ${mDate.format('YYYY-MM-DD')}:`)
    }


    const update = () => {
        if (startDate) {
            fetchData(startDate)
            setLabelInfo(`Фото за ${startDate.format('YYYY-MM-DD')}:`)

        }
    }


    const ImageComponent = Component ? Component : ImageGallery

    return (
        <div className="h-[700px] min-h-full">
            <div className="pt-5">
                <ResponsiveDatePickers
                    className='w-full'
                    date={startDate?.toDate()}
                    onChange={updateDate}
                />
            </div>

            {loading &&
                <div className="flex items-center justify-center mt-5">
                    <CircularProgress />
                </div>
            }
            {!loading && images.length === 0 &&
                <div>
                    Нет доступных фотографий
                </div>
            }
            {!loading && images.length !== 0 &&
                <div className="pt-5">
                    <div>{labelInfo}</div>
                    <ImageComponent
                        items={images}
                        showPlayButton={false}
                        showNav={true}
                        update={update}
                    />
                </div>
            }

        </div>

    );
}
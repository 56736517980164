export interface LeafletHeatmapData {
  lat: number;
  lng: number;
  value: number;
}

export interface LeafletHeatmapConfig {
  min: number;
  max: number;
  data: LeafletHeatmapData[];
}

export type HeatmapGradient = Record<number, string>;

// При желании изменить (сейчас значения подобраны), нужно изменить значения в исходном коде библиотеки temperatureMap
export const defaultHeatmapGradient: HeatmapGradient = {
  0.15: '#4089d6',
  0.35: '#79abe0',
  0.55: '#00ff00',
  0.85: '#ebe426',
  0.92: '#ff0000'
};

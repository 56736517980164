import { isMobile } from 'react-device-detect';
import { Grid } from '@mui/material';
import DevicesList from './sec3_tab_devices';
import Stack from '@mui/material/Stack';
import DeviceCard from './card';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


const devicesInfo = [
    {
        img: '/landing/devices/base_stantion_device.jpg',
        title: 'Базовая станция',
        description: 'Прием данных с модулей и передача на сервер',
        info: [
            'Связь с модулями: до 10 км (LoRa)',
            'Мах количество модулей: до 20 шт',
            'Частота сбора данных: 1 раз/60 мин',
            'Передача данных на сервер: 1 раз/60 мин',
            'Связь с сервером: WiFi, 2G, 3G, LTE (сим-карта)',
            'Доп. параметры: температура и влажность воздуха, атм. давление',
            'Питание: солнечная панель, ветрогенератор'
        ]
    },
    {
        img: '/landing/devices/arctic.jpg',
        title: 'Арктика',
        description: 'Мониторинг снежного покрова и таяния мерзлоты.',
        info: [
            'Толщина снежного покрова (см)',
            'Температура в трех точках (°C)',
            'Влажность поверхности (%)',
            'Доп. параметры: температура и влажность воздуха, атм. давление',
            'Связь с Базовой станцией: до 10 км (LoRa)',
            'Частота сбора и передачи данных: 1 раз/60 мин',
            'Питание: солнечная панель'
        ]
    },
    {
        img: '/landing/devices/agro_device.jpg',
        title: 'Агро',
        description: 'Мониторинг состояния почвы и растений в агрокомплексах.',
        info: [
            'Кислотность почвы (pH)',
            'Азот, фосфор, калий (мг/кг)',
            'Мультиспектры растений (видимый и ИК диапазоны)',
            'Температура почвы (°C)',
            'Влажность почвы (%)',
            'Доп. параметры: температура и влажность воздуха, атм. давление',
            'Связь с Базовой станцией: до 10 км (LoRa)',
            'Частота сбора и передачи данных: 1 раз/60 мин',
            'Питание: солнечная панель',
        ]
    },
    {
        img: '/landing/devices/city.jpg',
        title: 'Город',
        description: 'Мониторинг экологической обстановки города',
        info: [
            'Шум (дБ)',
            'Пыль (PM2.5, PM10)',
            'Радиация (мкР/ч)',
            'Газы (CO, NO₂, NH3)',
            'Доп. параметры: температура и влажность воздуха, атм. давление ',
            'Частота сбора данных: 1 раз/5 мин',
            'Передача данных на сервер: 1 раз/5 мин',
            'Связь с сервером: WiFi, 2G, 3G, LTE (сим-карта) ',
            'Питание: от сети 220 вольт',
        ]
    },
    {
        img: '/landing/devices/plant_photo_square.jpg',
        title: 'Растения',
        description: 'Мониторинг физиологических параметров деревьев',
        info: [
            'Прирост ствола (мм)',
            'Температура ствола (°C)',
            'Влажность ствола (%)',
            'Температура кроны (°C)',
            'Мультиспектры кроны (видимый и ИК диапазоны)',
            'Фенология развития: по фотографии',
            'Доп. параметры: температура и влажность воздуха, атм. давление',
            'Связь с Базовой станцией: до 10 км (LoRa) ',
            'Частота сбора и передачи данных: 1 раз/60 мин',
            'Питание: солнечная панель',
        ]
    },
]


function ModuleArch() {

    return (
        <div className={`mt-10 m-auto`}>
            <Swiper
                spaceBetween={30}

                modules={[Navigation]}
                className="mySwiper w-full"
                showsPagination={false}
                breakpoints={{
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1.1,
                        spaceBetween: 0,
                        slideToClickedSlide: true,
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 2.2,
                        spaceBetween: 0,
                        slideToClickedSlide: true,
                    },
                    // when window width is >= 640px
                    640: { 
                        slidesPerView: 4.3,
                    },
                }}
            >
                {devicesInfo.map((deviceInfo) => {
                    return (
                        <SwiperSlide>
                            <DeviceCard
                                {...deviceInfo}
                            />
                        </SwiperSlide>)
                })}
            </Swiper>
        </div>
    );
}

export default ModuleArch;

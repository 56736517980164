import { Tooltip } from '@mui/material';
import { Component } from 'react';
import * as utils from '../../../../utils';
import Header from './header';
import Footer from './Footer';


class Seismic extends Component {
	constructor(props) {
		super(props);
		this.state = {
			info: props.info,
		}
	}


	render() {
		return (
			<div className="widget-div">
				<Header
					showDeviceById={this.props.showDeviceById}
					info={this.props.info}
				/>
				<div className='max-h-48 overflow-hidden'>
					<Tooltip title="График значений по осям за последний час. Частота обновления 5 минут. Для получения подробной информации нажмите на заголовок виджета.">
						<img src={utils.getUrlForSeismicSparkline(this.props.info.device_id)} />
					</Tooltip>
				</div>

				<Footer device={this.props.info} />
			</div>
		);
	}
}
export default Seismic;


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ImageGallery from 'react-image-gallery';
import { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Grid, Stack } from '@mui/material';

const sec1_tab_tools = require('../../landing/sec1_tab_tools.html');

class Sec1Tabs extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showImageClass: '',
		}
		this.offset = isMobile ? ' w-full justify-center ' : 'offset-md-1 col-10';
		this.textSize = isMobile ? 'text-lg' : '';

	}

	selectTab = (index, label) => {
		const clazz = index == 'examples' ? 'hidden' : '';
		this.setState({ showImageClass: clazz })
	}

	


	render() {
		return (
			<>
				<div className={`row ${this.offset}  relative mt-2 f-text flex font-montserrat`}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<img src="/landing/assets/img/notebook_full.png" width="90%" />
						</Grid>
						<Grid item xs={12} md={6} className="justify-center flex items-center">
							<Stack>
									<ul className={`list-disc font-montserrat leading-8 ${this.textSize}`}>
										<li>Доступ к данным через веб-браузер</li>
										<li>Построение графиков и диаграмм в <br /><span className='mt-0'>режиме реального времени или архива</span></li>
										<li>Встроенные аналитические алгоритмы <br /><span>искусственного интеллекта</span></li>
										<li>Автоматическое оповещение клиента<br /><span>по специальному протоколу</span></li>
										<li>Импорт данных в Excel</li>
									</ul>
									<button className="f-button mt-6" onClick={() => window.open('/map')}>Платформа Мониторинг 4.0</button>
							</Stack>
						</Grid>
					</Grid>


				</div>
			</>
		);
	}
}

export default Sec1Tabs;


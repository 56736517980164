import Nav from '../../nav';
import AdminHeader from '../header';
import DeviceTypes from './DeviceTypes';
import { useEffect, useState } from 'react';
import { getDevicesTypes } from '../../../api/devices/get.devices.types';
import { DeviceType, ModuleType } from '../../../model/device.type';
import NotAccessPage from '../../lk/NotAccessPage';


export default function DeviceTypesMain() {
    const [deviceTypes, setDeviceTypes] = useState<DeviceType[]|null>([])
    const [moduleTypes, setModuleTypes] = useState<ModuleType[]>([])
    const [loading, setLoading] = useState(false)



    const fetchData = async () => {
        setLoading(true)

        const response = await getDevicesTypes()

        if (response) {
            setDeviceTypes(response.device_types)
            setModuleTypes(response.module_types)
        } else {
            setDeviceTypes(null)
        }

        setLoading(false)
    }


    useEffect(() => {
        fetchData()
    }, [])



    if (deviceTypes === null) {
        return <NotAccessPage />
    }

    return (
        <>
            <Nav />

            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AdminHeader headerText={`Типы устройств`} backUrl={`/lk`} />

                    <DeviceTypes 
                        moduleTypes={moduleTypes}
                        deviceTypes={deviceTypes}
                    />
                </div>
            </section>

        </>
    );
}
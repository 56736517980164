import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ImageGallery from 'react-image-gallery';
import ScienceExamples from './science_examples';

function Step5Row(props) {
	return (
		<div className="row f-text-div f-text">
			<Row>
				<Col md={1} xs={1}>
					&nbsp;
				</Col>
				<Col md={10} xs={10}>
					<ScienceExamples
						showRequestForm={props.showRequestForm}
						closeRequestForm={props.closeRequestForm}
					/>
				</Col>
			</Row>
		</div>
	);
}

export default Step5Row;




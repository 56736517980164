import Nav from '../nav';
import { Navigate, Link } from "react-router-dom";
import React, { Component } from 'react';
import axios from "axios";
import * as consts from '../../consts';
import OvenPlayer from "ovenplayer";

class Camera extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: props.token,
		}
	}

	componentDidMount() {
		const div = document.getElementById("player_id")
		if (div) {
			const player = OvenPlayer.create("player_id", {
				mute: false,
				volume: 100,
				sources: [
					{
						type: "webrtc",
						file: 'wss://smartecosystems.petrsu.ru:3334/app/stream1',
					},
				],
			});
		}
	}

	render() {
		return (
			<>
				<Nav />
				<section>
					<div className="container px-5 admin-section">
						<h1>Камера</h1>
						<div>
							<div id="player_id"></div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default Camera;



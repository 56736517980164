const MobilePage = (props) => {

    return (

        <div className="h-full">
            <div className="text-xl color-white font-montserrat text-center w-full px-5">
                Сервис мониторинга <br/>среды и природы 
            </div>

            <props.page />
        </div>
    );
}

export default MobilePage
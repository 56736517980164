class DeviceTypeIcon {
    id: number = NaN;

    device_type_id: number | undefined = undefined;
    module_type_id: number | undefined= undefined;
    icon_file: string | undefined = undefined;
    condition_min_value: number | string | undefined = NaN;
    condition_max_value: number | string | undefined = NaN;
    device_parameter_id: number | undefined  = undefined;
}

export default DeviceTypeIcon;

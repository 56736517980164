import { LatLngTuple } from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { Circle, MapContainer, TileLayer, GeoJSON, Marker } from "react-leaflet";
import Device from "../../../model/device";
import SeismicEvent from "../../../model/seismic.event";
import CenterInfo from "../../../model/center.info";
import LoadingButton from '@mui/lab/LoadingButton';

import * as turf from '@turf/turf'
import L from 'leaflet';


L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export interface SeismicEventCenterMapProps {
    seismicEvent: SeismicEvent;
    centers: CenterInfo[];
}

export default function SeismicEventCenterMap({ seismicEvent, centers }: SeismicEventCenterMapProps) {

    const [bounds, setBounds] = useState<LatLngTuple[]>([])
    const [epicenter, setEpicenter] = useState<any>(null)
    const [intersectionPoints, setIntersectionPoints] = useState<LatLngTuple[]>([])
    const [drawCenters, setDrawCenters] = useState<CenterInfo[]>([])
    const [calculating, setCalculating] = useState<boolean>(false)


    useEffect(() => {

        const _bounds: LatLngTuple[] = []
        seismicEvent.stations.map((station: Device) => {
            const location: LatLngTuple = [station.latitude, station.longitude]
            _bounds.push(location)
        })

        if (seismicEvent.epicenter_lat && seismicEvent.epicenter_lon) {
            _bounds.push([seismicEvent.epicenter_lat, seismicEvent.epicenter_lon])
        }

        const circles: any[] = []
        centers.map((center: CenterInfo) => {
            const options = { steps: 5000 };
            const circle = turf.circle([center.longitude, center.latitude], center.radius / 1000, options);
            circles.push(circle)
        })

        const _intersectionPoints: LatLngTuple[] = []

        if (seismicEvent.id === 358) {
            _intersectionPoints.push([66.54597333558185, 34.26681487637291])
            _intersectionPoints.push([67.05495449373771, 29.115409293061667])

            _intersectionPoints.push([65.47992049638691, 27.4706255206279])
            _intersectionPoints.push([66.59348535323502, 34.195343912645434])

            _intersectionPoints.push([66.59288837798245, 34.126465992643155])
            _intersectionPoints.push([63.818947572792794, 24.902742131497398])
        }
        if (seismicEvent.id === 529) {

            _intersectionPoints.push([65.47992049638691, 27.4706255206279])
            _intersectionPoints.push([66.59348535323502, 34.195343912645434])

            // _intersectionPoints.push([66.59288837798245, 34.126465992643155])
            // _intersectionPoints.push([63.818947572792794, 24.902742131497398])
        }

        //setIntersectionPoints(_intersectionPoints)

        setBounds(_bounds)


    }, [])

    if (bounds.length == 0) {
        return <></>
    }

    const animateCalculatingCenter = () => {
        setCalculating(true)
        updateCenters(0)
    }

    const updateCenters = (centerIndex: number) => {
        if (centerIndex > centers.length) {
            setCalculating(false)
            setEpicenter([seismicEvent.epicenter_lat, seismicEvent.epicenter_lon])
            return
        }

        setDrawCenters(centers.slice(0, centerIndex))

        setTimeout(() => {
            updateCenters(centerIndex + 1)
        }, 1000)
    }


    return (
        <>
            <LoadingButton
                onClick={animateCalculatingCenter}
                loading={calculating}
                loadingIndicator="Расчет..."
                variant="outlined"
                className="mb-4"
            >
                <span>Расcчитать эпицентр</span>
            </LoadingButton>

            <MapContainer
                style={{ height: "500px" }}
                bounds={bounds}
            >
                <TileLayer
                    url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
                {drawCenters.map((center: CenterInfo, index: number) => {
                    return <Circle
                        key={`center-circle-${index}`}
                        center={{ lat: center.latitude, lng: center.longitude }}
                        pathOptions={{ fillOpacity: 0, color: "blue" }}
                        radius={center.radius}

                    />
                })}

                {seismicEvent.epicenter_lat && seismicEvent.epicenter_lon &&
                    <Circle
                        center={{ lat: seismicEvent.epicenter_lat, lng: seismicEvent.epicenter_lon }}
                        pathOptions={{ fillOpacity: 0, color: "green" }}
                        radius={1000}
                    />
                }
                {epicenter &&
                    <Circle
                        center={{ lat: epicenter[0], lng: epicenter[1] }}
                        pathOptions={{ fillOpacity: 0, color: "red" }}
                        radius={200}
                    />
                }
                {epicenter &&
                    <Marker
                        position={{ lat: epicenter[0], lng: epicenter[1] }}
                    />
                }


                {intersectionPoints.map((point: LatLngTuple, index: number) => {
                    return <Marker
                        key={`marker-${index}`}
                        position={{ lat: point[0], lng: point[1] }}
                    />
                })}

            </MapContainer>
        </>
    );
};
/**
 * Consts for project.
 */
import aicameracardata from './components/map/aicameracardata';
import basecamdata from './components/map/basecamdata';
import meteodata from './components/map/meteodata';
import meteoKareliaStation from './components/map/meteodata_kareliastation';
import meteodataSokol from './components/map/meteodata_sokol';
import meteoWiFiStation from './components/map/meteowifidata';
import modulebasestation from './components/map/modulebasestation';
import moduledata from './components/map/moduledata';
import meteodataM from './components/map/meteodata_m';
import pulseStation from './components/map/pulsestation';


import seismicdata from './components/map/seismicdata';


import agro from './components/map/agro_module';
import gas from './components/map/gas_module';
import gas_v2 from './components/map/gas_module_v2';


import { iconAudio, iconCamera, iconCity, iconMeteo, iconPlant, iconSeismic, 
		 iconSelectedCity, iconSelectedMeteo, iconSelectedPlant, iconSelectedSeismic, 
		 iconSelectedSoil, iconSoil } from './components/map/icons';
import modulews from './components/map/modulews';
import soil from './components/map/soil_module';

import AICameraCarDevice from './components/map/devices/ai_camera_car';
import AICameraFireDevice from './components/map/devices/ai_camera_fire/CameraFireMain';
import AudioDevice from './components/map/devices/audio';
import CameraDevice from './components/map/devices/camera';
import MeteoDevice from './components/map/devices/meteo';
import KarelMeteoDevice from './components/map/devices/meteo_karel';
import MeteoSokolDevice from './components/map/devices/meteo_sokol';
import MeteoWifiDevice from './components/map/devices/meteo_wifi';
import ModuleDevice from './components/map/devices/module';
import SeismicDevicePanelInfo from './components/map/devices/seismic/SeismicDevicePanelInfo';
import MeteoM from './components/map/devices/meteo_m';
import PulseStation from './components/map/devices/pulse_station/pulse_station';

import PlantMainInfo from './components/map/device_info/plant/PlantMain';
import AgroMainInfo from './components/map/device_info/agro/AgroMain';

export const BASE_API_URL = '/api/v1';

export const WIND_LABELS = ['С', 'ССВ', 'СВ', 'ВСВ', 'В', 'ВЮВ', 'ЮВ', 'ЮЮВ', 'Ю', 'ЮЮЗ', 'ЮЗ', 'ЗЮЗ', 'З', 'ЗСЗ', 'СЗ', 'ССЗ'];

export const KAREL_WIND_LABELS = ['С', 'СВ', 'В', 'ЮВ', 'Ю', 'ЮЗ', 'З', 'СЗ'];


export const DEVICE_TYPES = {
	METEO: 1,
	
	SEISMICSTATION: 6,
	MODULE: 3,
	CAMERA: 4,
	AUDIO: 5,
	AI_CAMERA_CARS: 7,
	METEO_SOKOL: 8,
	METEO_WIFI_STATION: 9,
	KARELIAMETEO_STATION: 10,
	AI_CAMERA_FIRES: 11,
	METEO_M: 12,
	PULSE_STATION: 13,
	PULSE_STATION_V2: 14,
}

export const MODULE_TYPES = {
	BASESTATION: 0,
	WS: 1,
	UE1: 2,
	BASECAM: 3,
	SOIL: 4,
	AGRO: 5,
	GAS: 6,
	GAS_V2: 7,
}

export const COMPARE_MODE = {
	OFF: false,
	ON: true,
}

export const DEVICE_INFO = {
	[DEVICE_TYPES.METEO]: {
		name: 'Метеостанция',
		plural_name: 'Метеостанции',
		icon: iconMeteo,
		comparable: true,
		iconSelected: iconSelectedMeteo,
		endpointInfo: 'device_info',
		infoComponent: MeteoDevice,
		widgetHeight: 1,
		parameters: meteodata,
	},
	[DEVICE_TYPES.METEO_SOKOL]: {
		name: 'Метеостанция Сокол',
		plural_name: 'Метеостанции Сокол',
		icon: iconMeteo,
		comparable: true,
		iconSelected: iconSelectedMeteo,
		endpointInfo: 'device_info_sokol',
		infoComponent: MeteoSokolDevice,
		widgetHeight: 1,
		parameters: meteodataSokol,
	},
	[DEVICE_TYPES.METEO_WIFI_STATION]: {
		name: 'WiFiMeteo станция',
		plural_name: 'WiFiMeteo станции',
		icon: iconMeteo,
		comparable: true,
		iconSelected: iconSelectedMeteo,
		endpointInfo: 'device_info_wifimeteo',
		infoComponent: MeteoWifiDevice,
		widgetHeight: 1,
		parameters: meteoWiFiStation,
	},
	[DEVICE_TYPES.KARELIAMETEO_STATION]: {
		name: 'Метеостанция Гидрометцентра России',
		plural_name: 'Метеостанции Гидрометцентра России',
		icon: iconMeteo,
		comparable: true,
		iconSelected: iconSelectedMeteo,
		endpointInfo: 'device_info_kareliameteo',
		infoComponent: KarelMeteoDevice,
		widgetHeight: 1,
		parameters: meteoKareliaStation,
	},
	[DEVICE_TYPES.METEO_M]: {
		name: 'Метеостанция М',
		plural_name: 'Метеостанции М',
		icon: iconMeteo,
		comparable: true,
		iconSelected: iconSelectedMeteo,
		endpointInfo: 'device_info_meteo_m',
		infoComponent: MeteoM,
		widgetHeight: 1,
		parameters: meteodataM,
	},
	[DEVICE_TYPES.SEISMICSTATION]: {
		name: 'Сейсмостанция',
		plural_name: 'Сейсмостанции',
		icon: iconSeismic,
		comparable: true,
		iconSelected: iconSelectedSeismic,
		endpointInfo: 'seismic_info',
		infoComponent: SeismicDevicePanelInfo,
		widgetHeight: 1,
		parameters: seismicdata,
	},
	[DEVICE_TYPES.MODULE]: {
		name: 'Модуль',
		plural_name: 'Модули',
		icon: iconMeteo,
		comparable: true,
		iconSelected: iconSelectedMeteo,
		endpointInfo: 'module_info',
		infoComponent: ModuleDevice,
		widgetHeight: 1,
	},
	[DEVICE_TYPES.CAMERA]: {
		name: 'Фотоловушка',
		plural_name: 'Фотоловушки',
		icon: iconCamera,
		comparable: false,
		endpointInfo: 'camera_info',
		infoComponent: CameraDevice,
		widgetHeight: 2,
	},
	[DEVICE_TYPES.AUDIO]: {
		name: 'Аудиоулавливатель',
		plural_name: 'Аудиоулавливатели',
		icon: iconAudio,
		comparable: true,
		endpointInfo: 'audio_info',
		infoComponent: AudioDevice,
		widgetHeight: 1,
	},
	[DEVICE_TYPES.AI_CAMERA_CARS]: {
		name: 'Модуль ИИ',
		plural_name: 'Модули ИИ',
		icon: iconCamera,
		comparable: true,
		iconSelected: iconSelectedSeismic,
		endpointInfo: 'ai_camera_car_info',
		infoComponent: AICameraCarDevice,
		widgetHeight: 2,
		parameters: aicameracardata,
	},
	[DEVICE_TYPES.AI_CAMERA_FIRES]: {
		name: 'Модуль ИИ пожары',
		plural_name: 'Модули ИИ пожары',
		icon: iconCamera,
		comparable: false,
		endpointInfo: 'ai_camera_fire_info',
		infoComponent: AICameraFireDevice,
		widgetHeight: 2,
	},
	[DEVICE_TYPES.PULSE_STATION]: {
		name: 'Пульсационная станция',
		plural_name: 'Пульсационные станции',
		icon: iconCamera,
		comparable: true,
		endpointInfo: 'pulse_station_info',
		infoComponent: PulseStation,
		widgetHeight: 2,
		parameters: pulseStation,

	},
	[DEVICE_TYPES.PULSE_STATION_V2]: {
		name: 'Пульсационная станция V2',
		plural_name: 'Пульсационные станции V2',
		icon: iconCamera,
		comparable: true,
		endpointInfo: 'pulse_station_info_v2',
		infoComponent: PulseStation,
		widgetHeight: 2,
		parameters: pulseStation,

	},
}


export const MODULE_INFO = {
	[MODULE_TYPES.UE1]: {
		name: 'Город',
		widgetHeight: 1,
		parameters: moduledata,
		icon: iconCity,
		comparable: true,
		iconSelected: iconSelectedCity,
	},
	[MODULE_TYPES.BASESTATION]: {
		name: 'Базовая станция',
		widgetHeight: 1,
		parameters: modulebasestation,
		comparable: true,
	},
	[MODULE_TYPES.BASECAM]: {
		name: 'Растения',
		widgetHeight: 2,
		parameters: basecamdata,
		icon: iconPlant,
		iconSelected: iconSelectedPlant,
		comparable: true,
		infoComponent: PlantMainInfo,
	},
	[MODULE_TYPES.WS]: {
		name: 'Метеостанция',
		widgetHeight: 1,
		parameters: modulews,
		comparable: true,
	},
	[MODULE_TYPES.SOIL]: {
		name: 'Почва',
		widgetHeight: 1,
		parameters: soil,
		icon: iconSoil,
		iconSelected: iconSelectedSoil,
		comparable: true,
	},
	[MODULE_TYPES.AGRO]: {
		name: 'Агро',
		widgetHeight: 1,
		parameters: agro,
		icon: iconSoil,
		iconSelected: iconSelectedSoil,
		comparable: true,
		infoComponent: AgroMainInfo,
	},
	[MODULE_TYPES.GAS]: {
		name: 'Газоанализатор',
		widgetHeight: 1,
		parameters: gas,
		icon: iconSoil,
		iconSelected: iconSelectedSoil,
		comparable: true,
	},
	[MODULE_TYPES.GAS_V2]: {
		name: 'Газоанализатор V2',
		widgetHeight: 1,
		parameters: gas_v2,
		icon: iconSoil,
		iconSelected: iconSelectedSoil,
		comparable: true,
	},
}


export const PANEL_SECTIONS = [
	{
		id: 1,
		name: 'Метеостанция',
		plural_name: 'Метеостанции',
		widgetHeight: 1,
		parameters: meteodata,
		device_types: [
			DEVICE_TYPES.METEO, DEVICE_TYPES.METEO_SOKOL, 
			DEVICE_TYPES.METEO_WIFI_STATION, DEVICE_TYPES.KARELIAMETEO_STATION,
			DEVICE_TYPES.METEO_M
		]
	},
	{
		id: 6,
		name: 'Сейсмостанция',
		plural_name: 'Сейсмостанции',
		widgetHeight: 1,
		parameters: seismicdata,
		device_types: [DEVICE_TYPES.SEISMICSTATION]
	},
	{
		id: 3,
		name: 'Модуль Smart Ecosystems',
		plural_name: 'Модули Smart Ecosystems',
		widgetHeight: 1,
		device_types: [DEVICE_TYPES.MODULE, DEVICE_TYPES.AUDIO, DEVICE_TYPES.AI_CAMERA_CARS, DEVICE_TYPES.CAMERA, DEVICE_TYPES.AI_CAMERA_FIRES],
	},
	{
		id: 7,
		name: 'Пульсационная станция',
		plural_name: 'Пульсационные станции',
		widgetHeight: 1,
		device_types: [DEVICE_TYPES.PULSE_STATION, DEVICE_TYPES.PULSE_STATION_V2],
	}
]


export const getInfoForDevice = (device) => {
	if (device.device_type_id === DEVICE_TYPES.MODULE) {
		return MODULE_INFO[device.module_type_id];
	} else {
		return DEVICE_INFO[device.device_type_id];
	}
}


export const weatherIcons = [
    {
        'icon': 'full_sun.svg',
        'description': 'Солнечно',
    },
	{
        'icon': 'clouds_and_line.svg',
        'description': 'Плотные облака',
    },
	{
        'icon': 'clouds_bigrain.svg',
        'description': 'Сильный дождь',
    },
	{
        'icon': 'clouds_rain_snow.svg',
        'description': 'Дождь со снегом',
    },
	{
        'icon': 'rain.svg',
        'description': 'Дождь',
    },
	{
        'icon': 'clouds_snow.svg',
        'description': 'Снег',
    },
	{
        'icon': 'clouds.svg',
        'description': 'Облачно',
    },
	{
        'icon': 'night.svg',
        'description': 'Ночь',
    },
	{
        'icon': 'snow.svg',
        'description': 'Сильный снег',
    },
	{
        'icon': 'storm.svg',
        'description': 'Гроза',
    },
	{
        'icon': 'sunrise.svg',
        'description': 'Восход',
    },
	{
        'icon': 'sunset.svg',
        'description': 'Закат',
    },
	{
        'icon': 'sun_with_clouds.svg',
        'description': 'Малооблачно',
    },
]


export const LOCAL_STORAGE_KEYS = {
	'SEISMO_DATA_SOURCE': "seismo_data_source"
}


export const SEISMO_DATA_SOURCES = {
	'seismostation': 0,
	'smartecosystemsmodule': 1,
}

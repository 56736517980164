import Nav from '../nav';
import { Navigate } from "react-router-dom";
import { Component } from 'react';
import axios from "axios";
import * as consts from '../../consts';
import Token from '../token_v2';
import UserActions from './UserActions';
import checkUserIsAdmin from '../../api/user/check.user.isadmin';

class LK extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: new Token(),
			isAdmin: false,
		}
		this.checkToken(this.state.token.getToken());
	}

	checkToken(token) {
		axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/lk`,
			headers: {
				Authorization: 'Bearer ' + token
			}
		})
			.then(async (response) => {
				const res = response.data
				res.access_token && this.state.token.setToken(res.access_token)
				const isAdmin = await checkUserIsAdmin();
				this.setState({ isAdmin })
			}).catch((error) => {
				if (error.response) {
					this.state.token.removeToken();
					this.setState({ token: undefined })
					console.log(error.response)
					console.log(error.response.status)
					console.log(error.response.headers)
				}
			})
	}


	render() {
		if (!this.state.token || !this.state.token.getToken()) {
			return (<Navigate to="/login" />)
		}
		return (
			<>
				<Nav />

				<div className="lr-container py-5 min-h-[600px]">

					<h1 className='h1'>Личный кабинет</h1>
					<UserActions />

					{this.state.isAdmin &&
						<div className={`mt-5 mr-1`}>
							<hr className="border-dashed my-6" />

							<p>
								Возможности администратора:
							</p>
							<ul className="list-disc">
								<li><a className="text-blue-500 underline" href="/admin/requests">Управление пользователями</a></li>
								<li><a className="text-blue-500 underline" href="/admin/resources">Управление ресурсами</a></li>
								<li><a className="text-blue-500 underline" href="/admin/map/public">Настройка общедоступной панели мониторинга</a></li>
								<li><a className="text-blue-500 underline" href="/admin/devices/types">Управление типами устройств</a></li>
								<li><a className="text-blue-500 underline" href="/admin/device_parameters/groups">Управление группами параметров</a></li>
								<li><a className="text-blue-500 underline" href="/admin/devices/groups">Управление группами устройств</a></li>

							</ul>
						</div>
					}


					{!this.state.isAdmin &&
						<div className={`mt-5 mr-1`}>
							<hr className="border-dashed my-6" />

							<p>
								Другие возможности:
							</p>
							<ul className="list-disc">
								<li><a className="text-blue-500 underline" href="/admin/devices/types">Управление типами устройств</a></li>
							</ul>
						</div>
					}
				</div>
			</>
		);
	}
}

export default LK;


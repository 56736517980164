import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import DevicesGroup from '../../model/devices.group';

class GroupParams {
    group_slug: string = "";
}

const defaultLayouts = {
	[consts.DEVICE_TYPES.METEO]: [],
	[consts.DEVICE_TYPES.CAMERA]: [],
	[consts.DEVICE_TYPES.AUDIO]: [],
	[consts.DEVICE_TYPES.MODULE]: [],
}

export const getGroup = async (groupSlug?: string): Promise<DevicesGroup> => {

	const headers = getHeaders()

	console.log(groupSlug)
	const params = new GroupParams
	if (groupSlug) {
		params.group_slug = groupSlug
	}
	console.log(params)
	const response = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/devices/get_group`,
		headers,
		params,
	})
	const group = response.data.group as DevicesGroup
	if (!group.mpanel_layouts) {
		group.mpanel_layouts = JSON.stringify(defaultLayouts);
	}


	return group
};

export default getGroup;

import { LatLng, LatLngTuple } from "leaflet";
import { useMemo, useState } from "react";
import { Circle, useMap } from "react-leaflet";


export interface MissionCircleProps {
    cntr: LatLngTuple;
    number: number;
    handlers: any;
    updateCircles: (number: number, newCenter: LatLng) => void;
    dropCircle: (number: number) => void;
}


export default function MissionCircle({ cntr, number, handlers, updateCircles, dropCircle }: MissionCircleProps) {
    const [circleSelected, setCircleSelected] = useState<boolean>(false)
    const [center, setCenter] = useState<LatLngTuple>(cntr)
    const map = useMap()

    const eventHandlers = useMemo(
        () => {
            return {
                
                mousedown() {
                    setCircleSelected(true)
                    map.dragging.disable()
                },
                dblclick() {
                    dropCircle(number)
                },
                mouseover() {
                    map.removeEventListener('dblclick')
                },
                mouseout() {
                    map.addEventListener('dblclick', handlers.dblclick)
                }
            }
        },
        [],
    )

    const eventHandlers2 = useMemo(
        () => {
            return {
                mouseup() {
                    setCircleSelected(false)
                    map.dragging.enable()

                },
                mousemove(e: any) {
                    setCenter([e.latlng.lat, e.latlng.lng])
                    updateCircles(number, e.latlng)
                },
                mouseout() {
                    setCircleSelected(false)
                    map.dragging.enable()
                    map.addEventListener('dblclick', handlers.dblclick)

                },
                mouseover() {
                    map.removeEventListener('dblclick')
                }
            }
        },
        [],
    )

    const events = circleSelected ? eventHandlers2 : eventHandlers
    const fillOpacity = number === 0 ? 1 : 0.5;

    return (
        <Circle
            center={center}
            eventHandlers={events}
            pathOptions={{ fillOpacity: fillOpacity, color: '#508104' }}
            radius={100}>
        </Circle>
    )
}

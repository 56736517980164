import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export default (props) => {
    return (
        <>
            <FormControl fullWidth className="">
                <InputLabel id="module_type_id-select-label">Выбранный фильтр</InputLabel>

                <Select
                    size='small'
                    labelId="module_type_id-select-label"
                    name="module_type_id"
                    value={props.value}
                    label="Выбранный фильтр"
                    onChange={ (event) => props.setSelectedFilter(parseInt(event.target.value)) }
                >
                    <MenuItem value={0}>Без фильтра</MenuItem>
                    <MenuItem value={1}>Фильтр low/high cut (2-20)</MenuItem>
                    <MenuItem value={2}>FF: без фильтра</MenuItem>
                    <MenuItem value={3}>FF: фильтр low/high cut (2-20)</MenuItem>
                </Select>
            </FormControl>
        </>
    );
}
import { Component } from 'react';
import * as utils from '../../../../utils';
import Button from 'react-bootstrap/Button';
import Header from './header';
import Footer from './Footer';

class Camera extends Component {
	render() {
		return (

			<div className="widget-div">
				<Header
					showDeviceById={this.props.showDeviceById}
					info={this.props.info}
				/>
				<div className="flex justify-center align-middle items-center">
					<img src={utils.getUrlForResource({ ...this.props.info.last_parameter_values })} className='w-32' />
				</div>

				<Footer device={this.props.info} />
			</div>

		);
	}
}
export default Camera;


import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import getDevices from '../../../api/devices/get.devices';
import getDevicesLite from '../../../api/devices/get.devices.lite';

import Device from '../../../model/device';
import EcoModal from '../../eco_modal';
import Nav from '../../nav';
import NoItems from '../../NoItems';
import AdminHeader from '../header';
import RemoveForm from '../resources/remove_modal';
import AddUpdateForm from './addupdate';
import UpdateAISettingsForm from './ai_settings_car';
import DevicesTable from './table';


export interface SeismicEventsShortTableProps {
	token: any
}


const Devices = ({ token }: SeismicEventsShortTableProps) => {

	const [showAddUpdateForm, setShowAddUpdateForm] = useState(false)
	const [devices, setDevices] = useState<Device[]>([])
	const [deviceIdForRemove, setDeviceIdForRemove] = useState<number | undefined>(undefined)
	const [showAiSettingsCarModal, setShowAiSettingsCarModal] = useState(false)
	const [deviceForEdit, setDeviceForEdit] = useState<Device>(new Device())
	const [showRemoveForm, setShowRemoveForm] = useState(false)
	const [loading, setLoading] = useState(false)


	useEffect(() => {
		loadDevices()
	}, [])


	const loadDevices = async () => {
		setLoading(true)
		const deviceResponse = await getDevicesLite()
		setDevices(deviceResponse.devices)
		setLoading(false)
	}


	const showUpdateForm = (deviceForEdit: Device) => {
		setShowAddUpdateForm(true)
		setDeviceForEdit(deviceForEdit)
	}

	const handleShowRemoveForm = (deviceId: number) => {
		setShowRemoveForm(true)
		setDeviceIdForRemove(deviceId)
	}

	const openAiSettingsCarModal = (device: Device) => {
		setShowAiSettingsCarModal(true)
		setDeviceForEdit(device)
	}

	if (!token) {
		return (<Navigate to="/login" />)
	}

	return (
		<>
			<Nav />
			<section className="mt-0 min-h-[600px]">
				<div className="container px-5 admin-section">
					<AdminHeader headerText="Управление устройствами" />

					{loading ? (
						<div className="lr-loader-container h-[70vh]">
							<CircularProgress size={150} />
						</div>) : (
						<>
							<button className="lr-primary-button mb-3" onClick={() => showUpdateForm(new Device())}>Добавить новое устройство</button>
							{devices.length === 0
								&& <NoItems text="Нет устройств" />
							}


							{devices.length > 0 &&
								<DevicesTable
									devices={devices}
									showUpdateForm={showUpdateForm}
									showRemoveForm={handleShowRemoveForm}
									showAiSettingsCarModal={openAiSettingsCarModal}

								/>
							}
						</>
					)}
				</div>
			</section>

			<EcoModal
				key="addupdate-modal"
				open={showAddUpdateForm}
				device={deviceForEdit}
				handleClose={() => setShowAddUpdateForm(false)}
				content={AddUpdateForm}
				title={`Редактирование информации об устройстве`}
			/>
			<RemoveForm
				show={showRemoveForm}
				handleClose={() => setShowRemoveForm(false)}
				id={deviceIdForRemove}
				endpoint="remove_device"
				successLabel="Устройство успешно удалено!"
			/>
			<EcoModal
				key="aisettings-car-modal"
				open={showAiSettingsCarModal}
				device={deviceForEdit}
				handleClose={() => setShowAiSettingsCarModal(false)}
				content={UpdateAISettingsForm}
				title={`Редактирование настроек устройства ИИ`}
			/>
		</>
	);
}

export default Devices;


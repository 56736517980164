import { TextField } from '@mui/material';
import { useState } from 'react';
import saveRoi from '../../../api/devices/save.roi';
import { AiCameraRoi } from '../../../model/ai.camera.roi';

interface EditRoiFormProps {
    roiForEdit: AiCameraRoi
    handleClose?: () => void,
    afterSaveSuccess?: () => void
}


interface RoiEditFieldProps {
    user: AiCameraRoi,
    prop: keyof AiCameraRoi,
    changeUserProperty: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, prop: keyof AiCameraRoi) => void,
    label: string,
}


function ProfileEditField({ user, prop, changeUserProperty, label }: RoiEditFieldProps) {
    return (

        <TextField
            className="mt-5 shadow-none"
            fullWidth
            name="name"
            value={user[prop]}
            size="small"
            label={label}
            onChange={(event) => {
                changeUserProperty(event, prop)
            }}
            inputProps={{ style: { boxShadow: '0 0 #0000' } }}
        />
    )
}

export default function EditRoiForm({ roiForEdit, handleClose, afterSaveSuccess }: EditRoiFormProps) {

    const [roi, setRoi] = useState<AiCameraRoi>(Object.assign({}, roiForEdit))
    const [saving, setSaving] = useState<boolean>(false)

    const clickSaveAreaParameter = async () => {
        const result = await saveRoi(roi)
        if (result && afterSaveSuccess) {
            afterSaveSuccess()
        }
    }

    const changeUserProperty = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, prop: keyof AiCameraRoi) => {
        setProperty(roi, prop, event.target.value)
        setRoi(Object.assign({}, roi))
    }

    const setProperty = <T, K extends keyof T>(obj: T, key: K, value: T[K]) => {
        obj[key] = value;
    }


    return (
        <>
            <ProfileEditField
                user={roi}
                prop={'area_x1'}
                changeUserProperty={changeUserProperty}
                label={'X1'}
            />

            <ProfileEditField
                user={roi}
                prop={'area_y1'}
                changeUserProperty={changeUserProperty}
                label={'Y1'}
            />

            <ProfileEditField
                user={roi}
                prop={'area_x2'}
                changeUserProperty={changeUserProperty}
                label={'X2'}
            />

            <ProfileEditField
                user={roi}
                prop={'area_y2'}
                changeUserProperty={changeUserProperty}
                label={'Y2'}
            />

            <hr className="border-dashed my-6" />
            <p className='text-right'>
                <button className="lr-secondary-button" onClick={handleClose}>
                    Закрыть
                </button>
                <button className="ml-5 lr-primary-button" onClick={clickSaveAreaParameter}
                    disabled={saving}

                >
                    {saving ? 'Сохранение...' : 'Сохранить'}
                </button>
            </p>
        </>
    );
}
import Nav from '../nav';
import React, { Component } from 'react';
import axios from "axios";
import * as consts from '../../consts';
import { Spinner, Table } from 'react-bootstrap';
const queryString = require('query-string');


class AnalyzeAudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
	spinner_classes: "",
	table_classes: "hidden",
	filename: "--",
	results: []
    }
    this.analyzeAudio();
  }

  analyzeAudio() {
    const params = queryString.parse(window.location.search);
    axios({
      method: "GET",
      url: `${consts.BASE_API_URL}/analyze_audio?resource_id=${params.resource_id}`,
    })
    .then((response) => {
	const res = response.data;
	this.setState({
	    spinner_classes: "hidden", 
	    table_classes: "",
	    filename: res.file, 
	    results: res.results
	})

    }).catch((error) => {
	if (error.response) {
	    console.log(error.response)
	    console.log(error.response.status)
	    console.log(error.response.headers)
        }
    })
  }

  render() {
    return (
	<>
	    <Nav />
	    <section>
		<div className="container px-5">
		    <h1>Анализ аудиофайла</h1>
		    <div className={this.state.spinner_classes}>
			<Spinner animation="border"/>
		    </div>
		    <div>Файл: {this.state.filename}</div>
		    <div className={this.state.table_classes}>
			<Table striped bordered hover>
			    <thead>
				<tr>
				<th>Начало (с)</th>
				<th>Конец (с)</th>
				<th>Научное название</th>
				<th>Название</th>
				<th>Вероятность</th>
				</tr>
			    </thead>
			    <tbody>
			    {this.state.results.map(function(result, i) {
				return (
				    <tr>
					<td>{result.start}</td>
					<td>{result.end}</td>
					<td>{result.scientific_name}</td>
					<td>{result.common_name}</td>
					<td>{result.confidence}</td>
				    </tr>
				)
			    })}
			    </tbody>
			</Table>
		    </div>

		</div>
	    </section>
	</>
    );
  }
}

export default AnalyzeAudio;



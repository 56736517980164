import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Tooltip from '@mui/material/Tooltip';
import * as consts from '../../../../consts';


export default (props) => {
    if (isNaN(props.info.module_type_id) || props.info.module_type_id === consts.MODULE_TYPES.UE1) {
        return <>&nbsp;</>
    }

    if (!(props.info.last_parameter_values && (props.info.last_parameter_values.voltage || props.info.last_parameter_values.vin))) {
        return <>&nbsp;</>
    }
 
    const bounds = [4100, 3925,  3750, 3575,  3400, 3225,  3050, 2875,  2700]
    const boundsProc = [100, 90,  80, 70,  60, 50,  40, 20, 10, 0]
    
    const icons = [ BatteryFullIcon, Battery90Icon, Battery80Icon, Battery80Icon, 
                    Battery50Icon, Battery50Icon, Battery50Icon, Battery30Icon, Battery30Icon, 
                    Battery20Icon, Battery20Icon, Battery0BarIcon]
    const colors = ['text-lr-green-primary', 'text-lr-green-primary', 'text-lr-green-primary', 
                    'text-yellow-400', 'text-yellow-400', 'text-red-400', 'text-red-400', 
                    'text-red-800', 'text-red-800', 'text-red-800']

    
    const voltageValue = props.info.last_parameter_values.voltage ? props.info.last_parameter_values.voltage : props.info.last_parameter_values.vin

    const getBatteryIcon = (voltage) => {
        let index = 0
        bounds.forEach( (bound) => {
            if (bound > voltage) {
                index++
            }
        })

        const title = `${voltageValue}мВ (${boundsProc[index]}%)`
        return [icons[index], colors[index], title]
    }

    const [Component, color, title] = getBatteryIcon(voltageValue)
    return (
        <>
            <Tooltip title={`${title}`}>
                <Component className={`text-sm ${color} ml-0`}/>
            </Tooltip>
        </>
    );
}
import { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './widgets.css'
import RowsWithCommonDevices from './rows_common_devices';
import RowEcoSystemDevices from './row_eco_system_devices';

class Widgets extends Component {
    render() {
	return (
	<div className="widgets-div">
	    <RowsWithCommonDevices
		{...this.props}
	    />
	    <Row>
		<div className="label-smart-eco">Устройства Smart Ecosystems</div>
		<hr />
	    </Row>
	    <RowEcoSystemDevices
		{...this.props}
	    />
	</div>
	);
    }
}
export default Widgets;


import { DataGrid, gridClasses, GridToolbar, ruRU } from '@mui/x-data-grid';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Device from '../../../model/device';
import SeismicEvent from '../../../model/seismic.event';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EmailIcon from '@mui/icons-material/Email';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import repeatEmail from '../../../api/seismic/repeat.email';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveForm from '../resources/remove_modal';
import { useState } from 'react';


export interface SeismicEventsTableProps {
    events: SeismicEvent[];
}


const SeismicEventsTable = ({ events }: SeismicEventsTableProps) => {
    const sendEmail = async (e: any, row: any) => {
        repeatEmail(row.id)
        e.stopPropagation();
    }
    
    const removeEvent = async (e: any, row: any) => {
        setSelectedEventId(row.id);
        setShowRemoveForm(true);
        e.stopPropagation();
    }
    
    
    const columns = [
        {
            field: 'is_approved',
            headerName: '',
            flex: 0.1,
            renderCell: (param: any) => {
                if (param.value) {
                    return <Tooltip title={'Событие проверено'}>
                        <VerifiedUserIcon className="text-lg text-lr-green-primary " />
                    </Tooltip>
    
                }
                return null
            },
        },
        {
            field: 'id',
            headerName: '№',
            flex: 0.1,
        },
        {
            field: 'name',
            headerName: 'Название',
            flex: 1,
        },
        {
            field: 'mstime',
            headerName: 'Дата',
            flex: 1,
            renderCell: (param: any) => {
                return (
                    <>
                        {moment(param.value).format("YYYY-MM-DD HH:mm:ss")}
                    </>
                )
            },
        },
        {
            field: 'stations',
            headerName: 'Станции',
            flex: 1,
            renderCell: (param: any) => {
                return (
                    <ul>
                        {param.value.map((station: Device, i: number) => {
                            return <li key={`station-${i}`}>
                                {station.name}, {station.serial_number} <br />
                            </li>
                        })}
                    </ul>
                )
            },
        },
        {
            field: 'magnitude',
            headerName: 'Магнитуда',
            flex: 1,
        },
        {
            field: 'epicenter_description',
            headerName: 'Локация',
            flex: 1,
            renderCell: (param: any) => {
                return (
                    <>
                        {param.row.epicenter_description}<br />
                        Широта: {param.row.epicenter_lat}<br />
                        Долгота: {param.row.epicenter_lon}<br />
    
                    </>
                )
            },
        },
        {
            field: 'actions',
            headerName: 'Действия',
            flex: 0.4,
            renderCell: (param: any) => {
                return (
                    <>
                        <Tooltip title={'Отправить повторный EMail'}>
                            <IconButton
                                sx={{ color: 'black' }}
                                onClick={(e) => sendEmail(e, param.row)}
                            >
                                <EmailIcon className="text-lg" />
                            </IconButton>
                        </Tooltip>
    
                        <Tooltip title={'Удалить событие'}>
                            <IconButton
                                sx={{ color: 'red' }}
                                onClick={(e) => removeEvent(e, param.row)}
                            >
                                <DeleteIcon className="text-lg" />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            },
        },
    ];
    
    const navigate = useNavigate();

    const [showRemoveForm, setShowRemoveForm] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState<number>(0);


    const onRowClick = (row: any) => {
        navigate(`/admin/seismic/event/${row.id}`);
    }

    return (
        <>
            <DataGrid
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                getRowHeight={() => 'auto'}
                className='h-full cursor-pointer'
                rows={events}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                    },
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterValues: [''],
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 50, 100]}
                disableRowSelectionOnClick
                disableColumnFilter
                autoHeight={true}
                onRowClick={onRowClick}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                }}
                slots={{
                    toolbar: GridToolbar
                }}
                slotProps={{
                    toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },

                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        disableRowSelectionOnClick: true,

                    },
                }}
            />

            <RemoveForm
                show={showRemoveForm}
                handleClose={() => setShowRemoveForm(false)}
                id={selectedEventId}
                endpoint="seismicevents/remove_seismic_event"
                successLabel="Сейсмособытие успешно удалено!"
            />
        </>
    );

}

export default SeismicEventsTable;


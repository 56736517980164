import { DataGrid, gridClasses, GridToolbar, ruRU } from '@mui/x-data-grid';
import SeismicEvent from '../../../model/seismic.event';
import SeismicEventShortRow from './SeismicEventShortRow';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Tooltip from '@mui/material/Tooltip';

const columns = [
    {
        field: 'is_approved',
        headerName: '',
        flex: 0.05,
        renderCell: (param: any) => {
            if (param.value) {
                return <Tooltip title={'Событие проверено'}>
                    <VerifiedUserIcon className="text-lg text-lr-green-primary " />
                </Tooltip>

            }
            return null
        },
    },
    {
        field: 'name',
        headerName: '',
        flex: 0.95,
        renderCell: (param: any) => {
            return <SeismicEventShortRow
                event={param.row} />
        },
        sortable: false,
    }
];

export interface SeismicEventsShortTableProps {
    events: SeismicEvent[];
}


const SeismicEventsShortTable = ({ events }: SeismicEventsShortTableProps) => {

    const onRowClick = (row: any) => {
        window.open(`/admin/seismic/event/${row.id}`, "_blank", "noreferrer");

    }

    return (
        <>
            <DataGrid
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                getRowHeight={() => 'auto'}
                className='h-full cursor-pointer'
                rows={events}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                    },
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterValues: [''],
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 50, 100]}
                disableRowSelectionOnClick
                disableColumnFilter
                disableDensitySelector
                disableColumnSelector
                disableColumnMenu
                disableVirtualization
                autoHeight={true}
                onRowClick={onRowClick}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                }}
                slots={{
                    toolbar: GridToolbar,
                    columnHeaders: () => null,

                }}
                slotProps={{
                    toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },

                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        disableRowSelectionOnClick: true,

                    },
                }}
            />
        </>
    );

}

export default SeismicEventsShortTable;

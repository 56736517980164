import SeismicEvent from '../../../model/seismic.event';
import moment from 'moment';
import { Typography } from '@mui/material';


export interface SeismicEventShortRowProps {
    event: SeismicEvent;
}


const SeismicEventShortRow = ({ event }: SeismicEventShortRowProps) => {

    return (
        <>
            <div className="text-xs leading-none">
                {event.name}
                <Typography className="py-2 text-sm">
                    {moment(event.mstime).format("YYYY-MM-DD HH:mm:ss")}
                </Typography>
                {event.epicenter_description &&
                    <>
                        {event.epicenter_description}
                    </>
                }
                {event.epicenter_lat && event.epicenter_lon &&
                    <>
                        ({event.epicenter_lat}, {event.epicenter_lon})
                    </>
                }
                <br />

            </div>
        </>
    );

}

export default SeismicEventShortRow;

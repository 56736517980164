import moment from "moment";


/**
 * 
 * @param {*} newDeviceData - new data from server
 * @param {*} datasets - old datasets
 * @param {*} labels - old labels
 * @param {*} allData - old all data.
 */
export function updateData(newDeviceData, datasets, labels, allData) {
    const deviceData = JSON.parse(newDeviceData);

    console.log('mstime', deviceData.mstime)
    const newDataDate =  moment(deviceData.mstime, 'YYYY-MM-DD HH:mm:ss')

    labels.forEach( (label, i) => {
        const labelDate = moment(label, 'DD.MM.YY HH:mm:ss')
        if (newDataDate.diff(labelDate, 'seconds') === 0) {
            Object.keys(datasets).forEach(key => {
                const prefix = `${deviceData.device.id_device}_`
                if (key.startsWith(prefix)) {
                    const suffix = key.replace(prefix, '');
                    datasets[key][i] = deviceData[suffix];
                    allData[`${key}_normal`][i] = deviceData[suffix];
                    allData[`${key}_raw`][i] = deviceData[suffix];
                    
                }    
            });
        }
    })

    return [datasets, labels, allData]
}
import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import DeviceAlert from '../../../model/device.alert';
import PersonIcon from '@mui/icons-material/Person';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { DeviceAlertOrigType, DeviceAlertArchived } from "../../../model/device.alert";
import Badge from 'react-bootstrap/Badge';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

export interface AlertsTableProps {
    alerts: DeviceAlert[];
}

const AlertsTable = ({ alerts }: AlertsTableProps) => {

    return (
        <>

            <TableContainer className="mx-auto" component={Paper}>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell key="th-type-icon"></TableCell>
                            <TableCell key="th-device">Устройство</TableCell>
                            <TableCell key="th-description">Описание</TableCell>
                            <TableCell key="th-value">Значение</TableCell>
                            <TableCell key="th-date">Дата</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="min-h-[275px]">
                        {alerts.map((alert: DeviceAlert) => {
                            return (
                                <TableRow key={`${alert.id}`}>
                                    <TableCell key={`origin-type-${alert.id}`}>
                                        {alert.device_alert_type === DeviceAlertOrigType.System &&
                                            <Tooltip title="Системное оповещение">
                                                <SettingsSuggestIcon className="pr-1" />
                                            </Tooltip>
                                        }
                                        {alert.device_alert_type === DeviceAlertOrigType.User &&
                                            <Tooltip title="Пользовательское оповещение">
                                                <PersonIcon className="pr-1" />
                                            </Tooltip>
                                        }
                                        {alert.device_alert_type === DeviceAlertOrigType.Seismic &&
                                            <Tooltip title="Сейсмособытие">
                                                <MonitorHeartIcon className="pr-1" />
                                            </Tooltip>
                                        }
                                        {alert.archived === DeviceAlertArchived.Archived && 
                                            <><br /><Badge bg="info">В архиве</Badge></>
                                        }
                                    </TableCell>

                                    <TableCell key={`device-${alert.id}`}>
                                        <>
                                            {`${alert.device_name}`}
                                        </>
                                    </TableCell>
                                    <TableCell key={`description-${alert.id}`}>
                                        <>
                                            {`${alert.description}`}
                                        </>
                                    </TableCell>
                                    <TableCell key={`value-${alert.id}`}>
                                        <>
                                            {`${alert.value}`}
                                        </>
                                    </TableCell>
                                    <TableCell key={`date-${alert.id}`}>
                                        <>{alert.date}</>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}

export default AlertsTable;
import { isMobile } from 'react-device-detect';

//class Questions extends Component {
function Questions(props) {
	const margin = isMobile ? 'ml-7' : '';

	return (
		<div className="questions ">
			<div className={`row offset-md-1 ${margin}`}>
				<div className="q-header">
					Есть вопросы, но не знаете с чего начать?
				</div>
				<div className="q-text">
					Поможем с выбором решения, ответим на ваши вопросы <br /> и подготовим индивидуальное предложение
				</div>

				<div className="q-button-div">
					<button className="f-button" onClick={props.showRequestForm}>Оставить заявку</button>
				</div>
			</div>
			<img src="/landing/questions_image.png" className="questions-image" />
		</div>
	);
}

export default Questions;




import LastData from '../../last_data';
import CrownSpectr from './CrownSpectrTable';
import PhenologyGallery from './PhenologyGallery';
import CrownTeplovizor from './CrownTeplovizor';
import NdviComponent from './NDVI';

export interface PlantInfoProps {
    showDeviceById: () => {},
    data: any,
    deviceParameters: any,
}
export default function PlantMainInfo({ showDeviceById, data, deviceParameters }: PlantInfoProps) {
    let dParams : any = {}
    const moduleTypeId = data.info.module_type_id ? data.info.module_type_id : ''
    const keys = ['air_temp', 'air_hum', 'air_pressure', 'trunk_temp', 'hum_wood', 'thick_wood', 'mlx90640_max', 'mlx90640_min', 'mlx90640_avg']
	keys.forEach( (key : string) => {
		dParams[key] = deviceParameters[`${data.info.device_type_id}_${moduleTypeId}_${key}`]
	})

    return (
        <>
            <div className="pt-7">                
                <LastData
                    data={data.data}
                    keys={['air_temp', 'air_hum', 'air_pressure']}
                    label={'Метеообстановка'}
                    deviceParameters={dParams}
                />
            </div>
            <div className="pt-7">
                <LastData
                    data={data.data}
                    keys={['trunk_temp', 'hum_wood', 'thick_wood']}
                    label={'Ствол дерева'}
                    deviceParameters={dParams}

                />
            </div>

            <hr className='my-5' />
            <NdviComponent 
                data={data.data}
            />

            <hr className='my-5' />
            <div className="di-data-block-name">Спектр кроны</div>
            <div className="">
                <CrownSpectr
                    data={data.data}
                />
            </div>

            <hr className='my-5' />
            <div>
                <LastData
                    data={data.data}
                    keys={['mlx90640_max', 'mlx90640_min', 'mlx90640_avg']}
                    label={'Температура кроны'}
                    deviceParameters={dParams}
                />
            </div>

            <CrownTeplovizor deviceId={data.id} />

            <hr className='my-5' />
            <div className=".di-data-block-name">Фенология развития</div>
            <PhenologyGallery deviceId={data.id} />
        </>
    );
}
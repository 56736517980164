import { useEffect } from 'react';
import OvenPlayer from 'ovenplayer';

interface VideoStreamPlayerProps {
	device_id: number;
	file: string;
}

const VideoStreamPlayer = ({ device_id, file }: VideoStreamPlayerProps) => {

	useEffect(() => {
		const div = document.getElementById(`player_id_${device_id}`)
		if (div) {

			const player = OvenPlayer.create(`player_id_${device_id}`, {
				mute: false,
				volume: 100,
				sources: [
					{
						label: 'label_for_hls',
						type: "hls",
						file: file,
					},
				],
			});
		}
	}, [])

	// Don't removes key, occured error during compare mode.
	return (
		<div key={`key-div-div-${device_id}`}> 
			<div key={`key-player-div-${device_id}`} id={`player_id_${device_id}`}></div>
		</div>
	);
}

export default VideoStreamPlayer;

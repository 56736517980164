import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';


const loadDataForPeriod = async (device_id, dt_start, dt_end, ag_type) => {
    const headers = getHeaders()
    
    const result = await axios({
        method: "GET",
        url: `${consts.BASE_API_URL}/load_data_for_period`,
        headers,
        params: {
            device_id,
            dt_start,
            dt_end,
            timeoffset: new Date().getTimezoneOffset() / 60,
            ag_type,
            data_source: localStorage.getItem(consts.LOCAL_STORAGE_KEYS.SEISMO_DATA_SOURCE)
        }
    })

    return result.data
};


export default loadDataForPeriod
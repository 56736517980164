import { Component } from 'react';
import Header from './header';
import Footer from './Footer';

class AudioWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			info: props.info
		}
	}

	render() {
		return (
			<div className="widget-div">
				<Header
					showDeviceById={this.props.showDeviceById}
					info={this.props.info}
				/>

				<Footer device={this.props.info} />
			</div>
		);
	}
}
export default AudioWidget

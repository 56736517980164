import 'leaflet/dist/leaflet.css'
import { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import Map from '../../map/map';
import getDevices from '../../../api/devices/get.devices';
import getDevicesLite from '../../../api/devices/get.devices.lite';
import Device from '../../../model/device';
import Menu from '../../map/menu/menu';
import { saveMapSettings } from '../../../api/user/save.map.settings';
import * as consts from '../../../consts';
import WidgetsGrid from '../../map/panel/widgets_grid';
import Alert from '@mui/material/Alert';
import getAccountSettings from '../../../api/user/get.account.settings';
import getGroup from '../../../api/devices/get.group';

import Token from '../../token_v2';
import { useNavigate } from "react-router-dom";
import HelpDialog from './help_dialog';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Snackbar } from "@mui/material";
import { useParams } from 'react-router-dom';


function MapSettings(props) {
	const navigation = useNavigate();

	const [devices, setDevices] = useState([]);
	const [currentLayer, setCurrentLayer] = useState('osm');
	const [mapZoom, setMapZoom] = useState(undefined);
	const [mapCenter, setMapCenter] = useState(undefined);
	const [accountSettings, setAccountSettings] = useState(undefined)
	const [open, setOpen] = useState(localStorage.getItem('already_opened_help_dialog') === null);

	const [layouts, setLayouts] = useState({})

	const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
	const [actionMessage, setActionMessage] = useState('');
	const [deviceTypeParameters, setDeviceTypeParameters] = useState({});
	const params = useParams()


	const notify = (message) => {
		setActionMessage(message);
		setActionNotificationOpen(true);
	};


	const token = new Token();

	// Gets list of all devices from api.
	useEffect(() => {
		const fetchData = async () => {
			const groupSlug = params.group ? params.group : null;
			const devicesResponse = await getDevicesLite(NaN, groupSlug)
			let allDevices = Device.fromArray(devicesResponse.devices)

			if (props.publicPanel) {
				allDevices = allDevices.filter(device => device.is_public)
			}

			allDevices.sort((a, b) => {
				let r = (a.device_type_id > b.device_type_id) ? 1 : ((b.device_type_id > a.device_type_id) ? -1 : 0);
				if (r == 0 && a.device_type_id === consts.DEVICE_TYPES.MODULE) {
					r = (a.module_type_id > b.module_type_id) ? 1 : ((b.module_type_id > a.module_type_id) ? -1 : 0);
				}
				return r;
			})

			setDevices(allDevices)

			setDeviceTypeParameters(devicesResponse.deviceParameters)

			if (params.group) {
				const groupInfo = await getGroup(params.group)
				setAccountSettings(groupInfo)
				if (groupInfo.mpanel_layouts) {
					setLayouts(JSON.parse(groupInfo.mpanel_layouts))
				}

			} else {
				const accountId = props.publicPanel ? 0 : null
				const accountSettings = await getAccountSettings(accountId)
				setAccountSettings(accountSettings)
				if (accountSettings.mpanel_layouts) {
					setLayouts(JSON.parse(accountSettings.mpanel_layouts))
				}
			}
		}
		fetchData()
	}, [])

	const toggleLayer = (layer) => {
		setCurrentLayer(layer)
	}

	const clickSaveMapSettings = async () => {
		if (!mapZoom || !mapCenter) {
			return
		}
		const settings = {
			map_zoom: mapZoom,
			map_center_lat: mapCenter.lat,
			map_center_lng: mapCenter.lng,
			map_layer: currentLayer,
			mpanel_layouts: JSON.stringify(layouts),
			group_slug: params.group,
		}

		if (props.publicPanel) {
			settings.account_id = 0
		}

		await saveMapSettings(settings)
		notify("Сохранение прошло успешно!")
	}

	const changeMapPosition = (mapZoom, mapCenter) => {
		setMapZoom(mapZoom)
		setMapCenter(mapCenter)
	}

	const showDeviceById = () => {
	}

	const layoutChanged = (layout, type) => {
		layouts[type] = layout;
	}

	const closeHelperDialog = () => {
		setOpen(false)
		localStorage.setItem('already_opened_help_dialog', true)
	}

	if (!token.getToken()) {
		navigation('/login')
		return null
	}


	return (
		<div >
			<Alert severity="info" className="absolute top-3 right-3 z-[999]">
				Настройка {props.publicPanel && <>&nbsp;общедоступной&nbsp;</>}
				панели мониторинга
			</Alert>
			<HelpDialog open={open} handleClose={closeHelperDialog} />
			<Menu
				saveMapSettings={clickSaveMapSettings}
				top={78}
				toggleLayer={toggleLayer}
				openHelpDialog={() => setOpen(true)}
			/>
			<Map
				toggleLayer={toggleLayer}
				selectedDevices={[]}
				devices={devices}
				currentLayer={currentLayer}
				mapSettingsButton={true}
				changeMapPosition={changeMapPosition}
				accountSettings={accountSettings}
				deviceTypeParameters={deviceTypeParameters}

			/>
			{accountSettings &&
				<div className={`mpanel-container mpanel-container-w50`}>
					{consts.PANEL_SECTIONS.map((section) => {
						let devicesForType = devices.filter(device => section.device_types.includes(device.device_type_id))

						return (
							<Accordion defaultExpanded>
								<AccordionSummary
									sx={{
										height: 5,
										maxHeight: 10,
									}}
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`panel-content-${section.id}`}
									id={`panel-header--${section.id}`}
									className="my-0"
								>
									<Typography className="font-montserrat text-sm py-0 my-0">{section.plural_name}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<WidgetsGrid
										key={`layout-widget-grid-${section.id}`}
										devices={devicesForType}
										type={section.id}
										headerLabel={section.plural_name}
										layouts={layouts}
										layoutChanged={layoutChanged}
										classShowCompareComponent={false}
										showDeviceById={showDeviceById}
										deviceParameters={deviceTypeParameters}

									/>
								</AccordionDetails>
							</Accordion>
						)
					})}
				</div>
			}
			<Snackbar
				open={actionNotificationOpen}
				autoHideDuration={2500}
				onClose={() => setActionNotificationOpen(false)}
				message={actionMessage}
			/>

		</div>
	);
}

export default MapSettings;

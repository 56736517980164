import { LatLng } from "leaflet";
import { useState } from "react";
import { LayerGroup, Polyline, useMapEvents } from "react-leaflet";
import MissionCircle from "./MissionCircle";
import * as turf from '@turf/turf'
import { Position } from "@turf/turf";


interface MiccionCirclesProps {
    updatePath: (pat: LatLng[]) => void;
}


export default function MiccionCircles({ updatePath }: MiccionCirclesProps) {

    const [circles, setCircles] = useState<LatLng[]>([])
    const [count, setCount] = useState(0)
    const [box, setBox] = useState<LatLng[]>([])
    const [layer, setLayer] = useState<any>(undefined)


    const handlers = {
        dblclick(e: any) {
            const _circles = circles
            _circles.push(e.latlng)
            setCircles(_circles)
            setCount((count) => count + 1)
            _updatePath(_circles)
            //_calculateGrid(_circles)
        },
    }

    const updateCircles = (number: number, newCenter: LatLng) => {
        const _circles = circles
        _circles[number] = newCenter
        _updatePath(_circles)
    }

    const dropCircle = (number: number) => {
        const _circles = circles
        _circles.splice(number, 1);
        setCircles(_circles)
        setCount((count) => count + 1)

        _updatePath(_circles)
    }

    const _updatePath = (_circles: LatLng[]) => {
        const path = _circles.length > 1 ? _circles.concat([_circles[0]]) : _circles
        updatePath(path)
    }

    const _calculateGrid = (_circles: LatLng[]) => {
        if (_circles.length < 2) {
            return
        }
        const positions: Position[] = []
        const features: any[] = []
        _circles.forEach((circle) => {
            positions.push([circle.lat, circle.lng])
            features.push(turf.point([circle.lat, circle.lng]))
        })
        const line = turf.lineString(positions);
        const bbox = turf.bbox(line)
        const poly = turf.bboxPolygon(bbox);
        
        const fc = turf.featureCollection(features);
        
        const grid = turf.pointGrid(bbox, 0.3);
        console.log('g:', grid)

        const _box: any[] = []
        grid.features.forEach((feature) => {
            _box.push([feature.geometry.coordinates[0], feature.geometry.coordinates[1]])
        })
        setBox(_box)


        setCount((count) => count + 1)
    }


    const map = useMapEvents(handlers)
    map.doubleClickZoom.disable()

    if (circles.length == 0) {
        return <></>
    }

    return (
        <LayerGroup key={`c-${count}`}>

            {circles.map((point: LatLng, i) => {
                return <MissionCircle
                    cntr={[point.lat, point.lng]}
                    number={i}
                    handlers={handlers}
                    updateCircles={updateCircles}
                    dropCircle={dropCircle}
                    key={`mission-circle-${i}`}
                />
            })}

            {box.length > 1 &&
                <Polyline pathOptions={{ color: 'blue', fillOpacity: 0 }} positions={box} />
            }
        </LayerGroup>
    )
}

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { BsFillTrashFill } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';


class Project {
    name: string = '';
    amount_missions: number = 0;
    amount_loading: number = 0;
    amount_running: number = 0;
}

const projects = [
    {
        name: 'Обследование Панааярви',
        amount_missions: 10,
        amount_loading: 24,
        amount_running: 27
    },
    {
        name: 'По Ботаническому саду',
        amount_missions: 3,
        amount_loading: 9,
        amount_running: 1
    },
    {
        name: 'В Петрозаводске',
        amount_missions: 7,
        amount_loading: 2,
        amount_running: 7
    }
]


const ProjectsTable = () => {

    return (
        <>

            <TableContainer className="mx-auto" component={Paper}>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell key="th-name">Название</TableCell>
                            <TableCell key="th-amount-missions">Количество миссий</TableCell>
                            <TableCell key="th-amount-loading">Количество загрузок</TableCell>
                            <TableCell key="th-amount-running">Количество выполнений</TableCell>
                            <TableCell key="th-actions"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="min-h-[275px]">
                        {projects.map((project: Project, i: number) => {
                            return (
                                <TableRow key={`${i}`}>
                                    
                                    <TableCell key={`name-${i}`}>
                                        <>{project.name}</>
                                    </TableCell>
                                    <TableCell key={`type-${i}`}>
                                        <>{project.amount_missions}</>
                                    </TableCell>
                                    <TableCell key={`params-${i}`}>
                                        <>{project.amount_loading}</>
                                    </TableCell>
                                    <TableCell key={`params-${i}`}>
                                        <>{project.amount_running}</>
                                    </TableCell>
                                    
                                    <TableCell key={`actions-${i}`}>
                                        <Tooltip title="Редактировать информацию об устройстве">
                                            <div className="inline-block">
                                                <FaEdit className="text-2xl inline cursor-pointer" />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Удалить устройство">
                                            <div className="inline-block">
                                                <BsFillTrashFill className="text-2xl inline text-red-500 cursor-pointer" />
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}

export default ProjectsTable;
import UpdateIcon from '@mui/icons-material/Update';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import Device from '../../model/device';

const UUID = '2f2dc1e6-91b8-415b-9d22-150fd22a0d29'
const MODULE_TYPE_ID = 5;
const DEVICE_TYPE_ID = 3


export interface HeaderProps {
    device: Device;
}


export default function Header({ device }: HeaderProps) {

    useEffect(() => {

    }, [])

    const getMstime = () => {
        if (!device) {
            return ''
        }
        const timeoffset = new Date().getTimezoneOffset() / 60
        if (!device.last_parameter_values) {
            return ''
        }

        if (!moment(device.last_parameter_values?.mstime, "YYYY-MM-DD hh:mm:ss").isValid()) {
            return ''
        }

        const m_mstime = moment(device.last_parameter_values?.mstime, "YYYY-MM-DD HH:mm:SS")
        const mstime = m_mstime.subtract(timeoffset, 'hours').add(device.tz, 'hours')

        return mstime.format("YYYY-MM-DD HH:mm:SS")
    }

    const updateData = () => {

    }


    return (
        <>
            {device &&
                <div className="di-header">
                    <span className="di-name"> {device.name}
                        {device.serial_number &&
                            <>
                                , s/n {device.serial_number}
                            </>
                        }
                    </span>
                    <br />

                    {/* Location info. */}
                    {device.location_description &&
                        <>
                            <span className="di-location">{device.location_description}</span>
                            <br />
                        </>
                    }


                    <Tooltip title="Обновить данные">
                        <UpdateIcon onClick={updateData} className="text-xl inline mr-1 ml-0 cursor-pointer" />
                    </Tooltip>
                    <span className="di-last-date">Данные на {getMstime()}</span>
                </div>
            }

        </>
    );
}
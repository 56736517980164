import { useState, useEffect } from 'react';
import loadSeismicData from '../../../../api/data/load.seismicdata';
import moment from 'moment';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import SeismicHourChart from './seismic_hour_chart';

function AxisChart(props) {

    const [chartData, setChartData] = useState("")
    const [currentHour, setCurrentHour] = useState(undefined);
    const [currentDate, setCurrentDate] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [pointForChanged, setPointForChanged] = useState(undefined) // 'p' or 's'


    const fetchData = async () => {
        if (!currentDate) return []

        setLoading(true)

        const dtStart = currentDate.startOf('hour').unix() * 1000
        const dtEnd = currentDate.endOf('hour').unix() * 1000

        const data = await loadSeismicData(props.device_id, dtStart, dtEnd, 0, props.filter, props.selectedDataSource)
        setChartData(data)
        setLoading(false)
    }


    useEffect(() => {
        if (!props.date) return

        const date = moment(props.date).utcOffset(+3 * 60)
        if (!date) return
        setCurrentDate(date)
        if (props.seismic_event)
            setCurrentHour(date.hours())
    }, [props.date])


    useEffect(() => {
        fetchData()
    }, [currentHour])


    const changeHour = async (event, newHour) => {
        setCurrentHour(newHour);

        const _currentDate = currentDate.clone()
        if (currentDate.hour() != newHour) {
            _currentDate.set({ hour: newHour})
            if (props.updateEventDate) {
                props.updateEventDate(_currentDate)
            }
        }

        setCurrentDate(_currentDate)
    };

    const changePoint = async (event, newPoint) => {
        console.log('from change set point:', newPoint)
        setPointForChanged(newPoint)
    }

    const hours = [...Array(24).keys()];

    return (
        <>
            <div className='overflow-auto'>
                <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={currentHour}
                    exclusive
                    onChange={changeHour}
                    aria-label="hours"
                >
                    {hours.map((hour) => {
                        const label = hour < 10 ? `0${hour}` : `${hour}`
                        return (
                            <ToggleButton value={hour} key={`toggle-hour-${hour}`}>{label}</ToggleButton>
                        )
                    })}
                </ToggleButtonGroup>

                {props.seismic_event &&
                    <ToggleButtonGroup
                        className="pl-5"
                        size="small"
                        color="primary"
                        value={pointForChanged}
                        exclusive
                        onChange={changePoint}
                        aria-label="points"
                    >
                        {['p', 's'].map((point) => {
                            return (
                                <ToggleButton value={`${point}`} key={`toggle-point-${point}`}>Изменить {point}</ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                }

            </div>

            <div className="h-[400px]">
            {loading &&
                <div className="mt-5 flex justify-center items-center">
                    <CircularProgress />
                </div>
            }

            {chartData && !loading &&
                <SeismicHourChart
                    date={currentDate}
                    chartData={chartData}
                    seismicEvent={props.seismic_event}
                    pointForChanged={pointForChanged}
                    updatePointsInfo={props.updatePointsInfo}
                    device_id={props.device_id}
                />
            }
            </div>
        </>
    )
}

export default AxisChart;

import axios from 'axios';
import * as consts from '../../consts';
import Device from '../../model/device';
import getHeaders from '../headers';

export const getDevice = async (device_uuid: string | undefined): Promise<Device | null> => {

	try {
		const headers = getHeaders()

		const response = await axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/device`,
			headers,
			params: {
				device_uuid,
				timeoffset: new Date().getTimezoneOffset() / 60
			},
		})

		return response.data.device as Device
	} catch (error: any) {
		console.error(error);

		if (error.response.status === 401) {
			return null
		}

	}
	return null

};

export default getDevice;
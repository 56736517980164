import { Component } from 'react';
import moment from 'moment';
import Battery from './battery'
import Tooltip from '@mui/material/Tooltip';
import { getOnlineIndicatorClass } from '../../../../utils';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const onlineLabels = {
	'offline': 'Нет данных с устройства',
	'online': 'Устройство online'
}

class Header extends Component {

	render() {
		const mode = getOnlineIndicatorClass(this.props.info)
		const label = onlineLabels[mode]
		return (
			<>
				<div className="font-montserrat text-xs px-1 font-semibold pt-2 pb-1 cursor-pointer group transition-all hover:bg-[#00683720] active:bg-[#00683730]"
					onClick={() => this.props.showDeviceById(this.props.info.device_id)}>
					<div className='flex items-start'>
						<Battery info={this.props.info} />

						<div className={`flex gap-3 justify-between flex-auto ${this.props.info.is_verified === 1 ? 'pr-6' : ''}`}>
							<div className='group-hover:underline group-hover:text-lr-green-primary text-sm leading-none'>{this.props.info.name}</div>


							{this.props.info.serial_number &&
								<Tooltip title={this.props.info.serial_number}>
									<div className='overflow-hidden max-w-[100px]'>
										<div className='text-[10px] font-light text-gray-400 truncate'>{this.props.info.serial_number}</div>
									</div>
								</Tooltip>
							}
						</div>
					</div>

					<div className="font-montserrat text-[10px] leading-none mt-0.5 px-0.5 ml-px text-lr-green-secondary-1 font-light">{this.props.info.location_description}</div>
				</div>


				{this.props.info.is_verified === 1 &&
					<Tooltip title={`Сертифицированное устройство`}>
						<VerifiedUserIcon className="text-sm text-lr-green-primary absolute top-2 right-2" />
					</Tooltip>
				}


			</>
		);
	}
}
export default Header;





import Device from "./device";
import SeismicEventPoint from "./seismic.event.point";

class SeismicEvent {
    id: number = NaN;
    name: string = "";
    notes: string = "";
    epicenter_lat: number = NaN;
    epicenter_lon: number = NaN;
    epicenter_description: string = "";
    magnitude: number = NaN;
    mstime: string = '';
    is_approved: boolean = false;

    stations: Device[] = [];
    points: any;
}

export default SeismicEvent;

import { Grid } from "@mui/material";
import LeftPart from "./left_part";

const CoverPage3 = () => {


    return (

        <Grid container spacing={2}>
            <Grid item xs={1}></Grid>

            <LeftPart />

            <Grid xs={4}>
                <img src="/landing/assets/img/header_devices_03_s.png" className="w-full" />
            </Grid>
            <Grid xs={3}>
                <div className="mr-0 text-right">
                    <p className="cover-header">
                        03 | Оборудование
                    </p>
                    <p className="cover-text">
                        Предлагаем свои <br />
                        устройства для мониторинга<br />
                        и разрабатываем новые<br />
                    </p>
                    <p className="more-link-text">
                        <a className="more-link-text" href="#part03">Узнать подробнее</a>
                    </p>
                </div>

            </Grid>
            <Grid item xs={1}></Grid>

        </Grid>
    );
}

export default CoverPage3
import { Component } from 'react';


class Input extends Component {

    render() {
	return (
	    <input 
		type="input" name={this.props.name} 
		onChange={(event) => this.props.updateValue(this.props.name, event.target.value)}
		value={this.props.value} 
		className="input-field"
		placeholder={this.props.placeholder}
	    />
	)
    }
}

export default Input;

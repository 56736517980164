import axios from 'axios';
import * as consts from '../../consts';
import Device from '../../model/device';
import getHeaders from '../headers';

export const getBirdRecognitionData = async (device_id, period, confidence) => {

	const headers = getHeaders()

	const response = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/data/load_bird_recognition_data`,
		headers,
		params: {
			timeoffset: new Date().getTimezoneOffset() / 60,
			device_id,
			period,
			confidence
		},
	})

	return response.data.data
};

export default getBirdRecognitionData;
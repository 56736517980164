import { Component } from 'react';
import * as consts from '../../../consts';
import WidgetsGrid from './widgets_grid';
import WidgetsGridMobile from './widgets_grid_mobile';
import Notifications from "./Notifications";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchDevicesComponent from './search';
import { Tooltip } from "@mui/material";
import { isMobileOnly } from 'react-device-detect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class MonitoringPanel extends Component {

	constructor(props) {
		super(props);
		this.state = {
			layouts: undefined,
			deviceNames: [],
			sectionExpanding: {}
		}

		consts.PANEL_SECTIONS.forEach((section) => {
			const itemName = `type-panel-expanded-${section.id}`
			const expandedLC = localStorage.getItem(itemName)
			let expanded = expandedLC === null ? true : expandedLC === 'false' ? false : true
			this.state.sectionExpanding[section.id] = expanded
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.accountSettings) {
			if (nextProps.accountSettings.mpanel_layouts) {
				this.setState({ layouts: JSON.parse(nextProps.accountSettings.mpanel_layouts) })
			}
		}

		let deviceNames = []
		nextProps.devices.forEach((device) => {
			const option = {
				label: `${device.name}, s/n ${device.serial_number}`,
				id: device.device_id,
				location: device.location.description
			}
			deviceNames.push(option)
		})
		this.setState({ deviceNames })

	}


	getClassContainer() {
		let containerWidth = '';
		let containerHeight = '';
		let container = '';

		if (isMobileOnly) {
			container = 'mpanel-container-mobile';
		} else {
			container = 'mpanel-container-desktop';
			containerWidth = this.props.compareMode ? 'mpanel-container-w34' : 'mpanel-container-w50';
			containerHeight = this.props.selectedDevices.length > 0 && !this.props.compareMode ? 'mpanel-container-h58' : 'mpanel-container-h100';
		}
		return `${container} ${containerWidth} ${containerHeight}`
	}

	render() {
		const classContainer = this.getClassContainer()
		return (
			<>
				{this.state.layouts &&
					<div className={`mpanel-container ${classContainer} ${this.props.show} pt-12`}
					>
						<SearchDevicesComponent
							showDeviceById={this.props.showDeviceById}
							deviceNames={this.state.deviceNames}
						/>
						<Notifications />
						<Tooltip title="Закрыть панель мониторинга">
							<HighlightOffIcon onClick={this.props.hideMonitoringPanel} className="absolute right-5 top-4 cursor-pointer" />
						</Tooltip>
						{consts.PANEL_SECTIONS.map((section) => {

							// Check amount of devices.
							let devicesForType = this.props.devices.filter(device => section.device_types.includes(device.device_type_id))

							if (devicesForType.length == 0) {
								return null;
							}
							const Grid = isMobileOnly ? WidgetsGridMobile : WidgetsGrid
							
							return (
								<Accordion 
									key={`accordion-section-${section.id}`}
									defaultExpanded
									expanded={this.state.sectionExpanding[section.id]}
									onChange={(e, expanded) => {
										const itemName = `type-panel-expanded-${section.id}`
										localStorage.setItem(itemName, expanded)
										let sectionExpanding = this.state.sectionExpanding
										sectionExpanding[section.id] = expanded
										this.setState(sectionExpanding)
									}}
								>
									<AccordionSummary
										sx={{
											height: 5,
											maxHeight: 10,
										}}
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`panel-content-${section.name}`}
										id={`panel-header--${section.name}`}
										className="my-0"
									>
										<Typography className="font-montserrat text-sm py-0 my-0">{section.plural_name}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid
											type={section.id}
											key={`widgets-grid-${section.name}-${this.props.compareMode}-${this.props.show}`}
											devices={devicesForType}
											headerLabel={section.plural_name}
											layouts={this.state.layouts}
											layoutChanged={() => void 0}
											isDraggable={false}
											isResizable={false}
											classShowCompareComponent={this.props.classShowCompareComponent}
											showDeviceById={this.props.showDeviceById}
											deviceParameters={this.props.deviceParameters}
										/>
									</AccordionDetails>
								</Accordion>
							)
						})}
					</div>
				}
			</>
		);
	}
}

export default MonitoringPanel;

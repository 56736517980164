import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';


const sendAgroStatus = async (deviceUUID: string) => {

    const headers = getHeaders()
    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/devices/get_agro_status`,
		headers,
        params: {
            device_uuid: deviceUUID
        },
	})

    return result.data
};


export default sendAgroStatus
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { isMobile } from 'react-device-detect';
import ModuleArch from './sec_3_tab_arch';

const arch = require('../../landing/sec3_tab_arch.html');
const parameters = require('../../landing/sec3_tab_parameters.html');

function Landing() {
	const margin = isMobile ? 'ml-7' : '';
	const offset = isMobile ? '' : 'row offset-md-1 col-10';

	return (
		<div className={`${offset} f-text-div f-text ${margin}`}>
			<ModuleArch />
		</div>
	);
}

export default Landing;



import { useMap } from 'react-leaflet';
import { LeafletHeatmapConfig, LeafletHeatmapData } from '../types/leaflet-heatmap-config';
import TemperatureMap from '../js-libs/temperatureMap';
import { throttle } from 'lodash';
import { useEffect } from 'react';
import L from 'leaflet';
import React from 'react';

type Props = LeafletHeatmapConfig & {
  onUpdateMouseValue?: (value: number | undefined) => void;
  zIndex?: number;
};

const getMinResolution = (pointsCount: number) => {
  if (pointsCount <= 100) return 10;
  return 400;
}

const getResolutionByZoomMap = (pointsCount: number): Record<number, number> => {
  const MIN_RES = getMinResolution(pointsCount);

  return {
    1: MIN_RES,
    2: MIN_RES * 1.2,
    3: MIN_RES * 1.6,
    4: MIN_RES * 1.8,
    5: MIN_RES * 2,
    6: MIN_RES * 2.5,
    7: MIN_RES * 3,
    8: MIN_RES * 6,
    9: MIN_RES * 10,
    10: MIN_RES * 15,
    11: MIN_RES * 15,
    12: MIN_RES * 40,
    13: MIN_RES * 80,
    14: MIN_RES * 160,
    15: MIN_RES * 320,
    16: MIN_RES * 640,
    17: MIN_RES * 1280,
    18: MIN_RES * 2480,
  }
}

const getPoints = (data: LeafletHeatmapData[], converter: L.Map['latLngToContainerPoint']) => {
  return data.map(d => {
    const point = converter(d);
    return { x: point.x, y: point.y, value: d.value }
  });
}


const LeafletHeatmap = (props: Props) => {
  // Leaflet map instance;
  const map = useMap();

  const drawMap = (drw: any, config: LeafletHeatmapConfig, width: number, height: number, res: number) => {
    drw.setPoints(getPoints(config.data, map.latLngToContainerPoint.bind(map)), width, height);
    drw.drawLow(1, res, false);
  }

  const initMapDrawing = (canvasElement: HTMLCanvasElement) => {
    const ctx = canvasElement.getContext("2d") as CanvasRenderingContext2D;

    const drw = new TemperatureMap(ctx, { min: props?.min, max: props?.max });

    const resByZoom = getResolutionByZoomMap(props.data.length);
    const mapHandler = throttle(() => {
      const topLeft = map.containerPointToLayerPoint([0, 0]);
      L.DomUtil.setPosition(canvasElement, topLeft);

      const { x, y } = map.getSize();
      const width = x;
      const height = y;
      canvasElement.width = width;
      canvasElement.height = height;

      if (props) {
        drawMap(drw, props, width, height, resByZoom[map.getZoom()]);
      }
    }, 15);

    mapHandler();

    map.addEventListener('zoom', mapHandler);
    map.addEventListener('move', mapHandler);

    const mouseListener = throttle((e: L.LeafletMouseEvent) => {
      if (props?.data.length) {
        const p = e.containerPoint;
        const pointVal = drw.getPointValue(props.data.length, { x: p.x, y: p.y });

        props.onUpdateMouseValue?.(pointVal === -255 ? undefined : pointVal);
      }
    }, 15);

    map.addEventListener('mousemove', mouseListener);

    return () => {
      drw.clear();
      map.removeEventListener('zoom', mapHandler);
      map.removeEventListener('move', mapHandler);
      map.removeEventListener('mousemove', mouseListener);
      props.onUpdateMouseValue?.(undefined);
    }
  }

  useEffect(() => {
    if (props && props.data.length) {

      const heatmapPane = map.createPane('heatmap');
      heatmapPane.style.zIndex = (props?.zIndex ?? 450).toString();

      const canvasEl = document.createElement('canvas');
      heatmapPane.appendChild(canvasEl);

      // Forces and removes 1st render because of a very strange bug. After 1st render works fine
      initMapDrawing(canvasEl)();
      const clearFunction = initMapDrawing(canvasEl);

      return () => {
        heatmapPane.remove();
        clearFunction();
      }
    }
  }, [props.data, props.min, props.max, props.zIndex]);


  return <div></div>;
}

export default React.memo(LeafletHeatmap);

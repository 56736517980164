import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useState } from "react";
import * as consts from '../../consts';


function RForm() {
  const [validated, setValidated] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [handleRequest, setHandleRequest] = useState(false);


  const handleSubmit = (event) => {
    event.preventDefault();
    
    setShowSuccessAlert(false);
    setHandleRequest(true);
    setValidated(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setHandleRequest(false);
      event.stopPropagation();
      return
    }

    const formData = new FormData(event.currentTarget);
    let data = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }
    fetch(`${consts.BASE_API_URL}/save_request`, {
      method: "POST",
      headers : {
        'Content-Type':'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      console.log(response);
      setHandleRequest(false);
      setShowSuccessAlert(true);
    });
    
    event.stopPropagation();
  };

  return (
    <div>
	<Alert variant="success" className={!showSuccessAlert? 'hidden' : undefined}>
	    <p>
		Форма была успешно отправлена и будет обработана в ближайшее время.
		Логин и пароль для личного кабинета будут высланы на указанный
		адрес электронной почты.
	    </p>
	</Alert>
	<div  className={!handleRequest? 'hidden' : undefined}>
	    <Spinner animation="border"/>
	</div>
	<p>
	    <span className="required-field">*</span> - обязательные для заполнения поля.
	</p>
	<Form  noValidate validated={validated} onSubmit={handleSubmit}>
	  <Form.Group className="mb-3" controlId="name">
	    <Form.Label>Имя <span className="required-field">*</span></Form.Label>
	    <Form.Control type="input" required name="name"/>
	    <Form.Control.Feedback type="invalid">Необходимо заполнить имя.</Form.Control.Feedback>
	  </Form.Group>
	  
	  <Form.Group className="mb-3" controlId="second_name">
	    <Form.Label>Фамилия <span className="required-field">*</span></Form.Label>
	    <Form.Control type="input"  required name="second_name"/>
	    <Form.Control.Feedback type="invalid">Необходимо заполнить фамилию.</Form.Control.Feedback>
	  </Form.Group>
	  
	  <Form.Group className="mb-3" controlId="email">
	    <Form.Label>Адрес электронной почты <span className="required-field">*</span></Form.Label>
	    <Form.Control type="email"  required name="email"/>
	    <Form.Control.Feedback type="invalid">Необходимо заполнить адрес электронной почты.</Form.Control.Feedback>
	  </Form.Group>
	  
	  <Form.Group className="mb-3" controlId="phone">
	    <Form.Label>Телефон</Form.Label>
	    <Form.Control type="input"  name="phone"/>
	  </Form.Group>
	  
	  <Form.Group className="mb-3" controlId="org">
	    <Form.Label>Организация <span className="required-field">*</span></Form.Label>
	    <Form.Control type="input"  required name="organization"/>
	    <Form.Control.Feedback type="invalid">Необходимо заполнить организацию.</Form.Control.Feedback>
	  </Form.Group>
	  
	  <Button variant="primary" type="submit">
	    Отправить
	  </Button>
	</Form>
    </div>
  );
}
export default RForm;


import SpectrTable from '../../../common/SpectrTable';

export interface CrownSpectrProps {
    data: any,
}

export default function CrownSpectr({ data }: CrownSpectrProps) {

    const waves = [610, 680, 730, 760, 810, 860];

    return (
        <SpectrTable
            data={data} 
            waves={waves}
            prefix={'as7263'}
        />
    );
}
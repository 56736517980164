import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

const getTeplovizorData = async (device_id: number): Promise<number[][]> => {
    const headers = getHeaders()
    const result = await axios({
        method: "GET",
        url: `${consts.BASE_API_URL}/data/get_teplovizor_table`,
        headers,
        params: { device_id },
    })

    return result.data.data as number[][]
};


export default getTeplovizorData
import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';


const updateSeismicPoint = async (seismicEvent, pointsInfo) => {
    console.log(pointsInfo)
    
    const headers = getHeaders()
    const result = await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/seismicevents/update_seismic_event`,
		headers,
        data: {
            event: seismicEvent, 
            points: pointsInfo,
        },
	})

    return result.data
};


export default updateSeismicPoint
import axios from 'axios';
import * as consts from '../../consts';
import { AiCameraRoi } from '../../model/ai.camera.roi';
import getHeaders from '../headers';


const saveRoi = async (roi: AiCameraRoi) => {

    const headers = getHeaders()
    const result = await axios({
        method: "POST",
        url: `${consts.BASE_API_URL}/devices/save_roi`,
        headers,
        data: {
            roi
        },
    })

    return result.data
};


export default saveRoi
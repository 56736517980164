import Token from "../components/token_v2";

export const getHeaders = (): any => {
    const token = new Token;
    let headers = {};
    if (token.getToken()) {
        headers = { Authorization: 'Bearer ' + token.getToken() };
    }

    return headers;
};

export default getHeaders
import { Component } from 'react';
import LastData from '../../last_data';
import WindRose from '../../windrose';
import LastDataPanel from '../last_data_panel';

class PulseStation extends Component {
	render() {
		return (
			<div>
				<LastDataPanel 
					deviceParameters={this.props.deviceParameters}
					parameters={this.props.parameters}
					data={this.props.data}
				/>
				<hr className='mt-3' />
				<div>
					<WindRose 
                        key={`wind-rose-${this.props.device_id}`} 
                        device_id={this.props.device_id} 
                    />
					<hr className='mt-3' />
				</div>
			</div>
		);
	}
}

export default PulseStation;

export default {
	responsive: true,
	maintainAspectRatio: false,
	spanGaps: true,
	plugins: {
		legend: {
			display: false,
			position: 'top',
		},
		tooltip: {
			callbacks: {
				label: (v) => {
					return v;
				},
				title: (v) => {
					if (v.length > 0) {
						return `${v[0].dataset.device}, ${v[0].dataset.label}`
					}
					return "";
				}
			}
		}
	},
	scales: {
		y: {
			ticks: {
				display: true,
			},
		}
	},
}

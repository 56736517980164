import { TabContext } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import getProfile from '../../../api/user/get.profile';
import AdminHeader from '../../admin/header';
import Nav from '../../nav';
import ProfileInfo from './ProfileInfo';
import { User } from '../../../model/profile';
import ProfileSettings from './ProfileSettings';
import ChangePassword from './ChangePassword';

export enum ProfileTab {
    Info = 'Info',
    Settings = 'Settings',
    ChangePassword = 'ChangePassword',

}


export default function ProfilePage() {
    const [activeTab, setActiveTab] = useState<ProfileTab>(ProfileTab.Info);
    const [headerTitle, setHeaderTitle] = useState('Аккаунт...')
    const [user, setUser] = useState<User>(new User())

    useEffect(() => {
        const fetchData = async () => {
            const user = await getProfile()
            setUser(user)
            setHeaderTitle(`Аккаунт ${user.user_name}`)
        }

        fetchData()

    }, [])


    const onChangeTab = (value: ProfileTab) => {
        setActiveTab(value)
    }

    return (
        <>
            <Nav />

            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AdminHeader headerText={`${headerTitle}`} backUrl={`/lk`} />
                    
                    <Tabs className="mb-2" centered={true} value={activeTab} onChange={(_, value) => onChangeTab(value)}>
                        <Tab label="Информация" value={ProfileTab.Info} />
                        <Tab label="Изменить пароль" value={ProfileTab.ChangePassword} />
                        <Tab label="Настройки" value={ProfileTab.Settings} />
                    </Tabs>

                    <TabContext value={activeTab}>
                        <TabPanel className="p-0" value={ProfileTab.Info}>
                            <ProfileInfo user={user} />
                        </TabPanel>
                        <TabPanel className="p-0" value={ProfileTab.ChangePassword}>
                            <ChangePassword user={user} />
                        </TabPanel>
                        <TabPanel className="p-0" value={ProfileTab.Settings}>
                            <ProfileSettings user={user} />
                        </TabPanel>

                    </TabContext>

                </div>
            </section>
        </>
    );
}
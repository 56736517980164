import { useEffect, useState } from 'react';
import { getUrlForImageResource } from '../../../utils';
import ImageGallery from 'react-image-gallery';
import CircularProgress from '@mui/material/CircularProgress';
import ResponsiveDatePickers from './seismic/datepicker';
import { Tooltip } from '@mui/material';
import moment, { Moment } from 'moment';
import loadCameraImages from '../../../api/data/load.camera.images';
import { CameraImage, CameraImagesRequest } from '../../../model/camera.image';

export interface CameraDeviceProps {
    device_id: number,
    Component?: any,
}

export default function CameraDevice({ device_id, Component }: CameraDeviceProps) {

    const [images, setImages] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [labelInfo, setLabelInfo] = useState<string>('Последние 24 фото:')
    const [startDate, setStartDate] = useState< Moment | undefined>(moment());

    const fetchData = async (date: Moment|undefined) => {
        setLoading(true)
        const request = new CameraImagesRequest()
        request.device_id = device_id
        if (date) {
            request.dt_start = moment(date).startOf('day').unix()
            request.dt_end = moment(date).endOf('day').unix()
        }

        const imagesInfo = await loadCameraImages(request)

        const images = imagesInfo.map((image: CameraImage) => {
            const url = getUrlForImageResource(device_id, image.filename)
            return {
                original: url,
                thumbnail: url,
                description: `${image.mstime}`,
                id: image.id,
            }
        })
        if (imagesInfo.length > 0) {
            setStartDate(moment(imagesInfo[0].mstime))
        }
        setImages(images)
        setLoading(false)
    }

    useEffect(() => {
        fetchData(undefined)
    }, [device_id])


    const updateDate = (date: any) => {
        const mDate = moment(date)
        setStartDate(mDate)
        fetchData(mDate)

        setLabelInfo(`Фото за ${mDate.format('YYYY-MM-DD')}:`)
    }


    const update = () => {
        if (startDate) {
            fetchData(startDate)
            setLabelInfo(`Фото за ${startDate.format('YYYY-MM-DD')}:`)

        }
    }


    const ImageComponent = Component ? Component : ImageGallery

    return (
        <>
            <Tooltip title="Выбор дня для просмотра изображений за этот день" className='pt-5'>
                <div>
                    <ResponsiveDatePickers
                        className='w-full'
                        date={startDate?.toDate()}
                        onChange={updateDate}
                    />
                </div>
            </Tooltip>

            {loading &&
                <div className="flex items-center justify-center mt-5">
                    <CircularProgress />
                </div>
            }
            {!loading && images.length === 0 &&
                <div>
                    Нет доступных фотографий
                </div>
            }
            {!loading && images.length !== 0 &&
                <div className="pt-5">
                    <div>{labelInfo}</div>
                    <ImageComponent 
                        items={images} 
                        showPlayButton={false} 
                        showNav={true}
                        update={update}
                    />
                </div>
            }

        </>

    );
}


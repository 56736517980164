import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

export const saveCameraSettings = async (settings) => {

	const headers = getHeaders()

    const formData = new FormData();
    for ( var key in settings ) {
        formData.append(key, settings[key]);
    }
    
    headers["Content-Type"] = "multipart/form-data"

    const result = await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/devices/save_camera_settings`,
		headers,
        data: formData,
	})

    return result.status
};

export default saveCameraSettings;
import Device from '../../model/device';
import DeviceParameter from '../../model/device.parameter';
import { LeafletHeatmapConfig } from '../types/leaflet-heatmap-config';
import { removeDuplicatesByKey } from './array.utils';

export type DeviceParametersInfoMap = Record<string, DeviceParameter>;

export const getDeviceParameterInfo = (device: Device, parameterName: string, paramsInfoMap: DeviceParametersInfoMap): DeviceParameter => {
  return paramsInfoMap[`${device.device_type_id}_${device.module_type_id ?? ''}_${parameterName}`];
}

export const getDevicesParams = (devices: Device[], deviceParametersInfoMap: DeviceParametersInfoMap): DeviceParameter[] => {
  const params: DeviceParameter[] = [];

  devices.forEach(d => Object.keys(d.last_parameter_values).forEach(key => {
    if (!isNaN(+d.last_parameter_values[key])) {
      const paramInfo = getDeviceParameterInfo(d, key, deviceParametersInfoMap);
      if (paramInfo) {
        params.push(paramInfo)
      }
    }
  }));

  return removeDuplicatesByKey(params, 'id');
}

export const getParametersByIds = (paramsInfoMap: DeviceParametersInfoMap, ids: number[]): DeviceParameter[] => {
  const params = Object.values(paramsInfoMap);

  return params.filter(p => ids.includes(p.id));
}

export const getDevicesHeatmapDataByParameters = (devices: Device[], parameterNames: string[]): LeafletHeatmapConfig => {
  const data: LeafletHeatmapConfig['data'] = [];

  for (const device of devices) {
    Object.entries(device.last_parameter_values).forEach(([parameterName, value]) => {
      if (parameterNames.includes(parameterName)) {
        data.push({ lat: device.latitude, lng: device.longitude, value: Number(value) });
      }
    })
  }


  const max = data.length ? Math.max(...data.map(i => i.value)) : 0;
  const min = data.length ? Math.min(...data.map(i => i.value)) : 0;

  return { min, max, data };
}

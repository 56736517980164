import { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTriggerWrapper from './overlay_trigger_wrapper'
import DATA_CELLS from '../../../../data_cells';
import { Tooltip } from '@mui/material';

class SoilParameter extends Component {
	render() {
		const depth = Math.abs(this.props.depth * 100)
		const label = this.props.depth >= 0 ? 'Высота' : 'Глубина';
		const depthLabel = `${label} ${depth} см`

		const tempLabel = this.props.data_temp_key ? DATA_CELLS[this.props.data_temp_key].label : '';
		const humLabel = this.props.data_hum_key ? DATA_CELLS[this.props.data_hum_key].label : '';

		return (
			<div>
				<div className="soil-parameter-depth">
					{depthLabel}
				</div>
				<Row>
					<Tooltip title={tempLabel}>
						<Col md={6} xs={6} >
							<div className="soil-parameter-temp">
								{Math.round(this.props.temp * 10) / 10}°
							</div>
						</Col>
					</Tooltip>
					<Tooltip title={humLabel}>
						<Col md={6} xs={6} >
							<div className="soil-parameter-hum">
								{Math.round(this.props.hum)}%
							</div>
						</Col>
					</Tooltip>
				</Row>
			</div>
		);
	}
}
export default SoilParameter;

const ValueUnderMouse = (props: { value: number; className?: string }) => {
  const stringValue = props.value?.toFixed(1);

  if (!stringValue) {
    return <></>
  }

  return <div className={`bg-white/50 rounded-lg px-3 py-2.5 text-sm ${props?.className ?? ''}`}>{stringValue}</div>;
}

export default ValueUnderMouse;
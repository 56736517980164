import { Component } from 'react';
import { isMobile } from 'react-device-detect';

const model = require('../../landing/3dmodel.html');

class AeroMaterials extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentImage: '/landing/block04/aero_photo_s.jpg',
			classImage: '',
			class3D: 'hidden',
		}
	}

	setCurrentImage = (image) => {
		const imageName = `/landing/block04/${image}`;
		this.setState({ currentImage: imageName, classImage: '', class3D: 'hidden' })
	}


	set3D = () => {
		this.setState({ classImage: 'hidden w-full', class3D: '' })
	}

	render() {
		const center = isMobile ? 'justify-center items-center flex' : '';
		const aero = isMobile ? '' : 'img-aero';
		const cols = isMobile ? '' : 'col-md-6';

		return (

			<div className="row f-text sec3-tab">
				<div className={`${cols} f-center-text ${center}`}>
					<div className={`${this.state.classImage} ${center}`}>
						<img src={this.state.currentImage} className={`${aero}`} />
					</div>
					<div className={this.state.class3D}>
						<div dangerouslySetInnerHTML={{ __html: model }} />
					</div>
				</div>
				<div className={`${cols}`}>
					<div className="f-text sec4-header">
						Конечные данные:
					</div>

					<div className="f-text-div f-text">
						<span className="f-text-link" onClick={() => this.setCurrentImage('aero_photo_s.jpg')}>Аэрофотоснимки</span>
					</div>

					<div className="f-text-div f-text">
						<span className="f-text-link" onClick={() => this.setCurrentImage('orth_s_rotated.jpg')}>Ортофотоплан</span>
					</div>

					<div className="f-text-div f-text">
						<span className="f-text-link" onClick={() => this.setCurrentImage('map_height_s_rotated.jpg')}>Карта высот</span>
					</div>

					<div className="f-text-div f-text">
						<span className="f-text-link" onClick={() => this.setCurrentImage('cloud_points_s.jpg')}>Плотное облако точек</span>
					</div>

					<div className="f-text-div f-text">
						<span className="f-text-link" onClick={() => this.set3D()}>3D-модель местности</span>
					</div>


					<div className="f-text-div">
						<button className="f-button" onClick={this.props.showRequestForm}>Заказать аэрофотосъёмку</button>
					</div>

				</div>
			</div>
		);
	}
}

export default AeroMaterials;

const MobileCoverPage1 = () => {


    return (
        <>
            <div className="text-center mt-5 flex justify-center items-center">
                <img src="/landing/assets/img/header_02.png" className="w-[80%]" />
            </div>
            <div className="mb-5">
                <div className="text-center">
                    <p className="text-xl font-montserrat">
                        Подключение к сети
                    </p>
                    <p className="text-xl font-montserrat mt-5">
                        Подключаем к IoT-сети <br />
                        существующие посты<br />
                        мониторинга<br />
                    </p>
                    <p className="more-link-text">
                        <a className="more-link-text" href="#part02">Узнать подробнее</a>
                    </p>
                </div>
            </div>
        </>
    );
}

export default MobileCoverPage1
import {isMobile} from 'react-device-detect';


const SectionHeader = ({number, header, description, secondDescription}: 
        {number: string, header: string[], description: string[], secondDescription: string}) => {

    const offset = isMobile ? '' : 'offset-md-1 col-10';
    const textCenter = isMobile ? 'pl-5' : '';
    const textSize = isMobile ? 'text-lg' : '';
    const textSizeSecond = isMobile ? 'text-base' : 'text-xl';
    
    return (
        <div className={`section-div ${textCenter}  w-full p-1`}>
            <div className={`${offset} flex gap-1 items-center`}>
                <p className={`feature-number`}>{number}</p>
                <p className="f-delimiter"></p>
                <div className="f-header">
                    <p className={`leading-snug ${textSize} font-montserrat`}>
                        {header.map((line) => {
                            return <div dangerouslySetInnerHTML={{ __html: line }} />
                        })}
                    </p>
                </div>

            </div>
            <div className={`${offset} font-light font-montserrat leading-7  ${textSizeSecond}`}>
                <p className="leading-snug">
                    {description.map((line) => {
                        return <div dangerouslySetInnerHTML={{ __html: line }} />
                    })}
                </p>
            </div>

            <div className={`${offset} f-description-div f-description-second-div`}  dangerouslySetInnerHTML={{ __html: secondDescription }} />
        </div>


    );
}

export default SectionHeader
import * as React from 'react';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MenuIcon from '@mui/icons-material/Menu';
import LayersIcon from '@mui/icons-material/Layers';
import CompareIcon from '@mui/icons-material/Compare';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RequestForm from '../../requestform/modal';
import SaveIcon from '@mui/icons-material/Save';
import HelpIcon from '@mui/icons-material/Help';

import { Component } from 'react';
import LayersMenu from './layers_menu';
import Token from '../../token_v2';
import LoginForm from '../../loginform/main'
import logout from '../../../api/user/logout';
import { withRouter } from '../../withRouter';
import EcoModal from '../../eco_modal';

const mainColor = '#508104';

class UserMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: new Token,
            anchorEl: undefined,
            open: false,
            actions: this.getActions(),
            showLoginForm: false,
            showRequestForm: false,
        }
    }

    getActions() {
        let userProfileIcon = undefined;
        let userIcon = undefined;
        let compareIcon = undefined;
        let saveSettingsIcon = undefined;
        let helpIcon = undefined;

        const token = new Token
        if (this.props.saveMapSettings) {
            saveSettingsIcon = { icon: <SaveIcon />, name: 'Сохранить настройки', action: this.saveMapSettings.bind(this) }
        } else {
            if (token.getToken()) {
                userIcon = { icon: <LogoutIcon />, name: 'Выход', action: this.logoutClick.bind(this) }
            } else {
                userIcon = { icon: <LoginIcon />, name: 'Вход', action: this.login.bind(this) }
            }
        }

        if (token.getToken()) {
            userProfileIcon = { icon: <AccountCircleIcon />, name: 'Личный кабинет', action: this.goToProfile.bind(this) }
        }


        if (this.props.toggleCompare) {
            compareIcon = { icon: <CompareIcon />, name: 'Режим сравнения', action: this.toggleCompare.bind(this), operation: 'compare' }
        }

        if (this.props.openHelpDialog) {
            helpIcon = { icon: <HelpIcon />, name: 'Справка', action: this.openHelpDialog.bind(this) }

        }

        return [
            userIcon,
            userProfileIcon,
            compareIcon,
            saveSettingsIcon,
            { icon: <LayersIcon />, name: 'Изменить тип карты', action: this.openLayerMenu.bind(this), id: 'change-layer-button' },
            helpIcon,
        ]
    }

    login() {
        this.setState({ showLoginForm: true })
    }

    logoutClick() {
        logout(() => {
            window.location.reload()
        })
    }

    toggleCompare() {
        this.props.toggleCompare()
    }


    saveMapSettings() {
        this.props.saveMapSettings()
    }

    openHelpDialog() {
        this.props.openHelpDialog()
    }


    openLayerMenu(event) {
        this.setState({ anchorEl: event.currentTarget, open: true });
    };

    goToProfile() {
        this.props.navigate('/lk')
    }


    changeLayer(layer) {
        this.closeLayersMenu()
        this.props.toggleLayer(layer)
    };


    closeLayersMenu() {
        this.setState({ anchorEl: undefined, open: false }); // close menu
    };

    getFab() {
        return {
            sx: {
                bgcolor: mainColor,
                '&:hover': {
                    bgcolor: mainColor,
                }
            }
        }
    }

    showRequestForm = () => {
        this.setState({ showRequestForm: true, isOrder: false })
    }

    closeRequestForm = () => {
        this.setState({ showRequestForm: false })
    }

    render() {
        const top = this.props.top ? this.props.top : 16
        return (
            <>
                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'absolute', top, right: 16 }}
                    icon={<MenuIcon sx={{ color: 'white' }} />}
                    direction="left"
                    FabProps={this.getFab()}
                >
                    {this.state.actions.map((action) => {
                        if (!action) return;

                        const fabProps = action.operation == 'compare' && this.props.compareMode ? this.getFab() : {};
                        return (
                            <SpeedDialAction
                                id={action.id}
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.action}
                                aria-controls={this.state.open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={this.state.open ? 'true' : undefined}
                                FabProps={fabProps}
                            />
                        )
                    })}
                </SpeedDial>
                <LayersMenu
                    changeLayer={this.changeLayer.bind(this)}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    handleClose={this.closeLayersMenu.bind(this)}
                />

                <EcoModal
                    close={() => this.setState({ showLoginForm: false })}
                    showRequestForm={this.showRequestForm}
                    afterLogin={() => window.location.reload()}
                    show={this.state.showLoginForm}

                    key="aisettings-car-modal"
                    open={this.state.showLoginForm}
                    handleClose={() => this.setState({ showLoginForm: false })}
                    content={LoginForm}
                    title={``}
                />


                <EcoModal
                    close={() => this.closeRequestForm(false)}
                    show={this.state.showRequestForm}

                    key="request-modal"
                    open={this.state.showRequestForm}
                    handleClose={() => this.setState({ showRequestForm: false })}
                    content={RequestForm}
                    title={``}
                />
            </>
        );
    }
}

export default withRouter(UserMenu)

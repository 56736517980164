import Nav from '../../nav';
import AdminHeader from '../header';
import { useEffect, useState } from 'react';
import SeismicDevice from '../../map/devices/seismic/seismic';
import { useParams } from 'react-router-dom';
import SeismicEvent from '../../../model/seismic.event';
import getSeismicEvent from '../../../api/seismic/get.seismic.event';
import CircularProgress from '@mui/material/CircularProgress';
import updateSeismicPoint from '../../../api/data/update.seismic.point';
import { Snackbar } from '@mui/material';
import { Moment } from 'moment';
import SeismicEventEditForm from './SeismicEventEditForm';
import { Navigate } from "react-router-dom";
import moment from 'moment';
import SeismicEventCenter from './SeismicEventCenter';

export default function SeismicEventView() {
    const params = useParams()

    const [headerTitle, setHeaderTitle] = useState('Сейсмособытие')
    const [event, setEvent] = useState<SeismicEvent | null>()
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [pointsInfo, setPointsInfo] = useState(undefined)

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');
    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };


    const fetchData = async () => {
        if (params.seismic_event_id) {
            setLoading(true)
            const seismicEvent = await getSeismicEvent(parseInt(params.seismic_event_id))
            setEvent(seismicEvent)
            if (seismicEvent) {
                setHeaderTitle(`Сейсмособытие '${seismicEvent.name}'`)
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [params.seismic_event_id])


    const updateSeismicEvent = async () => {
        setUpdating(true)
        try {
            await updateSeismicPoint(event, pointsInfo)
            notify('Событие успешно сохранено')
        } catch (error) {
        }

        setUpdating(false)
    }

    const updatePointsInfo = (pointsInfo: any) => {
        setPointsInfo(pointsInfo)
    }

    const updateEventDate = (mstime: Moment) => {
        const _event = JSON.parse(JSON.stringify(event))
        if (mstime) {
            mstime = moment(mstime)
            _event.mstime = mstime.format('YYYY-MM-DD HH:mm:ss')
            setEvent(_event)
        }

    }

    const updateSeismicValue = (value: string, field: string) => {
        const _event = JSON.parse(JSON.stringify(event))
        _event[field] = value
        setEvent(_event)
    }

    const updateSeismicBooleanValue = (value: boolean, field: string) => {
        const _event = JSON.parse(JSON.stringify(event))
        _event[field] = value
        setEvent(_event)
    }

    if (event === null) {
        return (<Navigate to="/login" />)
    }



    return (
        <>
            <Nav />
            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AdminHeader headerText={`${headerTitle}`} backUrl={`/admin/seismic`} />
                    {loading &&
                        <div className="flex items-center justify-center mt-5">
                            <CircularProgress />
                        </div>
                    }
                    {!loading && event &&

                        <div>
                            <div className="flex align-middle">
                                <button disabled={updating} className="lr-primary-button" onClick={updateSeismicEvent}>Сохранить</button>
                                {updating &&
                                    <CircularProgress className="ml-5" />
                                }
                            </div>
                            <SeismicEventEditForm
                                seismicEvent={event}
                                updateSeismicValue={updateSeismicValue}
                                updateSeismicBooleanValue={updateSeismicBooleanValue}
                            />
                            <SeismicEventCenter
                                seismicEvent={event}
                            />
                            {event.stations.map((station) => {
                                return <div key={`seismic-device-${station.device_id}`}>
                                    <SeismicDevice
                                        device_id={station.device_id}
                                        seismic_event={event}
                                        updatePointsInfo={updatePointsInfo}
                                        updateEventDate={updateEventDate}
                                        headerLabel={`${station.name}, s/n ${station.serial_number}`}

                                    />
                                </div>
                            })}
                        </div>
                    }
                </div>
            </section>

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />
        </>
    );
}
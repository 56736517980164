import { useEffect, useState } from 'react';
import { Button, IconButton, MenuItem, Select } from '@mui/material';
import { isUndefined } from 'lodash';
import { DeviceParametersInfoMap, getDevicesParams, getParametersByIds } from '../utils/devices-utils';
import Device from '../../model/device';
import DeviceParametersGroup from '../../model/device.parameters.group';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
  devices?: Device[];
  deviceParameters: DeviceParametersInfoMap;
  paramsGroups?: DeviceParametersGroup[];
  className?: string;

  onChangeActiveGroupId?: (groupId: DeviceParametersGroup['id'] | undefined) => void;
}

const getInitialActiveGroupId = () => {
  const paramString = localStorage.getItem('selected-group-id');

  return paramString ? +paramString : undefined;
}

const ParametersSelector = (props: Props) => {
  const [activeParamsGroupId, _setActiveParamsGroupId] = useState<DeviceParametersGroup['id'] | undefined>(undefined);

  useEffect(() => {
    const initialActiveGroupId = getInitialActiveGroupId();

    if (initialActiveGroupId) {
      setActiveParamsGroupId(initialActiveGroupId);
    }
  }, [])

  const clearParameters = () => {
    setActiveParamsGroupId(undefined);
  }

  const setActiveParamsGroupId = (groupId: DeviceParametersGroup['id'] | undefined) => {
    _setActiveParamsGroupId(groupId);
    props?.onChangeActiveGroupId?.(groupId);

    if (groupId) {
      localStorage.setItem('selected-group-id', groupId.toString());
    } else {
      localStorage.removeItem('selected-group-id');
    }
  }


  return <div className={`${props?.className || ''}`}>
    {props.paramsGroups &&
      <Select endAdornment={activeParamsGroupId ? (
        <IconButton className='mr-4' onClick={clearParameters}>
          <ClearIcon />
        </IconButton>
      ) : null} className='w-full'
        value={isUndefined(activeParamsGroupId) ? '' : String(activeParamsGroupId)}
        onChange={e => setActiveParamsGroupId(Number(e.target.value) as number)}>
        {Boolean(props?.paramsGroups) && props.paramsGroups?.map(group => {
          return <MenuItem
            key={group.id}
            value={String(group.id)}>
            <div className='flex items-center gap-2'>
              <div>{group.name}</div>
            </div>
          </MenuItem>
        })}
      </Select>
    }
  </div>;
}

export default ParametersSelector;
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useEffect, useState } from 'react';
import { getDevicesTypes } from '../../../api/devices/get.devices.types';
import { DEVICE_TYPES } from '../../../consts';
import { DeviceType, ModuleType } from '../../../model/device.type';
import { useNavigate } from "react-router-dom";

interface DeviceTypesProps {
    deviceTypes: DeviceType[]
    moduleTypes: ModuleType[]
}


export default function DeviceTypes({deviceTypes, moduleTypes}: DeviceTypesProps) {
    
	const navigate = useNavigate();

    const clickOnDeviceType = (deviceTypeId: number) => {
		navigate(`/admin/devices/type/${deviceTypeId}`)
    }


    const clickOnModuleType = (moduleTypeId: number) => {
        console.log(moduleTypeId)
		navigate(`/admin/devices/module_type/${moduleTypeId}`)
    }
    

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <div className="font-medium text-2xl pt-10">Устройства:</div>
            <List component="nav" aria-label="main mailbox folders">
                {deviceTypes.map((deviceType) => {
                    if ([DEVICE_TYPES.MODULE, 2].includes(deviceType.id)) {
                        return null
                    }

                    return <ListItemButton
                        key={`device-type-button-${deviceType.id}`}
                        onClick={() => clickOnDeviceType(deviceType.id)}
                    >
                        <ListItemIcon>
                            <img src={`/device_icons/${deviceType.name}.png`} width="32" />
                        </ListItemIcon>
                        <ListItemText primary={`${deviceType.description}`} />
                    </ListItemButton>

                })}
            </List>

            <div className="font-medium text-2xl pt-10">Модули SmartecoSystems:</div>
            <List component="nav">
                {moduleTypes.map((moduleType) => {

                    return <ListItemButton
                        key={`device-type-button-${moduleType.id}`}
                        onClick={() => clickOnModuleType(moduleType.id)}
                    >
                        <ListItemIcon>
                            <img src={`/device_icons/${moduleType.name}.png`} width="32" />
                        </ListItemIcon>
                        <ListItemText primary={`${moduleType.description}`} />
                    </ListItemButton>

                })}
            </List>
        </Box>
    );
}
import { Component } from 'react';
import * as consts from '../../../consts';
import WidgetModule from '../../map/panel/widgets/widget_module';
import Camera from '../../map/panel/widgets/camera';
import Audio from '../../map/panel/widgets/audio';
import Mstation from '../../map/panel/widgets/msstation';

import Seismic from '../../map/panel/widgets/seismic';
import { WidthProvider, Responsive } from "react-grid-layout";
import './widgets.css'
import AICameraCars from './widgets/ai_camera_cars';
import AICameraFairs from './widgets/ai_camera_fires';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const deviceWidgets = {
	[consts.DEVICE_TYPES.METEO]: Mstation,
	[consts.DEVICE_TYPES.METEO_SOKOL]: Mstation,
	[consts.DEVICE_TYPES.METEO_WIFI_STATION]: Mstation,
	[consts.DEVICE_TYPES.KARELIAMETEO_STATION]: Mstation,
	[consts.DEVICE_TYPES.METEO_M]: Mstation,
	[consts.DEVICE_TYPES.PULSE_STATION]: Mstation,
	[consts.DEVICE_TYPES.PULSE_STATION_V2]: Mstation,
	[consts.DEVICE_TYPES.CAMERA]: Camera,
	[consts.DEVICE_TYPES.AUDIO]: Audio,
	[consts.DEVICE_TYPES.MODULE]: WidgetModule,
	[consts.DEVICE_TYPES.SEISMICSTATION]: Seismic,
	[consts.DEVICE_TYPES.AI_CAMERA_CARS]: AICameraCars,
	[consts.DEVICE_TYPES.AI_CAMERA_FIRES]: AICameraFairs,

}

class WidgetsGrid extends Component {

	render() {
		if (!this.props.type) {
			return null;
		}

		let x = -4, y = 0;
		return (
			<div className="widgets-grid">
				<ResponsiveReactGridLayout
					key={`layout-${this.props.type}-${this.props.classShowCompareComponent}-${this.props.layouts[this.props.type]}`}
					className="layout"
					isBounded={true}
					isDraggable={this.props.isDraggable}
					isResizable={this.props.isResizable}
					rowHeight={160}
					cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
					onLayoutChange={(layout) => this.props.layoutChanged(layout, this.props.type)}
				>
					{this.props.devices.map((device, i) => {
						if (device.device_type_id === consts.DEVICE_TYPES.KARELIAMETEO_STATION && isNaN(parseInt(device.serial_number))) {
							return null
						}
						let dataGrid = {}
						if (this.props.layouts[this.props.type] && this.props.layouts[this.props.type].length > 0) {
							this.props.layouts[this.props.type].forEach((grid) => {
								if (grid.i == `eco_widget_${device.device_id}`) {
									dataGrid = grid;
								}
							})
							// Check empty object.
							if (Object.keys(dataGrid).length === 0) {
								dataGrid = { x: 0, y: 0, w: 4, h: 1 }
							}
						} else {
							// x and y.
							if (x % 8 == 0 && x > 0) {
								x = 0;
								y += 2;
							} else {
								x += 4;
							}

							const h = device.soil_info ? 2 : consts.getInfoForDevice(device).widgetHeight
							dataGrid = { x, y, w: 4, h }
						}


						const Widget = deviceWidgets[device.device_type_id]

						return (
							<div key={`eco_widget_${device.device_id}`} data-grid={dataGrid}>
								<Widget
									device={device}
									info={device}
									showDeviceById={this.props.showDeviceById}
									deviceParameters={this.props.deviceParameters}
								/>
							</div>
						)
					})}
				</ResponsiveReactGridLayout>
			</div>
		)
	}
}

export default WidgetsGrid;



import { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Param from './soil_parameter';

class SoilDepthModule extends Component {

	render() {
		return (
			<div>
				<Param
					depth={this.props.info.soil_info.depth_sensor_3}
					temp={this.props.info.last_parameter_values.soil_temp_3}
					hum={this.props.info.last_parameter_values.soil_hum}
					data_temp_key="soil_temp_3"
					data_hum_key="soil_hum"
				/>
				<Param
					depth={this.props.info.soil_info.depth_sensor_2}
					temp={this.props.info.last_parameter_values.soil_temp_2}
					hum={this.props.info.last_parameter_values.soil_hum}
					data_temp_key="soil_temp_2"
					data_hum_key="soil_hum"
				/>
				<Param
					depth={this.props.info.soil_info.depth_sensor_1}
					temp={this.props.info.last_parameter_values.soil_temp}
					hum={this.props.info.last_parameter_values.soil_hum}
					data_temp_key="soil_temp"
					data_hum_key="soil_hum"
				/>
			</div>
		);
	}
}
export default SoilDepthModule;

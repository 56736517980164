export const removeDuplicatesByKey = <T>(array: T[], key: keyof T): T[] => {
  const uniqueMap = new Map<string | number, T>();

  for (const item of array) {
    const keyValue = item[key] as string | number;

    // Check if the key value is not in the Map
    if (!uniqueMap.has(keyValue)) {
      // If not, add the object to the Map using the key value
      uniqueMap.set(keyValue, item);
    }
  }

  const uniqueArray = Array.from(uniqueMap.values());
  return uniqueArray;
}

export function linspace(start: number, stop: number, num: number, endpoint = true) {
  const div = endpoint ? (num - 1) : num;
  const step = (stop - start) / div;
  return Array.from({ length: num }, (_, i) => start + step * i);
}

export const meshgrid = <T>(x: T[], y: T[]) => {
  const xx: T[][] = [];
  const yy: T[][] = [];

  for (let i = 0; i < y.length; i++) {
    const rowX = [];
    const rowY = [];
    for (let j = 0; j < x.length; j++) {
      rowX.push(x[j]);
      rowY.push(y[i]);
    }
    xx.push(rowX);
    yy.push(rowY);
  }

  return [xx, yy] as [T[][], T[][]];
}

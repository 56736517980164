import axios from 'axios';
import * as consts from '../../consts';
import { User } from '../../model/profile';
import getHeaders from '../headers';


const userRegistration = async (user: User) => {

    try {
        const headers = getHeaders()
        const result = await axios({
            method: "POST",
            url: `${consts.BASE_API_URL}/profile/registration`,
            headers,
            data: {
                user
            },
        })

        return result.data

    } catch (error: any) {
        console.error(error);
    }
    return null
};


export default userRegistration
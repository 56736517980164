const MobileCoverPage4 = () => {


    return (
        <>
            <div className="relative">

                <img src="/landing/assets/img/copter.png" className="copter-image-header" />
                <div className="rc-header">
                    <video src="/landing/assets/video/video_04.mp4" autoPlay width="870" height="870"
                        className="video-circle" />
                </div>
            </div>
            <div className="mb-5">
                <div className="text-center">
                    <p className="text-xl font-montserrat">
                        Аэромониторинг
                    </p>
                    <p className="text-xl font-montserrat mt-5">
                        Выполняем <br />
                        профессиональную<br />
                        аэрофотосъемку с БПЛА<br />
                    </p>
                    <p className="more-link-text">
                        <a className="more-link-text" href="#part04">Узнать подробнее</a>
                    </p>
                </div>
            </div>
        </>
    );
}

export default MobileCoverPage4
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import DeviceAdditionFields from "../../../../model/device.addition.fields";


interface BooleanFieldProps {
    field: DeviceAdditionFields;
    onChangeBooleanField: any;
}


const BooleanField = ({ field, onChangeBooleanField }: BooleanFieldProps) => {
    field.value = field.value === null ? false : field.value;
    
    return (
        <FormGroup className="my-3">
            <FormControlLabel control={<Switch onChange={(event: any) => onChangeBooleanField(event, field)} checked={field.value} />} label={`${field.description}`} />
        </FormGroup>
    )
}

export default BooleanField;
import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

const deletePlantPhenologyImage = async (imageId: string): Promise<any> => {
    const headers = getHeaders()
    const result = await axios({
        method: "GET",
        url: `${consts.BASE_API_URL}/data/delete_plant_phenology_image`,
        headers,
        params: { image_id: imageId },
    })

    return result.data
};


export default deletePlantPhenologyImage
import Nav from '../../nav';
import { Navigate } from "react-router-dom";
import { Component } from 'react';
import axios from "axios";
import * as consts from '../../../consts';
import Token from '../../token_v2';
import { ToastContainer, toast } from 'react-toastify'
import WidgetsGrid from '../../map/panel/widgets_grid';
import AdminHeader from '../header'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class MonitoringPanelSettings extends Component {
	constructor(props) {
		super(props);

		const layouts = Object.values(consts.DEVICE_TYPES).reduce((previous, current) => {
			previous[current] = []
			return previous;
		}, {});
		this.state = {
			devices: [],
			layouts
		}
		this.token = new Token;
		if (this.token.getToken()) {
			this.loadUserInfo();
			this.loadDevices();
		}
		this.saveLayouts = this.saveLayouts.bind(this)
		this.layoutChanged = this.layoutChanged.bind(this)
	}

	loadDevices() {
		const headers = {}
		if (!this.props.public_panel) { // for public panel only public devices.
			headers.Authorization = `Bearer ${this.token.getToken()}`
		}
		axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/devices`,
			headers
		})
			.then((response) => {
				this.setState({ devices: response.data.devices })
			}).catch((error) => {
				if (error.response) {
					console.log(error)
				}
			})
	}

	loadUserInfo() {
		const headers = {}
		if (!this.props.public_panel) { // for public panel only public devices.
			headers.Authorization = `Bearer ${this.token.getToken()}`
		}
		axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/load_layouts`,
			headers,
		})
			.then((response) => {
				console.log(response.data)
				const layouts = response.data.layouts
				if (layouts) {
					console.log(JSON.parse(layouts))
					this.setState({ layouts: JSON.parse(layouts) })
				}
			}).catch((error) => {
				if (error.response) {
					console.log(error)
				}
			})
	}

	saveLayouts() {
		const endpoint = this.props.public_panel ? 'save_public_panel_layouts' : 'save_layouts'
		axios({
			method: "POST",
			url: `${consts.BASE_API_URL}/${endpoint}`,
			headers: {
				Authorization: 'Bearer ' + this.token.getToken()
			},
			data: { layouts: JSON.stringify(this.state.layouts) }
		})
			.then((response) => {
				toast.success('Расположение успешно сохранено')
			}).catch((error) => {
				console.log(error)
				toast.error('Произошла ошибка при сохранении')
			})
	}

	layoutChanged(layout, type) {
		const layouts = this.state.layouts;
		layouts[type] = layout
		this.setState(layouts)
	}

	render() {
		if (!this.token.getToken()) {
			return (<Navigate to="/login" />)
		}
		const whoIsPanel = this.props.public_panel ? 'общедоступной' : 'моей';
		const labelHeader = `Настройка ${whoIsPanel} панели мониторинга`
		return (
			<>
				<ToastContainer autoClose={2000} hideProgressBar={false} />
				<Nav />
				<section>
					<div className="container px-5 admin-section">
						<AdminHeader headerText={labelHeader} />
						<div>
							<button className="lr-primary-button" onClick={this.saveLayouts}>
								Сохранить
							</button>
						</div>
						{Object.values(consts.DEVICE_TYPES).map((typeKey) => {
							return (
								<Accordion defaultExpanded>
									<AccordionSummary
										sx={{
											height: 5,
											maxHeight: 10,
										}}
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`panel-content-${typeKey}`}
										id={`panel-header--${typeKey}`}
										className="my-0"
									>
										<Typography className="font-montserrat text-sm py-0 my-0">{consts.DEVICE_INFO[typeKey].plural_name}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<WidgetsGrid
											key={`widgets-${typeKey}`}
											devices={this.state.devices}
											type={typeKey}
											headerLabel={consts.DEVICE_INFO[typeKey].plural_name}
											layouts={this.state.layouts}
											layoutChanged={this.layoutChanged}
											isDraggable={true}
											classShowCompareComponent={this.props.classShowCompareComponent}
										/>
									</AccordionDetails>
								</Accordion>
							)
						})}
					</div>
				</section>
			</>
		);

	}
}

export default MonitoringPanelSettings;


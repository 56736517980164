import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function LayersMenu(props) {

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.handleClose}
        MenuListProps={{
          'aria-labelledby': 'change-layer-button',
        }}
      >
        <MenuItem onClick={() => props.changeLayer('osm')}>OSM</MenuItem>
        <MenuItem onClick={() => props.changeLayer('sats')}>Cпутник</MenuItem>
      </Menu>
    </div>
  );
}

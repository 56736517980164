import { Snackbar } from '@mui/material';
import { useState } from 'react';
import Device from '../../../model/device';
import AddUpdateForm from '../devices/addupdate';

export interface EditDeviceFormProps {
    device: Device;
}

const EditDeviceForm = ({ device }: EditDeviceFormProps) => {

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string) => {
            setActionMessage(message);
            setActionNotificationOpen(true);
    };



    const handleClose = () => {
        notify('Сохранение прошло успешно!')
    }


    return (
        <>
            <AddUpdateForm 
                device={device} 
                hideCloseButton={true}
                notReload={true} 
                handleClose={handleClose}
            />
            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />
            
        </>
    );
}

export default EditDeviceForm;
import { Grid } from "@mui/material";
import moment from 'moment';
import Device from "../../../model/device";
import SeismicEvent from "../../../model/seismic.event";
import SeismicEventPoint from "../../../model/seismic.event.point";
import SeismicEventCenterMap from "./SeismicEventCenterMap";
import CenterInfo from "../../../model/center.info";

export interface SeismicEventCenterProps {
    seismicEvent: SeismicEvent;
}


export default function SeismicEventCenter({ seismicEvent }: SeismicEventCenterProps) {

    const centers: CenterInfo[] = []
    seismicEvent.stations.map((station: Device) => {
        console.log('st:', station);
        if (seismicEvent.points[station.device_id]) {
            seismicEvent.points[station.device_id].map((point: SeismicEventPoint) => {
                const timeP = moment.unix(point.timestamp_point_p / 1000)
                const timeS = moment.unix(point.timestamp_point_s / 1000)

                const diffSeconds = timeS.diff(timeP, 'seconds')
                const R = diffSeconds * 8; // km
                const info : CenterInfo = new CenterInfo()
                info.radius = R * 1000 // meters
                info.latitude = station.latitude
                info.longitude = station.longitude
                centers.push(info)
            })
        }
    })

    return (
        <div className="mb-5 mt-5">
            <hr />
            <p className='text-2xl mb-5'>Расчет эпицентра происхождения сейсмособытия</p>

            <Grid container>
                <Grid item xs={3}>

                    {seismicEvent.stations.map((station: Device) => {
                        return <div key={`seismic-device-center-${station.device_id}`}>
                            {station.serial_number}

                            {seismicEvent.points[station.device_id] && seismicEvent.points[station.device_id].map((point: SeismicEventPoint) => {
                                const timeP = moment.unix(point.timestamp_point_p / 1000)
                                const timeS = moment.unix(point.timestamp_point_s / 1000)

                                const sTimeP = timeP.format('YYYY-MM-DD HH:mm:ss')
                                const sTimeS = timeS.format('YYYY-MM-DD HH:mm:ss')
                                const diffSeconds = timeS.diff(timeP, 'seconds')
                                const R = diffSeconds * 8;

                                return (
                                    <div key={`p-s-${station.device_id}`} className="mb-5">
                                        <p className="ml-3">
                                            P: {sTimeP} <br />
                                            S: {sTimeS} <br />
                                            P - S: {diffSeconds} сек. <br />
                                            Радиус окружности: ~ {diffSeconds} * 8 = {R} км
                                        </p>

                                    </div>
                                )
                            })}
                        </div>
                    })}

                </Grid>
                <Grid item xs={9}>
                    <SeismicEventCenterMap
                        key={`center-map-${centers.length}`}
                        seismicEvent={seismicEvent}
                        centers={centers}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Component } from 'react';
import { FiExternalLink } from "react-icons/fi";
import ImageGallery from 'react-image-gallery';
import { Grid, Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';

class ScienceExamples extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentImage: '/landing/block05/lidar.jpg',
		}


		this.links = [
			{
				text: 'LiDAR и аэрофотосъемка древостоев',
				pub: 'http://ncr-journal.bear-land.org/article/399',
			},
			{
				text: 'Цифровое сканирование фенологии растений',
				pub: 'https://journals.narfu.ru/index.php/fj/article/view/639/761',
			},
			{
				text: 'Георадарный поиск корней деревьев',
				pub: 'http://journals.tsu.ru/biology/&journal_page=archive&id=2003&article_id=45199',
			},
			{
				text: 'Анализ лесовосстановления после пожара',
				pub: 'https://xn--80abmehbaibgnewcmzjeef0c.xn--p1ai/articles/kabonen-a-v-gavrilova-o-i-gryazkin-a-v-pak-k-a-otsenka-estestvennogo-vozobnovleniya-lesa-na-gari-s-i/',
			},
			{
				text: '3D-моделирование архитектоники деревьев',
				pub: 'https://les-vest.msfu.ru/contents/2022/1/pdf/50-57.pdf',
			},
			{
				text: 'Мониторинг и 3D-реконструкция зданий',
			},
		]
		const files = ['lidar.jpg', 'roots.jpg', 'gis.jpg', 'after_fair.jpg', '3d_trees.jpg', '3d_buildings.jpg']
		this.images = [];
		files.forEach((image) => {
			this.images.push({
				original: `/landing/block05/${image}`,
				thumbnail: `/landing/block05/${image}`,
			});
		});
	}

	setCurrentImage = (image) => {
		const imageName = `/landing/block05/${image}`;
		this.setState({ currentImage: imageName })
	}

	render() {
		const center = isMobile ? 'justify-center items-center flex' : '';

		return (
			<Grid container spacing={2}>
				<Grid item xs={12} md={6} className="w-full flex items-center">
					<Stack>
					<div className="f-list">
						<ul>
							{this.links.map((item, i) => {
								return (
									<li key={i}>
										{item.pub &&
											<span className="f-text-link"
												onClick={() => window.open(item.pub, '_blank')}
											>
												{item.text}
											</span>
										}
										{!item.pub &&
											<>
												{item.text}
											</>
										}
									</li>
								)
							})}
						</ul>
					</div>

					<div className={`f-button-div`}>
						<button className="f-button" onClick={this.props.showRequestForm}>Сотрудничество</button>
					</div>
					</Stack>
				</Grid>
				<Grid item xs={12} md={6}>
					<ImageGallery
						items={this.images} showPlayButton={false} showNav={true}
						showBullets={true} showThumbnails={false}
						showFullscreenButton={false} autoPlay={true}
					/>
				</Grid>
			</Grid>
		);
	}
}

export default ScienceExamples;


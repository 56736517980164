import ReactDOM from 'react-dom/client';
import App from './App';

import './index.scss';

import appTheme from './material-theme';
import { ThemeProvider } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={appTheme}>
        <App />
    </ThemeProvider>
);

import React, { Component } from 'react';
import axios from "axios";
import * as consts from '../../../consts';
import { Form, Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';


class AddResourceForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedFile: null,
			description: "",
			device_id: -1,
			isSaving: false
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.devices.length > 0) {
			this.setState({ device_id: nextProps.devices[0].id })
		}
	}

	saveResource = () => {
		this.setState({ isSaving: true })
		const formData = new FormData();
		formData.append("file", this.state.selectedFile);
		formData.append("description", this.state.description);
		formData.append("device_id", this.state.device_id);
		try {
			axios({
				method: "post",
				url: `${consts.BASE_API_URL}/upload_new_resources`,
				data: formData,
				headers: {
					"Content-Type": "multipart/form-data"
				},
			})
				.then((response) => {
					if (response.data.result === 'ok') {
						toast.success("Ресурс успешно добавлен!")
						this.props.handleClose()
						this.setState({ isSavging: false })
						setTimeout(() => {
							window.location.reload()
						}, 1000);
					}
					console.log(response)
				}).catch((error) => {
					if (error.response) {
						console.log(error.response)
						console.log(error.response.status)
						console.log(error.response.headers)
					}
				});
		} catch (error) {
			console.log(error)
		}
	}

	handleFileSelect = (event) => {
		this.setState({ selectedFile: event.target.files[0] })
	}

	handleChangeDescription = (event) => {
		this.setState({ description: event.target.value })
	}

	handleChangeCameraId = (event) => {
		this.setState({ device_id: event.target.value })
	}



	render() {
		return (
			<>
				<ToastContainer autoClose={2000} hideProgressBar={false} />
				<Modal show={this.props.show} onHide={this.props.handleClose} size="lg">
					<Modal.Header closeButton>
						<Modal.Title>Добавление нового мультимедиа файла</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group className="mb-3" controlId="name">
								<Form.Label>Устройство <span className="required-field">*</span>:</Form.Label>
								<Form.Select onChange={this.handleChangeCameraId}>
									{this.props.devices.map(function (device, i) {
										return <option value={device.device_id}>{device.name}</option>
									})}
								</Form.Select>
							</Form.Group>
							<Form.Group className="mb-3" controlId="name">
								<Form.Label>Описание:</Form.Label>
								<Form.Control type="input" name="description" onChange={this.handleChangeDescription} />
							</Form.Group>
							<Form.Group className="mb-3" controlId="name">
								<Form.Label>Выбрать файл <span className="required-field">*</span>:</Form.Label>
								<Form.Control type="file" required name="file" onChange={this.handleFileSelect} />
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<button className="lr-secondary-button" onClick={this.props.handleClose}>
							Закрыть
						</button>
						<button className="lr-primary-button" onClick={this.saveResource}
							disabled={this.state.isSaving}

						>
							{this.state.isSaving ? 'Сохранение...' : 'Сохранить'}
						</button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}

export default AddResourceForm;





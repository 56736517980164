import { Component } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Tooltip as MuiTooltip } from '@mui/material';
import getBirdRecognitionData from '../../../../api/devices/get.bird.recognition.data';
import Slider from '@mui/material/Slider';

class BirdRecognitionStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            period: 'hour',
            loading: false,
            observations: [],
            confidence: 0.5
        }
        this.loadBirdRecognitionData();
    }

    async loadBirdRecognitionData() {
        this.setState({ loading: true })
        let observations = await getBirdRecognitionData(this.props.device_id, this.state.period, this.state.confidence)
        this.setState({ loading: false, observations })
    }

    changePeriod(event, newPeriod) {
        this.setState({ period: newPeriod }, () => {
            this.loadBirdRecognitionData()
        })
    };

    onConfidenceChanged(event, confidence) {
        if (typeof confidence === 'number') {
            console.log(confidence)
            this.setState({ confidence })
        }
    }

    render() {
        return (<div className="h-80">
            <p className='font-montserrat my-1 font-semibold'>Статистика распознаваний (топ-20)</p>
            <MuiTooltip title="Минимальная вероятность выборки">

                <Slider
                    size="small"
                    defaultValue={0.5}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    step={0.01}
                    min={0}
                    max={1}
                    onChange={this.onConfidenceChanged.bind(this)}
                />
            </MuiTooltip>
            <ToggleButtonGroup
                color="primary"
                value={this.state.period}
                exclusive
                onChange={this.changePeriod.bind(this)}
                aria-label="Platform"
                size="small"
                className="py-3 w-full justify-center items-center flex"
            >
                <ToggleButton value="hour" key="toggle-button-hour">
                    <MuiTooltip title="Статистика распознаваний за последний час">
                        <p>час</p>
                    </MuiTooltip>
                </ToggleButton>

                <ToggleButton value="day" key="toggle-button-day">
                    <MuiTooltip title="Статистика распознаваний за последние 24 часа">
                        <p>сутки</p>
                    </MuiTooltip>
                </ToggleButton>
                <ToggleButton value="week" key="toggle-button-week">
                    <MuiTooltip title="Статистика распознаваний за последние 7 дней">
                        <p>неделя</p>
                    </MuiTooltip>
                </ToggleButton>
                <ToggleButton value="month" key="toggle-button-month">
                    <MuiTooltip title="Статистика распознаваний за последние 30 дней">
                        <p>месяц</p>
                    </MuiTooltip>
                </ToggleButton>
                <ToggleButton value="year" key="toggle-button-year">
                    <MuiTooltip title="Статистика распознаваний за последний год">
                        <p>год</p>
                    </MuiTooltip>
                </ToggleButton>
            </ToggleButtonGroup>
            {this.state.loading &&
                <div className="mt-5 flex justify-center items-center">
                    <CircularProgress />
                </div>
            }
            {!this.state.loading &&
                <>
                    {this.state.observations.length > 0 &&
                        <Grid container>
                            {this.state.observations.slice(0, 30).map((obs) => {
                                return (
                                    <Grid item xs={6} key={`grid-${obs.label}-${obs.amount}`}>
                                        <MuiTooltip title={obs.common_name}>
                                            <p>{obs.label}: {obs.amount}</p>
                                        </MuiTooltip>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    }
                    {this.state.observations.length == 0 &&
                        <>За выбранный период распознанных объектов не добавлено.</>
                    }
                </>
            }
        </div>)
    }
}

export default BirdRecognitionStatistics;

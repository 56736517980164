import { Component } from 'react';
import WindRose from '../windrose';
import RainBarChart from '../rain_bar_chart';
import LastDataPanel from './last_data_panel';

class MeteoSokolDevice extends Component {
	
	render() {
	
		return (
			<div>
				<LastDataPanel 
					deviceParameters={this.props.deviceParameters}
					parameters={this.props.parameters}
					data={this.props.data}
				/>
				<hr className='mt-3' />
				<div>
					<RainBarChart  key={`rain-bar-chart-${this.props.device_id}`}  device_id={this.props.device_id} />
					<hr className='mt-3' />
				</div>
				<div>
					<WindRose key={`wind-rose-${this.props.device_id}`} device_id={this.props.device_id} />
					<hr className='mt-3' />
				</div>
			</div>
		);
	}
}

export default MeteoSokolDevice;

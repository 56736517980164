import Nav from '../nav';
import RForm from './form';

function RequestForm() {
  return (
    <div>
	<Nav />
	<section>
	    <div className="container px-5">
		<h1>Запрос регистрации</h1>
		<RForm />
	    </div>
	</section>
    </div>
  );
}

export default RequestForm;



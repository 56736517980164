import Nav from '../components/nav'


export default function Agreement() {
    return (
        <>
            <Nav />
            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">

                    <div className="text-3xl">
                        Пользовательское соглашение
                    </div>


                    <p>Использование веб-сайта smartecosystems.petrsu.ru и доступ к нему определяется настоящими Правилами.</p>

                    <p className="mt-4"><strong>1. Общие положения</strong><br />
                        Веб-сайт smartecosystems.petrsu.ru является информационным ресурсом, предоставляемым ООО «Умные Экосистемы». Заходя на сайт, 
                        пользователь принимает настоящие Правила. ООО «Умные Экосистемы» оставляет за собой право изменять настоящие Правила и 
                        обязуется отражать любые изменения в настоящем документе. Пользователи самостоятельно отслеживают данные изменения и принимают 
                        решение о возможности дальнейшего использования сайта.</p>

                    <p className="mt-4"><strong>2. Использование материалов сайта</strong><br />
                        Информация, указанная на данном сайте представляется как публичная информация (если иное не указано в материалах) и может 
                        свободно распространяться, или копироваться для некоммерческого использования в информационных, научных, учебных или 
                        культурных целях.</p>

                    <p className="mt-2">Любое коммерческое использование материалов без письменного согласия правообладателей запрещено.</p>

                    <p className="mt-2">Использование любого материала, размещенного на данном сайте, цитирование в СМИ возможно только со ссылкой 
                    на источник с указанием интернет-страницы, с которой данные материалы были взяты.</p>

                    <p className="mt-2">Все содержание сайта smartecosystems.petrsu.ru охраняется авторским правом как произведение, созданное 
                    коллективным творческим трудом в соответствии с законодательством Российской Федерации.</p>

                    
                    <p  className="mt-4"><strong>3. Использование сайта</strong><br />
                        Использование сайта должно осуществляться исключительно в рамках действующего законодательства Российской Федерации и не 
                        должно нарушать или ограничивать права третьих лиц. Запрещаются любые действия, связанные с передачей материалов непристойного 
                        содержания или ведущие к сбою функционирования сайта.</p>

                    <p className="mt-2">Информация, имена, изображения, логотипы и иные элементы, имеющие отношение к ООО «Умные Экосистемы», ее продукции и услугам 
                        (или к продукции и услугам третьей стороны) предоставляются и доступны так, как вы их видите.</p>

                    <p className="mt-2">ООО «Умные Экосистемы» и/или редакция сайта smartecosystems.petrsu.ru не несут ответственности за любые прямые и косвенные 
                        убытки, связанные с использованием, утратой возможности эксплуатации, утратой данных, неполучением прибыли вследствие судебного 
                        иска, халатности или иных обстоятельств, имеющих отношение или возникающих из использования сайта.<br />
                        Имена, названия, изображения, логотипы и товарные знаки, идентифицирующие ООО «Умные Экосистемы», сайт 
                        smartecosystems.petrsu.ru или третьих лиц и их продукцию или услуги, являются собственностью ООО «Умные Экосистемы» 
                        и/или третьих лиц. Использование этих элементов на сайте не может считаться предоставлением лицензии или права в отношении 
                        любого товарного знака или патента или любой другой интеллектуальной собственности, принадлежащей ООО «Умные Экосистемы» 
                        или третьей стороне.</p>

                    <p className="mt-4"><strong>4. Использование персональных данных</strong></p>

                    <p>В рамках использования посетителем сайта могут быть получены:</p>

                    <ul className="list-disc ml-10">
                        <li>данные в автоматическом режиме (информацию об используемом устройстве, IP-адрес, данные, сохраненные в файлах cookies, 
                            user-agent, дата и время доступа к сайту, адреса запрашиваемых страниц)</li>
                        <li>данные, предоставленные пользователем самостоятельно (фамилия, имя, отчество, номер телефона, электронная почта, адрес, 
                            местоположение, фотография, дата рождения, ссылки на персональный сайт или социальную сеть)</li>
                    </ul>

                    <p className="mt-2">Персональные данные посетителя могут быть использованы в следующих целях:</p>

                    <ul className="list-disc ml-10">
                        <li>Идентификация в рамках обращений со стороны посетителя. Хранение и обработка запросов и переданной информации. 
                            Осуществление обрабной связи с пользователями в рамках их обращений и запросов.</li>
                        <li>Предоставление посетителю персонализированных сервисов, услуг, предложений</li>
                        <li>Использование в системах статистики для мониторинга, анализа и улучшение работы сайта</li>
                        <li>Проведение статистических и иных исследований</li>
                        <li>Передача третьим лицам в рамках деятельности сайта, либо в случаях прямо предусмотренных законодательством.</li>
                    </ul>

                    <p>При обработке персональных данных пользователей ООО «Умные Экосистемы» руководствуется Федеральным законом от 27.07.2006 
                        N 152-ФЗ «О персональных данных» и иными нормативно-правовыми актами Российской Федерации.</p>

                    <p className="mt-4"><strong>5. Сайт предназначен для лиц старше 16 лет.</strong></p>

                    <p className="mt-4"><strong>6. Согласие с Правилами</strong><br />
                        Настоящие Правила вступают в силу в момент входа на сайт. Если вы не принимаете настоящие Правила в их целостности, 
                        просьба сразу же покинуть сайт.
                    </p>

                </div>
            </section >

        </>
    );
}
import { Component } from 'react';
import * as utils from '../../../utils';
import ReactAudioPlayer from 'react-audio-player';
import VideoStreamPlayer from '../../videostreamplayer';
import BirdRecognitionStatistics from './audio/bird_recognitions_chart';
import BirdLastRecognitionRows from './audio/bird_last_recognitions_rows';

const defaultState = {
	audio_resources: [],
}

class AudioDevice extends Component {
	constructor(props) {
		super(props);
		this.state = defaultState
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.data) {
			this.handleData(nextProps.data);
		}
	}

	handleData(data) {
		this.setState({ ...defaultState })

		if (data.resources && data.resources.length) {
			let audio_resources = [];
			data.resources.forEach((resource) => {
				if (resource.type === "audio") {
					audio_resources.push(resource)
				}
			})
			this.setState({ audio_resources })
		}

		if (data.info && data.info.stream_url) {
			this.setState({ stream_url: data.info.stream_url })
		}
	}


	render() {
		return (
			<div>
				{this.state.stream_url &&
					<>
						<p className="mb-2">Аудио поток реального времени:</p>
						<VideoStreamPlayer file={this.state.stream_url} />
						<BirdLastRecognitionRows device_id={this.props.device_id} />
					</>
				}
				<hr className='mt-3' />
				<BirdRecognitionStatistics device_id={this.props.device_id}/>
			</div>
		);
	}
}
export default AudioDevice;

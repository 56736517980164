import { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class OverlayTriggerWrapper extends Component {
	render() {
		if (!this.props.label) {
			return (
				<>
					{this.props.children}
				</>
			)
		}

		return (
			<OverlayTrigger
				overlay={
					<Tooltip style={{ zIndex: 999999999 }}>
						{this.props.label}
					</Tooltip>
				}
			>
				{this.props.children}
			</OverlayTrigger>
		);
	}
}
export default OverlayTriggerWrapper;

import {
	BsWifi, BsFillPeopleFill, BsSpeedometer
}
	from "react-icons/bs";
import { CgEditNoise } from "react-icons/cg";
import { MdHeight } from "react-icons/md";
import { AiFillCar } from "react-icons/ai";
import { BiBus } from "react-icons/bi";
import { FaMotorcycle } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { TbSum, TbArrowsDownUp, TbArrowsDoubleNeSw, TbArrowsLeftRight } from "react-icons/tb";
import { WIND_LABELS } from './consts';


const create_cell = (label, short_label, units, icon, color, chart = true) => {
	return {
		label, short_label, units, icon, color, chart
	}
}

const DATA_CELLS = {
	'solar_rad': create_cell('Солнечное излучение', 'Rd', 'вт/м2', 'sun.svg', '#B19A1C'),
	'uv_index': create_cell('Ультрафиолет', 'UV', '', 'sun.svg', '#AD3F64'),
	'solar_rad_high': create_cell('Максимальная солнечное излучение', 'MRd', 'вт/м2', 'sun.svg', '#B57172'),
	'uv_index_high': create_cell('Максимальный ультрафиолет', 'MUV', '', 'sun.svg', '#9255FA'),
	'rain_amount': create_cell('Осадки', 'R', 'мм', 'rain.svg', '#F1C921'),
	'rain_high_rate': create_cell('Скорость осадков', 'SR', 'мм/час', 'rain_speed.svg', '#387A5F'),
	'soil_moisture1': create_cell('Влажность 1', 'H1', 'cB', 'hum1.svg', '#1ED8A0'),
	'soil_moisture2': create_cell('Влажность 2', 'H2', 'cB', 'hum2.svg', '#35A81A'),
	'soil_moisture3': create_cell('Влажность 3', 'H3', 'cB', 'hum3.svg', '#0CCEEC'),
	'soil_moisture4': create_cell('Влажность 4', 'H4', 'cB', 'hum4.svg', '#8A93EF'),
	'soil_temp1': create_cell('Температура 1', 'T1', '°', 'temp.svg', '#E444BE'),
	'soil_temp2': create_cell('Температура 2', 'T2', '°', 'temp.svg', '#E4E460'),
	'soil_temp3': create_cell('Температура 3', 'T3', '°', 'temp.svg', '#41008A'),
	'soil_temp4': create_cell('Температура 4', 'T4', '°', 'temp.svg', '#5C2604'),
	'leaf_wetness1': create_cell('Влажность 1', 'H1', '', 'hum1.svg', '#FEF084'),
	'leaf_wetness2': create_cell('Влажность 2', 'H2', '', 'hum2.svg', '#A41E8C'),
	'leaf_temp1': create_cell('Температура 1', 'T1', '°', 'temp.svg', '#73CB34'),
	'leaf_temp2': create_cell('Температура 2', 'T2', '°', 'temp.svg', '#6B281D'),
	'msbar': create_cell('Давление', 'P', 'мм.рт.ст.', 'pressure.svg', '#630E54'),
	'mstemp': create_cell('Температура', 'T', '°', 'temp.svg', '#F6EF73'),
	'mshum': create_cell('Влажность', 'H', '%', 'hum.svg', '#2B2CD0'),
	'ms_windspeed': create_cell('Скорость ветра', 'WS', 'м/с', 'wind_speed.svg', '#E66D94'),
	'ms_winddir': create_cell('Направление ветра', 'WD', '', 'wind_direction.svg', 'undefined', false),
	'dew_point': create_cell('Точка росы', 'TP', '°', 'temp.svg', '#92577F'),
	'heat_index': create_cell('Тепловой индекс', 'HI', '°', 'temp.svg', '#D6288C'),
	'thsw_index': create_cell('Индекс THSW', 'THSW', '°', 'temp.svg', '#0133BC'),
	'low_wind_chill': create_cell('Охлаждения ветром', 'LWC', '°', 'temp.svg', '#7773CE'),
	'et_index': create_cell('Эвапотранспирация', 'ETI', 'мм', undefined, '#F34269'),
	'pressure': create_cell('Давление', 'P', 'мм.рт.ст.', 'pressure.svg', '#630E54'),
	'temp': create_cell('Температура воздуха', 'T', '°', 'temp.svg', '#F6EF73'),
	'hum': create_cell('Влажность', 'H', '%', 'hum.svg', '#2B2CD0'),
	'rad_avg2': create_cell('Радиация, среднее за 2 минуты', 'Rd2', 'мкР/ч', 'radiation.svg', '#160f67'),
	'rad_avg10': create_cell('Радиация, среднее за 10 минут', 'Rd10', 'мкР/ч', 'radiation.svg', '#e2221a'),
	'rad_amound_impulse': create_cell('Радиация, количество импульсов', 'AI', '', 'radiation.svg', '#997fca'),
	'pm1': create_cell('Пыль ppm1.0', 'PM1', 'ppm', CgEditNoise, '#5d4108'),
	'pm10': create_cell('Пыль ppm10', 'PM10', 'ppm', CgEditNoise, '#ef10d2'),
	'pm25': create_cell('Пыль ppm2.5', 'PM25', 'ppm', CgEditNoise, '#520727'),
	'db_max': create_cell('Шум: максимальное значение за 15 минут', 'Vmax', 'дБ', 'noise_max.svg', '#379c17'),
	'db_min': create_cell('Шум: минимальное значение за 15 минут', 'Vmin', 'дБ', 'noise_min.svg', '#49e2e8'),
	'db_avg': create_cell('Шум: cреднее за 15 минут', 'Vavg', 'дБ', 'noise_avg.svg', '#d64cdb'),
	'air_pressure': create_cell('Давление', 'P', 'мм.рт.ст.', 'pressure.svg', '#05ce92'),
	'air_temp': create_cell('Температура воздуха', 'T', '°', 'temp.svg', '#7cc13d'),
	'air_hum': create_cell('Влажность', 'H', '%', 'hum.svg', '#4381a0'),
	'vs_temp': create_cell('Температура по видимому спектру', 'Tvs', '°', 'temp.svg', '#415df3'),
	'is_temp': create_cell('Температура по ИК спектру', 'Tis', '°', 'temp.svg', '#bd54a7'),
	'vin': create_cell('Входящее напряжение', 'Vin', 'мВ', 'batt_mid_min.svg', '#804119'),
	'uv_sens': create_cell('UV sens', 'UV', 'мкВт', 'batt_mid_min.svg', '#0fc41e'),
	'lora_status': create_cell('Lora Статус', 'St', '', BsWifi, '#5353cf', false),
	'windspeed': create_cell('Скорость ветра текущая', 'WSc', 'м/с', 'wind_speed.svg', '#97fa78'),
	'winddir': create_cell('Направление ветра текущее', 'WDc', '°', 'wind_direction.svg', '#290883', false),
	'windspeed_avg_10min': create_cell('Скорость ветра средняя за 10 минут', 'Ws10', 'м/с', 'wind_speed.svg', '#1b1e40'),
	'winddir_avg_10min': create_cell('Направление ветра среднее за 10 минут', 'WD10', '°', 'wind_direction.svg', '#0503cf', false),
	'windspeed_max_3h': create_cell('Скорость ветра максимальная за 3 часа', 'Ws3h', 'м/с', 'wind_speed.svg', '#46319e'),
	'winddir_max_3h': create_cell('Направление ветра максимальное за 3 часа', 'WD3h', '°', 'wind_direction.svg', '#380a40', false),
	'soil_temp': create_cell('Температура почвы 1', 'Ts', '°', 'temp.svg', '#391985'),
	'soil_temp_2': create_cell('Температура почвы 2', 'Ts', '°', 'temp.svg', '#879549'),
	'soil_temp_3': create_cell('Температура почвы 3', 'Ts', '°', 'temp.svg', '#6d32cd'),
	'soil_hum': create_cell('Влажность почвы', 'Hs', '', 'hum.svg', '#ea52df'),
	'voltage': create_cell('Напряжение АКБ', 'V', 'мВ', 'batt_mid_min.svg', '#79abee'),
	'voltage_2': create_cell('Напряжение АКБ 2', 'V', 'мВ', 'batt_mid_min.svg', '#51441b'),
	'height': create_cell('Высота', 'H', 'мм', MdHeight, '#9b1cca'),
	'soil7_temp': create_cell('Температура почвы', 'Ts', '°', 'temp.svg', '#391985'),
	'soil7_hum': create_cell('Влажность почвы', 'Hs', '%', 'hum.svg', '#ea52df'),
	'soil7_con': create_cell('Проводимость', 'Cs', 'мкСм/см', CgEditNoise, '#390825'),
	'soil7_ph': create_cell('PH почвы', 'Phs', '', CgEditNoise, '#076401'),
	'soil7_nit': create_cell('Азот', 'Nits', 'mg/kg', CgEditNoise, '#736e31'),
	'soil7_phos': create_cell('Фосфор', 'Fs', 'mg/kg', CgEditNoise, '#7dbb2a'),
	'soil7_pot': create_cell('Калий', 'K', 'mg/kg', CgEditNoise, '#e413c3'),

	'no2_avg': create_cell('Диоксид азота, среднее', 'NO2avg', 'ppm', CgEditNoise, '#a14cb6'),
	'no2_min': create_cell('Диоксид азота, минимальное', 'NO2min', 'ppm', CgEditNoise, '#58eac4'),
	'no2_max': create_cell('Диоксид азота, максимальное', 'NO2max', 'ppm', CgEditNoise, '#9417d7'),
	'nh3_avg': create_cell('Аммиак, среднее', 'NH3avg', 'ppm', CgEditNoise, '#9f8ef7'),
	'nh3_min': create_cell('Аммиак, минимальное', 'NH3min', 'ppm', CgEditNoise, '#fa821d'),
	'nh3_max': create_cell('Аммиак, максимальное', 'NH3max', 'ppm', CgEditNoise, '#7ad36b'),
	'co_avg': create_cell('Угарный газ, среднее', 'COavg', 'ppm', CgEditNoise, '#3f47ca'),
	'co_min': create_cell('Угарный газ, минимальное', 'COmin', 'ppm', CgEditNoise, '#78669b'),
	'co_max': create_cell('Угарный газ, максимальное', 'COmax', 'ppm', CgEditNoise, '#3643bd'),

	'car_objects': create_cell('Легковые автомобили', 'Cars', '', AiFillCar, '#f6d08e'),
	'bus_objects': create_cell('Автобусы', 'Bus', '', BiBus, '#a31a89'),
	'motorcycle_objects': create_cell('Мотоциклы', 'Mcyc', '', FaMotorcycle, '#723f72'),
	'truck_objects': create_cell('Грузовики', 'Tr', '', FiTruck, '#e70c0c'),
	'people_objects': create_cell('Люди', 'P', '', BsFillPeopleFill, '#2a4794'),
	'all_objects': create_cell('Всего объектов', 'All', '', TbSum, '#ebbc54'),

	'pollution_co2': create_cell('Угарный газ', 'CO2', 'мг/м3', CgEditNoise, '#164212'),
	'pollution_ch4': create_cell('Метан', 'CH4', 'мг/м3', CgEditNoise, '#0c0b3f'),
	'pollution_n2o': create_cell('Диоксид азота', 'N2O', 'мг/м3', CgEditNoise, '#8698d5'),

	'avg_speed': create_cell('Ср. скорость', 'S', 'км/ч', BsSpeedometer, '#eb6bc7'),
	'value_n': create_cell('Направление N', 'N', '', TbArrowsDownUp, '#99db54'),
	'value_e': create_cell('Направление E', 'E', '', TbArrowsLeftRight, '#14c9fe'),
	'value_z': create_cell('Направление Z', 'Z', '', TbArrowsDoubleNeSw, '#0d4e8e'),

	't': create_cell('Температура', 't', '°', 'temp.svg', '#dcb855'),
	'rn': create_cell('Осадки', 'rn', 'мм', 'rain.svg', '#c32722'),
	'pr': create_cell('Атмосферное давление', 'pr', 'мм.рт.ст', 'pressure.svg', '#7128e1'),
	'wd': create_cell('Направление ветра', 'wd', 'град.', 'wind_direction.svg', '#88aa6f', false),
	'hm': create_cell('Влажность', 'hm', '%', 'hum.svg', '#f66488'),
	'wv': create_cell('Скорость ветра', 'wv', 'м/с', 'wind_speed.svg', '#4d1cfb'),
	'wv2': create_cell('Скорость ветра усредненная', 'wv2', 'м/с', 'wind_speed.svg', '#b02831'),
	'wm': create_cell('Порыв ветра', 'wm', 'м/с', 'wind_speed.svg', '#fa7b97'),

	'uv': create_cell('Уровень ультрафиолета', 'uv', 'Вт/м2', 'sun.svg', '#d15a80'),
	'pr1': create_cell('Атмосферное давление', 'pr1', 'мм.рт.ст', 'pressure.svg', '#3c7f47'),
	'ang2': create_cell('ang2', 'ang2', '', undefined, '#2c2110'),
	'ang1': create_cell('ang1', 'ang1', '', undefined, '#ff88f8'),
	'td': create_cell('Точка росы', 'td', '°', 'temp.svg', '#018528'),
	'evls': create_cell('evls', 'evls', '', TbArrowsDoubleNeSw, '#0d4e8e'),
	'ks': create_cell('ks', 'ks', '', TbArrowsDoubleNeSw, '#0d4e8e'),
	'uvi': create_cell('uvi', 'uvi', '', TbArrowsDoubleNeSw, '#0d4e8e'),
	'upow': create_cell('upow', 'upow', '', TbArrowsDoubleNeSw, '#0d4e8e'),
	'l': create_cell('l', 'l', '', TbArrowsDoubleNeSw, '#0d4e8e'),
	'rssi': create_cell('rssi', 'rssi', '', TbArrowsDoubleNeSw, '#0d4e8e'),
	'li': create_cell('li', 'li', '', TbArrowsDoubleNeSw, '#0d4e8e'),
	'tr': create_cell('tr', 'tr', '', TbArrowsDoubleNeSw, '#0d4e8e'),
	'temperature1': create_cell('Температура1', 'T1', '°', 'temp.svg', '#31c97e'),
	'temperature2': create_cell('Температура2', 'T2', '°', 'temp.svg', '#a6caf5'),
	
	'hm1': create_cell('Влажность1', 'H1', '%', 'hum.svg', '#9d621a'),
	'hm2': create_cell('Влажность2', 'H2', '%', 'hum.svg', '#55d4b2'),
	
	'rad1': create_cell('Радиация1', 'Rd1', 'мкР/ч', 'radiation.svg', '#2ce8fb'),
	'rad2': create_cell('Радиация2', 'Rd2', 'мкР/ч', 'radiation.svg', '#b5c1c8'),

	'trunk_temp': create_cell('Температура ствола', 'TT', '°', 'temp.svg', '#3f23c7'),
	'mlx90640_max': create_cell('Тепловизор. Максимальная температура', 'TTmax', '°', 'temp.svg', '#dca42a'),
	'mlx90640_min': create_cell('Тепловизор. Минимальная температура', 'TTmin', '°', 'temp.svg', '#988cee'),
	'mlx90640_avg': create_cell('Тепловизор. Средняя температура', 'TTavg', '°', 'temp.svg', '#70078e'),
	'hum_wood': create_cell('Влажность ствола', 'Hwood', '%', 'hum.svg', '#b1071e'),
	'thick_wood': create_cell('Прирост ствола', 'Thick', 'мм', MdHeight, '#b1071e'),
	
	'humidity': create_cell('Влажность', 'H', '%', 'hum.svg', '#2B2CD0'),
	'temperature': create_cell('Температура', 'T1', '°', 'temp.svg', '#31c97e'),
	'winddirection': create_cell('Направление ветра текущее', 'WDc', '', 'wind_direction.svg', '#290883', false),

	'mq_2': create_cell('Газ', 'MQ2', 'mV', CgEditNoise, '#34e9f5'),
	'mq_4': create_cell('Газ', 'MQ4', 'mV', CgEditNoise, '#ee524f'),
	'mq_9': create_cell('Газ', 'MQ9', 'mV', CgEditNoise, '#054025'),
	'mq_135': create_cell('Газ', 'MQ135', 'mV', CgEditNoise, '#cd605d'),

	'mq_136': create_cell('H2S (SO2) - сероводород (или оксид серы)', 'MQ136', 'mV', CgEditNoise, '#cd605d'),
	'mq_137': create_cell('NH3 - аммиак', 'NH3', 'mV', CgEditNoise, '#bbdf6a'),
	'misc_co': create_cell('NH3 - аммиак', 'NH3 (misc)', 'mV', CgEditNoise, '#71508a'),
	'misc_nh3': create_cell('CO - угарный газ', 'CO', 'mV', CgEditNoise, '#76f962'),
	'misc_no2': create_cell('NO2 - диокид азота', 'NO2', 'mV', CgEditNoise, '#d422c6'),

	'ccs811_co2': create_cell('CO2 в ppm', 'ccs811_co2', 'ppm', CgEditNoise, '#3142ab'),
	'ccs811_tvoc': create_cell('Органические примеси в ppm', 'ccs811_tvoc', 'ppm', CgEditNoise, '#bdbe90'),
	'ccs811_bsln': create_cell('BaseLine, безразмерная величина', 'ccs811_tvoc', 'ppm', CgEditNoise, '#899986'),

	'cnr1_t': create_cell('CNR1_T', 'cnr1_t', 'C', CgEditNoise, '#823B8A'),
	'swin': create_cell('SWin', 'swin', 'W+1m-2', CgEditNoise, '#7C6819'),
	'swout': create_cell('SWout', 'swout', 'W+1m-2', CgEditNoise, '#CEB17F'),
	'lwin_meas': create_cell('LWin_meas', 'lwin_meas', 'W+1m-2', CgEditNoise, '#7CC601'),
	'lwout_meas': create_cell('LWout_meas', 'lwout_meas', 'W+1m-2', CgEditNoise, '#0248AB'),
	'rn_lite_rid': create_cell('Rn_lite_rid', 'rn_lite_rid', 'W+1m-2', CgEditNoise, '#7D00C5'),
	'ppfdin_rid': create_cell('PPFDin_rid', 'ppfdin_rid', 'umol+1m-2s-1', CgEditNoise, '#F76D96'),
	'ppfdin_hol': create_cell('PPFDin_hol', 'ppfdin_hol', 'umol+1m-2s-1', CgEditNoise, '#BE6B8B'),
	'ppfdout_rid': create_cell('PPFDout_rid', 'ppfdout_rid', 'umol+1m-2s-1', CgEditNoise, '#7452CF'),
	'ppfdout_hol': create_cell('PPFDout_hol', 'ppfdout_hol', 'umol+1m-2s-1', CgEditNoise, '#67C432'),
	'ta_rid': create_cell('Ta_rid', 'ta_rid', 'C', CgEditNoise, '#8C1AFA'),
	'ta_hol': create_cell('Ta_hol', 'ta_hol', 'C', CgEditNoise, '#6F802E'),
	'rh_rid': create_cell('RH_rid', 'rh_rid', 'percent', CgEditNoise, '#DBBCD3'),
	'rh_hol': create_cell('RH_hol', 'rh_hol', 'percent', CgEditNoise, '#F5BEAC'),
	'pa': create_cell('Pa', 'pa', 'hPa', CgEditNoise, '#28B4CC'),
	'ws_10m': create_cell('WS_10m', 'ws_10m', 'm+1s-1', CgEditNoise, '#A21728'),
	'wd_10m': create_cell('WD_10m', 'wd_10m', 'azimuth', CgEditNoise, '#97DBFB'),
	'ws_2m': create_cell('WS_2m', 'ws_2m', 'm+1s-1', CgEditNoise, '#30B417'),
	'wd_2m': create_cell('WD_2m', 'wd_2m', 'azimuth', CgEditNoise, '#2A6318'),
	'p': create_cell('P', 'p', 'mm', CgEditNoise, '#B7A786'),
	'water_above_sensor': create_cell('Water_above_sensor', 'water_above_sensor', 'cm', CgEditNoise, '#872E93'),
	'wtd': create_cell('WTD', 'wtd', 'cm', CgEditNoise, '#4F743F'),
	'shf_ref_1': create_cell('SHF_ref_1', 'shf_ref_1', 'W+1m-2', CgEditNoise, '#07BB87'),
	'shf_ref_2': create_cell('SHF_ref_2', 'shf_ref_2', 'W+1m-2', CgEditNoise, '#049EB2'),
	'shf_ref_3': create_cell('SHF_ref_3', 'shf_ref_3', 'W+1m-2', CgEditNoise, '#7B060F'),
	'shf_cor_1': create_cell('SHF_cor_1', 'shf_cor_1', 'W+1m-2', CgEditNoise, '#9767A2'),
	'shf_cor_2': create_cell('SHF_cor_2', 'shf_cor_2', 'W+1m-2', CgEditNoise, '#3E30B5'),
	'shf_cor_3': create_cell('SHF_cor_3', 'shf_cor_3', 'W+1m-2', CgEditNoise, '#22650D'),
	'tsoil_av_1': create_cell('Tsoil_av_1', 'tsoil_av_1', 'C', CgEditNoise, '#74B555'),
	'tsoil_av_2': create_cell('Tsoil_av_2', 'tsoil_av_2', 'C', CgEditNoise, '#1D3E85'),
	'stp_1_02': create_cell('STP_1_02', 'stp_1_02', 'C', CgEditNoise, '#F60D20'),
	'stp_1_05': create_cell('STP_1_05', 'stp_1_05', 'C', CgEditNoise, '#A94A64'),
	'stp_1_10': create_cell('STP_1_10', 'stp_1_10', 'C', CgEditNoise, '#F79C07'),
	'stp_1_20': create_cell('STP_1_20', 'stp_1_20', 'C', CgEditNoise, '#5AF7C8'),
	'stp_1_50': create_cell('STP_1_50', 'stp_1_50', 'C', CgEditNoise, '#56F915'),
	'stp_2_02': create_cell('STP_2_02', 'stp_2_02', 'C', CgEditNoise, '#4E397B'),
	'stp_2_05': create_cell('STP_2_05', 'stp_2_05', 'C', CgEditNoise, '#A18AD9'),
	'stp_2_10': create_cell('STP_2_10', 'stp_2_10', 'C', CgEditNoise, '#9F88DE'),
	'stp_2_20': create_cell('STP_2_20', 'stp_2_20', 'C', CgEditNoise, '#2DDC31'),
	'stp_2_50': create_cell('STP_2_50', 'stp_2_50', 'C', CgEditNoise, '#844C72'),
	'stp_3_02': create_cell('STP_3_02', 'stp_3_02', 'C', CgEditNoise, '#5C52C4'),
	'stp_3_05': create_cell('STP_3_05', 'stp_3_05', 'C', CgEditNoise, '#1916A4'),
	'stp_3_10': create_cell('STP_3_10', 'stp_3_10', 'C', CgEditNoise, '#686EC4'),
	'stp_3_20': create_cell('STP_3_20', 'stp_3_20', 'C', CgEditNoise, '#569E1C'),
	'stp_3_50': create_cell('STP_3_50', 'stp_3_50', 'C', CgEditNoise, '#CA73B5'),
	'stp_4_02': create_cell('STP_4_02', 'stp_4_02', 'C', CgEditNoise, '#C13F0F'),
	'stp_4_05': create_cell('STP_4_05', 'stp_4_05', 'C', CgEditNoise, '#4925C5'),
	'stp_4_10': create_cell('STP_4_10', 'stp_4_10', 'C', CgEditNoise, '#554D63'),
	'stp_4_20': create_cell('STP_4_20', 'stp_4_20', 'C', CgEditNoise, '#FE48C7'),
	'stp_4_50': create_cell('STP_4_50', 'stp_4_50', 'C', CgEditNoise, '#3302A8'),
	'hfp_heat_mv_1': create_cell('HFP_heat_mv_1', 'hfp_heat_mv_1', 'mV', CgEditNoise, '#08B099'),
	'hfp_heat_mv_2': create_cell('HFP_heat_mv_2', 'hfp_heat_mv_2', 'mV', CgEditNoise, '#5FEC26'),
	'hfp_heat_mv_3': create_cell('HFP_heat_mv_3', 'hfp_heat_mv_3', 'mV', CgEditNoise, '#3F6250'),
	'stp_heat_mv_1': create_cell('STP_heat_mv_1', 'stp_heat_mv_1', 'mV', CgEditNoise, '#9CACD2'),
	'stp_heat_mv_2': create_cell('STP_heat_mv_2', 'stp_heat_mv_2', 'mV', CgEditNoise, '#3EF9EA'),
	'stp_heat_mv_3': create_cell('STP_heat_mv_3', 'stp_heat_mv_3', 'mV', CgEditNoise, '#AB8C5B'),
	'stp_heat_mv_4': create_cell('STP_heat_mv_4', 'stp_heat_mv_4', 'mV', CgEditNoise, '#AD5326'),
	'stp_heat_q_1': create_cell('STP_heat_Q_1', 'stp_heat_q_1', 'W+1m-1', CgEditNoise, '#A55822'),
	'stp_heat_q_2': create_cell('STP_heat_Q_2', 'stp_heat_q_2', 'W+1m-1', CgEditNoise, '#9C2F5F'),
	'stp_heat_q_3': create_cell('STP_heat_Q_3', 'stp_heat_q_3', 'W+1m-1', CgEditNoise, '#D3AB9E'),
	'stp_heat_q_4': create_cell('STP_heat_Q_4', 'stp_heat_q_4', 'W+1m-1', CgEditNoise, '#A49DD3'),
	'hfp_heat_on': create_cell('HFP_heat_on', 'hfp_heat_on', '#', CgEditNoise, '#4D3441'),
	'stp_heat_on': create_cell('STP_heat_on', 'stp_heat_on', '#', CgEditNoise, '#62FBA9'),
	'cnr_heat_on': create_cell('CNR_heat_ON', 'cnr_heat_on', '#', CgEditNoise, '#BFC665'),
	'batt_volt': create_cell('Batt_volt', 'batt_volt', 'V', CgEditNoise, '#48B252'),
	'batt_v24': create_cell('Batt_v24', 'batt_v24', 'V', CgEditNoise, '#30E58C'),
	'ptemp': create_cell('PTemp', 'ptemp', 'C', CgEditNoise, '#32A66F'),
	'lwin_meas': create_cell('LWin_meas', 'lwin_meas', 'W+1m-2', CgEditNoise, '#918E80'),
	'lwout_meas': create_cell('LWout_meas', 'lwout_meas', 'W+1m-2', CgEditNoise, '#D409B5'),
	'rn': create_cell('Rn', 'rn', 'W+1m-2', CgEditNoise, '#B0833A'),
	'alb': create_cell('Alb', 'alb', '#', CgEditNoise, '#176EC9'),


	'doy': create_cell('DOY', 'doy', 'ddd.ddd', CgEditNoise, '#7B7108'),
	'daytime': create_cell('daytime', 'daytime', '1=daytime', CgEditNoise, '#A7A21A'),
	'file_records': create_cell('file_records', 'file_records', '#', CgEditNoise, '#0F8990'),
	'used_records': create_cell('used_records', 'used_records', '#', CgEditNoise, '#8621B1'),
	'tau': create_cell('Tau', 'tau', 'kg+1m-1s-2', CgEditNoise, '#B13315'),
	'qc_tau': create_cell('qc_Tau', 'qc_tau', '#', CgEditNoise, '#95308B'),
	'rand_err_tau': create_cell('rand_err_Tau', 'rand_err_tau', 'kg+1m-1s-2', CgEditNoise, '#5CB6B5'),
	'h': create_cell('H', 'h', 'W+1m-2', CgEditNoise, '#207431'),
	'qc_h': create_cell('qc_H', 'qc_h', '#', CgEditNoise, '#93C733'),
	'rand_err_h': create_cell('rand_err_H', 'rand_err_h', 'W+1m-2', CgEditNoise, '#2F866C'),
	'le': create_cell('LE', 'le', 'W+1m-2', CgEditNoise, '#EE5B5E'),
	'qc_le': create_cell('qc_LE', 'qc_le', '#', CgEditNoise, '#781AAC'),
	'rand_err_le': create_cell('rand_err_LE', 'rand_err_le', 'W+1m-2', CgEditNoise, '#37C9C6'),
	'co2_flux': create_cell('co2_flux1', 'co2_flux', 'µmol+1s-1m-22', CgEditNoise, '#5F730B'),
	'qc_co2_flux': create_cell('qc_co2_flux', 'qc_co2_flux', '#', CgEditNoise, '#0987B9'),
	'rand_err_co2_flux': create_cell('rand_err_co2_flux', 'rand_err_co2_flux', 'µmol+1s-1m-2', CgEditNoise, '#3B6A59'),
	'h2o_flux': create_cell('h2o_flux', 'h2o_flux', 'mmol+1s-1m-2', CgEditNoise, '#0AAECE'),
	'qc_h2o_flux': create_cell('qc_h2o_flux', 'qc_h2o_flux', '#', CgEditNoise, '#6D1DF5'),
	'rand_err_h2o_flux': create_cell('rand_err_h2o_flux', 'rand_err_h2o_flux', 'mmol+1s-1m-2', CgEditNoise, '#543B58'),
	'ch4_flux': create_cell('ch4_flux', 'ch4_flux', 'µmol+1s-1m-2', CgEditNoise, '#9A1588'),
	'qc_ch4_flux': create_cell('qc_ch4_flux', 'qc_ch4_flux', '#', CgEditNoise, '#3468FB'),
	'rand_err_ch4_flux': create_cell('rand_err_ch4_flux', 'rand_err_ch4_flux', 'µmol+1s-1m-2', CgEditNoise, '#A6C1B0'),
	'none_flux': create_cell('none_flux', 'none_flux', 'µmol+1s-1m-2', CgEditNoise, '#45F759'),
	'qc_none_flux': create_cell('qc_none_flux', 'qc_none_flux', '#', CgEditNoise, '#23BA82'),
	'rand_err_none_flux': create_cell('rand_err_none_flux', 'rand_err_none_flux', 'µmol+1s-1m-2', CgEditNoise, '#85AD89'),
	'h_strg': create_cell('H_strg', 'h_strg', 'W+1m-2', CgEditNoise, '#E7BB6A'),
	'le_strg': create_cell('LE_strg', 'le_strg', 'W+1m-2', CgEditNoise, '#887C7E'),
	'co2_strg': create_cell('co2_strg', 'co2_strg', 'µmol+1s-1m-2', CgEditNoise, '#599AC6'),
	'h2o_strg': create_cell('h2o_strg', 'h2o_strg', 'mmol+1s-1m-2', CgEditNoise, '#5A6EA1'),
	'ch4_strg': create_cell('ch4_strg', 'ch4_strg', 'µmol+1s-1m-2', CgEditNoise, '#1D9F5B'),
	'none_strg': create_cell('none_strg', 'none_strg', 'µmol+1s-1m-2', CgEditNoise, '#945147'),
	'co2_v_adv': create_cell('co2_v-adv', 'co2_v_adv', 'µmol+1s-1m-2', CgEditNoise, '#BD38B5'),
	'h2o_v_adv': create_cell('h2o_v-adv', 'h2o_v_adv', 'mmol+1s-1m-2', CgEditNoise, '#947080'),
	'ch4_v_adv': create_cell('ch4_v-adv', 'ch4_v_adv', 'µmol+1s-1m-2', CgEditNoise, '#201ADD'),
	'none_v_adv': create_cell('none_v-adv', 'none_v_adv', 'µmol+1s-1m-2', CgEditNoise, '#0F74BC'),
	'co2_molar_density': create_cell('co2_molar_density', 'co2_molar_density', 'mmol+1m-3', CgEditNoise, '#2954F4'),
	'co2_mole_fraction': create_cell('co2_mole_fraction', 'co2_mole_fraction', 'µmol+1mol_a-1', CgEditNoise, '#881521'),
	'co2_mixing_ratio': create_cell('co2_mixing_ratio', 'co2_mixing_ratio', 'µmol+1mol_d-1', CgEditNoise, '#DD177A'),
	'co2_time_lag': create_cell('co2_time_lag', 'co2_time_lag', 's', CgEditNoise, '#F4E73E'),
	'co2_def_timelag': create_cell('co2_def_timelag', 'co2_def_timelag', '1=default', CgEditNoise, '#AE5FF1'),
	'h2o_molar_density': create_cell('h2o_molar_density', 'h2o_molar_density', 'mmol+1m-3', CgEditNoise, '#969FD8'),
	'h2o_mole_fraction': create_cell('h2o_mole_fraction', 'h2o_mole_fraction', 'mmol+1mol_a-1', CgEditNoise, '#38D43D'),
	'h2o_mixing_ratio': create_cell('h2o_mixing_ratio', 'h2o_mixing_ratio', 'mmol+1mol_d-1', CgEditNoise, '#5E6EAC'),
	'h2o_time_lag': create_cell('h2o_time_lag', 'h2o_time_lag', 's', CgEditNoise, '#BCEF1D'),
	'h2o_def_timelag': create_cell('h2o_def_timelag', 'h2o_def_timelag', '1=default', CgEditNoise, '#845109'),
	'ch4_molar_density': create_cell('ch4_molar_density', 'ch4_molar_density', 'mmol+1m-3', CgEditNoise, '#0D2C89'),
	'ch4_mole_fraction': create_cell('ch4_mole_fraction', 'ch4_mole_fraction', 'µmol+1mol_a-1', CgEditNoise, '#A50E78'),
	'ch4_mixing_ratio': create_cell('ch4_mixing_ratio', 'ch4_mixing_ratio', 'µmol+1mol_d-1', CgEditNoise, '#C68CAD'),
	'ch4_time_lag': create_cell('ch4_time_lag', 'ch4_time_lag', 's', CgEditNoise, '#EBD2F2'),
	'ch4_def_timelag': create_cell('ch4_def_timelag', 'ch4_def_timelag', '1=default', CgEditNoise, '#1D361B'),
	'none_molar_density': create_cell('none_molar_density', 'none_molar_density', 'mmol+1m-3', CgEditNoise, '#6376C2'),
	'none_mole_fraction': create_cell('none_mole_fraction', 'none_mole_fraction', 'µmol+1mol_a-1', CgEditNoise, '#01C773'),
	'none_mixing_ratio': create_cell('none_mixing_ratio', 'none_mixing_ratio', 'µmol+1mol_d-1', CgEditNoise, '#362439'),
	'none_time_lag': create_cell('none_time_lag', 'none_time_lag', 's', CgEditNoise, '#F6B358'),
	'none_def_timelag': create_cell('none_def_timelag', 'none_def_timelag', '1=default', CgEditNoise, '#E88E91'),
	'sonic_temperature': create_cell('sonic_temperature', 'sonic_temperature', 'K', CgEditNoise, '#B27871'),
	'air_temperature': create_cell('air_temperature', 'air_temperature', 'K', CgEditNoise, '#EE8D9C'),
	'air_pressure': create_cell('air_pressure', 'air_pressure', 'Pa', CgEditNoise, '#731C4C'),
	'air_density': create_cell('air_density', 'air_density', 'kg+1m-3', CgEditNoise, '#3334FD'),
	'air_heat_capacity': create_cell('air_heat_capacity', 'air_heat_capacity', 'J+1kg-1K-1', CgEditNoise, '#C4C02C'),
	'air_molar_volume': create_cell('air_molar_volume', 'air_molar_volume', 'm+3mol-1', CgEditNoise, '#071C33'),
	'et': create_cell('ET', 'et', 'mm+1hour-1', CgEditNoise, '#3E99C3'),
	'water_vapor_density': create_cell('water_vapor_density', 'water_vapor_density', 'kg+1m-3', CgEditNoise, '#4457C8'),
	'e': create_cell('e', 'e', 'Pa', CgEditNoise, '#3CA828'),
	'es': create_cell('es', 'es', 'Pa', CgEditNoise, '#27A57C'),
	'specific_humidity': create_cell('specific_humidity', 'specific_humidity', 'kg+1kg-1', CgEditNoise, '#186C55'),
	'rh': create_cell('RH', 'rh', '%', CgEditNoise, '#A2E358'),
	'vpd': create_cell('VPD', 'vpd', 'Pa', CgEditNoise, '#7D8C65'),
	'tdew': create_cell('Tdew', 'tdew', 'K', CgEditNoise, '#5E049D'),
	'u_unrot': create_cell('u_unrot', 'u_unrot', 'm+1s-1', CgEditNoise, '#722988'),
	'v_unrot': create_cell('v_unrot', 'v_unrot', 'm+1s-1', CgEditNoise, '#FA0416'),
	'w_unrot': create_cell('w_unrot', 'w_unrot', 'm+1s-1', CgEditNoise, '#AEC397'),
	'u_rot': create_cell('u_rot', 'u_rot', 'm+1s-1', CgEditNoise, '#033557'),
	'v_rot': create_cell('v_rot', 'v_rot', 'm+1s-1', CgEditNoise, '#10F00C'),
	'w_rot': create_cell('w_rot', 'w_rot', 'm+1s-1', CgEditNoise, '#486AE5'),
	'wind_speed': create_cell('wind_speed', 'wind_speed', 'm+1s-1', CgEditNoise, '#C2E4F8'),
	'max_wind_speed': create_cell('max_wind_speed', 'max_wind_speed', 'm+1s-1', CgEditNoise, '#E53A94'),
	'wind_dir': create_cell('wind_dir', 'wind_dir', 'deg_from_north', CgEditNoise, '#57509D'),
	'yaw': create_cell('yaw', 'yaw', 'deg', CgEditNoise, '#7F0DB5'),
	'pitch': create_cell('pitch', 'pitch', 'deg', CgEditNoise, '#98CE48'),
	'roll': create_cell('roll', 'roll', 'deg', CgEditNoise, '#A828C3'),
	'u__': create_cell('u*', 'u__', 'm+1s-1', CgEditNoise, '#412652'),
	'tke': create_cell('TKE', 'tke', 'm+2s-2', CgEditNoise, '#0A9752'),
	'l': create_cell('L', 'l', 'm', CgEditNoise, '#A46083'),
	'__z_d___l': create_cell('(z-d)/L', '__z_d___l', '#', CgEditNoise, '#E9A156'),
	'bowen_ratio': create_cell('bowen_ratio', 'bowen_ratio', '#', CgEditNoise, '#A71994'),
	't__': create_cell('T*', 't__', 'K', CgEditNoise, '#BE5E1B'),
	'model': create_cell('model', 'model', '0=KJ/1=KM/2=HS', CgEditNoise, '#F4FCB5'),
	'x_peak': create_cell('x_peak', 'x_peak', 'm', CgEditNoise, '#433CC5'),
	'x_offset': create_cell('x_offset', 'x_offset', 'm', CgEditNoise, '#F5F434'),
	'x_10': create_cell('x_10%', 'x_10', 'm', CgEditNoise, '#AA91D3'),
	'x_30': create_cell('x_30%', 'x_30', 'm', CgEditNoise, '#AA13DC'),
	'x_50': create_cell('x_50%', 'x_50', 'm', CgEditNoise, '#45FC4B'),
	'x_70': create_cell('x_70%', 'x_70', 'm', CgEditNoise, '#A422A2'),
	'x_90': create_cell('x_90%', 'x_90', 'm', CgEditNoise, '#B4DB73'),
	'un_tau': create_cell('un_Tau', 'un_tau', 'kg+1m-1s-2', CgEditNoise, '#09B194'),
	'tau_scf': create_cell('Tau_scf', 'tau_scf', '#', CgEditNoise, '#A249DE'),
	'un_h': create_cell('un_H', 'un_h', 'W+1m-2', CgEditNoise, '#39B6E3'),
	'h_scf': create_cell('H_scf', 'h_scf', '#', CgEditNoise, '#FFE42A'),
	'un_le': create_cell('un_LE', 'un_le', 'W+1m-2', CgEditNoise, '#A20880'),
	'le_scf': create_cell('LE_scf', 'le_scf', '#', CgEditNoise, '#26A68E'),
	'un_co2_flux': create_cell('un_co2_flux', 'un_co2_flux', 'µmol+1s-1m-2', CgEditNoise, '#5BB41C'),
	'co2_scf': create_cell('co2_scf', 'co2_scf', '#', CgEditNoise, '#C1E4B3'),
	'un_h2o_flux': create_cell('un_h2o_flux', 'un_h2o_flux', 'mmol+1s-1m-2', CgEditNoise, '#ED7E61'),
	'h2o_scf': create_cell('h2o_scf', 'h2o_scf', '#', CgEditNoise, '#7E68B1'),
	'un_ch4_flux': create_cell('un_ch4_flux', 'un_ch4_flux', 'µmol+1s-1m-2', CgEditNoise, '#041547'),
	'ch4_scf': create_cell('ch4_scf', 'ch4_scf', '#', CgEditNoise, '#947085'),
	'un_none_flux': create_cell('un_none_flux', 'un_none_flux', 'µmol+1s-1m-2', CgEditNoise, '#2AC765'),
	'un_none_scf': create_cell('un_none_scf', 'un_none_scf', '#', CgEditNoise, '#9C3F78'),
	'spikes_hf': create_cell('spikes_hf', 'spikes_hf', '8u/v/w/ts/co2/h2o/ch4/none', CgEditNoise, '#39123B'),
	'amplitude_resolution_hf': create_cell('amplitude_resolution_hf', 'amplitude_resolution_hf', '8u/v/w/ts/co2/h2o/ch4/none', CgEditNoise, '#50A562'),
	'drop_out_hf': create_cell('drop_out_hf', 'drop_out_hf', '8u/v/w/ts/co2/h2o/ch4/none', CgEditNoise, '#606C3A'),
	'absolute_limits_hf': create_cell('absolute_limits_hf', 'absolute_limits_hf', '8u/v/w/ts/co2/h2o/ch4/none', CgEditNoise, '#A8B17C'),
	'skewness_kurtosis_hf': create_cell('skewness_kurtosis_hf', 'skewness_kurtosis_hf', '8u/v/w/ts/co2/h2o/ch4/none', CgEditNoise, '#05EECE'),
	'skewness_kurtosis_sf': create_cell('skewness_kurtosis_sf', 'skewness_kurtosis_sf', '8u/v/w/ts/co2/h2o/ch4/none', CgEditNoise, '#1724B2'),
	'discontinuities_hf': create_cell('discontinuities_hf', 'discontinuities_hf', '8u/v/w/ts/co2/h2o/ch4/none', CgEditNoise, '#594023'),
	'discontinuities_sf': create_cell('discontinuities_sf', 'discontinuities_sf', '8u/v/w/ts/co2/h2o/ch4/none', CgEditNoise, '#0B9F6E'),
	'timelag_hf': create_cell('timelag_hf', 'timelag_hf', '8co2/h2o/ch4/none', CgEditNoise, '#16FBCA'),
	'timelag_sf': create_cell('timelag_sf', 'timelag_sf', '8co2/h2o/ch4/none', CgEditNoise, '#CDC1BD'),
	'attack_angle_hf': create_cell('attack_angle_hf', 'attack_angle_hf', '8aa', CgEditNoise, '#543B72'),
	'non_steady_wind_hf': create_cell('non_steady_wind_hf', 'non_steady_wind_hf', '8U', CgEditNoise, '#39A4B8'),
	'u_spikes': create_cell('u_spikes', 'u_spikes', '#', CgEditNoise, '#C556D0'),
	'v_spikes': create_cell('v_spikes', 'v_spikes', '#', CgEditNoise, '#F171F6'),
	'w_spikes': create_cell('w_spikes', 'w_spikes', '#', CgEditNoise, '#AACD6C'),
	'ts_spikes': create_cell('ts_spikes', 'ts_spikes', '#', CgEditNoise, '#203653'),
	'co2_spikes': create_cell('co2_spikes', 'co2_spikes', '#', CgEditNoise, '#536807'),
	'h2o_spikes': create_cell('h2o_spikes', 'h2o_spikes', '#', CgEditNoise, '#BEAFE5'),
	'ch4_spikes': create_cell('ch4_spikes', 'ch4_spikes', '#', CgEditNoise, '#0D6DFA'),
	'none_spikes': create_cell('none_spikes', 'none_spikes', '#', CgEditNoise, '#C82FC1'),
	'head_detect_li_7200': create_cell('head_detect_LI-7200', 'head_detect_li_7200', '#_flagged_recs', CgEditNoise, '#C553AA'),
	't_out_li_7200': create_cell('t_out_LI-7200', 't_out_li_7200', '#_flagged_recs', CgEditNoise, '#DBEC7E'),
	't_in_li_7200': create_cell('t_in_LI-7200', 't_in_li_7200', '#_flagged_recs', CgEditNoise, '#21DE19'),
	'aux_in_li_7200': create_cell('aux_in_LI-7200', 'aux_in_li_7200', '#_flagged_recs', CgEditNoise, '#008731'),
	'delta_p_li_7200': create_cell('delta_p_LI-7200', 'delta_p_li_7200', '#_flagged_recs', CgEditNoise, '#E3F1F5'),
	'chopper_li_7200': create_cell('chopper_LI-7200', 'chopper_li_7200', '#_flagged_recs', CgEditNoise, '#AF81C0'),
	'detector_li_7200': create_cell('detector_LI-7200', 'detector_li_7200', '#_flagged_recs', CgEditNoise, '#ED7EA1'),
	'pll_li_7200': create_cell('pll_LI-7200', 'pll_li_7200', '#_flagged_recs', CgEditNoise, '#245C4E'),
	'sync_li_7200': create_cell('sync_LI-7200', 'sync_li_7200', '#_flagged_recs', CgEditNoise, '#E4B934'),
	'chopper_li_7500': create_cell('chopper_LI-7500', 'chopper_li_7500', '#_flagged_recs', CgEditNoise, '#FFBAFB'),
	'detector_li_7500': create_cell('detector_LI-7500', 'detector_li_7500', '#_flagged_recs', CgEditNoise, '#F40B31'),
	'pll_li_7500': create_cell('pll_LI-7500', 'pll_li_7500', '#_flagged_recs', CgEditNoise, '#6E9FD0'),
	'sync_li_7500': create_cell('sync_LI-7500', 'sync_li_7500', '#_flagged_recs', CgEditNoise, '#9392D0'),
	'not_ready_li_7700': create_cell('not_ready_LI-7700', 'not_ready_li_7700', '#_flagged_recs', CgEditNoise, '#F3D422'),
	'no_signal_li_7700': create_cell('no_signal_LI-7700', 'no_signal_li_7700', '#_flagged_recs', CgEditNoise, '#D5BB9A'),
	're_unlocked_li_7700': create_cell('re_unlocked_LI-7700', 're_unlocked_li_7700', '#_flagged_recs', CgEditNoise, '#DED4C6'),
	'bad_temp_li_7700': create_cell('bad_temp_LI-7700', 'bad_temp_li_7700', '#_flagged_recs', CgEditNoise, '#6569CE'),
	'laser_temp_unregulated_li_7700': create_cell('laser_temp_unregulated_LI-7700', 'laser_temp_unregulated_li_7700', '#_flagged_recs', CgEditNoise, '#03FB69'),
	'block_temp_unregulated_li_7700': create_cell('block_temp_unregulated_LI-7700', 'block_temp_unregulated_li_7700', '#_flagged_recs', CgEditNoise, '#9305ED'),
	'motor_spinning_li_7700': create_cell('motor_spinning_LI-7700', 'motor_spinning_li_7700', '#_flagged_recs', CgEditNoise, '#193BCD'),
	'pump_on_li_7700': create_cell('pump_on_LI-7700', 'pump_on_li_7700', '#_flagged_recs', CgEditNoise, '#7E5107'),
	'top_heater_on_li_7700': create_cell('top_heater_on_LI-7700', 'top_heater_on_li_7700', '#_flagged_recs', CgEditNoise, '#FF8DE5'),
	'bottom_heater_on_li_7700': create_cell('bottom_heater_on_LI-7700', 'bottom_heater_on_li_7700', '#_flagged_recs', CgEditNoise, '#1BEA5F'),
	'calibrating_li_7700': create_cell('calibrating_LI-7700', 'calibrating_li_7700', '#_flagged_recs', CgEditNoise, '#0A7AAB'),
	'motor_failure_li_7700': create_cell('motor_failure_LI-7700', 'motor_failure_li_7700', '#_flagged_recs', CgEditNoise, '#CE3DAE'),
	'bad_aux_tc1_li_7700': create_cell('bad_aux_tc1_LI-7700', 'bad_aux_tc1_li_7700', '#_flagged_recs', CgEditNoise, '#0FF967'),
	'bad_aux_tc2_li_7700': create_cell('bad_aux_tc2_LI-7700', 'bad_aux_tc2_li_7700', '#_flagged_recs', CgEditNoise, '#9802C2'),
	'bad_aux_tc3_li_7700': create_cell('bad_aux_tc3_LI-7700', 'bad_aux_tc3_li_7700', '#_flagged_recs', CgEditNoise, '#CFEE99'),
	'box_connected_li_7700': create_cell('box_connected_LI-7700', 'box_connected_li_7700', '#_flagged_recs', CgEditNoise, '#A879EA'),
	'mean_value_rssi_li_7200': create_cell('mean_value_RSSI_LI-7200', 'mean_value_rssi_li_7200', '#', CgEditNoise, '#D085BC'),
	'mean_value_li_7500': create_cell('mean_value_LI-7500', 'mean_value_li_7500', '#', CgEditNoise, '#0E586B'),
	'u_var': create_cell('u_var', 'u_var', 'm+2s-2', CgEditNoise, '#B12439'),
	'v_var': create_cell('v_var', 'v_var', 'm+2s-2', CgEditNoise, '#8B6C87'),
	'w_var': create_cell('w_var', 'w_var', 'm+2s-2', CgEditNoise, '#4941BC'),
	'ts_var': create_cell('ts_var', 'ts_var', 'K+2', CgEditNoise, '#479F8F'),
	'co2_var': create_cell('co2_var', 'co2_var', '--', CgEditNoise, '#3D3E76'),
	'h2o_var': create_cell('h2o_var', 'h2o_var', '--', CgEditNoise, '#2E10D2'),
	'ch4_var': create_cell('ch4_var', 'ch4_var', '--', CgEditNoise, '#6B34BD'),
	'none_var': create_cell('none_var', 'none_var', '--', CgEditNoise, '#436FCE'),
	'w_ts_cov': create_cell('w/ts_cov', 'w_ts_cov', 'm+1s-1K+1', CgEditNoise, '#EBB8F7'),
	'w_co2_cov': create_cell('w/co2_cov', 'w_co2_cov', '--', CgEditNoise, '#7C281F'),
	'w_h2o_cov': create_cell('w/h2o_cov', 'w_h2o_cov', '--', CgEditNoise, '#15CC76'),
	'w_ch4_cov': create_cell('w/ch4_cov', 'w_ch4_cov', '--', CgEditNoise, '#3CD8A8'),
	'w_none_cov': create_cell('w/none_cov', 'w_none_cov', '--', CgEditNoise, '#2BF63B'),
	'co2_mean': create_cell('co2_mean', 'co2_mean', '--', CgEditNoise, '#B23EB8'),
	'h2o_mean': create_cell('h2o_mean', 'h2o_mean', '--', CgEditNoise, '#5074EB'),
	'u_mean': create_cell('u_mean', 'u_mean', '--', CgEditNoise, '#3F27DA'),
	'v_mean': create_cell('v_mean', 'v_mean', '--', CgEditNoise, '#0AF0F2'),
	'w_mean': create_cell('w_mean', 'w_mean', '--', CgEditNoise, '#5381A3'),
	'ts_mean': create_cell('ts_mean', 'ts_mean', '--', CgEditNoise, '#14E11A'),
	'vin_sf_mean': create_cell('vin_sf_mean', 'vin_sf_mean', '--', CgEditNoise, '#B9D71E'),
	'dew_point_mean': create_cell('dew_point_mean', 'dew_point_mean', '--', CgEditNoise, '#19EC23'),
	'avg_signal_strength_7200_mean': create_cell('avg_signal_strength_7200_mean', 'avg_signal_strength_7200_mean', '--', CgEditNoise, '#C4C461'),
	'co2_signal_strength_7200_mean': create_cell('co2_signal_strength_7200_mean', 'co2_signal_strength_7200_mean', '--', CgEditNoise, '#7C8777'),
	'h2o_signal_strength_7200_mean': create_cell('h2o_signal_strength_7200_mean', 'h2o_signal_strength_7200_mean', '--', CgEditNoise, '#A10A56'),
	'delta_signal_strength_7200_mean': create_cell('delta_signal_strength_7200_mean', 'delta_signal_strength_7200_mean', '--', CgEditNoise, '#DD814B'),
	'flowrate_mean': create_cell('flowrate_mean', 'flowrate_mean', '--', CgEditNoise, '#C36050'),
	'hit_power_mean': create_cell('hit_power_mean', 'hit_power_mean', '--', CgEditNoise, '#1E77FB'),
	'hit_vin_mean': create_cell('hit_vin_mean', 'hit_vin_mean', '--', CgEditNoise, '#63AACF'),
	'chk': create_cell('CHK', 'chk', '030', CgEditNoise, '#EFCBD0'),
}

const convertPressure = (data) => {
	if (data > 2000) { // перевод в мм.рт.ст.
		return Math.round(data / 100000 * 750.06158)
	} else {
		return data // мм.рт.ст.
	}
}


const convertSokolPressure = (data) => {
	return Math.round(data * 0.75006 * 10) / 10;
}

const checkBounds = (data) => {
	if (data > 100) { // перевод в мм.рт.ст.
		return '--'
	}

	return data // 
}

DATA_CELLS['msbar'].callback = convertPressure
DATA_CELLS['ms_winddir'].callback = (data) => {
	return WIND_LABELS[data]
}

DATA_CELLS['pressure'].callback = convertPressure
DATA_CELLS['air_pressure'].callback = convertPressure
DATA_CELLS['pr'].callback = convertSokolPressure
DATA_CELLS['soil7_hum'].callback = checkBounds
DATA_CELLS['soil_temp'].callback = checkBounds
DATA_CELLS['soil_temp_2'].callback = checkBounds
DATA_CELLS['soil_hum'].callback = checkBounds

export default DATA_CELLS;


export const callbacks = {
	'pressure': convertPressure,
	'air_pressure': convertPressure,
	'pr': convertSokolPressure,
	'soil7_hum': checkBounds,
	'soil_temp': checkBounds,
	'soil_temp_2': checkBounds,
	'soil_hum': checkBounds,

	'msbar': convertPressure,
	'ms_winddir': (data) => {
		return WIND_LABELS[data]
	}
}

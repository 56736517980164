import axios from 'axios';
import * as consts from '../../consts';
import Device from '../../model/device';
import getHeaders from '../headers';

export const getDevices = async (deviceType: number = NaN): Promise<Device[]> => {
	const headers = getHeaders()

	const response = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/devices`,
		headers,
		params: {
			timeoffset: new Date().getTimezoneOffset() / 60,
			device_type: deviceType,
		},
	})

	return response.data.devices as Device[]
};

export default getDevices;
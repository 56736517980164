import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import DeviceAdditionFields from '../../model/device.addition.fields';


export const getAdditionFieldsForDevice = async (device_id: string | undefined): Promise<DeviceAdditionFields[]> => {

    const headers = getHeaders()

    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/device/get_addition_fields`,
		headers,
        params: {
            device_id,
		},
	})

    return result.data.result as DeviceAdditionFields[]
};

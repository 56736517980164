import { TextField, FormControlLabel, Switch, FormGroup } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import { useState } from 'react';
import saveDeviceParameter from '../../../api/devices/save.device.parameter';
import DeviceParameter from '../../../model/device.parameter';

interface EditDeviceParameterFormProps {
    deviceParameterForEdit: DeviceParameter
    handleClose?: () => void,
    afterSaveSuccess?: () => void
}

export default function EditDeviceParameterForm({ deviceParameterForEdit, handleClose, afterSaveSuccess }: EditDeviceParameterFormProps) {

    const [deviceParameter, setDeviceParameter] = useState<DeviceParameter>(Object.assign({}, deviceParameterForEdit))
    const [saving, setSaving] = useState<boolean>(false)

    const clickSaveDeviceParameter = async () => {
        const result = await saveDeviceParameter(deviceParameter)
        if (result && afterSaveSuccess) {
            afterSaveSuccess()
        }
    }

    return (
        <>

            <>
                {deviceParameter.name}
            </>

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="label"
                value={deviceParameter.label}
                size="small"
                label="Описание"
                onChange={(event) => {
                    deviceParameter.label = event.target.value
                    setDeviceParameter(Object.assign({}, deviceParameter))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="short_label"
                value={deviceParameter.short_label}
                size="small"
                label="Краткое обозначение"
                onChange={(event) => {
                    deviceParameter.short_label = event.target.value
                    setDeviceParameter(Object.assign({}, deviceParameter))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="units"
                value={deviceParameter.units}
                size="small"
                label="Единицы измерения"
                onChange={(event) => {
                    deviceParameter.units = event.target.value
                    setDeviceParameter(Object.assign({}, deviceParameter))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <MuiColorInput
                className="mt-5 shadow-none w-[100%]"
                size='small'
                label="Цвет"
                value={deviceParameter.color}
                onChange={(newValue) => {
                    deviceParameter.color = newValue
                    setDeviceParameter(Object.assign({}, deviceParameter))
                }}
                format={'hex'}
            />

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="min_limit"
                value={deviceParameter.min_limit}
                size="small"
                label="Нижняя граница диапазона фильтрации"
                onChange={(event) => {
                    deviceParameter.min_limit = event.target.value
                    setDeviceParameter(Object.assign({}, deviceParameter))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="max_limit"
                value={deviceParameter.max_limit}
                size="small"
                label="Верхняя граница диапазона фильтрации"
                onChange={(event) => {
                    deviceParameter.max_limit = event.target.value
                    setDeviceParameter(Object.assign({}, deviceParameter))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <FormGroup className="my-3">
                <FormControlLabel control={
                    <Switch
                        defaultChecked
                        onChange={(newValue) => {
                            const isShowOnChart = newValue.target.checked ? 1 : 0;
                            deviceParameter.chart = isShowOnChart
                            setDeviceParameter(Object.assign({}, deviceParameter))
                        }}
                        checked={deviceParameter.chart === 1} />} label="Отображать на графике"
                />
            </FormGroup>

            <hr className="border-dashed my-6" />
            <p className='text-right'>
                <button className="lr-secondary-button" onClick={handleClose}>
                    Закрыть
                </button>
                <button className="ml-5 lr-primary-button" onClick={clickSaveDeviceParameter}
                    disabled={saving}

                >
                    {saving ? 'Сохранение...' : 'Сохранить'}
                </button>
            </p>
        </>
    );
}
import { LegacyRef, useEffect, useMemo, useRef, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination, Navigation, Virtual, Autoplay } from "swiper";
import SwiperInstance from "swiper";


import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { throttle } from "lodash";

const AUTOPLAY_DELAY = 500;
const timelineItemWidth = 80;

export type PlayerTimelineItem<T> = {
    label: string;
    value: T;
    id: string | number;
}

const ButtonPrev = () => {
    const swiper = useSwiper();

    return (
        <button className="shadow-md h-full bg-gray-300 z-10 px-1" onClick={() => swiper.slidePrev()}>
            <ChevronLeftIcon />
        </button>
    )
}

const ButtonNext = () => {
    const swiper = useSwiper();

    return (
        <button className="shadow-md h-full bg-gray-300 z-10 px-1" onClick={() => swiper.slideNext()}>
            <ChevronRightIcon />
        </button>
    )
}

type Props<T> = {
    items: PlayerTimelineItem<T>[];
    activeItemId?: PlayerTimelineItem<T>['id'];
    onItemChange?: (item: PlayerTimelineItem<T>) => void;
}

const PalyerTimeline = function <T>(props: Props<T>) {
    const windowWidth = useWindowWidth();

    const slidesPerView = useMemo(() => {
        return Math.floor(windowWidth / timelineItemWidth);
    }, [windowWidth]);


    const [swiperInstance, setSwiperInstance] = useState<SwiperInstance | null>(null);
    const [play, setPlay] = useState(false);


    useEffect(() => {
        if (play) {
            const interval = setInterval(() => swiperInstance?.slideNext(), AUTOPLAY_DELAY);

            return () => clearInterval(interval);
        }
    }, [play, swiperInstance])

    useEffect(() => {
        if (props.activeItemId && props.items.length && swiperInstance) {
            const activeIndex = Math.max(props.items.findIndex(i => i.id === props.activeItemId), 0);

            if (activeIndex !== swiperInstance.activeIndex) {
                swiperInstance.slideTo(activeIndex, 0);
            }
        }
    }, [props.activeItemId, props.items, swiperInstance])


    const selectIndex = (index: number) => {
        swiperInstance?.slideTo?.(index);
        updateSelectedIndex(index);
    }

    const updateSelectedIndex = (index: number) => {
        props?.onItemChange?.(props.items[index]);

        if (index === props.items.length - 1) {
            setPlay(false);
        }
    }

    const handleSlideChange = throttle((index: number) => updateSelectedIndex(index), 50);

    return <div className="bg-gray-100 select-none relative">
        <div className="bg-white h-14 shadow-md border-t border-x opacity-80 border-black/20 absolute left-1/2 -translate-x-1/2 bottom-0 rounded-t-lg" style={{ width: timelineItemWidth }}></div>

        <Swiper
            modules={[Virtual, Autoplay]}
            onSwiper={setSwiperInstance}
            slidesPerView={slidesPerView}
            centeredSlides={true}
            virtual
            onSlideChange={e => handleSlideChange(e.activeIndex)}
        >
            <div className="absolute left-0 top-0 h-full z-10 flex">
                <ButtonPrev />

                {swiperInstance &&
                    <div className="bg-white flex items-center shadow-lg">
                        <IconButton onClick={() => setPlay(play => !play)} color="primary">
                            {play ? <PauseIcon /> : <PlayArrowIcon />}
                        </IconButton>
                    </div>
                }
            </div>

            <div className="absolute right-0 top-0 h-full z-10">
                <ButtonNext />
            </div>
            {props?.items?.map?.((v, ind) => {
                return <SwiperSlide virtualIndex={ind} style={{ width: 'auto' }} key={v.id}>
                    <div
                        key={v.id}
                        onClick={() => selectIndex(ind)}
                        style={{ width: timelineItemWidth }}
                        className={`cursor-pointer leading-none text-center h-12 flex text-sm items-center justify-center transition-all
                                    ${v.id === props.activeItemId ? 'scale-125 -translate-y-0.5 font-medium text-green-600' : 'hover:bg-black/10 active:bg-black/5'}`}>
                        {v.label}
                    </div>
                </SwiperSlide>
            })}
        </Swiper>
    </div>;
}

export default PalyerTimeline;

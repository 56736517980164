import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import DeviceParameter from '../../model/device.parameter';


export const getAllDeviceParameters = async (): Promise<DeviceParameter[]> => {

    const headers = getHeaders()

    const result = await axios({
        method: "GET",
        url: `${consts.BASE_API_URL}/all_device_parameters`,
        headers,
    })

    return result.data.device_parameters as DeviceParameter[]
};

import { Component } from 'react';
import { Form } from 'react-bootstrap';
import { BsXCircle } from "react-icons/bs";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import * as React from 'react';
import ParameterCheckBox from './ParameterCheckBox'


const AMOUNT_PARAMETERS = 5;


const ParameterChooser = (props) => {

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = anchorEl ? true : false;
	const id = open ? 'simple-popover' : undefined;

	return (
		<div className={`device-compares-chooser-general ${props.chooser_class} ${props.show} mb-3`}>
			<Row>
				<Col md={2} xs={2}>
					<div className="font-montserrat text-lr-gray-primary font-semibold text-xs ">
						<Typography variant="subtitle1">
							{`${props.device.name}, s/n ${props.device.serial_number}`}
						</Typography>

					</div>
					<div className="devices-list-device-name">
						{props.device.location_description}
					</div>
				</Col>
				<Col md={10} xs={10}>
					<Row>
						<Col md={11} xs={11}>
							<div className={`device-compares-chooser-form`}>
								<>
									{props.deviceParameters &&
										<>
											{
												props.deviceParameters.slice(0, AMOUNT_PARAMETERS).map((info, i) => {
													return <ParameterCheckBox
														info={info}
														i={i}
														device={props.device}
														changeChartParameters={props.changeChartParameters}
														data_checked={props.data_checked}
														inline={true}
														key={`parameter-check-box-${i}`}
													/>
												})
											}
											{props.deviceParameters.length > AMOUNT_PARAMETERS &&
												<>
													<Tooltip title="Больше параметров">
														<MoreHorizIcon aria-describedby={id} className="text-2xl inline cursor-pointer" onClick={handleClick} />
													</Tooltip>
													<Popover
														id={id}
														open={open}
														anchorEl={anchorEl}
														onClose={handleClose}
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'left',
														}}
														style={{zIndex: 10000}}
													>
														<Typography sx={{ p: 2 }}>
															{props.deviceParameters.map((info, i) => {
																return <ParameterCheckBox
																	info={info}
																	i={i}
																	device={props.device}
																	changeChartParameters={props.changeChartParameters}
																	data_checked={props.data_checked}
																	inline={false}
																	full
																	key={`parameter-check-box-in-list-${i}`}
																/>
															})}
														</Typography>
													</Popover>
												</>

											}

										</>
									}
								</>
							</div>
						</Col>
						<Col md={1} xs={1} className="parameter-chooser-close-col">
							<BsXCircle onClick={props.deleteDeviceFromCompare} className="parameter-chooser-close-icon" />&nbsp;
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}

export default ParameterChooser;

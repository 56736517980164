import { Component } from 'react';
import LastCellData from './last_data_cell';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { callbacks } from '../../data_cells';

class LastData extends Component {
	constructor(props) {
		super(props);
	}


	render() {
		const columns = 4

		return (
			<>
				<div className="di-data-block-name">
					{this.props.label}
				</div>
				<Row>
					{this.props.keys.map((key, i) => {
						
						const info = this.props.deviceParameters[key]
						let data = this.props.data[key]
						if (key in callbacks) {	
							data = callbacks[key](data)							
						}
						return (
							<Col md={columns} xs={columns} key={`col-key-${i}`}>
								<LastCellData info={info} data={data} />
							</Col>
						)
					})}
				</Row>
			</>
		);
	}
}
export default LastData;


import { FormControlLabel, FormGroup, Snackbar, Switch, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { User } from "../../../model/profile";
import { useEffect, useState } from "react";
import { ChangePasswordSchema } from '../../../model/change.password';
import changePassword from '../../../api/devices/change.password';

export interface ChangePasswordProps {
    user: User;
}


export default function ChangePassword({ user }: ChangePasswordProps) {
    const [schema, setSchema] = useState<ChangePasswordSchema>(new ChangePasswordSchema())
    const [passwordDuplicate, setPasswordDuplicate] = useState<string>('')
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [alertContentProps, setAlertContentProps] = useState({})

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string, props: any) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
        setAlertContentProps(props)
    };


    useEffect(() => {
        schema.id_user = user.id_user
    }, [])


    const saveNewPassword = async () => {
        if (!schema.new_password || !passwordDuplicate || !schema.old_password) {
            notify('Пароли поля должны быть заполнены!', {background: "red"})
            return
        }

        if (schema.new_password != passwordDuplicate) {
            notify('Пароли не совпадают!', {background: "red"})
            return
        }

        setIsSaving(true)
        const data = await changePassword(schema)
        if (data) {
            if (data['result'] === 'ok') {
                notify('Новый пароль успешно сохранен.', {})
            } else {
                notify(data['description'], {background: "red"})
            }
        }

        setIsSaving(false)
    }


    return (
        <div className="pl-16">
            <FormControl fullWidth>
                <FormGroup className="my-3">
                    <TextField
                        className="mt-5 shadow-none"
                        fullWidth
                        name="label"
                        value={schema.old_password}
                        size="small"
                        label="Текущий пароль"
                        onChange={(event) => {
                            schema.old_password = event.target.value
                            setSchema(Object.assign({}, schema))
                        }}
                        inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                        type="password"
                        required
                    />

                    <TextField
                        className="mt-5 shadow-none"
                        fullWidth
                        name="label"
                        value={schema.new_password}
                        size="small"
                        label="Новый пароль"
                        onChange={(event) => {
                            schema.new_password = event.target.value
                            setSchema(Object.assign({}, schema))
                        }}
                        inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                        type="password"
                        required
                    />

                    <TextField
                        className="mt-5 shadow-none"
                        fullWidth
                        name="label"
                        value={passwordDuplicate}
                        size="small"
                        label="Подтвердить новый пароль"
                        onChange={(event) => {
                            setPasswordDuplicate(event.target.value)
                        }}
                        inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                        type="password"
                        required
                    />
                </FormGroup>
            </FormControl>

            <hr className="border-dashed my-6" />
            <p className='text-right'>
                <button className="ml-5 lr-primary-button" onClick={saveNewPassword}
                    disabled={isSaving}
                >
                    {isSaving ? 'Изменение...' : 'Изменить'}
                </button>
            </p>

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
                ContentProps={{
                    sx: alertContentProps
                }}
            />

        </div>
    );
}
import Nav from '../../nav';
import AdminHeader from '../header';
import SeismicEvents from './SeismicEvents';
import getSeismicEvents from "../../../api/seismic/get.seismic.events";
import { useEffect, useState } from "react";
import SeismicEvent from "../../../model/seismic.event";
import { Navigate } from "react-router-dom";



export default function SeismicPage() {
    const [seismicEvents, setSeismicEvents] = useState<SeismicEvent[]|null>([])


    const fetchData = async () => {
        const seismicEvents = await getSeismicEvents()
        setSeismicEvents(seismicEvents)
    }

    useEffect(() => {   
        fetchData()
    }, [])


    if (seismicEvents === null) {
        return (<Navigate to="/login" />)
    }


    return (
        <>
            <Nav />

            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AdminHeader headerText={`Управление сейсмособытиями`} backUrl={`/lk`} />
                    <SeismicEvents 
                        seismicEvents={seismicEvents}
                    />
                </div>
            </section>

        </>
    );
}
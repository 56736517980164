export class CameraImagesRequest {
    device_id: number = NaN;
    dt_start: number = NaN;
    dt_end: number = NaN;
}

export class CameraImage {
    id: string = '';
    filename: string = '';
    mstime: string = '';
    description: string = '';
}
import Nav from '../../nav';
import { Navigate } from "react-router-dom";
import { Component } from 'react';
import axios from "axios";
import * as consts from '../../../consts';
import RTable from './table';
import RemoveForm from '../resources/remove_modal';
import AdminHeader from '../header'

class Requests extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: props.token,
			setToken: props.setToken,
			removeToken: props.removeToken,
			requests: [],
			devices: [],
		}
		this.loadRequests(props.token);
	}


	loadRequests(token) {
		axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/load_requests`,
			headers: {
				Authorization: 'Bearer ' + token
			}
		})
			.then((response) => {
				const res = response.data
				this.setState({ requests: res.requests, devices: res.devices })
			}).catch((error) => {
				if (error.response) {
					console.log(error)
				}
			})
	}

	handleShowRemoveForm = (requestId) => {
		this.setState({ showRemoveForm: true, requestIdForRemove: requestId })
	}


	render() {
		if (!this.state.token) {
			return (<Navigate to="/login" />)
		}
		return (
			<>
				<Nav />
				<section className="min-h-[600px]">
					<div className="container px-5 admin-section">
						<AdminHeader headerText="Управление пользователями" />
						<RTable
							requests={this.state.requests}
							devices={this.state.devices}
							showModalRemoveResource={this.handleShowRemoveForm}
						/>
					</div>
				</section>
				<RemoveForm
					show={this.state.showRemoveForm}
					handleClose={() => this.setState({ showRemoveForm: false })}
					id={this.state.requestIdForRemove}
					endpoint="remove_request"
					successLabel="Заявка успешно удалено!"
				/>
			</>
		);
	}
}

export default Requests;

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
}
    from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles, styled } from '@material-ui/core/styles';
import CrownProcChart from './CrownProcChart';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 2,
        paddingRight: 2,
        lineHeight: 1,
    },
}));

export interface SpectrTableProps {
    data: any,
    waves: number[],
    prefix: string,
}

export default function SpectrTable({ data, waves, prefix }: SpectrTableProps) {
    const wavesValues = waves.map((wave) => parseFloat(data[`${prefix}_${wave}`]))
    const sum = waves.reduce((total, item) => total + parseFloat(data[`${prefix}_${item}`]), 0);
    const proc = waves.map((wave) => Math.round(data[`${prefix}_${wave}`] / sum * 100))
    return (

        <>
            <TableContainer component={Paper}>

                <Table size="small" >
                    <TableBody className="min-h-[275px]">
                        <TableRow>
                            <StyledTableCell key="th-head"  className="font-bold">Near-IR Channels, nm</StyledTableCell>
                            {waves.map((wave) => {
                                return <StyledTableCell key={`th-${wave}`}  className="font-bold">{wave} nm</StyledTableCell>
                            })}
                        </TableRow>

                        <TableRow key={`tr-nm`}>
                            <StyledTableCell key={`cell-name-nm`}>
                                Значение, μW/cm2
                            </StyledTableCell>
                            {waves.map((wave) => {
                                return <StyledTableCell key={`cell-${wave}-nm`}>
                                    {data[`${prefix}_${wave}`]}
                                </StyledTableCell>
                            })}
                        </TableRow>

                        <TableRow key={`tr-proc`}>
                            <TableCell key={`cell-name-proc`}>
                                %
                            </TableCell>
                            {waves.map((wave) => {
                                return <StyledTableCell key={`cell-${wave}-proc`}>
                                    {Math.round(data[`${prefix}_${wave}`] / sum * 100)}
                                </StyledTableCell>
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <div className='pt-5'>
                <CrownProcChart 
                    waves={waves} 
                    proc={proc} 
                    wavesValues={wavesValues}
                />
            </div>
        </>

    );
}
import { useEffect, useMemo, useState } from "react";
import { ConcentrationModelParameters, defaultModelParameters } from "../concentration-model/concentration-model";
import { Button, Slider, Switch, TextField } from "@mui/material";
import { defaultConcentrationLevels } from "./LeafletConcentrationMap";
import { debounce } from "lodash";

export type LeafletConcentrationMapConfig = ConcentrationModelParameters & { directionAngle: number; show: boolean; levels: [number, number, number, number, number] };
export const defaultLeafletConcentrationMapConfig: LeafletConcentrationMapConfig = { ...defaultModelParameters, show: false, directionAngle: 0, levels: defaultConcentrationLevels }

type ConcentrationMapConfig = {
    config: LeafletConcentrationMapConfig;
    onConfigChange?: (config: LeafletConcentrationMapConfig) => void;
}

function degToRadians(degrees: number) {
    return degrees * (Math.PI / 180);
}

function radiansToDeg(radians: number) {
    return radians * (180 / Math.PI);
}

const ConcentrationMapConfig = (props: ConcentrationMapConfig) => {
    const [config, setConfig] = useState<LeafletConcentrationMapConfig>();

    const emitConfigUpdate = useMemo(() => debounce((newConfig: LeafletConcentrationMapConfig) => {
        apply(newConfig);
    }, 500), [props.onConfigChange]);

    const handleParam = <T extends keyof LeafletConcentrationMapConfig>(param: T, value: LeafletConcentrationMapConfig[T]) => {
        if (config) {
            const newConfig = { ...config, [param]: value };
            setConfig(newConfig);
            emitConfigUpdate(newConfig);
        }
    }

    useEffect(() => {
        setConfig({ ...props.config, directionAngle: radiansToDeg(props.config.directionAngle) });
    }, [props.config]);

    const apply = (config: LeafletConcentrationMapConfig) => {
        if (config) {
            props?.onConfigChange?.({ ...config, directionAngle: degToRadians(config?.directionAngle) })
        }
    }

    const reset = () => {
        if (config) {
            props?.onConfigChange?.(defaultLeafletConcentrationMapConfig)
        }
    }

    const resetConcentrations = () => {
        if (config) {
            const newConfig = { ...config, levels: defaultConcentrationLevels };

            setConfig(newConfig);
            emitConfigUpdate(newConfig);
        }
    }

    const concentrationMarks = useMemo(() => {
        return props.config.levels.map(l => ({
            value: l,
            label: l.toString(),
        }))
    }, [props.config.levels])

    if (!config) {
        return <></>
    }

    return <div className="overflow-auto max-w-full" style={{ maxHeight: '100vh' }}>
        <h1 className="font-bold mb-3">Настройка "факела загрязнения"</h1>

        <div className="flex flex-col gap-4 min-w-[400px]">
            <div className="flex items-center gap-1">
                <TextField className="max-w-[180px]" type="number" size='small' label="Инт-сть (Q, кг/c)" value={config.Q} onChange={e => handleParam('Q', Number(e.target.value))} />
                <TextField className="max-w-[180px]" type="number" size='small' label="Высота (м)" value={config.H} onChange={e => handleParam('H', Number(e.target.value))} />
                <TextField className="max-w-[180px]" type="number" size='small' label="КМД (м^2/с)" value={config.K} onChange={e => handleParam('K', Number(e.target.value))} />
            </div>

            <div className="flex flex-col gap-1">
                <div className="text-sm">Длина обработки (м)</div>

                <div className='flex items-center gap-1'>
                    <Slider orientation="horizontal"
                        min={0}
                        max={30000}
                        value={config.rmax}
                        valueLabelDisplay="auto"
                        onChange={(_, val) => handleParam('rmax', Number(val))}
                        step={100} />
                    <TextField className="max-w-[180px]" size="small" type="number" value={config.rmax} onChange={e => handleParam('rmax', Number(e.target.value))} />
                </div>
            </div>

            <div className="flex flex-col gap-1 mt-2">
                <div className="text-sm">Ширина обработки (м)</div>

                <div className='flex items-center gap-1'>
                    <Slider orientation="horizontal"
                        min={0}
                        max={15000}
                        value={config.ymax}
                        valueLabelDisplay="auto"
                        onChange={(_, val) => handleParam('ymax', Number(val))}
                        step={100} />
                    <TextField className="max-w-[180px]" size="small" type="number" value={config.ymax} onChange={e => handleParam('ymax', Number(e.target.value))} />
                </div>
            </div>

            <div className="flex items-center gap-2">
                <Switch checked={config.show} onChange={e => handleParam('show', e.target.checked)} />
                <div className="text-sm">Отображать на карте факел загрязнения</div>
            </div>

            <div className="flex gap-4 justify-center">
                <Button size="small" onClick={reset}>Сбросить все настройки</Button>
            </div>
        </div>
    </div>;
}

export default ConcentrationMapConfig;
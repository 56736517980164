import { Component } from 'react';
import HeaderNavigation from './header_navigation'
import CoverPage1 from './cover/page_1';
import CoverPage2 from './cover/page_2';
import CoverPage3 from './cover/page_3';
import CoverPage4 from './cover/page_4';
import CoverPage5 from './cover/page_5';
import MobileCoverPage1 from './cover/mobile_page_1';
import MobileCoverPage2 from './cover/mobile_page_2';
import MobileCoverPage3 from './cover/mobile_page_3';
import MobileCoverPage4 from './cover/mobile_page_4';
import MobileCoverPage5 from './cover/mobile_page_5';

import MobilePage from './cover/mobile';

import Box from '@mui/material/Box';


class Header extends Component {
	constructor(props) {
		super(props);

		this.pages = [CoverPage1, CoverPage2, CoverPage3, CoverPage4, CoverPage5]
		this.mobile_pages = [MobileCoverPage1, MobileCoverPage2, MobileCoverPage3, MobileCoverPage4, MobileCoverPage5]

		this.state = {
			current_page: 0,
		}

		this.timer = undefined;
		this.autoChangeSlide(); // start auto play slider.
	}

	autoChangeSlide = () => {
		this.timer = setTimeout(() => {
			const current_page = this.state.current_page + 1 >= this.pages.length ? 0 : this.state.current_page + 1;
			this.setState({
				current_page
			})
			this.autoChangeSlide();
		}, 7000)
	}

	changeSlide = (direction) => {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		const current_page = this.state.current_page + direction
		this.setState({
			current_page
		}, () => {
			this.autoChangeSlide();
		})
	}

	updateSlide = (current_page) => {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		this.setState({
			current_page
		}, () => {
			this.autoChangeSlide();
		})
	}

	render() {
		const Page = this.pages[this.state.current_page];
		const MobileCurrentPage = this.mobile_pages[this.state.current_page];
		
		return (
			<header className="relative masthead">
				<div className="relative h-full">
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						<Page />
					</Box>
					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						<MobilePage page={MobileCurrentPage}/>
					</Box>
					<HeaderNavigation
						className=""
						changeSlide={this.changeSlide}
						currentPage={this.state.current_page}
						updateSlide={this.updateSlide}
					/>
				</div>
			</header>
		);
	}
}

export default Header;





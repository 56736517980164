import React, { Component } from 'react';
import axios from "axios";
import * as consts from '../../consts';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Token from '../token_v2';
import { BsXCircle } from "react-icons/bs";
import Input from './input';
import Select from "react-dropdown-select";
import services from './services';


const defaultState = {
	name: "",
	organization: "",
	email: "",
	phone: "",
	service: 0,
}

class RequestForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			show: true,
			...defaultState,
		}
		this.token = new Token;
		this.options = services;
	}

	saveRequest = () => {

		const data = {
			name: this.state.name,
			organization: this.state.organization,
			email: this.state.email,
			phone: this.state.phone,
			service: this.state.service,
		}
		axios({
			method: "POST",
			url: `${consts.BASE_API_URL}/save_request`,
			data
		})
			.then((response) => {
				this.setState({ ...defaultState })
				toast.success("Заявка отправлена!")
				setTimeout(() => {
					this.props.close()
				}, 2000);
			}).catch((error) => {
				if (error.response) {
					console.log(error.response)
					console.log(error.response.status)
					console.log(error.response.headers)
				}
				toast.error("Произошла ошибка при сохранении заявки!")
			})

	}

	updateValue = (name, value) => {
		this.setState({ [name]: value })
	}

	selectServices = (values) => {
		let value = 0
		if (values.length) {
			value = values[0].value
		}
		this.updateValue("service", value)
	}

	render() {
		const selectedValues = this.props.defaultService ? [this.options[this.props.defaultService - 1]] : [];
		return (
			<>
				<div className="login-header">
					<BsXCircle onClick={this.props.close} className="close-icon" />
					<img src="/brand_image_dark.svg" alt="" width="50%" />
				</div>
				<div className="login-header">
					Оставить заявку
				</div>
				{!this.props.isOrder &&
					<div className="modal-description">
						Регистрация на данном сайте имеет закрытую форму,<br />
						после отправки заявки с Вами свяжется администратор.
					</div>
				}
				<div className="self-modal-content">
					<Input name="name" placeholder="Введите имя" updateValue={this.updateValue} value={this.state.name} />
					<Input name="email" placeholder="Адрес электронной почты" updateValue={this.updateValue} value={this.state.email} />
					<Input name="phone" placeholder="Телефон для связи" updateValue={this.updateValue} value={this.state.phone} />
					<Input name="organization" placeholder="Организация" updateValue={this.updateValue} value={this.state.organization} />
				</div>
				{this.props.isOrder &&
					<div className="self-modal-content-select">
						<Select options={this.options}
							values={selectedValues}
							onChange={this.selectServices}
							className="input-field"
							placeholder="Выберите услугу"
						/>
					</div>
				}
				<div className="self-modal-content">
					<div className="self-modal-content-buttons">
						<button className="f-button" onClick={this.saveRequest}>Отправить</button>
					</div>
				</div>
			</>
		);
	}
}

export default RequestForm;



import { Component } from 'react';
import { FormControlLabel, Switch, FormGroup, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import getProfile from '../../../api/user/get.profile';

class AlertTypeForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			type: 0,
			parameter_id: -1,
			name: '',
			description: '',
			is_visible_for_all: 0,
			emails: '',
		}

		const alertType = props.alertTypeForUpdate;

		if (alertType) {
			this.state = {
				value: alertType.value,
				type: alertType.type,
				parameter_id: alertType.parameter_id,
				name: alertType.name,
				description: alertType.description,
				is_visible_for_all: alertType.is_visible_for_all,
				emails: alertType.emails,
			}
		}
	}

	saveResource = () => {
		this.props.addNewAlertType(
			this.state.value,
			this.state.type,
			this.state.parameter_id,
			this.state.name,
			this.state.description,
			this.state.is_visible_for_all,
			this.state.emails,
		)
	}

	changePublicProperty = (event) => {
		const isVisible = event.target.checked ? 1 : 0;
		this.setState({ is_visible_for_all: isVisible })
	}

	addMyEmail = async () => {
		const userInfo = await getProfile()
		console.log('from my email:', userInfo)
		const email = userInfo ? userInfo.email : ''

		const newEmailsValue = this.state.emails ? `${this.state.emails}, ${email}` : email
		this.setState({emails: newEmailsValue})
	}

	render() {
		return (
			<>
				<div>
					<TextField
						className="shadow-none"
						fullWidth
						name="name"
						value={this.state.name}
						size="small"
						label="Название"
						onChange={(event) => this.setState({ name: event.target.value })}
						inputProps={{ style: { boxShadow: '0 0 #0000' } }}

					/>
					<FormControl fullWidth className="mt-5">
						<InputLabel id="alert_type_parameter_id-select-label">Параметр</InputLabel>

						<Select
							size='small'
							labelId="alert_type_parameter_id-select-label"
							name="type"
							value={this.state.parameter_id}
							label="Параметр"
							onChange={(event) => this.setState({ parameter_id: parseInt(event.target.value) })}
							required
						>
							{this.props.deviceParameters.map((parameter) => {
								return (
									<MenuItem value={`${parameter.id}`}>{`${parameter.label}, ${parameter.units} (${parameter.name})`}</MenuItem>
								)
							})}
						</Select>
					</FormControl>

					<FormControl fullWidth className="mt-6">
						<InputLabel id="alert_type_type-select-label">Тип сравнения</InputLabel>

						<Select
							size='small'
							labelId="alert_type_type-select-label"
							name="type"
							value={this.state.type}
							label="Тип устройства"
							onChange={(event) => this.setState({ type: parseInt(event.target.value) })}
						>
							<MenuItem value={`0`}>Меньше</MenuItem>
							<MenuItem value={`3`}>Меньше или равно</MenuItem>
							<MenuItem value={`1`}>Больше</MenuItem>
							<MenuItem value={`4`}>Больше или равно</MenuItem>
							<MenuItem value={`2`}>Равно</MenuItem>
						</Select>
					</FormControl>

					<TextField
						className="mt-5 shadow-none"
						fullWidth
						name="value"
						value={this.state.value}
						size="small"
						label="Значение параметра"
						onChange={(event) => this.setState({ value: event.target.value })}
						required
						inputProps={{ style: { boxShadow: '0 0 #0000' } }}

					/>
					<TextField
						className="mt-5 shadow-none"
						fullWidth
						name="description"
						value={this.state.description}
						size="small"
						label="Сообщение"
						onChange={(event) => this.setState({ description: event.target.value })}
						inputProps={{ style: { boxShadow: '0 0 #0000' } }}
						multiline
					/>
					<TextField
						className="mt-5 shadow-none"
						fullWidth
						name="emails"
						value={this.state.emails}
						size="small"
						label="Отправлять на email-ы (укажите через запятую)"
						onChange={(event) => this.setState({ emails: event.target.value })}
						inputProps={{ style: { boxShadow: '0 0 #0000' } }}
						multiline
					/>
					<p className="text-sm text-lr-green-primary cursor-pointer" onClick={this.addMyEmail}>Мой email</p>
					<FormGroup className="my-3" controlId="is_public">
						<FormControlLabel control={<Switch defaultChecked onChange={this.changePublicProperty} checked={this.state.is_visible_for_all === 1 ? true : false} />} label="Видно всем, у кого есть доступ к устройству" />
					</FormGroup>
					
				</div>
				<hr className="border-dashed my-6" />
				<p className='text-right'>
					<button className="lr-secondary-button" onClick={this.props.handleClose}>
						Закрыть
					</button>
					<button className="ml-5 lr-primary-button" onClick={this.saveResource}
						disabled={this.state.isSaving}

					>
						{this.state.isSaving ? 'Сохранение...' : 'Сохранить'}
					</button>
				</p>
			</>
		);
	}
}

export default AlertTypeForm;

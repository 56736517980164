import './App.css';
import Landing from './components/landing';
import RequestForm from './components/requestform/main';
import Login from './components/login/main';
import LK from './components/lk/main';
import Resources from './components/admin/resources/main';
import Requests from './components/admin/requests/main';
import Devices from './components/admin/devices/Devices';
import Map from './components/map/main';
import AnalyzeAudio from './components/analyze_audio/main';
import useToken from './components/token';
import MonitoringPanelSettings from './components/admin/mpanel/main';
import Camera from './components/camera/main';
import MapSettings from './components/admin/map/main';
import AISettings from './components/admin/devices/ai_settings';
import DevicePage from './components/admin/device/DeviceMain';
import Alerts from './components/admin/alerts/Alerts';
import SeismicPage from './components/admin/seismic/SeismicMain';
import SeismicEventView from './components/admin/seismic/SeismicEvent';
import FindSeismicEvents from './components/admin/seismic/FindEvents';
import DeviceTypesMain from './components/admin/device_types/DeviceTypesMain';
import DeviceTypePage from './components/admin/device_types/DeviceType';
import ProfilePage from './components/lk/Profile/ProfileMain';
import Agreement from './static/Agreement';
import SeismicEchart from './sandbox/SeismicEchart';
import TeddingEchart from './sandbox/TeddingEchart';
import MissionEditMain from './components/admin/missions/edit/MissionEditMain'
import MissionsMain from './components/admin/missions/list/MissionsMain'
import ProjectsMain from './components/admin/missions/list/ProjectsMain'
import DeviceParametersGroupsMain from './components/admin/device_parameters_groups/DeviceParametersGroups'
import Agro2Main from './components/agro2/Agro2Main';
import DevicesGroupsMain from './components/admin/devices_groups/DevicesGroups'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";


function App() {
  const { removeToken, token, setToken } = useToken();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/request" element={<RequestForm />} />
        <Route path="/login" element={<Login setToken={setToken} token={token} />} />
        <Route path="/map" element={<Map />} />
        <Route exact path="/map/device/:device_uuid" element={<Map />} />
        <Route exact path="/groups/:group" element={<Map />} />

        <Route path="/analyze_audio" element={<AnalyzeAudio />} />
        <Route path="/lk" element={<LK />}></Route>
        <Route path="/agro2" element={<Agro2Main />}></Route>

        <Route path="/admin/resources" element={<Resources setToken={setToken} token={token} removeToken={removeToken} />}></Route>
        <Route path="/admin/requests" element={<Requests setToken={setToken} token={token} removeToken={removeToken} />}></Route>
        <Route path="/admin/devices" element={<Devices token={token} />}></Route>
        <Route path="/admin/mpanel" element={<MonitoringPanelSettings />}></Route>
        <Route path="/admin/map" element={<MapSettings />}></Route>
        <Route path="/admin/map/public" element={<MapSettings publicPanel={true} />}></Route>
        <Route exact path="/admin/devices/settings/roi/:device_id" element={<AISettings />}></Route>
        <Route exact path="/admin/devices/types" element={<DeviceTypesMain />}></Route>
        <Route exact path="/admin/devices/type/:device_type_id" element={<DeviceTypePage />}></Route>
        <Route exact path="/admin/devices/module_type/:module_type_id" element={<DeviceTypePage />}></Route>
        <Route exact path="/admin/device_parameters/groups" element={<DeviceParametersGroupsMain />}></Route>
        <Route exact path="/admin/devices/groups" element={<DevicesGroupsMain />}></Route>
        <Route exact path="/admin/devices/groups/map/:group" element={<MapSettings />}></Route>

        <Route exact path="/admin/device/:device_uuid" element={<DevicePage />}></Route>
        <Route path="/admin/alerts" element={<Alerts />}></Route>

        <Route path="/admin/seismic/" element={<SeismicPage />}></Route>
        <Route path="/admin/seismic/event/:seismic_event_id" element={<SeismicEventView />}></Route>
        <Route path="/admin/seismic/findevents" element={<FindSeismicEvents />}></Route>
        <Route path="/profile" element={<ProfilePage />}></Route>

        <Route path="/camera" element={<Camera />}></Route>

        <Route path="/agreement" element={<Agreement />}></Route>

        <Route path="/sandbox/seismic_echart" element={<SeismicEchart />}></Route>
        <Route path="/sandbox/tedding" element={<TeddingEchart />}></Route>
        <Route path="/sandbox/mission" element={<MissionEditMain />}></Route>
        <Route path="/sandbox/projects" element={<ProjectsMain />}></Route>
        <Route path="/sandbox/missions" element={<MissionsMain />}></Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { Grid, Tooltip } from '@mui/material';
import { Component } from 'react';
import * as consts from '../../../../consts';
import DATA_CELLS from '../../../../data_cells';
import Header from './header';
import IconsWidget from './icons';
import Footer from './Footer';

const PARAMETERS = {
	[consts.DEVICE_TYPES.METEO]: ['mstemp', 'mshum'],
	[consts.DEVICE_TYPES.METEO_SOKOL]: ['t', 'hm'],
	[consts.DEVICE_TYPES.METEO_WIFI_STATION]: ['temperature1', 'hm1'],
	[consts.DEVICE_TYPES.KARELIAMETEO_STATION]: ['temperature', 'humidity'],
	[consts.DEVICE_TYPES.METEO_M]: ['cnr1_t', 'hm'],
	[consts.DEVICE_TYPES.PULSE_STATION]: ['tau', 'h'],
	[consts.DEVICE_TYPES.PULSE_STATION_V2]: ['tau', 'h'],
}


class Mstation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			info: props.info
		}
		this.params = PARAMETERS[this.props.device.device_type_id]
	}

	render() {
		let valueT = '';
		let valueH = '';
		if (this.props.device.last_parameter_values) {
			valueT = Math.round(this.props.device.last_parameter_values[this.params[0]] * 100) / 100
			valueH = Math.round(this.props.device.last_parameter_values[this.params[1]] * 100) / 100
		}

		return (
			<div className="widget-div">
				<Header
					showDeviceById={this.props.showDeviceById}
					info={this.props.device}
				/>
				<div className="mx-1 font-montserrat  mt-3">
					<Grid container spacing={1}>
						<Grid item xs={6} className="font-semibold  justify-center flex">
							<Tooltip title={`${DATA_CELLS[this.params[0]].label}`}>
								<div>{valueT} {DATA_CELLS[this.params[0]].units}C</div>
							</Tooltip>
						</Grid>

						<Grid item xs={6} className="justify-center flex">
							<Tooltip title={`${DATA_CELLS[this.params[1]].label}`}>
								<div>{valueH} {DATA_CELLS[this.params[1]].units}</div>
							</Tooltip>
						</Grid>
					</Grid>
				</div>

				{this.props.device.last_parameter_values &&
					<div className="relative left-0">
						<IconsWidget
							info={this.props.device}
							deviceParameters={this.props.deviceParameters}
						/>
					</div>
				}

				<Footer device={this.props.info} />
			</div>
		);
	}
}
export default Mstation;

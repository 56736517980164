import { Snackbar, Tooltip } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar, ruRU } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { FaEdit, FaCog } from 'react-icons/fa';
import EcoModal from '../../eco_modal';
import DevicesGroup from '../../../model/devices.group';
import Device from '../../../model/device';
import { BsFillTrashFill } from 'react-icons/bs';
import RemoveForm from '../resources/remove_modal';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import ChangedItemDeviceListForm from '../requests/ChangedRequestDeviceListForm';
import getDevicesLite from '../../../api/devices/get.devices.lite';
import { ACCESS_TYPES } from './AddUpdateForm';

export interface GroupsTableProps {
    groups: DevicesGroup[];
    showUpdateForm: (group: DevicesGroup) => void,
}


const GroupsTable = ({ groups, showUpdateForm }: GroupsTableProps) => {

    const [groupId, setGroupId] = useState<number>(0);
    const [checkedDevices, setCheckedDevices] = useState<{ [id: number]: boolean }>({});
    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');
    const [showRemoveForm, setShowRemoveForm] = useState(false)
    const [eventIdForRemove, setEventIdForRemove] = useState<number>(0)
    const [showDevicesForm, setShowDevicesForm] = useState(false)
    const [devices, setDevices] = useState<Device[]>([])
    
    
    useEffect(() => {
        const fetchData = async () => {
            const deviceResponse = await getDevicesLite()
		    setDevices(deviceResponse.devices)
        }
        fetchData()

    }, [])

    const handleCloseDevicesForm = () => {
        setShowDevicesForm(false)
    }

    const clickShowDevicesForm = (group: DevicesGroup) => {
        setShowDevicesForm(true)

        const checked: { [id: number]: boolean } = {}
        group.devices.forEach((device: Device) => {
            checked[device.id_device] = true
        })
        setCheckedDevices(checked)
        setGroupId(group.id)
    }

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };

    const afterSaving = () => {
        notify('Сохранение прошло успешно.')
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    const showModalRemoveResource = (event_id: number) => {
        setEventIdForRemove(event_id)
        setShowRemoveForm(true)
    }

    const goToGroup = (group: DevicesGroup) => {
        const url = `/groups/${group.slug}`
        window.open(url, "_blank", "noreferrer");
    }

    const goToMap = (group: DevicesGroup) => {
        const url = `/admin/devices/groups/map/${group.slug}`
        window.open(url, "_blank", "noreferrer");
    }

    const columns = [
        {
            field: 'id',
            headerName: '#',
            flex: 0.2,
        },
        {
            field: 'name',
            headerName: 'Название',
            flex: 1,
        },
        {
            field: 'description',
            headerName: 'Описание',
            flex: 1,
        },
        {
            field: 'slug',
            headerName: 'Ссылка',
            flex: 1,
        },
        {
            field: 'devices',
            headerName: 'Устройства',
            flex: 2,
            renderCell: (param: any) => {
                return (
                    <div className='w-full'>
                        {param.row.devices.map((device: Device, i: number) => {
                            return (<div className="py-3 w-full" key={`group-devices-${i}`}>
                                - {device.name}
                                {device.description && <>
                                    ({device.description})
                                </>}
                            </div>
                            )
                        })
                        }
                    </div>
                )
            },
        },
        {
            field: 'access_type',
            headerName: 'Доступ',
            flex: 1,
            renderCell: (param: any) => {
                return (
                    <div className='w-full'>
                        {ACCESS_TYPES[param.row.access_type]}
                    </div>
                )
            },
        },
        {
            field: 'actions',
            headerName: 'Действия',
            flex: 1,
            renderCell: (param: any) => {
                return (
                    <>
                        <Tooltip title="Изменить информацию о группе">
                            <div className="inline-block">
                                <FaEdit className="text-2xl inline cursor-pointer" onClick={() => showUpdateForm(param.row)} />
                            </div>
                        </Tooltip>
                        <Tooltip title="Изменить список устройст для группы">
                            <div className="inline-block">
                                <FaCog className="text-2xl inline cursor-pointer" onClick={() => clickShowDevicesForm(param.row)} />
                            </div>
                        </Tooltip>
                        <Tooltip title="Удалить группу">
                            <div className="inline-block">
                                <BsFillTrashFill onClick={() => showModalRemoveResource(param.row.id)} className="text-2xl inline cursor-pointer text-red-500" />
                            </div>
                        </Tooltip>
                        <Tooltip title="Перейти к группе на карте">
                            <div className="inline-block">
                                <OpenInNewIcon className="text-2xl inline cursor-pointer" onClick={() => goToGroup(param.row)} />
                            </div>
                        </Tooltip>
                        <Tooltip title="Редактировать карту группы">
                            <div className="inline-block">
                                <EditLocationIcon className="text-2xl inline cursor-pointer" onClick={() => goToMap(param.row)} />
                            </div>
                        </Tooltip>
                        
                    </>
                )
            },
        },
    ];

    return (
        <>
            <DataGrid
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                getRowHeight={() => 'auto'}
                className='h-full cursor-pointer'
                rows={groups}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                    },
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterValues: [''],
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 50, 100]}
                disableRowSelectionOnClick
                disableColumnFilter
                disableDensitySelector
                disableColumnSelector
                disableColumnMenu
                disableVirtualization
                autoHeight={true}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                }}
                slots={{
                    toolbar: GridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },

                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },

                    },
                }}
            />

            {/* <EcoModal
                key="add-parameters-to-group"
                open={showParametersForm}
                handleClose={handleCloseParametersForm}
                content={ChangedParametersForGroupForm}
                title={`Изменение параметров доступа`}
                allParameters={deviceParameters}
                checkedParameters={checkedParameters}
                groupId={groupId}
                afterSaveSuccess={afterSaving}
            /> */}

            <EcoModal
                key="change-devices-modal"
                open={showDevicesForm}
                handleClose={handleCloseDevicesForm}
                content={ChangedItemDeviceListForm}
                title={`Изменение списка устройств для группы`}
                allDevices={devices}
                checkedDevices={checkedDevices}
                id={groupId}
                afterSaveSuccess={afterSaving}
                url="devices/save_devices_for_group"
            />

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />

            <RemoveForm
                show={showRemoveForm}
                handleClose={() => setShowRemoveForm(false)}
                id={eventIdForRemove}
                endpoint="devices/remove_devices_group"
                successLabel="Информация об иконке успешно удалена!"
            />
        </>
    );

}

export default GroupsTable;
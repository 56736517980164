import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import * as utils from '../../../utils';
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player';
import { BsFillTrashFill } from "react-icons/bs";


class RTable extends Component {

	render() {
		return (
			<Table striped bordered hover>
				<thead>
					<tr>
						<th></th>
						<th>Информация</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{this.props.resources.map((resource, i) => {
						let media;
						if (resource.type === 'image') {
							media = (
								<img src={utils.getUrlForResource(resource, 1)} width="200px" alt="" />
							)
						} else if (resource.type === 'video') {
							media = (
								<ReactPlayer url={utils.getUrlForResource(resource)} controls={true} />
							)
						} else if (resource.type === 'audio') {
							media = (
								<ReactAudioPlayer src={utils.getUrlForResource(resource)} controls style={{ width: "100%" }} />
							)
						}
						return (
							<tr>
								<td style={{ width: '45%' }}>{media}</td>
								<td>
									Имя файла: {resource.filename} <br />
									Дата добавления: {resource.created_at} <br />
									Описание: {resource.description} <br />
									Оборудование: {resource.device} <br />
								</td>
								<td>
									<BsFillTrashFill onClick={() => this.props.showModalRemoveResource(resource.id)} className="remove-resource-icon" />
								</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		);
	}
}

export default RTable;




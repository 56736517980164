export class User {
    id_user: string = '';
    email: string = '';
    name: string = '';
    second_name: string = '';
    organization: string = '';
    user_name: string = '';
    phone: string = '';
    is_send_emails_not_devices_link: number = 0;
    user_password_hash: string = '';
}
import { useState, useEffect } from "react";
import DeviceAlert from "../../../model/device.alert";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Popover from '@mui/material/Popover';
import * as React from 'react';
import { IconButton, Snackbar, Stack, Tooltip } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { getAlertsForUser, getAlertsForDevice } from "../../../api/devices/get.alerts";
import { styled } from '@mui/material/styles';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { DeviceAlertOrigType } from "../../../model/device.alert";
import PersonIcon from '@mui/icons-material/Person';
import ArchiveIcon from '@mui/icons-material/Archive';
import { alertToArchive } from "../../../api/devices/alert.toarchive";
import Token from "../../token_v2";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export interface NotificationsProps {
    deviceUUID?: string
}


const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));


const Notifications = ({ deviceUUID }: NotificationsProps) => {

    const [alerts, setAlerts] = useState<DeviceAlert[]>([])
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

    }

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const classes = deviceUUID ? 'text-sm inline mr-1 ml-0 cursor-pointer' : 'absolute right-12 top-2'

    const fetchAlerts = async () => {
        const alerts = deviceUUID ? await getAlertsForDevice(deviceUUID, true) : await getAlertsForUser() 
        setAlerts(alerts)
    }


    useEffect(() => {
        fetchAlerts()
    }, [open])

    return (
        <>
            <Tooltip title="Оповещения">
                <IconButton onClick={openPopover} className={classes}>
                    <StyledBadge badgeContent={alerts.length} color="error">
                        <NotificationsIcon />
                    </StyledBadge>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <AlertsList alerts={alerts} fetchAlerts={fetchAlerts} />
            </Popover>
        </>
    );
}

export default Notifications;

interface AlertsListProps {
    alerts: DeviceAlert[]
    fetchAlerts: () => void
}


function AlertsList({ alerts, fetchAlerts }: AlertsListProps) {
    const [token, setToken] = useState<Token>(new Token())

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };

    const navigate = useNavigate();
    const goToAlertsList = () => {
        navigate('/admin/alerts')
    }

    if (alerts.length === 0) {
        return (
            <div className="p-5">
                Нет предупреждений.
            </div>
        )
    }

    const toArchive = async (alertId: number) => {
        const result = await alertToArchive(alertId)
        fetchAlerts()
        if (result === 1) {
            notify('Сообщение успешно отправлено в архив')
        }
    }

    return (
        <Stack>
            {token.getToken() &&
                <p className=" justify-end items-end flex">
                    <button className="lr-secondary-button m-3" onClick={goToAlertsList}>Смотреть все</button>
                </p>
            }

            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} className="max-h-96">
                {alerts.map((alert: DeviceAlert) => {
                    return (
                        <>
                            <ListItem alignItems="flex-start" key={`list-item-${alert.id}`}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            {alert.device_alert_type === DeviceAlertOrigType.System &&
                                                <Tooltip title="Системное оповещение">
                                                    <SettingsSuggestIcon className="pr-1" />
                                                </Tooltip>
                                            }
                                            {alert.device_alert_type === DeviceAlertOrigType.User &&
                                                <Tooltip title="Пользовательское оповещение">
                                                    <PersonIcon className="pr-1" />
                                                </Tooltip>
                                            }
                                            {alert.device_alert_type === DeviceAlertOrigType.Seismic &&
                                                <Tooltip title="Сейсмособытие">
                                                    <MonitorHeartIcon className="pr-1" />
                                                </Tooltip>
                                            }
                                            {alert.device_name}
                                        </React.Fragment>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {alert.date}
                                            </Typography>
                                            {` — ${alert.description}`}
                                            {(alert.device_alert_type === DeviceAlertOrigType.User  || alert.device_alert_type === DeviceAlertOrigType.Seismic) && token.getToken() &&
                                                <Tooltip title="Переместить сообщение в архив" onClick={() => toArchive(alert.id)}>
                                                    <ArchiveIcon className="absolute right-1 cursor-pointer" />
                                                </Tooltip>
                                            }
                                            {alert.alert_url &&
                                                <Tooltip title="Перейти к событию" onClick={() => navigate(alert.alert_url)}>
                                                    <OpenInNewIcon className="absolute right-8 cursor-pointer" />
                                                </Tooltip>
                                            }
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider variant="fullWidth" />
                        </>
                    )
                })}
            </List>

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />

        </Stack>
    );
}
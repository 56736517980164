import { Component } from 'react';
import Mstation from './widgets/msstation';
import Camera from './widgets/camera';
import Audio from './widgets/audio';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as consts from '../../../consts';

const deviceWidgets = {
    [consts.DEVICE_TYPES.METEO]: Mstation,
    [consts.DEVICE_TYPES.CAMERA]: Camera,
    [consts.DEVICE_TYPES.AUDIO]: Audio,
}

const deviceCols = {
    [consts.DEVICE_TYPES.METEO]: 3,
    [consts.DEVICE_TYPES.CAMERA]: 4,
    [consts.DEVICE_TYPES.AUDIO]: 5,
}

class RowsWithCommonDevices extends Component {

    render() {
	return (
	<>
	    <Row>
		{this.props.devices.map( (device, i) => {
		    if (device.device_type_id in deviceWidgets) {
			const Widget = deviceWidgets[device.device_type_id];
			const cols = 4;//deviceCols[device.device_type_id];
			return (
			    <Col md={cols} xs={cols} className="ecosystem-devices-col-offset">
				<Widget 
				    key={`common_widget_${i}`}
				    info={device}
				    showDeviceById={this.props.showDeviceById}
				/>
			    </Col>
			)
		    }
		    return null;
		})}
	    </Row>
	</>
	);
    }
}
export default RowsWithCommonDevices;



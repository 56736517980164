import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import { PlantPhenologyImage, PlantPhenologyRequest } from '../../model/plant';


const loadPlantPhenologyImages =  async (schema: PlantPhenologyRequest): Promise<PlantPhenologyImage[]> => {
    const headers = getHeaders()
    const data = JSON.parse(JSON.stringify(schema))

    const result = await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/data/get_phenology_list_images`,
		headers,
        data,
	})

    return result.data.images as PlantPhenologyImage[]
};


export default loadPlantPhenologyImages
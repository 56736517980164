import { Component } from 'react';
import LastData from '../last_data';
import CameraDevice from './camera';
import LastDataPanel from './last_data_panel';


const defaultState = {
	data: {},
	parameters: {},
	resources: []
}


class ModuleDevice extends Component {
	constructor(props) {
		super(props);
		this.state = defaultState
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.data) {
			this.handleData(nextProps.data, nextProps.parameters, nextProps.resources);
		}
	}

	handleData(data, parameters, resources) {
		console.log("resources:" + resources.length)
		this.setState({
			data_loaded: true,
			data: data.data,
			info: data.info,
			parameters,
			resources,
		})
	}


	render() {
		if (!this.state.info) {
			return null
		}
		const info = this.state.info
    	info['last_parameter_values'] = this.state.data
    	const dataForPanel = { info };
    
		return (
			<div>
				<LastDataPanel 
					deviceParameters={this.props.deviceParameters}
					parameters={this.state.parameters}
					data={dataForPanel}
				/>
			</div>
		);
	}
}

export default ModuleDevice;


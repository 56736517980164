
export default function getTeddingData() {
    const data: any[] = [];
    var types = [
        { name: 'Ворошение', color: '#92d050' },
        { name: 'Завоз', color: '#d9d9d9' },
        { name: 'Переворот', color: '#ccc1da' },
        { name: 'Погрузка', color: '#ddd9c3' },
        { name: 'Замешивание', color: '#d9d9d9' }
    ];

    const periods = [
        [7, 1, 1690435800, 1690462800],
        [7, 3, 1690522200, 1690551000],
        [7, 1, 1690869600, 1690889400],
        [7, 0, 1690880400, 1690893000],

        [6, 0, 1690783200, 1690788600],
        [6, 0, 1691143200, 1691148600],

        [5, 0, 1690435800, 1690443000],
        [5, 0, 1690799400, 1690804800],
        [5, 0, 1691042400, 1691049600],
        
    ]
    
    periods.forEach( (period: any) => {
        const index = period[0]
        const typeItem = types[period[1]]
        const start = period[2]
        const end = period[3]
        
        data.push({
            name: typeItem.name,
            value: [index, start, end, end - start],
            itemStyle: {
                normal: {
                    color: typeItem.color
                }
            }
        });    
    } )

    return data
}

import axios from 'axios';
import * as consts from '../../consts';
import Device from '../../model/device';
import DeviceParameter from '../../model/device.parameter';
import getHeaders from '../headers';

export class DeviceResponse {
	devices: Device[] = []
	deviceParameters: Map<string, DeviceParameter> = new Map<string, DeviceParameter>()
}


export const getDevicesLite = async (deviceType: number = NaN, group: string = ''): Promise<DeviceResponse> => {
	const headers = getHeaders()

	const response = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/devices_lite`,
		headers,
		params: {
			timeoffset: new Date().getTimezoneOffset() / 60,
			device_type: deviceType,
			group_slug: group,
		},
	})

	return response.data as DeviceResponse
};

export default getDevicesLite;
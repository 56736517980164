import { useState } from 'react';
import { Form } from 'react-bootstrap';
import Device from '../../../model/device';

interface SeismicStationsSelectingFormProps {
    stations: Device[],
    checkedStations: Device[],
    handleClose?: () => void,
    afterSaveSuccess?: (stations: Device[]) => void
}


export default function SeismicStationsSelectingForm({ stations, checkedStations, handleClose, afterSaveSuccess }: SeismicStationsSelectingFormProps) {

    const [checkedDevices, setCheckedDevices] = useState<Device[]>(Object.assign([], checkedStations))


    const clickSaveProfile = async () => {
        if (afterSaveSuccess) {
            afterSaveSuccess(checkedDevices)
        }
        if (handleClose) {
            handleClose()
        }
    }

    const changeSelectedListDevices = (checked: boolean, device: Device) => {
        const _checkedDevices = Object.assign([], checkedDevices)

        if (checked) {
            _checkedDevices.push(device)
        } else {
            const index = _checkedDevices.indexOf(device);
            if (index > -1) { // only splice array when item is found
                _checkedDevices.splice(index, 1); // 2nd parameter means remove one item only
            }
        }

        setCheckedDevices(_checkedDevices)        
    }


    return (
        <>
            {stations.map((device: Device, i: number) => {
                return <div className="mb-3" key={`select-station-${i}`}>
                    <Form.Check
                        type="checkbox"
                        label={`${device.name}, s/n ${device.serial_number}`}
                        checked={checkedDevices.includes(device)}
                        onChange={(evnt) => changeSelectedListDevices(evnt.target.checked, device)}
                    />
                </div>
            })}

            <hr className="border-dashed my-6" />
            <p className='text-right'>
                <button className="lr-secondary-button" onClick={handleClose}>
                    Закрыть
                </button>
                <button className="ml-5 lr-primary-button" onClick={clickSaveProfile}>
                    Выбрать
                </button>
            </p>
        </>
    )
}
class DeviceParameter {
    id: number = NaN;

    device_id: number = NaN;
    name: string = ''; 
    label: string = ''; 
    short_label: string = '';
    units: string = '';
    icon: string = '';
    color: string = '';
    chart: number = NaN;
    max_limit: number | string = '';
    min_limit: number | string = '';

    device_type_id: number = NaN;
    module_type_id: number = NaN;

    get description() {
        return `${this.label}, ${this.units} (${this.name})`
    }
}

export default DeviceParameter;

import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';

const actions = [
    { icon: <SettingsIcon />, name: 'Настройки' },
    { icon: <SaveIcon />, name: 'Сохранить' },
    { icon: <DownloadIcon />, name: 'Загрузить' },
    { icon: <HelpIcon />, name: 'Справка' },

];


export default function MissionMenu() {
    const [isOpen, setOpen] = useState(true)

    return (
        <SpeedDial
            ariaLabel="Mission map menu"
            sx={{ position: 'absolute', top: 16, right: 16 }}
            icon={<SpeedDialIcon />}
            direction="left"
            open={isOpen}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                />
            ))}
        </SpeedDial>
    );
}

import { useEffect, useState } from 'react';
import { getAllDeviceParameters } from '../../../api/devices/get.all.device.parameters';
import { getDevicesTypes } from '../../../api/devices/get.devices.types';
import DeviceParameter from '../../../model/device.parameter';
import DevicesGroup from '../../../model/devices.group';
import { DeviceType, ModuleType } from '../../../model/device.type';
import EcoModal from '../../eco_modal';
import NotAccessPage from '../../lk/NotAccessPage';
import Nav from '../../nav';
import NoItems from '../../NoItems';
import AdminHeader from '../header';
import { getDevicesGroups } from '../../../api/devices/get.devices.groups';
import AddUpdateForm from './AddUpdateForm';
import GroupsTable from './GroupsTable';


interface INumberToDeviceTypeDictionary {
    [index: number]: DeviceType;
}

interface INumberToModuleTypeDictionary {
    [index: number]: ModuleType;
}

export default function DevicesGroupsMain() {
    const [loading, setLoading] = useState(false)
    const [groups, setGroups] = useState<DevicesGroup[] | null>([])
    const [showAddUpdateForm, setShowAddUpdateForm] = useState(false)
    const [groupForEdit, setGroupForEdit] = useState<DevicesGroup>(new DevicesGroup())
    const [deviceParameters, setDeviceParameters] = useState<{ [id: string]: DeviceParameter[]; }>({})



    const fetchData = async () => {
        setLoading(true)

        const groups = await getDevicesGroups()
        console.log(groups)
        setGroups(groups)

        const deviceParameters = await getAllDeviceParameters()

        const metaInfoModules = await getDevicesTypes()
        const deviceTypes = metaInfoModules?.device_types.reduce((accumulator: INumberToDeviceTypeDictionary, deviceType: DeviceType) => {
            accumulator[deviceType.id] = deviceType;
            return accumulator
        }, {})
        const moduleTypes = metaInfoModules?.module_types.reduce((accumulator: INumberToModuleTypeDictionary, moduleType: ModuleType) => {
            accumulator[moduleType.id] = moduleType;
            return accumulator
        }, {})

        if (!deviceTypes || !moduleTypes) {
            return
        }

        const devParameters: { [id: string]: DeviceParameter[]; } = {};
        deviceParameters.map((param: DeviceParameter) => {
            let label = deviceTypes[param.device_type_id] ? deviceTypes[param.device_type_id].description : '';
            if (param.module_type_id && moduleTypes[param.module_type_id]) {
                label += `, ${moduleTypes[param.module_type_id].description}`
            }
            if (!devParameters[label]) {
                devParameters[label] = []
            }

            devParameters[label].push(param)
        })
        setDeviceParameters(devParameters)


        setLoading(false)
    }


    useEffect(() => {
        fetchData()
    }, [])

    const showUpdateForm = (group: DevicesGroup) => {
        setGroupForEdit(group)

        setShowAddUpdateForm(true)
    }

    const showAddForm = () => {
        setGroupForEdit(new DevicesGroup())
        setShowAddUpdateForm(true)
    }


    const afterSaveSuccess = () => {
        window.location.reload()
    }



    if (groups === null) {
        return <NotAccessPage />
    }

    return (
        <>
            <Nav />

            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AdminHeader headerText={`Группы устройств`} backUrl={`/lk`} />

                    <button className="lr-primary-button mb-3" onClick={() => showAddForm()}>Добавить группу</button>
                    {groups.length === 0
                        && <NoItems text="Нет групп" />
                    }
                    {groups.length > 0 &&
                        <GroupsTable groups={groups} showUpdateForm={showUpdateForm} />
                    }
                </div>
            </section>

            <EcoModal
                key="addupdate-group"
                open={showAddUpdateForm}
                handleClose={() => setShowAddUpdateForm(false)}
                content={AddUpdateForm}
                groupForEdit={groupForEdit}
                title={`Редактирование информации о группе`}
                afterSaveSuccess={afterSaveSuccess}
            />
        </>
    );
}
import { useEffect, useState } from "react"

export interface NdviComponentProps {
    data: any,
}

export default function NdviComponent({ data }: NdviComponentProps) {
    const [percentage, setPercentage] = useState(0)
    const [ndvi, setNdvi] = useState(0)

    useEffect( () => {
        const ndvi = Math.round(data[`ndvi`] * 100) / 100
        let percentage = ndvi * 100 - 5 
        percentage = percentage < 0 ? 0 : percentage;
        
        setPercentage(percentage)
        setNdvi(ndvi)
            
    }, [])

    
    return (
        <>
            <div className="di-data-block-name">NDVI (вегетационный индекс): {ndvi}</div>

            <div className="ndvi-line-wrapper">
                <div className={`ndvi-icon`}  style={{left: `${percentage}%`}}><div className="dot"></div></div>
                <div className="ndvi-line"></div>
            </div>
        </>
    );
}

import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

export default async (checked) => {
    const headers = getHeaders()
    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/devices/check_access_for_downloading`,
		headers,
        params: {checked},
	})

    return result.data.result
};

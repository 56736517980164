import { Component } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Tooltip from '@mui/material/Tooltip';
import { CgEditNoise } from "react-icons/cg";



class LastCellData extends Component {

	render() {
		const info = this.props.info
		if (!info) {
			return <></>
		}

		const data = (this.props.data === undefined || this.props.data === null) ? '--' : this.props.data;
		const Icon = !info.icon ? 'span' : info.icon;
		const unitsClass = info.units === '°' ? 'di-cell-units-cels' : 'di-cell-units';

		const value = (data === '--' && isNaN(parseFloat(data))) ? data : Math.round(data * 100) / 100;

		const tooltipText = `${info.label}, ${info.units}`;
		return (
			<nobr>
				<Tooltip title={tooltipText}>
					<div>
						<Button variant="default" size="sm" className="p-0">
							{typeof info.icon !== 'string' &&
								<Icon className="device-data-icon" title={info.label} />
							}
							{typeof info.icon === 'string' && info.icon !== '' &&
								<img src={`/static_map/device_info/${info.icon}`} className="device-data-icon" />
							}

							{typeof info.icon === 'string' && info.icon === '' &&
								<CgEditNoise className="device-data-icon" title={info.label} />
							}

						</Button>
						<span className="di-cell-data">{value}</span>
						<span className={`${unitsClass}`}>{info.units}</span>
					</div>
				</Tooltip>

			</nobr>
		);
	}
}
export default LastCellData;



import getProfile from "../../../../api/user/get.profile";
import DeviceAdditionFields from "../../../../model/device.addition.fields";
import SimpleTextField from "./SimpleTextField";


interface EMailFieldProps {
    field: DeviceAdditionFields;
    onChangeField: any;
}


const EMailField = ({ field, onChangeField }: EMailFieldProps) => {
    
    const addMyEmail = async () => {
		const userInfo = await getProfile()
		console.log('from my email:', userInfo)
		const email = userInfo ? userInfo.email : ''

		const newValue = field.value ? `${field.value}, ${email}` : email
		onChangeField(newValue, field)
	}

    return (
        <>
            <SimpleTextField
                field={field}
                onChangeField={onChangeField}
            />
            <p className="text-sm text-lr-green-primary cursor-pointer" onClick={addMyEmail}>Мой email</p>

        </>
    )
}

export default EMailField;
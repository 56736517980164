import { InputLabel, ListItemIcon, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import saveDeviceTypeIcon from '../../../api/devices/save.device.type.icon';
import { weatherIcons } from '../../../consts';
import DeviceParameter from '../../../model/device.parameter';
import DeviceTypeIcon from '../../../model/device.type.icon';


interface EditIconFormProps {
    deviceParameters: DeviceParameter[]
    iconForEdit: DeviceTypeIcon,
    handleClose?: () => void,
    afterSaveSuccess?: (icon: DeviceTypeIcon) => void,
    deviceTypeId?: string | undefined,
    moduleTypeId?: string | undefined,
}

export default function EditIconForm({ deviceParameters, iconForEdit, handleClose, afterSaveSuccess, deviceTypeId, moduleTypeId }: EditIconFormProps) {

    const [icon, setIcon] = useState<DeviceTypeIcon>(Object.assign({}, iconForEdit))
    const [saving, setSaving] = useState<boolean>(false)

    const saveIcon = async () => {
        if (deviceTypeId) {
            icon.device_type_id = parseInt(deviceTypeId)
        }
        if (moduleTypeId) {
            icon.module_type_id = parseInt(moduleTypeId)
        }
        if (icon.condition_min_value === '') {
            icon.condition_min_value = NaN
        }
        if (icon.condition_max_value === '') {
            icon.condition_max_value = NaN
        }

        const result = await saveDeviceTypeIcon(icon)
        if (result && afterSaveSuccess) {
            afterSaveSuccess(icon)
        }
	}


    return (
        <>

            <FormControl fullWidth className="mt-5" key="icon-field">
                <InputLabel id="icon-select-label">Иконка</InputLabel>

                <Select
                    size='small'
                    labelId="icon-select-label"
                    name="icon_file"
                    value={icon.icon_file}
                    label="Иконка"
                    onChange={(event) => {
                        icon.icon_file = `${event.target.value}`
                        setIcon(Object.assign({}, icon))
                    }}
                    required
                >
                    {weatherIcons.map((iconInfo) => {
                        return (

                            <MenuItem value={iconInfo.icon} key={`menu-item-${iconInfo.icon}`}>
                                <ListItemIcon>
                                    <img src={`/weather_icons/${iconInfo.icon}`} />
                                </ListItemIcon>
                                <ListItemText primary={`${iconInfo.description}`} />
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>

            <FormControl fullWidth className="mt-5" key="parameter-field">
                <InputLabel id="device_parameter_id-select-label">Параметр</InputLabel>

                <Select
                    size='small'
                    labelId="device_parameter_id-select-label"
                    name="device_parameter_id"
                    value={icon.device_parameter_id}
                    label="Параметр"
                    onChange={(event) => {
                        icon.device_parameter_id = parseInt(`${event.target.value}`)
                        setIcon(Object.assign({}, icon))
                    }}
                    required
                >
                    {deviceParameters.map((parameter) => {
                        return (
                            <MenuItem value={`${parameter.id}`}>{`${parameter.label}, ${parameter.units} (${parameter.name})`}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="condition_min_value"
                value={icon.condition_min_value}
                size="small"
                label="Минимальное значение параметра (не указывать, если нет нижней границы)"
                onChange={(event) => {
                    icon.condition_min_value = event.target.value
                    setIcon(Object.assign({}, icon))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' }, inputMode: 'numeric', step: 0.01 }}
                type="number"
                            
            />

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="condition_max_value"
                value={icon.condition_max_value}
                size="small"
                label="Максимальное значение параметра (не указывать, если нет верхней границы)"
                onChange={(event) => {
                    icon.condition_max_value = parseInt(`${event.target.value}`)
                    setIcon(Object.assign({}, icon))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' }, inputMode: 'numeric', step: 0.01 }}
                type="number"

            />
            <hr className="border-dashed my-6" />
            <p className='text-right'>
                <button className="lr-secondary-button" onClick={handleClose}>
                    Закрыть
                </button>
                <button className="ml-5 lr-primary-button" onClick={saveIcon}
                    disabled={saving}

                >
                    {saving ? 'Сохранение...' : 'Сохранить'}
                </button>
            </p>
        </>
    );
}
import axios from 'axios';
import * as consts from '../../consts';
import Device from '../../model/device';
import getHeaders from '../headers';

export const saveAdditionFields = async (fields: any, deviceId: string | undefined): Promise<void> => {

	const headers = getHeaders()

	const response = await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/device/save_addition_fields`,
		headers,
		data: {
            fields,
            device_id: deviceId
		},
	})

};

export default saveAdditionFields;
import { Component } from 'react';
import VideoStreamPlayer from '../../../videostreamplayer';
import Header from './header';
import Footer from './Footer';

class AICameraCars extends Component {

    render() {
        return (
            <div className="widget-div">
                <Header
                    showDeviceById={this.props.showDeviceById}
                    info={this.props.info}
                />

                <Footer device={this.props.info} />
            </div>


        );
    }
}
export default AICameraCars;




import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import DeviceAlertType from '../../../model/device.alert.type';
import { AlertConditionLabels } from '../../../model/device.alert.type'
import { BsFillTrashFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import Badge from 'react-bootstrap/Badge';

export interface AlertTypesTableProps {
    alertTypes: DeviceAlertType[];
    showUpdateForm: (alertType: DeviceAlertType) => any;
    showRemoveForm: (alertId: number) => any;

}

const AlertTypesTable = ({ alertTypes, showUpdateForm, showRemoveForm }: AlertTypesTableProps) => {

    return (
        <>

            <TableContainer className="mx-auto" component={Paper}>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell key="th-name">Название</TableCell>
                            <TableCell key="th-parameter">Параметр</TableCell>
                            <TableCell key="th-condition">Условие</TableCell>
                            <TableCell key="th-description">Сообщение</TableCell>
                            <TableCell key="th-emails">EMail-ы для отправки</TableCell>
                            <TableCell key="th-actions"></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody className="min-h-[275px]">
                        {alertTypes.map((alertType: DeviceAlertType, i) => {
                            return (
                                <TableRow key={`${alertType.id}`}>

                                    <TableCell key={`name-${alertType.id}`}>
                                        <>
                                            {alertType.name} <br />
                                            {alertType.is_visible_for_all == 1 &&
                                                <Badge bg="secondary">Виден всем</Badge>
                                            }
                                        </>
                                    </TableCell>
                                    <TableCell key={`parameter-${alertType.id}`}>
                                        {alertType.parameter &&
                                            <>
                                                {`${alertType.parameter.label}, ${alertType.parameter.units} (${alertType.parameter.name})`}
                                            </>
                                        }
                                    </TableCell>
                                    <TableCell key={`condition-${alertType.id}`}>
                                        <>{Object.values(AlertConditionLabels)[alertType.type]} {alertType.value}</>
                                    </TableCell>
                                    <TableCell key={`description-${alertType.id}`}>
                                        <>{alertType.description}</>
                                    </TableCell>
                                    <TableCell key={`emails-${alertType.id}`}>
                                        <>{alertType.emails}</>
                                    </TableCell>
                                    <TableCell key={`actions-${alertType.id}`}>
                                        <Tooltip title="Редактировать информацию об устройстве">
                                            <div className="inline-block">
                                                <FaEdit onClick={() => showUpdateForm(alertType)} className="text-2xl inline cursor-pointer" />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Удалить устройство">
                                            <div className="inline-block">
                                                <BsFillTrashFill onClick={() => showRemoveForm(alertType.id)} className="text-2xl inline text-red-500 cursor-pointer" />
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}

export default AlertTypesTable;
import { Tooltip } from '@mui/material';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Form from 'react-bootstrap/Form';
import { isMobile } from 'react-device-detect';
import { BsCheck } from "react-icons/bs";
import { FaSave } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import loadDataForPeriod from '../../../api/data/load.data.for.period';
import * as utils from '../../../utils';
import ChartEcharts from './chart_echarts';
import chartOptions from './chart_options.js';
import ChooserAgType from './chooser_ag_type';
import ChooserSaverFile from './chooser_saver_file';
import './compare_component.css';
import ParameterChooser from './parameter_chooser';
import * as consts from '../../../consts';

const defaultCurrentDateType = localStorage.getItem('current_date_type') || 'day';
const DATE_PICKER_FORMAT = 'DD-MM-YYYY HH:mm';


const CompareComponent = (props) => {
	//const socket = io.connect('/');

	const [data, setData] = useState({ labels: [], datasets: [] })
	const [datasets, setDatasets] = useState({})
	const [allData, setAllData] = useState({})
	const [deviceParameters, setDeviceParameters] = useState({})
	const [dataChecked, setDataChecked] = useState([])
	const [firstKey, setFirstKey] = useState('')
	const [dtStart, setDtStart] = useState(utils.getDtStartForTypePeriod(defaultCurrentDateType).unix())
	const [dtEnd, setDtEnd] = useState(utils.getDtEndForTypePeriod(defaultCurrentDateType))
	const [currentAgType, setCurrentAgType] = useState(localStorage.getItem('current_ag_type') || 'auto')
	const [currentDateType, setCurrentDateType] = useState(defaultCurrentDateType)
	const [dataLoaded, setDataLoaded] = useState(true)
	const [isChartNormal, setIsChartNormal] = useState(false)
	const [showChartPreviewModal, setShowChartPreviewModal] = useState(false)
	const [parameterChooserCounter, setParameterChooserCounter] = useState(0)
	const [hideAggregationType, setHideAggregationType] = useState(false)
	const [periodTypes, setPeriodTypes] = useState(utils.periodTypes)

	useEffect(() => {
		// Load info in case device id is changed.
		if (props.selectedDevices.length == 0) {
			setData({ labels: [], datasets: [] })
			setDatasets({})
			setAllData({})
			setDeviceParameters({})
			return
		}

		for (const device of props.selectedDevices) {
			if (device.device_type_id === consts.DEVICE_TYPES.SEISMICSTATION) {
				setHideAggregationType(true)
				setPeriodTypes(utils.periodSeismoTypes)

				loadDataForTypePeriod('minutes_5')
				break;
			}
		}
		let data_checked = [];

		props.selectedDevices.forEach((device) => {

			for (let dataCheckedIndex in dataChecked) {
				if (dataCheckedIndex.startsWith(device.device_id + '_')) {
					data_checked[dataCheckedIndex] = dataChecked[dataCheckedIndex];
				}
			}
		})

		let datasets = [];
		const suffix = isChartNormal ? 'normal' : 'raw'
		Object.keys(data_checked).forEach((k) => {
			if (data_checked[k] && datasets[k]) {
				let dataset = datasets[k];
				dataset.data = allData[`${k}_${suffix}`]
				datasets.push(dataset)
			}
		})

		let _data = data;
		_data.datasets = datasets;
		const _firstKey = Object.keys(data_checked).length > 0 ? firstKey : undefined;

		setDataChecked(data_checked)
		setFirstKey(_firstKey)
		setData(_data)

		chartOptions.plugins.tooltip.callbacks.label = chartLabel


	}, [props.selectedDevices])


	const changeChartParameters = (v, key, device_id) => {
		// Updates list of checked parameters.
		let _dataChecked = dataChecked;
		_dataChecked[`${device_id}_${key}`] = v.target.checked;

		let datasets = [];
		const suffix = isChartNormal ? 'normal' : 'raw'
		Object.keys(_dataChecked).forEach((k) => {
			if (_dataChecked[k] && datasets[k]) {
				let dataset = datasets[k];
				dataset.data = allData[`${k}_${suffix}`]
				datasets.push(dataset)
			}
		})

		let _data = data;
		_data.datasets = datasets;

		setData(_data)
		setDataChecked(_dataChecked)
		setParameterChooserCounter(c => c + 1)
	}


	const loadDeviceData = async (device, dt_start, dt_end) => {
		setDataLoaded(false)
		const response = await loadDataForPeriod(device.device_id, dt_start, dt_end, currentAgType)

		let _deviceParameters = deviceParameters;
		_deviceParameters[device.device_id] = response.device_parameters
		setDeviceParameters(_deviceParameters)

		const rdata = response.data;

		if (device.device_type_id === consts.DEVICE_TYPES.SEISMICSTATION) {
			updateSeismicChart(rdata, response, device);
		} else {
			let labels = response.labels;
			let _datasets = datasets
			let _firstKey = firstKey
			let _allData = allData;

			response.device_parameters.forEach((info) => {
				if (info.chart === false) {
					setDataLoaded(true)
					return;
				}
				let data = [];
				let data_normal = [];

				if (!rdata[info.name]) {
					setDataLoaded(true)
					return;
				}

				rdata[info.name].data.forEach((v) => {
					data.push(v.raw);
					data_normal.push(v.normal);
				})
				_allData[`${device.device_id}_${info.name}_normal`] = data_normal;
				_allData[`${device.device_id}_${info.name}_raw`] = data;
				_datasets[`${device.device_id}_${info.name}`] = {
					label: info.label,
					data: isChartNormal ? data_normal : data,
					borderColor: info.color,
					backgroundColor: info.color + '40',
					borderWidth: 1,
					pointRadius: 2,
					diff: rdata[info.name].diff,
					min: rdata[info.name].min,
					unit: info.units,
					device: `${device.name} ${device.serial_number}`,
				};
				if (!_firstKey) {
					_firstKey = `${device.device_id}_${info.name}`;
				}
			})

			showChart(_firstKey, _allData, labels, _datasets)
		}

	}


	const updateSeismicChart = (rdata, response, device) => {
		let labels = response.labels;
		let _datasets = datasets
		let _firstKey = firstKey
		let _allData = allData;

		if (!rdata) {
			setDataLoaded(true)
			return
		}
		labels = []
		let data_e = [];
		let data_n = [];
		let data_z = [];

		const data = rdata.split(',');
		if (data.length > 0) {
			let dtStart = parseInt(data[data.length - 1]);

			let index = 0;
			data.forEach((el) => {
				if (index == data.length - 1) {
					setDataLoaded(true)
					return
				}

				if (index % 3 == 0) {
					data_n.push(el)
					labels.push(moment(dtStart).format("DD MMM YYYY hh:mm:ss.SSS"))
					dtStart += 20
				}
				if (index % 3 == 1) data_e.push(el)
				if (index % 3 == 2) data_z.push(el)

				index++;
			})
		}

		const datas = {
			'value_z': data_z,
			'value_n': data_n,
			'value_e': data_e,
		}

		Object.keys(datas).forEach((key) => {
			const module_type_id = device.module_type_id ? device.module_type_id : '';
			const paramKey = `${device.device_type_id}_${module_type_id}_${key}`
			const info = props.deviceTypeParameters[paramKey]

			_allData[`${device.device_id}_${key}_normal`] = datas[key];
			_allData[`${device.device_id}_${key}_raw`] = datas[key];
			_datasets[`${device.device_id}_${key}`] = {
				label: info.label,
				data: datas[key],
				borderColor: info.color,
				backgroundColor: info.color + '40',
				borderWidth: 1,
				pointRadius: 2,
				diff: 0,
				min: 0,
				unit: info.units,
				device: `${device.name} ${device.serial_number}`,
			};

			if (!_firstKey) {
				_firstKey = `${device.device_id}_${key}`;
			}
		});

		showChart(_firstKey, _allData, labels, _datasets)
	}


	const showChart = (_firstKey, all_data, labels, _datasets) => {
		if (!firstKey) {
			const data = {
				labels,
				datasets: [
					_datasets[_firstKey],
				],
			};
			let data_checked = {};
			data_checked[_firstKey] = true
			setData(data)
			setDataChecked(data_checked)

			setFirstKey(_firstKey)
		} else {
			const newDatasets = [];
			Object.keys(dataChecked).forEach((k) => {
				if (dataChecked[k]) {
					newDatasets.push(_datasets[k])
				}
			});

			const data = {
				labels,
				datasets: newDatasets,
			};
			setData(data)

		}


		setDataLoaded(true)
		setAllData(all_data)
		setDatasets(_datasets)
	}


	const applyPeriod = (event, picker) => {
		const type = 'custom';
		localStorage.setItem('custom_date_start', picker.startDate.format(DATE_PICKER_FORMAT));
		localStorage.setItem('custom_date_end', picker.endDate.format(DATE_PICKER_FORMAT));
		localStorage.setItem('current_date_type', 'custom');

		setDtStart(picker.startDate.unix())
		setDtEnd(picker.endDate.unix())
		setCurrentDateType(type)

		loadDataForAllDevices(picker.startDate.unix(), picker.endDate.unix());
	}

	const loadDataForTypePeriod = (type) => {
		const _dtEnd = moment();
		const _dtStart = utils.getDtStartForTypePeriod(type);

		setDtStart(_dtStart.unix())
		setDtEnd(_dtEnd.unix())
		setCurrentDateType(type)
		localStorage.setItem('current_date_type', type);

		loadDataForAllDevices(_dtStart.unix(), _dtEnd.unix());
	}


	/**
	 * dt_start, dt_end - unix timestamp
	 */
	const loadDataForAllDevices = (dt_start, dt_end) => {
		
		console.log('currentDateType:', currentDateType)
		console.log('includes:', Object.keys(utils.periodSeismoTypes).includes(currentDateType))
		for (const device of props.selectedDevices) {
			if (device.device_type_id === consts.DEVICE_TYPES.SEISMICSTATION) {
				if (!Object.keys(utils.periodSeismoTypes).includes(currentDateType)) {
					continue;
				}
			}
			console.log(device, dt_start, dt_end)
			loadDeviceData(device, dt_start, dt_end);
		}
	}

	const deleteDatasets = (device) => {
		let newDatasets = []
		let newDataChecked = {}
		let _data = data;

		Object.keys(dataChecked).forEach((k) => {
			if (!k.startsWith(device.device_id)) {
				newDatasets.push(datasets[k])
				newDataChecked[k] = true
			}
		})

		_data.datasets = newDatasets;
		setData(_data)
		setDataChecked(newDataChecked)
	}


	const getDatePickerParameters = () => {
		let startDate = localStorage.getItem('custom_date_start');
		if (!startDate) {
			startDate = moment(dtStart * 1000).format(DATE_PICKER_FORMAT);
		}
		let endDate = localStorage.getItem('custom_date_end');
		if (!endDate) {
			endDate = moment(dtEnd * 1000).format(DATE_PICKER_FORMAT);
		}
		return {
			startDate,
			endDate,
			timePicker24Hour: true,
			timePicker: true,
			drops: 'up',
			locale: {
				format: DATE_PICKER_FORMAT,
			},
		}
	}

	const changeUnits = () => {
		const _isChartNormal = !isChartNormal
		chartOptions.scales.y.ticks.display = !isChartNormal

		let _data = data;
		let newDatasets = []

		Object.keys(dataChecked).forEach((k) => {
			let dataset = datasets[k];
			const suffix = _isChartNormal ? 'normal' : 'raw'
			dataset.data = allData[`${k}_${suffix}`]
			newDatasets.push(dataset)
		})

		_data.datasets = newDatasets;
		setData(_data)
		setIsChartNormal(_isChartNormal)
	}

	const chartLabel = (v) => {
		let value = v.raw;
		if (isChartNormal) {
			value = v.raw * v.dataset.diff + v.dataset.min;
		}
		return `${Math.round(value * 10) / 10} ${v.dataset.unit}`;
	}


	const getContainterClass = () => {
		let suffix = props.compareMode ? '66' : 'full';

		if (isMobile) {
			suffix = 'full';
		} else {
			if (!props.compareMode) {
				suffix = props.classShowDeviceInfo === '' ? '70' : 'full';
			} else {
				suffix = props.classShowMonitoringPanel === '' ? '66' : 'full';
			}
		}
		return `device-compares-container-${suffix}`;
	}


	const changeAggregationType = (atype) => {
		setCurrentAgType(atype)
	}

	useEffect(() => {
		localStorage.setItem('current_ag_type', currentAgType);
		loadDataForAllDevices(dtStart, dtEnd);
	}, [currentAgType])


	const containerClass = getContainterClass();
	const hiddenAgType = hideAggregationType ? 'hidden' : '';


	return (
		<>
			<div className={`${props.show}`}>
				{props.selectedDevices.map((device, i) => {

					return <div key={`div-parameter_chooser_${i}_${parameterChooserCounter}`}>
						<ParameterChooser
							key={`parameter_chooser_${i}_${dataChecked}`}
							chooser_class={`${containerClass} device-compares-chooser-container-${i + 2}`}
							deviceParameters={deviceParameters[device.device_id]}

							show={''}
							deleteDeviceFromCompare={() => {
								deleteDatasets(device)
								props.deleteDeviceFromCompare(device);
							}}
							device={device}
							changeChartParameters={(v, key) => changeChartParameters(v, key, device.device_id)}
							data_checked={dataChecked}
						/>
					</div>
				})}

				<div data-tip data-for='choose-aggregation-type' className={`cc-aggregations-button-div cc-close-button-div-${props.selectedDevices.length} ${hiddenAgType}`} data-event='click'>
					<Button className="cc-close-button">
						Усреднение <img className="inline" src="/static_map/triangle_up.png" />
					</Button>
				</div>
				<div data-tip data-for='choose-interval' className={`cc-dates-button-div cc-close-button-div-${props.selectedDevices.length}`} data-event='click'>
					<Button className="cc-close-button">
						Задать интервал <img className="inline" src="/static_map/triangle_up.png" />
					</Button>
				</div>
				<div className={`cc-save-button-div cc-close-button-div-${props.selectedDevices.length}`}>
					<Button className="cc-close-button" onClick={() => setShowChartPreviewModal(true)}>
						<FaSave className='inline' /> <img className="inline" src="/static_map/triangle_up.png" />
					</Button>
				</div>
				<div>
					<div className={`device-compares-dates ${containerClass} mb-3`}>
						<div className="cc-units-digits-label">
							123
						</div>
						<Tooltip title="Позволяет переключать режим просмотра графика между абсолютными и относительными значениями">
							<Form.Check
								onClick={changeUnits}
								className="cc-units-switcher"
								type="switch"
								id="custom-switch"
								label=""
							/>
						</Tooltip>
						<div className="cc-units-digits-proc">
							%
						</div>
						{!dataLoaded &&
							<div className="cc-units-loader">
								<Spinner animation="border" size="sm" />
							</div>
						}
						<div className="cc-period-dates">
							{moment.unix(dtStart).format('YYYY-MM-DD HH:mm:SS')} - {moment.unix(dtEnd).format('YYYY-MM-DD HH:mm:SS')}
						</div>
					</div>
				</div>
				<div className={`device-compares-container ${containerClass}`} key={`chart-div-echarts-${dataChecked}`}>
					<ChartEcharts
						key={`chart-echarts-${dataChecked}`}
						data={data}
						data_checked={dataChecked}
						datasets={datasets}
					/>
				</div>

				<ReactTooltip
					id='choose-interval'
					aria-haspopup='true' effect='solid'
					backgroundColor='#fff'
					className="cc-toolip-dates"
					clickable={true}
					padding="1px 10px"
					offset={{ top: -10, left: 0 }}
					globalEventOff='click'
				>
					{Object.keys(periodTypes).map((ptype, i) => {
						if (ptype === 'custom') {
							return (
								<DateRangePicker
									key={`date-range-picker-${ptype}-${i}`}
									initialSettings={getDatePickerParameters()}
									onApply={applyPeriod}
								>
									<div className="btn-date-period">
										{periodTypes[ptype]}
										{currentDateType == ptype &&
											<>&nbsp;<BsCheck /></>
										}
									</div>
								</DateRangePicker>
							)
						}
						return (
							<div
								key={`btn-${ptype}-${i}`}
								className="btn-date-period" onClick={() => loadDataForTypePeriod(ptype)}>
								{periodTypes[ptype]}
								{currentDateType == ptype &&
									<>&nbsp;<BsCheck className='inline' /></>
								}
							</div>
						)
					})}
				</ReactTooltip>
				<ChooserAgType
					className="chooser-ag-type"
					changeAggregationType={changeAggregationType}
					current_ag_type={currentAgType}
				/>
				<ChooserSaverFile
					onClose={() => setShowChartPreviewModal(false)}
					show={showChartPreviewModal}
					data_checked={dataChecked}
					dt_start={dtStart}
					dt_end={dtEnd}
					current_ag_type={currentAgType}
					chartOptions={chartOptions}
					data={data}
				/>

			</div>
		</>
	)
}


export default CompareComponent;

import { Grid } from "@mui/material";
import LeftPart from "./left_part";

const CoverPage1 = () => {


    return (

        <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <LeftPart />

            <Grid item xs={4}>
                <img src="/landing/assets/img/header_01.png" className="w-full" />
            </Grid>
            <Grid item xs={3}>
                <div className="mr-0 text-right">
                    <p className="cover-header">
                        01 | Мониторинг 4.0
                    </p>
                    <p className="cover-text">
                        Создаем сети и сервисы <br />
                        для мониторинга состояния <br />
                        в режиме реального времени <br />
                    </p>
                    <p className="more-link-text">
                        <a className="more-link-text" href="#part01">Узнать подробнее</a>
                    </p>
                </div>
            </Grid>
            <Grid item xs={1}></Grid>
        </Grid>
    );
}

export default CoverPage1
import axios from 'axios';
import * as consts from '../../consts';
import { ChangePasswordSchema } from '../../model/change.password';
import getHeaders from '../headers';


const changePassword = async (schema: ChangePasswordSchema) => {

    try {
        const headers = getHeaders()
        const result = await axios({
            method: "POST",
            url: `${consts.BASE_API_URL}/profile/change_password`,
            headers,
            data: {
                schema
            },
        })

        console.log(result)

        return result.data

    } catch (error: any) {
        if (error.response.status !== 200) {
            throw new Error(`Request failed with status code ${error.response.status}`);
        } else {
            console.error(error);
        }
    }
};


export default changePassword
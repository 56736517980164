import { useEffect, useState } from "react";
import { getAlertsForDevice } from "../../../api/devices/get.alerts";
import DeviceAlert from "../../../model/device.alert";
import NoItems from "../../NoItems";
import AlertsTable from "./AlertsTable";


export interface AlertsProps {
    deviceUUID: string | undefined;
}

const Alerts = ({ deviceUUID }: AlertsProps) => {
    const [alerts, setAlerts] = useState<DeviceAlert[]>([])
    
    useEffect(() => {
        const fetchData = async () => {
            const alerts = await getAlertsForDevice(deviceUUID)
            setAlerts(alerts)
        }
        fetchData()

    }, [deviceUUID])


    return (
        <>
            {(!alerts || alerts.length === 0) &&
                <NoItems text="Нет оповещений" />
            }

            {alerts && alerts.length != 0 &&
                <>
                    <AlertsTable
                        alerts={alerts}
                    />
                </>
            }

        </>
    );
}

export default Alerts;
import { Component } from 'react';
import { Form } from 'react-bootstrap';
import { BsXCircle } from "react-icons/bs";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';


class ParameterChooser extends Component {
    render() {
        return (
            <div className={``}>
                <div className={``}>
                    <>
                        {this.props.deviceParameters &&
                            <>
                                {
                                    this.props.deviceParameters.map((info, i) => {
                                        if (!info) {
                                            return null;
                                        }
                                        if (info.chart === false || info.chart === 0) {
                                            return null;
                                        }
                                        let label = (
                                            <Tooltip title={info.label}>
                                                <div style={{ color: info.color }}>
                                                    {info.short_label}
                                                </div>
                                            </Tooltip>
                                        )
                                        return <Tooltip
                                            key={`form-check-tooltip-${i}`}
                                            title={info.label}
                                        >
                                            <Form.Check
                                                key={`form-check-${info.name}`}
                                                inline
                                                label={label}
                                                name="paramsonchart"
                                                type="checkbox"
                                                onChange={(e) => {
                                                    this.props.changeChartParameters(e, info.name)
                                                }}
                                                checked={this.props.data_checked[`${this.props.device.device_id}_${info.name}`]}
                                            />
                                        </Tooltip>
                                    })
                                }
                            </>
                        }
                    </>
                </div>
            </div>
        );
    }
}

export default ParameterChooser;

import { useEffect, useState } from "react";
import { getAlertTypes } from "../../../api/devices/get.alert.types";
import DeviceAlertType from "../../../model/device.alert.type";
import NoItems from "../../NoItems";
import AlertTypeForm from './AlertTypeForm';
import EcoModal from '../../eco_modal';
import { saveAlertType } from "../../../api/devices/add.alert.type";
import DeviceParameter from "../../../model/device.parameter";
import AlertTypesTable from "./AlertTypesTable";
import { Snackbar } from "@mui/material";
import RemoveForm from "../resources/remove_modal";

export interface AlertTypesProps {
    deviceId: number | undefined;
    deviceUUID: string | undefined;
    deviceParameters: DeviceParameter[];
}

const AlertTypes = ({ deviceId, deviceUUID, deviceParameters }: AlertTypesProps) => {
    const [alertTypes, setAlertTypes] = useState<DeviceAlertType[]>([])
    const [showAlertTypeForm, setShowAlertTypeForm] = useState<Boolean>(false)
    const [alertTypeForUpdate, setAlertTypeForUpdate] = useState<DeviceAlertType | undefined>(undefined)
    const [showRemoveAlertTypeForm, setShowRemoveAlertTypeForm] = useState<Boolean>(false)
    const [alertTypeId, setAlertTypeId] = useState<number | undefined>( undefined)

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string) => {
            setActionMessage(message);
            setActionNotificationOpen(true);
    };

    const fetchData = async () => {
        const alertTypes = await getAlertTypes(deviceUUID)
        setAlertTypes(alertTypes)
    }

    useEffect(() => {   
        fetchData()
    }, [deviceUUID])


    const showNewAlertTypeForm = () => {
        setAlertTypeForUpdate(undefined)
        setShowAlertTypeForm(true)
    }

    const showUpdateForm = (alertType: DeviceAlertType) => {
        console.log(alertType)
        setAlertTypeForUpdate(alertType)
        setShowAlertTypeForm(true)
    }

    const showRemoveForm = (alertTypeId: number) => {
        setAlertTypeId(alertTypeId)
        setShowRemoveAlertTypeForm(true)
    }

    const addNewAlertType = async (value: string, type: number, parameter_id: number, name: string, description: string, is_visible_for_all: number, emails: string) => {
        const alertType = new DeviceAlertType()
        alertType.device_id = deviceId!
        alertType.value = value
        alertType.type = type
        alertType.parameter_id = parameter_id
        alertType.name = name;
        alertType.description = description;
        alertType.is_visible_for_all = is_visible_for_all;
        alertType.emails = emails;

        if (alertTypeForUpdate) {
            alertType.id = alertTypeForUpdate.id
        }

        const res = await saveAlertType(alertType);

        if (res) {           
            notify('Сохранение прошло успешно!')
            setShowAlertTypeForm(false)
            fetchData()
        } else {
            notify('Ошибка сохранения.')
            
        }

    }

    return (
        <>
            <button className="lr-primary-button mb-3 align-bottom" onClick={showNewAlertTypeForm}>
                Добавить
            </button>
            {alertTypes.length === 0 &&
                <NoItems text="Нет заданных оповещений" />
            }

            {alertTypes.length != 0 &&
                <>
                    <AlertTypesTable
                        alertTypes={alertTypes}
                        showUpdateForm={showUpdateForm}
                        showRemoveForm={showRemoveForm}
                    />
                </>
            }

            <EcoModal
                key="addupdate-modal"
                open={showAlertTypeForm}
                handleClose={() => setShowAlertTypeForm(false)}
                content={AlertTypeForm}
                addNewAlertType={addNewAlertType}
                deviceParameters={deviceParameters}
                title={`Добавление нового типа предупреждения`}
                alertTypeForUpdate={alertTypeForUpdate}
            />

            <RemoveForm
					show={showRemoveAlertTypeForm}
					handleClose={() => setShowRemoveAlertTypeForm(false)}
					id={alertTypeId}
					endpoint="device/remove_device_alert_type"
					successLabel="Тип предупреждения успешно удален!"
			/>
            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />

        </>
    );
}

export default AlertTypes;
import { Grid } from '@mui/material';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ImageGallery from 'react-image-gallery';
import { isMobile } from 'react-device-detect';

const sec2_steps = require('../../landing/sec2_steps.html');

const images = [
	{
		original: '/gallery2/image_01.jpg',
		thumbnail: '/gallery2/image_01_s.jpg'
	},
	{
		original: '/gallery2/image_02.jpg',
		thumbnail: '/gallery2/image_02_s.jpg'
	},
	{
		original: '/gallery2/image_03.jpg',
		thumbnail: '/gallery2/image_03_s.jpg'
	},
]

function Sec2Row() {
	const offset = isMobile ? '' : 'offset-md-1 col-10';
	const center = isMobile ? 'justify-center items-center flex' : '';
	const mdColls = isMobile ? 6 : 5;

	return (
		<div className={`${offset}`}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6} className={`ml-7`}>
					<div dangerouslySetInnerHTML={{ __html: sec2_steps }} />
				</Grid>
				<Grid item xs={12} md={mdColls} className={`${center}`}>
					<ImageGallery
						items={images} showPlayButton={false} showNav={true}
						showBullets={true} showThumbnails={false}
						showFullscreenButton={false} autoPlay={true}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

export default Sec2Row;

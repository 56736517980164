import Nav from '../nav';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Token from '../token_v2';
import LoginForm from '../loginform/main';
import { Snackbar } from '@mui/material';

interface LoginProps {
	token: Token,
}

function Login({ token }: LoginProps) {

	const navigate = useNavigate()
	const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
	const [actionMessage, setActionMessage] = useState('');

	const notify = (message: string) => {
		setActionMessage(message);
		setActionNotificationOpen(true);
	};


	useEffect(() => {
		if (token) {
			navigate("/")
		}
	}, [navigate, token])

	if (token) {
		return (<></>)
	}


	const afterLogin = () => {
		notify('Вход успешно выполнен')
		setTimeout(() => {
			window.location.reload()
		}, 1000)
	}

	return (
		<>
			<Nav />
			<section>
				<div className="container px-5 admin-section">
					<LoginForm close={() => void 0} afterLogin={afterLogin} />
				</div>
			</section>

			<Snackbar
				open={actionNotificationOpen}
				autoHideDuration={2500}
				onClose={() => setActionNotificationOpen(false)}
				message={actionMessage}
			/>
		</>
	);
}

export default Login;




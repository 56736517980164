import { Component } from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

class Chart extends Component {
    render() {
	return (
	    <div className="device-compares-charts">
		<Line 
		    key={this.props.chartOptions.scales.y.ticks.display} 
		    options={this.props.chartOptions} data={this.props.data}
		    plugins={this.props.plugins}
		    className="charts-div" 
		/>
	    </div>
	);
    }
}
export default Chart;

import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { BsXCircle } from 'react-icons/bs';
import { User } from '../../model/profile'
import userRegistration from '../../api/user/registration'
import Token from '../token_v2';
import axios from "axios";
import * as consts from '../../consts';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import EcoCustomNotification from '../common/EcoCustomNotification';


interface RegistrationFormProps {
    handleClose?: () => void,
    afterSaveSuccess?: () => void
}

export default function RegistrationForm({ handleClose, afterSaveSuccess }: RegistrationFormProps) {

    const [user, setUser] = useState<User>(new User())
    const [saving, setSaving] = useState<boolean>(false)
    const [repeatPassword, setRepeatPassword] = useState<string>('')
    const [alertContentProps, setAlertContentProps] = useState({})
    const [token, setToken] = useState(new Token)
    const [agreementApplied, setAgreementApplied] = useState<boolean>(false)
	const [notifyMessage, setNotifyMessage] = useState<string>('')
	const [errorMessage, setErrorMessage] = useState<string>('')


    useEffect(() => {
        user.id_user = '-1'
    }, [])

    const clickRegistration = async () => {
        if (!user.email) {
            setErrorMessage('Поле EMail (логин) не может быть пустым.')
            return
        }

        if (!user.user_password_hash) {
            setErrorMessage('Поле Пароль не может быть пустым.')
            return
        }

        if (user.user_password_hash != repeatPassword) {
            setErrorMessage('Пароли не совпадают.')
            return
        }

        setSaving(true)
        const data = await userRegistration(user)
        if (data) {
            if (data['result'] === 'ok') {
                setNotifyMessage('Регистрация прошла успешно')
                setTimeout(() => {
                    login()
                }, 1000);

            } else {
                setErrorMessage(data['description'])
            }
        } else {
            setErrorMessage('Произошла неизвестная ошибка')
        }
        setSaving(false)

    }

    const login = () => {

        axios({
            method: "POST",
            url: `${consts.BASE_API_URL}/token`,
            data: {
                login: user.email,
                password: user.user_password_hash
            }
        })
            .then((response: any) => {
                token.saveToken(response.data.access_token)
                localStorage.setItem('user.login', user.email)
                setTimeout(() => {
                    window.location.reload()
                }, 1000);

            }).catch((error: any) => {

            })

    }

    return (
        <>

            <div className="login-header">
                <BsXCircle onClick={handleClose} className="close-icon" />

                <img src="/brand_image_dark.svg" alt="" width="50%" />
            </div>
            <div className="login-header">
                Добро пожаловать
            </div>
            <div className="self-modal-content">

                <TextField
                    className="mt-5 shadow-none"
                    fullWidth
                    name="email"
                    value={user.email}
                    size="small"
                    label="Email (логин)"
                    required
                    onChange={(event) => {
                        user.email = event.target.value
                        setUser(Object.assign({}, user))
                    }}
                    inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                />

                <TextField
                    className="mt-5 shadow-none"
                    fullWidth
                    name="name"
                    value={user.name}
                    size="small"
                    label="ФИО"
                    onChange={(event) => {
                        user.name = event.target.value
                        setUser(Object.assign({}, user))
                    }}
                    inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                />

                <TextField
                    className="mt-5 shadow-none"
                    fullWidth
                    name="organization"
                    value={user.organization}
                    size="small"
                    label="Организация"
                    onChange={(event) => {
                        user.organization = event.target.value
                        setUser(Object.assign({}, user))
                    }}
                    inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                />

                <TextField
                    className="mt-5 shadow-none"
                    fullWidth
                    name="label"
                    value={user.user_password_hash}
                    size="small"
                    label="Пароль"
                    required
                    type="password"
                    onChange={(event) => {
                        user.user_password_hash = event.target.value
                        setUser(Object.assign({}, user))
                    }}
                    inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                />
                <TextField
                    className="mt-5 shadow-none"
                    type="password"
                    fullWidth
                    name="label"
                    value={repeatPassword}
                    size="small"
                    label="Повторить пароль"
                    required
                    onChange={(event) => {
                        setRepeatPassword(event.target.value)
                    }}
                    inputProps={{ style: { boxShadow: '0 0 #0000' } }}
                />

                <div className="flex mt-5">
                    <FormControlLabel control={
                        <Switch
                            onChange={(newValue) => {
                                setAgreementApplied(newValue.target.checked)
                            }}
                            checked={agreementApplied} />}
                        label={''}
                    />
                    <div>Нажимая на кнопку 'Зарегистироваться' я принимаю <Typography className="text-lr-green-primary" component="a" target="_blank" href="/agreement">условия пользовательского соглашения</Typography></div>
                </div>
            </div>

            <hr className="border-dashed my-6" />
            <p className='text-right'>
                <button className="lr-secondary-button" onClick={handleClose}>
                    Закрыть
                </button>
                <button className="ml-5 lr-primary-button" onClick={clickRegistration}
                    disabled={saving || !agreementApplied}

                >
                    {saving ? 'Регистрация...' : 'Зарегистироваться'}
                </button>
            </p>

			<EcoCustomNotification 
				message={notifyMessage}
				errorMessage={errorMessage}
			/>
        </>
    );
}
import { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DATA_CELLS from '../../../../data_cells';
import OverlayTriggerWrapper from './overlay_trigger_wrapper'
import { Tooltip } from '@mui/material';


class WidgetCell extends Component {

	render() {
		const label = this.props.data_key ? DATA_CELLS[this.props.data_key].label : '';

		return (
			<Tooltip
				title={label}
			>
				<Col ms={6} xs={6} className={this.props.clazz}>
					{this.props.value}{DATA_CELLS[this.props.data_key].units}
				</Col>
			</Tooltip>
		);
	}
}
export default WidgetCell;


import Nav from '../../nav';
import { Navigate } from "react-router-dom";
import { Component } from 'react';
import Token from '../../token_v2';
import { ToastContainer, toast } from 'react-toastify'
import AdminHeader from '../header'
import { withRouter } from '../../withRouter';
import AiCanvas from './ai_canvas';
import saveCameraRoiSettings from '../../../api/devices/save.camera.roi.settings';
import Button from '@mui/material/Button';

class AISettings extends Component {
    constructor(props) {
        super(props);
        this.token = new Token;
        this.box = undefined;
        this.file = undefined;

        this.state = {
            img: '',
            height: 100,
            width: 300,
        }
    }

    handleFileSelect(e) {
        console.log(e.target.files[0])
        const url = URL.createObjectURL(e.target.files[0])
        this.setState({ [e.target.name]: url })
        this.file = e.target.files[0]


        var img = document.createElement('img');
        img.src = url;

        img.onload = () => {
            this.setState({ width: img.width, height: img.height })
        }

    }

    async saveROI() {
        if (this.box) {
            const httpCode = await saveCameraRoiSettings({ ...this.box, device_id: this.props.params.device_id, file: this.file })

            if (httpCode === 200) {
                toast.success('Сохранение прошло успешно')
            }
        }
    }


    onUpdateBox(box) {
        this.box = box;
    }


    render() {
        if (!this.token.getToken()) {
            return (<Navigate to="/login" />)
        }
        return (
            <>
                <ToastContainer autoClose={2000} hideProgressBar={false} />
                <Nav />
                <section className="mt-10 min-h-[600px]">
                    <div className="container px-5 admin-section">
                        <AdminHeader headerText="Настройка ROI" backUrl="/admin/devices" />
                        <div>
                            <button className="lr-primary-button mb-3 align-bottom" onClick={this.saveROI.bind(this)}>
                                Сохранить
                            </button>
                            <Button className="lr-primary-button m-3 align-bottom" component="label">
                                Выбрать файл
                                <input hidden accept="image/*" type="file" name="img" onChange={this.handleFileSelect.bind(this)} />
                            </Button>

                        </div>

                        <AiCanvas
                            width={this.state.width}
                            height={this.state.height}
                            img={this.state.img}
                            onUpdateBox={this.onUpdateBox.bind(this)}
                            device_id={this.props.params.device_id}
                        />

                    </div>


                </section>
            </>
        );

    }
}

export default withRouter(AISettings);


import Nav from '../../nav';
import RTable from './table';
import AddResourceForm from './add';
import RemoveForm from './remove_modal';
import { Navigate } from "react-router-dom";
import React, { Component } from 'react';
import axios from "axios";
import * as consts from '../../../consts';
import { Form, Button } from 'react-bootstrap';
import AdminHeader from '../header'

class Resources extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: props.token,
			setToken: props.setToken,
			removeToken: props.removeToken,
			resources: [],
			allResources: [],
			devices: [],
			showAddForm: false,
			showRemoveForm: false,
			resourceIdForRemove: null,
		}
		this.loadDevices(props.token);
	}

	loadResources(token, device_id) {
		axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/load_resources`,
			headers: {
				Authorization: 'Bearer ' + token
			},
			params: { device_id }
		})
			.then((response) => {
				const res = response.data
				res.access_token && this.state.setToken(res.access_token)
				this.setState({ resources: res.resources, allResources: res.resources })
			}).catch((error) => {
				if (error.response) {
					this.state.removeToken();
					this.setState({ token: undefined })
					console.log(error.response)
					console.log(error.response.status)
					console.log(error.response.headers)
				}
			})
	}

	loadDevices(token) {
		axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/devices`,
			headers: {
				Authorization: 'Bearer ' + token
			}
		})
			.then((response) => {
				this.setState({ devices: response.data.devices })
			}).catch((error) => {
				if (error.response) {
					this.state.removeToken();
					this.setState({ token: undefined })
				}
			})
	}

	handleShowRemoveForm = (resourceId) => {
		this.setState({ showRemoveForm: true, resourceIdForRemove: resourceId })
	}

	handleCloseRemoveForm = () => {
		this.setState({ showRemoveForm: false })
	}

	handleChangeCameraId = (event) => {
		let device_id = parseInt(event.target.value);
		this.loadResources(this.props.token, device_id);
	}

	render() {
		if (!this.state.token) {
			return (<Navigate to="/login" />)
		}
		return (
			<div className="h-100">
				<Nav />
				<section className="min-h-[600px]">
					<div className="container px-5 admin-section h-100">
						<AdminHeader headerText="Управление ресурсами" />
						
						<button className="lr-primary-button mb-3" onClick={() => this.setState({ showAddForm: true })}>
							Добавить новый файл
						</button>
						
						<p>
							<Form.Select onChange={this.handleChangeCameraId}>
								<option value="-2" disabled selected>Выбрать оборудование</option>
								<option value="-1">Все</option>
								{this.state.devices.map((device, i) => {
									return <option value={device.device_id}>{device.name}</option>
								})}
							</Form.Select>
						</p>
						<AddResourceForm show={this.state.showAddForm} handleClose={() => this.setState({ showAddForm: false })} devices={this.state.devices} />
						<RemoveForm
							show={this.state.showRemoveForm}
							handleClose={this.handleCloseRemoveForm}
							id={this.state.resourceIdForRemove}
							endpoint="remove_resource"
							successLabel="Ресурс успешно удален!"
						/>
						<div className={this.state.resources.length > 0 ? '' : 'hidden'}>
							<RTable
								resources={this.state.resources}
								showModalRemoveResource={this.handleShowRemoveForm}
							/>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default Resources;



import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import DeviceAlert from '../../model/device.alert';


export const getAlertsForDevice = async (deviceUUID: string | undefined, onlyActual: boolean = false): Promise<DeviceAlert[]> => {
	const headers = getHeaders()
	const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/device/alerts`,
		headers,
        params: {
            device_uuid: deviceUUID,
			only_actual: onlyActual,
		},
	})

    return result.data.result as DeviceAlert[]
};


export const getAlertsForUser = async (): Promise<DeviceAlert[]> => {

    const headers = getHeaders()

    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/device/alerts_for_user`,
		headers,
	})

    return result.data.result as DeviceAlert[]
};

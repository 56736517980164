import { Component } from 'react';
import axios from "axios";
import * as consts from '../../consts';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip as MuiTooltip } from '@mui/material';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

class RainBarChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.getDataForBar([], []),
			options: this.getOptions(),
			period: 'week',
			loading: false,
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.loadRainData(nextProps.device_id, nextProps.period);
	}

	getDataForBar(data, labels) {
		return {
			labels,
			datasets: [{
				label: 'Осадки в сутки, мм',
				data: data,
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},],
		};
	}

	getOptions() {
		return {
			responsive: true,
			plugins: {
				legend: {
					display: false,
					position: 'top',
				},
				title: {
					display: true,
					text: 'Осадки',
				},
			},
		}
	}

	loadRainData(device_id, period) {
		this.setState({ loading: true })
		axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/load_rain_data`,
			params: { device_id, period }
		})
			.then((response) => {
				this.setState({
					loading: false,
					data: this.getDataForBar(response.data.data, response.data.labels),
				})

			}).catch((error) => {
				if (error.response) {
					console.log(error.response)
				}
			})
	}

	changePeriod(event, newPeriod) {
		this.setState({ period: newPeriod }, () => {
			this.loadRainData(this.props.device_id, newPeriod)
		})
	};

	render() {
		return (<div className="h-80">
			<ToggleButtonGroup
				color="primary"
				value={this.state.period}
				exclusive
				onChange={this.changePeriod.bind(this)}
				aria-label="Platform"
				size="small"
				className="pt-3 w-full justify-center items-center flex"
			>
				<ToggleButton value="day">
					<MuiTooltip title="Осадки за последние 24 часа с шагом 1 час">
						<p>сутки</p>
					</MuiTooltip>
				</ToggleButton>
				<ToggleButton value="week">
					<MuiTooltip title="Осадки за последние 7 дней с шагом 1 день">
						<p>неделя</p>
					</MuiTooltip>
				</ToggleButton>
				<ToggleButton value="month">
					<MuiTooltip title="Осадки за последние 30 дней с шагом 1 день">
						<p>месяц</p>
					</MuiTooltip>
				</ToggleButton>
				<ToggleButton value="year">
					<MuiTooltip title="Осадки за последний год с шагом 1 месяц">
						<p>год</p>
					</MuiTooltip>
				</ToggleButton>
			</ToggleButtonGroup>
			{this.state.loading &&
				<div className="mt-5 flex justify-center items-center">
					<CircularProgress />
				</div>
			}

			{!this.state.loading &&
				<Bar options={this.state.options} data={this.state.data} />
			}
		</div>)
	}
}

export default RainBarChart;





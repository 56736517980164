import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import DeviceParameter from '../../model/device.parameter';


export const getDeviceParameters = async (device_uuid: string | undefined): Promise<DeviceParameter[]> => {

    const headers = getHeaders()

    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/device/parameters`,
		headers,
        params: {
            device_uuid,
		},
	})

    return result.data.result as DeviceParameter[]
};

import Nav from '../../nav';
import AdminHeader from '../header';
import { useEffect, useState } from 'react';
import getDevice from '../../../api/devices/get.device';
import { useParams } from 'react-router-dom';
import TabPanel from '@mui/lab/TabPanel';
import { TabContext } from '@mui/lab';
import { Tab, Tabs, Tooltip } from '@mui/material';
import AlertTypes from './AlertTypes'
import DeviceParameter from '../../../model/device.parameter';
import { getDeviceParameters } from '../../../api/devices/get.device.parameters';
import EditDeviceForm from './EditDeviceForm';
import Device from '../../../model/device';
import Alerts from './Alerts';
import LinkIcon from '@mui/icons-material/Link';
import { Snackbar } from '@mui/material';
import { MODULE_TYPES, DEVICE_TYPES } from '../../../consts';
import PhenologyTab from './PhenologyTab';
import RoiTab from './RoiTab';
import NotAccessPage from '../../lk/NotAccessPage';
import checkDeviceAccess from '../../../api/devices/check.device.access';
import SeismicFilesTab from './SeismicFilesTab';



export enum DeviceTab {
    Edit = 'Edit',
    Bounds = 'Bounds',
    Alerts = 'Alerts',
    Phenology = 'Phenology',
    Roi = 'Roi', // Region of inteterests for AI module car.
    SeismicFiles = 'SeismicFiles', // List of files for downloading of seismo stations.
}


export default function DevicePage() {
    const [activeTab, setActiveTab] = useState<DeviceTab>(DeviceTab.Edit);
    const [deviceParameters, setDeviceParameters] = useState<DeviceParameter[]>([])
    const [headerTitle, setHeaderTitle] = useState('Устройство...')
    const [device, setDevice] = useState<Device|null>()
    const params = useParams()

    useEffect(() => {
        const fetchData = async () => {
            const check = await checkDeviceAccess(params.device_uuid)
            if (!check) {
                setDevice(null)
                return
            }


            const device = await getDevice(params.device_uuid)
            if (device) {
                setHeaderTitle(`Устройство ${device.name}, s/n ${device.serial_number}`)
            }
            setDevice(device)

            const deviceParameters = await getDeviceParameters(params.device_uuid)
            setDeviceParameters(deviceParameters)
        }

        fetchData()

    }, [])


    const onChangeTab = (value: DeviceTab) => {
        setActiveTab(value)
    }

    const copyLink = () => {
        const url = `${window.location.protocol}://${window.location.hostname}/map/device/${device?.uuid}`
        navigator.clipboard.writeText(url)
        notify('Ссылка скопирована успешно')
    }

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };

    if (device === null) {
        return <NotAccessPage />
    }

    return (
        <>
            <Nav />

            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AdminHeader headerText={`${headerTitle}`} backUrl={`/admin/devices`} />
                    <Tooltip title="Скопировать ссылку">
                        <LinkIcon className="pl-2 cursor-pointer text-4xl" onClick={copyLink} />
                    </Tooltip>

                    <Tabs className="mb-2" centered={true} value={activeTab} onChange={(_, value) => onChangeTab(value)}>
                        <Tab label="Редактировать" value={DeviceTab.Edit} />
                        <Tab label="Типы оповещений" value={DeviceTab.Bounds} />
                        <Tab label="Оповещения" value={DeviceTab.Alerts} />
                        {device?.module_type_id === MODULE_TYPES.BASECAM &&
                            <Tab label="Фенология" value={DeviceTab.Phenology} />
                        }
                        {device?.device_type_id === DEVICE_TYPES.AI_CAMERA_CARS &&
                            <Tab label="Зоны интереса" value={DeviceTab.Roi} />
                        }
                        {/* {device?.device_type_id === DEVICE_TYPES.SEISMICSTATION &&
                            <Tab label="Файлы данных" value={DeviceTab.SeismicFiles} />
                        } */}
                    </Tabs>

                    <TabContext value={activeTab}>
                        <TabPanel className="p-0" value={DeviceTab.Edit}>
                            {device &&
                                <EditDeviceForm
                                    device={device}
                                />
                            }
                        </TabPanel>
                        <TabPanel className="p-0" value={DeviceTab.Bounds}>
                            {device &&
                                <AlertTypes
                                    deviceUUID={params.device_uuid}
                                    deviceId={device.device_id}
                                    deviceParameters={deviceParameters}
                                />
                            }
                        </TabPanel>
                        <TabPanel className="p-0" value={DeviceTab.Alerts}>
                            <Alerts
                                deviceUUID={params.device_uuid}
                            />
                        </TabPanel>
                        <TabPanel className="p-0" value={DeviceTab.Phenology}>
                            {device &&
                                <PhenologyTab deviceId={device.device_id} />
                            }
                        </TabPanel>
                        <TabPanel className="p-0" value={DeviceTab.Roi}>
                            {device &&
                                <RoiTab deviceId={`${device.device_id}`} />
                            }
                        </TabPanel>

                        {/* <TabPanel className="p-0" value={DeviceTab.SeismicFiles}>
                            {device &&
                                <SeismicFilesTab deviceUUID={device.uuid} />
                            }
                        </TabPanel> */}

                    </TabContext>

                </div>
            </section>

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />

        </>
    );
}
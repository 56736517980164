import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

export const loadLastBirdObservation = async (device_id) => {

	const headers = getHeaders()

	const response = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/data/load_last_bird_observation`,
		headers,
		params: {
			timeoffset: new Date().getTimezoneOffset() / 60,
			device_id,
		},
	})

	return response.data.data
};

export default loadLastBirdObservation;
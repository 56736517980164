
export class PlantPhenologyRequest {
    device_id: number = NaN;
    dt_start: number = NaN;
    dt_end: number = NaN;
}

export class PlantPhenologyImage {
    id: string = '';
    filename: string = '';
    mstime: string = '';
}
import { Tooltip } from '@mui/material';
import Form from 'react-bootstrap/Form';


const ParameterCheckBox = (props) => {

    const info = props.info;


    const getTextLabel = (info) => {
        if (props.full) {
            return `${info.short_label} (${info.label})`
        }

        return info.short_label
    }


    if (!info) {
        return null;
    }
    if (info.chart === false || info.chart === 0) {
        return null;
    }
    let label = (
        <Tooltip title={info.label}>
            <div style={{ color: info.color }}>
                {getTextLabel(info)}
            </div>
        </Tooltip>
    )
    return <Tooltip
        key={`form-check-tooltip-${props.i}`}
        title={info.label}
    >
        <Form.Check
            key={`form-check-${info.name}`}
            inline={props.inline}
            label={label}
            name="paramsonchart"
            type="checkbox"
            onChange={(e) => {
                props.changeChartParameters(e, info.name)
            }}
            checked={props.data_checked[`${props.device.device_id}_${info.name}`]}
        />
    </Tooltip>

}

export default ParameterCheckBox;

import { useEffect, useState } from 'react';
import getTeplovizorData from '../../../../api/data/get.teplovizor.data';
import CrownSpectrChart from './CrownSpectrChart';

export interface CrownTeplovizorProps {
    deviceId: number,
}

export default function CrownTeplovizor({ deviceId }: CrownTeplovizorProps) {

    const [loading, setLoading] = useState<boolean>()
    const [dataRow, setDataRow] = useState<number[][] | undefined>(undefined)

    const fetchData = async () => {
        setLoading(true)

        const dataRow = await getTeplovizorData(deviceId)

        setDataRow(dataRow)
        setLoading(false)
    }



    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {!loading && dataRow &&
                <div className="flex justify-center pt-5">
                    <CrownSpectrChart
                        rawData={dataRow}
                    />
                </div>
            }
        </>
    );
}
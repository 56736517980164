import { useEffect, useState } from 'react';
import getSeismicEvents from '../../../../api/seismic/get.seismic.events';
import SeismicEvent from '../../../../model/seismic.event';
import SeismicEventsShortTable from '../../../admin/seismic/SeismicEventsShortTable';
import SeismicDevice from './seismic';

export interface SeismicDevicePanelInfoProps {
    device_id: number;
    device_uuid: string;
}

const SeismicDevicePanelInfo = ({ device_id, device_uuid }: SeismicDevicePanelInfoProps) => {
    const [seismicEvents, setSeismicEvents] = useState<SeismicEvent[] | null>([])


    const fetchData = async () => {
        const seismicEvents = await getSeismicEvents(device_uuid)
        setSeismicEvents(seismicEvents)
    }

    useEffect(() => {
        fetchData()
    }, [])


    return (
        <div className='pt-5' key={`seismic-day-figures-gallery-key-${device_id}`}>
            <SeismicDevice
                device_id={device_id}
            />


            {seismicEvents &&
                <>
                    <hr className="my-5"/>
                    <p className='text-2xl mb-5'>Сейсмособытия</p>

                    <SeismicEventsShortTable
                        events={seismicEvents}
                    />
                </>
            }
        </div>
    );
}
export default SeismicDevicePanelInfo;



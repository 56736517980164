import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

export const createSeismicEvent = async (mstime: string, station_ids: number[]): Promise<number> => {

	const headers = getHeaders()

	const response = await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/seismicevents/create_seismic_event`,
		headers,
		data: {
            mstime,
            station_ids,
			timeoffset: new Date().getTimezoneOffset() / 60
		},
	})

    console.log(response.data)
	return response.data.event_id as number
};

export default createSeismicEvent;
import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

export const saveMapSettings = async (settings) => {

	const headers = getHeaders()

    await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/update_map_settings`,
		headers,
        data: settings
	})
};

export default saveMapSettings;
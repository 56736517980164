import { Grid } from '@mui/material';
import { Component } from 'react';
import { isMobile } from 'react-device-detect';

class AeroArch extends Component {
	render() {
		const center = isMobile ? 'justify-center items-center flex' : '';
		const videoSize = isMobile ? 280 : 600;
		const videoClass = isMobile ? 'rc-mobile' : 'rc';
		

		return (
			<Grid container spacing={0} className="f-text sec3-tab">
				<Grid item xs={12} md={6} className="f-center-text">
					<img src="/landing/header/copter.png" className="copter-image" />
					<div className={videoClass} onClick={() => this.refs.aerovideo.play()}>
						<video ref="aerovideo" src="/landing/videos/video_04.mp4" autoplay width={videoSize} height="300" className="video-circle" loop muted playsinline />
					</div>
				</Grid>

				<Grid item  xs={12} md={6}>
					<div className="f-text sec4-header">
						Характеристики аэрофотосъемки:
					</div>

					<div className="f-text-div f-text">
						Площадь: от 1 до 100 км<sup>2</sup>
					</div>

					<div className="f-text-div f-text">
						Пространственное разрешение: 1-10 см/пикс
					</div>

					<div className="f-text-div f-text">
						Система координат: WGS-84
					</div>

					<div className="f-text-div f-text">
						Режимы съемки: RGB, мультиспектральная, тепловизионная
					</div>

					<div className={`f-button-div mt-5`}>
						<button className={`f-button ${center}`} onClick={this.props.showRequestForm}>Заказать аэрофотосъёмку</button>
					</div>

				</Grid>
			</Grid>

		);
	}
}

export default AeroArch;





import { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WidgetModule from './widgets/widget_module';

class RowsEcoSystemDevices extends Component {
    render() {
	return (
	<Row>
	    {this.props.devices.map( (device, i) => {
		return (
			<WidgetModule 
			    key={`eco_widget_${i}`}
			    device={device}
			    showDeviceById={this.props.showDeviceById}
			/>
		)
	    })}
	</Row>
	);
    }
}
export default RowsEcoSystemDevices;

import { Component } from 'react';
import WindRose from '../windrose';
import RainBarChart from '../rain_bar_chart';
import meteodata from '../meteodata';
import LastDataPanel from './last_data_panel';

class MeteoDevice extends Component {
	
	render() {
		return (
			<div>
				
				<LastDataPanel 
					deviceParameters={this.props.deviceParameters}
					parameters={meteodata}
					data={this.props.data}
				/>
				<hr className='mt-3' />
					<div>
						<RainBarChart device_id={this.props.device_id} />
						<hr className='mt-3' />
					</div>
					<div>
						<WindRose device_id={this.props.device_id} />
						<hr className='mt-3' />
					</div>
			</div>
		);
	}
}

export default MeteoDevice;

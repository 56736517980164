import { Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';


export interface PlantWidgetProps {
    showDeviceById: () => {},
    info: any,
}

export const NDVI_COLORS = ['#d73027', '#fdae61', '#fee08b', '#ffffbf', '#d9ef8b', '#a6d96a', '#66bd63', '#1a9850', '#006837']


const ParamTypography = ({text}: {text: string}) => {
    return <Typography className="font-montserrat font-extrabold leading-4"
                sx={{
                }}
            >
                {text}
            </Typography>
}


export default function PlantWidget({ showDeviceById, info }: PlantWidgetProps) {

    const getNDVI = () => {
        const ndvi = info['last_parameter_values']['ndvi']
        
        const bounds = [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
        let index = 0
        bounds.forEach((bound) => {
            if (bound > ndvi) {
                return false
            }
            index++
        })
        if (index >= NDVI_COLORS.length) {
            index = NDVI_COLORS.length - 1
        }
        
        return NDVI_COLORS[index]
    }

    if (!info || !info['last_parameter_values']) {
        return null
    }

    return (     
        <>
            <Grid container className="pt-2 pl-1 font-montserrat font-extrabold">
                <Grid item xs={8}>
                    <Grid container>
                        <Grid item xs={5}>
                            <img src="/widgets_icons/plant_widget.jpg" className="h-[70%]"/>
                        </Grid>
                        <Grid item xs={7}>
                            <Stack direction={'column'} className="font-montserrat font-extrabold">
                                <ParamTypography text={`${info['last_parameter_values']['thick_wood']} мм`} />
                                <ParamTypography text={`${info['last_parameter_values']['trunk_temp']} C`} />
                                <ParamTypography text={`${info['last_parameter_values']['hum_wood']} %`} />
                            </Stack>
                        </Grid>
                    </Grid>    
                </Grid>

                <Grid item xs={4} className="">
                    <Typography  className="font-montserrat font-black text-xl leading-4" 
                        sx={{
                            textDecoration: 'underline', 
                            textDecorationColor: `${getNDVI()}`,
                            textDecorationThickness: '3px'
                        }}>
                            NDVI
                    </Typography>
                                
                </Grid>
            </Grid>
        </>
    );
}
import { Component } from 'react';
import LastData from '../last_data';


class LastDataPanel extends Component {
	
	render() {
        if (!this.props.data) return null
        const data = this.props.data.info.last_parameter_values
		return (
			<div>
				{Object.keys(this.props.parameters).map((key, i) => {
					const dataArea = this.props.parameters[key];

                    let dParams = {}
					const moduleTypeId = this.props.data.info.module_type_id ? this.props.data.info.module_type_id : ''

					dataArea.keys.forEach( (key) => {
						const paramKey = `${this.props.data.info.device_type_id}_${moduleTypeId}_${key}`
						dParams[key] = this.props.deviceParameters[paramKey]
					})

					if (data && Object.keys(data).length !== 0) {
						return (
							<div className="di-data-block" key={`${key}-${i}`}>
								<LastData
									data={data}
									keys={dataArea.keys}
									label={dataArea.label}
									deviceParameters={dParams}
								/>
							</div>
						)
					}
					return null
				})}
			</div>
		);
	}
}

export default LastDataPanel;

import { useEffect, useState } from "react";
import { getAdditionFieldsForDevice } from "../../../api/devices/get.addition.fields";
import DeviceAdditionFields from "../../../model/device.addition.fields";
import SimpleTextField from "./fields/SimpleTextField";
import BooleanField from "./fields/BooleanField";
import EMailField from "./fields/EMailField";

export interface AdditionFieldsProps {
    deviceId: string | undefined;
    saveAdditionFields: (fields: DeviceAdditionFields[]) => void;
}


const AddupdateAdditionFields = ({ deviceId, saveAdditionFields }: AdditionFieldsProps) => {
    const [additionFields, setAdditionFields] = useState<DeviceAdditionFields[]>([])

    useEffect(() => {
        const fetchAdditionFields = async () => {
            const fields = await getAdditionFieldsForDevice(deviceId)
            setAdditionFields(fields)
        }
        fetchAdditionFields()

    }, [deviceId])

    if (additionFields.length == 0) {
        return <></>
    }

    const onChangeField = (value: any, field: DeviceAdditionFields) => {
        field.value = value
        saveAdditionFields(additionFields)
    }


    const onChangeFieldBoolean = (event: any, field: DeviceAdditionFields) => {
        console.log('from change boolean:', event.target.checked)
		field.value = event.target.checked;
        saveAdditionFields(additionFields)
	}


    return (
        <>
            <hr className="border-dashed my-6" />
            <p className="h5">
                Дополнительные поля устройства
            </p>
            {additionFields.map((field : DeviceAdditionFields) => {
                if (field.type === 'boolean') {
                    return (
                        <BooleanField
                            key={`${field.name}-bfield`}
                            field={field}
                            onChangeBooleanField={onChangeFieldBoolean}
                        />
                    )    
                }
                if (field.type === 'email') {
                    return (
                        <EMailField
                            key={`${field.name}-efield`}
                            field={field}
                            onChangeField={onChangeField}
                        />
                    )    
                }
                return (
                    <SimpleTextField
                        key={`${field.name}-tfield`}
                        field={field}
                        onChangeField={onChangeField}
                    />
                )
            })

            }
        </>
    );
}

export default AddupdateAdditionFields;
import Nav from '../nav';


export default function NotAccessPage() {
    return (
        <>
            <Nav />
            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">

                    <div className="text-3xl">
                        Недостаточно прав для доступа к данной странице
                    </div>

                </div>
            </section>

        </>
    );
}
import Device from "../../../../model/device";
import { getOnlineIndicatorClass } from "../../../../utils";

const onlineLabels = {
    'offline': 'Не в сети',
    'online': 'В сети'
}

const Footer = (props: { device: Device }) => {
    const mode = getOnlineIndicatorClass(props.device)
    const label = onlineLabels[mode]

    return <div className="p-1">
        <div className="flex items-center justify-between">
            <div className="text-[9px] text-gray-400 font-light truncate">{props.device?.last_parameter_values?.updated_at ?? ''}</div>
            <div className="text-[9px] leading-none truncate">
                <span className={`widget-data-indicator-${mode} leading-1`}>&#9679;</span><span className="text-gray-500 font-light"> {label}</span>
            </div>
        </div>
    </div>;
}

export default Footer;

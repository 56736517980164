import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { Divider, Grid } from '@mui/material';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import logout from "../api/user/logout";
import LoginForm from './loginform/main';
import Token from './token_v2';
import EcoModal from './eco_modal';

function ResponsiveAppBar(props) {
    const navigate = useNavigate();

    const token = new Token()
    const [showLoginForm, setShowLoginForm] = useState(false);

    const logoutClick = () => {
        logout(() => {
            navigate('/')
        })
    }


    const LoginModalForm = () => {
        return (
            <EcoModal
                close={() => setShowLoginForm(false)}
                showRequestForm={() => props.showRequestForm(false)}

                key="aisettings-car-modal"
                open={showLoginForm}
                handleClose={() => setShowLoginForm(false)}
                content={LoginForm}
                title={``}

            />
        )
    }

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        console.log(event.currentTarget)
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const goToMap = () => {
        window.open("/map");
    }

    const goToContacts = () => {
        navigate('/#contacts')
        const contacts = document.getElementById("contacts");
        contacts && contacts.scrollIntoView();
    }

    const goToLK = () => {
        navigate('/lk')
    }

    const buttonSx = { my: 2, color: 'white', display: 'block', "&:hover": { backgroundColor: "transparent" } };

    return (
        <AppBar position="static" className='w-full !bg-navtop text-2xl'>
            <Grid container>
                <Grid item xs={1} />
                <Grid item xs={10} >
                    <Toolbar disableGutters className='w-full'>

                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'Montserrat',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                textDecoration: 'none',
                            }}
                        >
                            <img src="/brand_image.svg" alt="" />
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="primary"
                                className="color-white"
                            >
                                <MenuIcon className='text-white' />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                                disableScrollLock={true}
                            >

                                <MenuItem key={'key-main'} onClick={() => navigate('/')}>
                                    <Typography textAlign="center" className='font-montserrat'>ГЛАВНАЯ</Typography>
                                </MenuItem>
                                <MenuItem key={'key-map'} onClick={goToMap}>
                                    <Typography textAlign="center" className='font-montserrat'>МОНИТОРИНГ 4.0</Typography>
                                </MenuItem>
                                <MenuItem key={'key-contacts'} onClick={goToContacts}>
                                    <Typography textAlign="center" className='font-montserrat'>КОНТАКТЫ</Typography>
                                </MenuItem>
                                <MenuItem key={'key-request'} onClick={() => props.showRequestForm(true)}>
                                    <Typography textAlign="center" className='font-montserrat'>ОСТАВИТЬ ЗАЯВКУ</Typography>
                                </MenuItem>
                                {token.getToken() &&
                                    <MenuItem key={'key-lk'} onClick={goToLK}>
                                        <Typography textAlign="center" className='font-montserrat'>ЛИЧНЫЙ КАБИНЕТ</Typography>
                                    </MenuItem>
                                }
                            </Menu>
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'Montserrat',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src="/brand_image.svg" alt="" />
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="w-full md:w-auto"
                        >
                            <Grid container
                                alignItems="center"
                                justifyContent="center"
                                display="flex">
                                <Grid item xs={2}>
                                    <Button
                                        key={`key-main`}
                                        onClick={() => navigate('/')}
                                        sx={buttonSx}
                                        className="font-montserrat w-full text-base"
                                    >
                                        ГЛАВНАЯ
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        key={`key-map`}
                                        onClick={goToMap}
                                        sx={buttonSx}
                                        className="font-montserrat w-full text-base"
                                    >
                                        МОНИТОРИНГ 4.0
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        key={`key-contacts`}
                                        onClick={goToContacts}
                                        sx={buttonSx}
                                        className="font-montserrat w-full text-base"
                                    >
                                        КОНТАКТЫ
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        key={`key-request`}
                                        onClick={() => props.showRequestForm(true)}
                                        sx={buttonSx}
                                        className="font-montserrat w-full text-base"
                                    >
                                        ОСТАВИТЬ ЗАЯВКУ
                                    </Button>
                                </Grid>

                                {token.getToken() &&
                                    <Grid item xs={2}>
                                        <Button
                                            key={`key-lk`}
                                            onClick={goToLK}
                                            sx={buttonSx}
                                            className="font-montserrat w-full text-base"
                                        >
                                            ЛИЧНЫЙ КАБИНЕТ
                                        </Button>
                                    </Grid>
                                }
                            </Grid>

                        </Box>

                        {token.getToken() &&
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Личный кабинет">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt={localStorage.getItem('user.login')} src="/img.png" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    disableScrollLock={true}

                                    sx={{ mt: '45px', position: 'absolute', right: '0px' }}
                                    id="user-menu-appbar"
                                    anchorEl={anchorElUser}

                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <Typography textAlign="center" className='mx-4 font-montserrat'>{localStorage.getItem('user.login')}</Typography>
                                    <Divider />
                                    <MenuItem component="a" href="/profile" className="font-montserrat">
                                        Профиль
                                    </MenuItem>
                                    <MenuItem component="a" href="/admin/devices" className="font-montserrat">
                                        Мои устройства
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem key={'logout-key'} onClick={logoutClick}>
                                        <Typography textAlign="center" className='font-montserrat'>Выйти</Typography>
                                    </MenuItem>

                                </Menu>
                            </Box>
                        }
                        {!token.getToken() &&
                            <Box sx={{ flexGrow: 0 }}>
                                <a href="#" onClick={() => setShowLoginForm(true)}><img src="/login_button.svg" /></a>
                            </Box>
                        }
                    </Toolbar>
                </Grid>
                <Grid item xs={1}>
                </Grid>
            </Grid>

            <LoginModalForm />

        </AppBar>
    );
}
export default ResponsiveAppBar;
import { Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import loadRoiForDevice from "../../../api/devices/load.roi";
import { AiCameraRoi } from "../../../model/ai.camera.roi";
import EcoModal from "../../eco_modal";
import NoItems from "../../NoItems";
import EditRoiForm from "./EditRoiForm";
import RoisTable from "./RoisTable";

export interface RoiProps {
    deviceId: string | undefined;
}

const RoiTab = ({ deviceId }: RoiProps) => {

    const [areas, setAreas] = useState<AiCameraRoi[]>([])
    const [showEditRoiForm, setShowEditRoiForm] = useState<boolean>(false)
    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');
    const [roiForEdit, setRoiForEdit] = useState<AiCameraRoi>(new AiCameraRoi);

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };

    const afterSaveSuccess = () => {
        notify('Сохранение прошло успешно.')
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    const showAddRoiFormClick = () => {
        setShowEditRoiForm(true)
    }

    const showEditRoiFormClick = (area: AiCameraRoi) => {
        setRoiForEdit(area)
        setShowEditRoiForm(true)
    }

    useEffect(() => {
        if (deviceId) {
            const fetchData = async () => {
                const roiAreas = await loadRoiForDevice(deviceId)
                setAreas(roiAreas)
            }

            fetchData()
        }
    }, [])

    useEffect(() => {

        if (deviceId) {
            roiForEdit.device_id = deviceId
        }
    }, [roiForEdit])

    return (
        <>
            {deviceId &&
                <>
                    <button className="lr-primary-button mb-3 align-bottom" onClick={showAddRoiFormClick}>
                        Добавить
                    </button>
            
                    {areas.length > 0 &&
                        <>
                            <RoisTable 
                                areas={areas} 
                                clickEditRoi={showEditRoiFormClick}
                            />
                        </>
                    }
                    {areas.length === 0 &&
                        <>
                            <NoItems text="Нет добавленных зон" />
                        </>
                    }
                </>
            }
            <EcoModal
                key="addupdate-modal"
                open={showEditRoiForm}
                roiForEdit={roiForEdit}
                handleClose={() => setShowEditRoiForm(false)}
                content={EditRoiForm}
                title={`Редактирование информации о параметре`}
                afterSaveSuccess={afterSaveSuccess}
            />
           
            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />


        </>
    );
}

export default RoiTab;
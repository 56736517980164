import React, { Component } from 'react';
import axios from "axios";
import * as consts from '../../../consts';
import { toast } from 'react-toastify';
import * as utils from '../../../utils';
import getHeaders from '../../../api/headers';
import { FormControlLabel, Switch, FormGroup, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import AddupdateAdditionFields from '../device/AddupdateAdditionFields'
import saveAdditionFields from '../../../api/devices/save.addition.fields';

class AddUpdateForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSaving: false,
			...props.device,
		}
	}


	saveResource = () => {
		// Validate data.
		if (!utils.isNumeric(this.state.latitude)) {
			toast.error("Необходимо корректно заполнить поле широты.")
			return
		}
		if (!utils.isNumeric(this.state.longitude)) {
			toast.error("Необходимо корректно заполнить поле долготы.")
			return
		}

		// Save data.
		this.setState({ isSaving: true })
		const formData = new FormData();
		formData.append("name", this.state.name);
		formData.append("description", this.state.description);
		formData.append("id", this.state.id_device ? this.state.id_device : -1);
		formData.append("latitude", this.state.latitude);
		formData.append("longitude", this.state.longitude);
		formData.append("device_type_id", this.state.device_type_id);
		formData.append("location_description", this.state.location_description);
		formData.append("serial_number", this.state.serial_number);
		formData.append("is_public", this.state.is_public);
		formData.append("is_allow_download", this.state.is_allow_download);
		formData.append("is_verified", this.state.is_verified);
		formData.append("file_format", this.state.file_format); // For seismostation

		formData.append("module_type_id", this.state.module_type_id);
		formData.append("tz", this.state.tz);
		formData.append("time_not_online", this.state.time_not_online);


		try {
			const headers = getHeaders()
			headers["Content-Type"] = "multipart/form-data"
			axios({
				method: "post",
				url: `${consts.BASE_API_URL}/update_device_info`,
				data: formData,
				headers,
			})
				.then((response) => {
					if (response.data.result === 'ok') {
						toast.success("Информацию об устройстве обновлена!")
						this.props.handleClose()
						this.setState({ isSaving: false })

						if (!this.props.notReload) {
							setTimeout(() => {
								window.location.reload()
							}, 1000);
						}
					}

					if (this.state.additionFields) {
						saveAdditionFields(this.state.additionFields, this.state.id_device)
					}
				}).catch((error) => {
					this.setState({ isSaving: false })
					toast.error("Произошла ошибка при сохранении!")
					if (error.response) {
						console.log(error.response)
						console.log(error.response.status)
						console.log(error.response.headers)
					}
				});
		} catch (error) {
			console.log(error)
		}
	}

	changePublicProperty = (event) => {
		const isPublic = event.target.checked ? 1 : 0;
		this.setState({ is_public: isPublic })
	}

	changeDownloadProperty = (event) => {
		const isAllowDownload = event.target.checked ? 1 : 0;
		this.setState({ is_allow_download: isAllowDownload })
	}

	changeVerifiedProperty = (event) => {
		const isVerified = event.target.checked ? 1 : 0;
		this.setState({ is_verified: isVerified })
	}

	updateAdditionFields = (fields) => {
		this.setState({additionFields: fields})
	}

	render() {
		return (
			<>
				<div>
					<FormControl fullWidth>
						<InputLabel id="device_type_id-select-label">Тип устройства</InputLabel>

						<Select
							size='small'
							labelId="device_type_id-select-label"
							name="device_type_id"
							value={this.state.device_type_id}
							label="Тип устройства"
							onChange={(event) => this.setState({ device_type_id: parseInt(event.target.value) })}
						>
							{Object.keys(consts.DEVICE_TYPES).map((type, i) => {
								return (
									<MenuItem key={`device-type-${i}`} value={`${consts.DEVICE_TYPES[type]}`}>{consts.DEVICE_INFO[consts.DEVICE_TYPES[type]].name}</MenuItem>
								)
							})}
						</Select>
					</FormControl>
					{this.state.device_type_id === consts.DEVICE_TYPES.MODULE &&
						<FormControl fullWidth className="mt-5">
							<InputLabel id="module_type_id-select-label">Тип модуля</InputLabel>

							<Select
								size='small'
								labelId="module_type_id-select-label"
								name="module_type_id"
								value={this.state.module_type_id}
								label="Тип модуля"
								onChange={(event) => this.setState({ module_type_id: parseInt(event.target.value) })}
							>
								{Object.keys(consts.MODULE_TYPES).map((type, i) => {
									return (
										<MenuItem  key={`module-type-${i}`} value={`${consts.MODULE_TYPES[type]}`}>{consts.MODULE_INFO[consts.MODULE_TYPES[type]].name}</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					}
					{this.state.device_type_id === consts.DEVICE_TYPES.SEISMICSTATION &&
						<FormControl fullWidth className="mt-5">
							<InputLabel id="module_type_id-select-label">Тип сейсмостанции</InputLabel>

							<Select
								size='small'
								labelId="file_format-select-label"
								name="module_type_id"
								value={this.state.file_format}
								label="Тип сейсмостанции"
								onChange={(event) => this.setState({ file_format: event.target.value })}
							>
								
								<MenuItem value={`CMG_3ESP`}>CMG-3ESP</MenuItem>
								<MenuItem value={`CMG_6TD`}>CMG-6TD</MenuItem>
							</Select>
						</FormControl>
					}
					<TextField
						className="mt-5 shadow-none"
						fullWidth
						name="name"
						value={this.state.name}
						size="small"
						label="Название"
						onChange={(event) => this.setState({ name: event.target.value })}
						required
						inputProps={{ style: { boxShadow: '0 0 #0000' } }}
						
					/>

					<TextField
						className="mt-5"
						fullWidth
						name="description"
						value={this.state.description}
						size="small"
						label="Описание"
						onChange={(event) => this.setState({ description: event.target.value })}
						inputProps={{ style: { boxShadow: '0 0 #0000' } }}
					/>
					<TextField
						className="mt-5"
						fullWidth
						name="serial_number"
						value={this.state.serial_number}
						size="small"
						label="Серийный номер"
						onChange={(event) => this.setState({ serial_number: event.target.value })}
						inputProps={{ style: { boxShadow: '0 0 #0000' } }}
					/>
					<TextField
						className="mt-5"
						fullWidth
						name="location_description"
						value={this.state.location_description}
						size="small"
						label="Местоположение"
						onChange={(event) => this.setState({ location_description: event.target.value })}
						inputProps={{ style: { boxShadow: '0 0 #0000' } }}
					/>
					<TextField
						className="mt-5"
						fullWidth
						name="latitude"
						value={this.state.latitude}
						size="small"
						label="Широта"
						onChange={(event) => this.setState({ latitude: event.target.value })}
						inputProps={{ style: { boxShadow: '0 0 #0000' } }}
						required
					/>
					<TextField
						className="mt-5"
						fullWidth
						name="longitude"
						value={this.state.longitude}
						size="small"
						label="Долгота"
						onChange={(event) => this.setState({ longitude: event.target.value })}
						required
						inputProps={{ style: { boxShadow: '0 0 #0000' } }}
					/>
					<TextField
						inputProps={{ inputMode: 'numeric', style: { boxShadow: '0 0 #0000' } }}
						type="number"
						className="mt-5"
						fullWidth
						name="tz"
						value={this.state.tz}
						size="small"
						label="Временная зона устройства"
						onChange={(event) => this.setState({ tz: event.target.value })}
					/>

					<TextField
						inputProps={{ inputMode: 'numeric', style: { boxShadow: '0 0 #0000' } }}
						type="number"
						className="mt-5"
						fullWidth
						name="time_not_online"
						value={this.state.time_not_online}
						size="small"
						label="Время, после которого считать устройство не в онлайн (в минутах)"
						onChange={(event) => this.setState({ time_not_online: event.target.value })}
					/>
					<FormGroup className="my-3">
						<FormControlLabel control={<Switch onChange={this.changePublicProperty} checked={this.state.is_public === 1} />} label="Публичность устройства" />
					</FormGroup>
					<FormGroup className="my-3">
						<FormControlLabel control={<Switch onChange={this.changeDownloadProperty} checked={this.state.is_allow_download === 1} />} label="Разрешить скачивать данные" />
					</FormGroup>
					<FormGroup className="my-3">
						<FormControlLabel control={<Switch onChange={this.changeVerifiedProperty} checked={this.state.is_verified === 1} />} label="Сертифицированное устройство" />
					</FormGroup>
				</div>
				
				<AddupdateAdditionFields 
					deviceId={this.state.id_device}
					saveAdditionFields={this.updateAdditionFields}
				/>
				<hr className="border-dashed my-6" />
				<p className='text-right'>
					{!this.props.hideCloseButton &&
					<button className="lr-secondary-button" onClick={this.props.handleClose}>
						Закрыть
					</button>
					}
					<button className="ml-5 lr-primary-button" onClick={this.saveResource}
						disabled={this.state.isSaving}

					>
						{this.state.isSaving ? 'Сохранение...' : 'Сохранить'}
					</button>
				</p>
			</>
		);
	}
}

export default AddUpdateForm;

import axios from "axios";
import { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import checkDownloadAccess from '../../../api/devices/check.download.access';
import getHeaders from '../../../api/headers';
import * as consts from '../../../consts';
import EcoModal from "../../eco_modal";
import ChartPreviewModal from './chart_preview_modal';
import SaveExcelDialog from './saver/SaveExcelDialog';


class ChooserSaverFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showChartPreviewModal: false,
			showSavePreviewModal: props.show,
			isCheckingAccess: true,
			allNotAccessed: false,
			someNotAccessed: false,
			allAccessed: false,
			excelDownloading: false,
		}

		this.checkAccessForDownloading(this.props.data_checked)
	}

	checkAccessForDownloading = async (dataChecked) => {
		const checked = Object.keys(dataChecked).filter(key => dataChecked[key]).join(",");
		if (!checked) {
			this.setState({
				isCheckingAccess: false,
			})
			return
		}
		const result = await checkDownloadAccess(checked)

		let allNotAccessed = true
		let someNotAccessed = false
		let allAccessed = true
		Object.keys(result).forEach((device_id) => {
			if (result[device_id] === true) {
				allNotAccessed = false
			} else {
				someNotAccessed = true
				allAccessed = false
			}
		})

		this.setState({
			allNotAccessed,
			isCheckingAccess: false,
			someNotAccessed,
			allAccessed,
		})
	}

	handleCloseChartPreviewModal = () => {
		this.setState({ showChartPreviewModal: false })
	}


	UNSAFE_componentWillReceiveProps(nextProps) {
		// Load info in case device id is changed.
		this.setState({ showSavePreviewModal: nextProps.show })

		this.checkAccessForDownloading(nextProps.data_checked)

	}

	saveDataToFile = () => {
		this.setState({excelDownloading: true})
		const checked = Object.keys(this.props.data_checked).filter(key => this.props.data_checked[key]);
		const headers = getHeaders()

		axios.post(
			`${consts.BASE_API_URL}/load_excel_file`,
			{
				checked,
				dt_start: this.props.dt_start,
				dt_end: this.props.dt_end,
				timeoffset: new Date().getTimezoneOffset() / 60,
				ag_type: this.props.current_ag_type,
			},
			{ responseType: 'blob', headers }
		)
			.then((response) => {
				this.setState({excelDownloading: false})

				let fileName = response.headers["content-disposition"].split("filename=")[1];
				if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
					window.navigator.msSaveOrOpenBlob(new Blob([response.data],
						{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
					),
						fileName
					);
				} else {
					const url = window.URL.createObjectURL(new Blob([response.data],
						{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download',
						response.headers["content-disposition"].split("filename=")[1]);
					document.body.appendChild(link);
					link.click();
				}
			})
	}

	saveDataToPngFile = () => {
		this.setState({ showChartPreviewModal: true })
	}

	closeModal = () => {
		this.setState({ showSavePreviewModal: false })
		this.props.onClose()
	}

	render() {

		return (
			<>
				<ReactTooltip
					aria-haspopup='true' effect='solid'
					backgroundColor='#fff'
					className="cc-toolip-dates"
					clickable={true}
					padding="1px 10px"
					offset={{ top: -10, left: 0 }}
					globalEventOff='click'
				>
					<div className="btn-date-period" onClick={() => this.saveDataToFile()}>Таблицей (xlsx)</div>
					<div className="btn-date-period" onClick={() => this.saveDataToPngFile()}>Изображением (png)</div>
					<div></div>
				</ReactTooltip>

				<ChartPreviewModal
					show={this.state.showChartPreviewModal}
					handleClose={this.handleCloseChartPreviewModal}
					chartOptions={this.props.chartOptions}
					data={this.props.data}
					dt_start={this.props.dt_start}
					dt_end={this.props.dt_end}
				/>

				<EcoModal
					open={this.state.showSavePreviewModal}
					content={SaveExcelDialog}
					title={`Сохранение данных с устройств`}
					saveDataToFile={this.saveDataToFile}
					saveDataToPngFile={this.saveDataToPngFile}
					dtStart={this.props.dt_start}
					dtEnd={this.props.dt_end}
					onClose={this.props.onClose}
					handleClose={this.closeModal}
					isCheckingAccess={this.state.isCheckingAccess}
					allNotAccessed={this.state.allNotAccessed}
					allAccessed={this.state.allAccessed}
					excelDownloading={this.state.excelDownloading}	
				/>

			</>
		);
	}
}
export default ChooserSaverFile;



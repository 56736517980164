import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { BsFillTrashFill } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';


class Mission {
    name: string = '';
    type: string = '';
    distance: string = '';
    time: string = '';
    screenshot: string = '';
}

const missions = [
    {
        name: 'Съемка акватории Петрозаводской губы',
        type: 'Полет по маршруту',
        distance: '10 км',
        time: '3 часа, 10 минут',
        screenshot: 'mission_1.png'
    },
    {
        name: 'Замеры в ботаническом саду',
        type: 'Ортофотосъемка',
        distance: '7.4 км',
        time: '2 часа, 34 минут',
        screenshot: 'mission_2.png'

    }
]


const MisstionsTable = () => {

    return (
        <>

            <TableContainer className="mx-auto" component={Paper}>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell key="th-image"></TableCell>
                            <TableCell key="th-name">Название</TableCell>
                            <TableCell key="th-type">Тип</TableCell>
                            <TableCell key="th-params">Параметры маршрута</TableCell>
                            <TableCell key="th-actions"></TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody className="min-h-[275px]">
                        {missions.map((mission: Mission, i: number) => {
                            return (
                                <TableRow key={`${i}`}>
                                    <TableCell key={`image-${i}`}>
                                        <img src={`/missions/${mission.screenshot}`} width="100px" />
                                    </TableCell>
                                    
                                    <TableCell key={`name-${i}`}>
                                        <>{mission.name}</>
                                    </TableCell>
                                    <TableCell key={`type-${i}`}>
                                        <>{mission.type}</>
                                    </TableCell>
                                    <TableCell key={`params-${i}`}>
                                        <>Дистанция: {mission.distance}</><br />
                                        <>Оценка времени: {mission.time}</>

                                    </TableCell>
                                    <TableCell key={`actions-${i}`}>
                                        <Tooltip title="Редактировать информацию об устройстве">
                                            <div className="inline-block">
                                                <FaEdit className="text-2xl inline cursor-pointer" />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Удалить устройство">
                                            <div className="inline-block">
                                                <BsFillTrashFill className="text-2xl inline text-red-500 cursor-pointer" />
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}

export default MisstionsTable;
import { Grid } from "@mui/material";
import LeftPart from "./left_part";

const CoverPage5 = () => {


    return (

        <Grid container spacing={2}>
            <Grid item xs={1}></Grid>

            <LeftPart />

            <Grid xs={4}>
                <img src="/landing/assets/img/header_05.png" className="w-full" />
            </Grid>
            <Grid xs={3}>
                <div className="mr-0 text-right">
                    <p className="cover-header">
                        05 | Анализ данных
                    </p>
                    <p className="cover-text">
                        Проводим научный<br />
                        анализ данных<br />
                        и полевые исследования<br />
                    </p>
                    <p className="more-link-text">
                        <a className="more-link-text" href="#part05">Узнать подробнее</a>
                    </p>
                </div>
            </Grid>
            <Grid item xs={1}></Grid>

        </Grid>
    );
}

export default CoverPage5
export interface NoItemsProps {
    text: string;
    children?: React.ReactNode;
}

const NoItems = ({ text, children }: NoItemsProps) => {
    return (
            <div className="flex flex-col text-lr-gray-secondary-1 font-light justify-center items-center w-full h-[70vh]">
                    <span>{text}</span>
                    {children}
            </div>
    );
};

export default NoItems;
import { Component } from 'react';
import Param from './city_parameter';
import { Grid } from '@mui/material';

class BaseStation extends Component {

	render() {
		return (
			<Grid container spacing={1}>
				<Param
					icon="/widgets_icons/temperature.svg"
					value={this.props.info.last_parameter_values.air_temp}
					unitClass="city-parameter-units-lg"
					units="°"
					data_key="air_temp"
				/>

				<Param
					icon="/widgets_icons/hum.svg"
					value={this.props.info.last_parameter_values.air_hum}
					units="%"
					divClass="ecosystem-center-data"
					data_key="air_hum"
				/>

				<Param
					icon="/widgets_icons/lora_status.svg"
					value=""
					units=""
					paramType="string"
					divClass="ecosystem-right-data"
					data_key="lora_status"
				/>

			</Grid>
		);
	}
}
export default BaseStation;


const getBarChartOptions = (birdNames, birdConfidence) => {
    return {
        title: {
            text: 'Последние распознанные объекты'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: {
            type: 'value',
            position: 'top',
            splitLine: {
                lineStyle: {
                    type: 'dashed'
                }
            },
            min: 0,
            max: 1,
        },
        yAxis: {
            type: 'category',
            axisLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: birdNames,
            animationDuration: 300,
            animationDurationUpdate: 300
        },
        series: [
            {
                name: 'Вероятность',
                type: 'bar',
                stack: 'Total',
                label: {
                    show: true,
                    formatter: '{b}'
                },
                data: birdConfidence,
                barCategoryGap: '3%',
                barWidth: '30'

            }
        ]
    };
}

export default getBarChartOptions
import L from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import './patched-contour';
import { calculateConcentrationModelData, convertModelPointToLatLng } from "../concentration-model/concentration-model";
import { LeafletConcentrationMapConfig } from "./ConcentrationMapConfig";

export const DEFAULT_MIN_VISIBLE_CONCENTRATION = 0.000002;
export const DEFAULT_MAX_CONCENTRATION = 0.01;

const colors = ['#ffffb350', '#f2ce6d80', '#f7a34f70', '#e8616180'] as const;
export const defaultConcentrationLevels: LeafletConcentrationMapProps['parameters']['levels'] = [DEFAULT_MIN_VISIBLE_CONCENTRATION, 0.00002, 0.00004, 0.00006, 0.006];

export const concentrationColorByVal = (_levels: number[], colors: string[],  val: number): string => {
    const sortedLevels = [..._levels].sort((a, b) => a - b);

    for (let i = 0; i < sortedLevels.length - 2; i++) {
        if (val >= sortedLevels[i] && val < sortedLevels[i + 1]) {
            return colors[i];
        }
    }

    return colors.at(-1) as string;
}

export type LeafletConcentrationMapProps = {
    parameters: LeafletConcentrationMapConfig;
    position: L.LatLngLiteral;
}


const replaceBordersWithNull = (arr: (number | null)[][], widthLeft: number, widthRight: number): number[][] => {
    return arr.map((ar, rowIndex) => {
        if (rowIndex < widthLeft || rowIndex >= arr.length - widthRight) {
            // Если текущая строка входит в границы, заполняем её полностью null
            return new Array(ar.length).fill(null as unknown as number);
        } else {
            // Иначе обрабатываем каждый элемент текущей строки
            return ar.map((a, colIndex) => {
                if (colIndex < widthLeft || colIndex >= ar.length - widthRight) {
                    // Если текущая колонка входит в границы, делаем элемент null
                    return null as unknown as number;
                } else {
                    // Иначе оставляем элемент без изменений
                    return a;
                }
            });
        }
    });
}

function nonzero(data: (number | null)[][]): number[] {
    const result: number[] = [];

    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
            if (data[i][j] !== null) {
                result.push(data[i][j] as number);
            }
        }
    }

    return result;
}


function percentile(data: number[], percent: number): number {
    if (data.length === 0) throw new Error("The data array cannot be empty.");
    if (percent < 0 || percent > 100) throw new Error("Percent must be between 0 and 100.");

    data.sort((a, b) => a - b);
    const rank = (percent / 100) * (data.length - 1);
    const lowerIndex = Math.floor(rank);
    const upperIndex = Math.ceil(rank);
    const weight = rank - lowerIndex;

    if (lowerIndex === upperIndex) {
        return data[lowerIndex];
    }

    return data[lowerIndex] * (1 - weight) + data[upperIndex] * weight;
}

const min = (data: number[]) => {
    if (!data.length) return null;

    let minimum = data[0];
    for (let i = 1; i < data.length; i++) {
        if (minimum > data[i]) {
            minimum = data[i]
        }
    }

    return minimum;
}

const max = (data: number[]) => {
    if (!data.length) return null;

    let maximum = data[0];
    for (let i = 1; i < data.length; i++) {
        if (maximum < data[i]) {
            maximum = data[i]
        }
    }

    return maximum;
}

function onEachContour() {
    return function onEachFeature(feature: { value: number }, layer: L.Layer) {
        layer.bindPopup(
            `<div>
              <span class="text-xs font-light text-gray-400 ">Примерное содержание:</span> <br />  ${feature.value}
            </div>`
        );
    };
}

const LeafletConcentrationMap = (props: LeafletConcentrationMapProps) => {
    const map = useMap();

    useEffect(() => {
        try {
            let [xx, yy, cc] = calculateConcentrationModelData(props.parameters, 0);

            const data = nonzero(cc);

            const lev = [min(data), percentile(data, 25), percentile(data, 50), percentile(data, 75), max(data)] as [number, number, number, number, number];

            const latLng = xx.map((row, i) =>
                row.map((x, j) => convertModelPointToLatLng(x, yy[i][j], props.position.lat, props.position.lng, props.parameters.directionAngle))
            );

            let lat = latLng.map(row => row.map(point => point.lat));
            let lng = latLng.map(row => row.map(point => point.lng));

            // // Add contour (https://github.com/JamesRunnalls/leaflet-contour/tree/master?tab=readme-ov-file#data)
            cc = replaceBordersWithNull(cc, 1, 2);


            const contour = (L as any).contour({ x: lng, y: lat, z: cc }, {
                thresholds: [...lev].reverse(),
                style: (feature: any) => ({
                    color: concentrationColorByVal(lev, colors as unknown as string[], feature.geometry.value),
                    opacity: 0,
                    fillOpacity: 1,
                }),
                onEachFeature: onEachContour(),
            }).addTo(map);

            return () => {
                contour.remove();
            }
        } catch (error) {
            console.log(error);
        }
    }, [props.parameters, props.position])

    return <></>;
}

export default LeafletConcentrationMap;

import React from "react";
import { YMaps, Map, TypeSelector, Button, Placemark, ListBox, ListBoxItem } from "@pbe/react-yandex-maps";
import * as consts from '../../consts';
import {  iconCamera, iconMeteo, iconAudio, iconSoil, iconCity, iconPlant  } from './icons';


class YandexMap extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
	    devices: [],
	    bounds: [[]],
	    updateBounds: false,
	}

	this.icons = {
	    [consts.DEVICE_TYPES.METEO]: iconMeteo,
	    [consts.DEVICE_TYPES.MODULE]: iconMeteo,
	    [consts.DEVICE_TYPES.CAMERA]: iconCamera,
	    [consts.DEVICE_TYPES.AUDIO]: iconAudio,
	}
	this.moduleIcons = {
	    [consts.MODULE_TYPES.SOIL]: iconSoil,
	    [consts.MODULE_TYPES.UE1]: iconCity,
	    [consts.MODULE_TYPES.BASECAM]: iconPlant,
	}
	this.ymaps = undefined;
	this.myMap = undefined;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

	let bounds = [];
	if (nextProps.devices.length) {
	    nextProps.devices.forEach((device) => {
		bounds.push([device.latitude, device.longitude])
	    })

	}
	if (this.myMap && !this.state.updateBounds) {
	    // this.myMap.setBounds(bounds, {preciseZoom: true, checkZoomRange: true})
	}

	if (!this.state.updateBounds) {
	    this.setState({
		bounds,
		updateBounds: true,
	    })
	}

	this.setState({
	    devices: nextProps.devices,
	})
    }

    render() {
	return (
	    <YMaps query={{ apikey: process.env.REACT_APP_YANDEX_API_KEY }}
	    >
		<Map 
		    className="map-container"
		    defaultState={{ 
			//bounds: this.state.bounds,
			center: [61.84329925920227, 34.385385217494985],
			zoom: 15,
		    }} 
		    options={{
			maxAnimationZoomDifference: Infinity,
		    }}
		    onLoad={(ymaps: any) => {
			this.ymaps = ymaps
		    }}
		    instanceRef={ref => {
			this.myMap = ref
			if (ref && ref.geoObjects.getBounds()) {
			    // ref.setBounds(ref.geoObjects.getBounds(), {preciseZoom: true, checkZoomRange: true, zoomMargin: 9})
			}
			if (ref && this.state.bounds.length > 0) {
			    // ref.setBounds(this.state.bounds, {preciseZoom: true, checkZoomRange: true, zoomMargin: 9})
			    // ref.setBounds(ref.geoObjects.getBounds(), {preciseZoom: true, checkZoomRange: true, zoomMargin: 9})
			}
		    }}
		>
		    <TypeSelector options={{
			float: 'right'
		    }} />
		    <Button options={{maxWidth: 156, float: 'right'}} 
			data={{ content: 'Режим сравнения' }} 
			defaultState={{ selected: false }}
			onClick={this.props.changeCompareMode}
		    />
		    <ListBox 
			data={{content: 'Оборудование'}}
			options={{float: 'right'}}
		    >
			{this.props.devices.map((device, i) => {
			    return <ListBoxItem
					data={{content: device.name}} 
					options={{selectOnClick: false}}
					onClick={ () => {
					    if (this.myMap) {
						this.myMap.setCenter([device.latitude, device.longitude])
					    }
					    this.props.markerClick(device);
					}}
				/>
			})}
		    </ListBox>
		    <Button options={{maxWidth: 156, position: {top: 50, right: 10}, selectOnClick: false}} 
			data={{ content: 'Панель мониторинга' }} 
			defaultState={{ selected: false }}
			onClick={this.props.toggleMonitoringPanel}
		    />
		    {this.props.devices.map((device, i) => {
			let icon = this.icons[device.device_type_id].options.iconUrl
			if (device.device_type_id == consts.DEVICE_TYPES.MODULE && this.moduleIcons[device.module_type_id]) {
			    icon = this.moduleIcons[device.module_type_id].options.iconUrl
			}
			return <Placemark 
			    key={device.device_id}
			    geometry={[device.latitude, device.longitude]} 
			    options={{
				iconLayout: 'default#image',
				iconImageHref: icon,
			    }}
			    onClick={ () => this.props.markerClick(device)}
			/>
		    })}
		</Map>
	    </YMaps>
	)
    }
}


export default YandexMap;

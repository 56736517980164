import { Moment } from 'moment';
import React from 'react';
import { LOCAL_STORAGE_KEYS, SEISMO_DATA_SOURCES } from '../../../../consts';
import SeismicEvent from '../../../../model/seismic.event';
import SeismicDayFiguresGallery from './day_figures';
import SeismicDataSource from './source';

export interface SeismicDeviceProps {
	device_id: number;
	seismic_event?: SeismicEvent;
	updatePointsInfo?: (pintsInfo: any) => void;
	updateEventDate?: (mstime: Moment) => void;
	headerLabel?: string;
	startDate?: Date;
}

const SeismicDevice = ({ device_id, seismic_event, updatePointsInfo, updateEventDate, headerLabel, startDate }: SeismicDeviceProps) => {
	
	const getSelectedDataSource = () => {
		const savedDataSource = localStorage.getItem(LOCAL_STORAGE_KEYS.SEISMO_DATA_SOURCE)

        if (savedDataSource === null) {
            return SEISMO_DATA_SOURCES.seismostation;
        }
        return parseInt(savedDataSource);
    }
	
	
	const [selectedDataSource, setSelectedDataSource] = React.useState(getSelectedDataSource());


	const updateSelectedDataSource = (newValue: string | number) => {
		setSelectedDataSource(typeof newValue === 'number' ? newValue : parseInt(newValue));
		localStorage.setItem(LOCAL_STORAGE_KEYS.SEISMO_DATA_SOURCE, newValue.toString());
	} 

	return (
		<div className='pt-5' key={`seismic-day-figures-gallery-key-${device_id}`}>
			
			<SeismicDataSource 
				currentValue={selectedDataSource} 
				setSelectedDataSource={updateSelectedDataSource}
			/>
			
			<SeismicDayFiguresGallery
				device_id={device_id}
				seismic_event={seismic_event}
				updatePointsInfo={updatePointsInfo}
				updateEventDate={updateEventDate}
				headerLabel={headerLabel}
				startDate={startDate}
				selectedDataSource={selectedDataSource}
			/>
		</div>
	);
}
export default SeismicDevice;



import { FormControlLabel, FormGroup, Snackbar, Switch } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useState } from "react";
import saveUserProfile from "../../../api/devices/save.user.profile";
import { User } from "../../../model/profile";

export interface ProfileSettingsProps {
    user: User;
}


export default function ProfileSettings({ user }: ProfileSettingsProps) {

    const [currentUser, setCurrentUser] = useState<User>(Object.assign({}, user))
    const [isSaving, setIsSaving] = useState<boolean>(false)


    const changePublicProperty = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const _user = Object.assign({}, currentUser)
        _user.is_send_emails_not_devices_link = checked ? 1 : 0;
        setCurrentUser(_user)
    }

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };


    const saveProfile = async () => {
        const data = await saveUserProfile(currentUser)
        if (data) {
            notify('Настройки успешно сохранены.')
        }
    }

    return (
        <div className="pl-16">
            <FormControl fullWidth>
                <FormGroup className="my-3">
                    <FormControlLabel   control={<Switch 
                                                    onChange={changePublicProperty} 
                                                    checked={currentUser.is_send_emails_not_devices_link === 1} />} 
                                        label="Отправлять EMail, когда от устройств нет данных" />
                </FormGroup>
            </FormControl>

            <hr className="border-dashed my-6" />
            <p className='text-right'>
                <button className="ml-5 lr-primary-button" onClick={saveProfile}
                    disabled={isSaving}
                >
                    {isSaving ? 'Сохранение...' : 'Сохранить'}
                </button>
            </p>

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />

        </div>
    );
}
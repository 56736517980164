import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';

const reorderDeviceParameters = async (newOrderIds: number[]) => {
    const ids = newOrderIds.join(',')
    const headers = getHeaders()
    const result = await axios({
		method: "GET",
		url: `${consts.BASE_API_URL}/devices/reorder_device_parameter`,
		headers,
        params: {ids},
	})

    return result
};


export default reorderDeviceParameters
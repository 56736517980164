import { LatLng } from "leaflet";
import { useState } from "react";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";
import MiccionCircles from "./MissionCircles";
import MissionMenu from "./MissionMenu";

export default function MissionEditMain() {
    const [path, setPath] = useState<LatLng[]>([])
    
    return (
        <>
            <MapContainer
                className="map-container"
                center={{ lat: 51.512, lng: -0.09 }}
                zoom={13}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MiccionCircles
                    updatePath={setPath}
                />
                <Polyline pathOptions={{ color: 'red', fillOpacity: 0 }} positions={path} />

            </MapContainer>
            <MissionMenu />

        </>
    );
}



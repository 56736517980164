import { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import * as consts from '../../../consts';
import axios from "axios";
import Chart from './chart'
import moment from 'moment';

import 'react-toastify/dist/ReactToastify.css';

class ChartPreviewModal extends Component {

	saveDataToPngFile = () => {
		const downloadLink = document.getElementById('chart-href-for-downloading');
		downloadLink.target = '_self';
		downloadLink.download = this.getPngFileName();
		downloadLink.click();
	}

	getPngFileName = () => {
		const format = 'YYYYMMDDHHmm'
		const dt_start = moment.unix(this.props.dt_start).format(format)
		const dt_end = moment.unix(this.props.dt_end).format(format)
		return `dataecosystems_${dt_start}_${dt_end}.png`
	}

	getTitle = () => {
		const format = 'YYYY-MM-DD HH:mm:ss'
		const dt_start = moment.unix(this.props.dt_start).format(format)
		const dt_end = moment.unix(this.props.dt_end).format(format)
		return `Данные за период ${dt_start} ${dt_end}`
	}

	updateChartOptions = () => {
		let chartOptions = JSON.parse(JSON.stringify(this.props.chartOptions));
		chartOptions.plugins.title = {
			display: true,
			text: this.getTitle(),
			padding: {
				top: 10,
				bottom: 30
			}
		}
		chartOptions.plugins.legend = {
			display: true,
		}
		chartOptions.animation = {
			onComplete: function (event) {
				const url = this.toBase64Image();
				document.getElementById("chart-href-for-downloading").href = url;
			}
		}
		return chartOptions;
	}

	updateChartPlugins = () => {
		return [{
			beforeDraw: (chart, args, options) => {
				const ctx = chart.ctx;
				ctx.save();
				ctx.fillStyle = 'white';
				ctx.fillRect(0, 0, chart.width, chart.height);
				ctx.restore();
			}
		}]
	}

	render() {
		const chartOptions = this.updateChartOptions()
		const chartPlugins = this.updateChartPlugins()

		return (
			<Modal show={this.props.show} onHide={this.props.handleClose} dialogClassName="modal-ku">
				<Modal.Header closeButton>
					<Modal.Title>Предпросмотр результата сохранения графиков</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="chart-dialog">
						<Chart chartOptions={chartOptions} data={this.props.data} plugins={chartPlugins} />
						<a id="chart-href-for-downloading" className="hidden" />
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="ml-5 lr-primary-button" onClick={this.saveDataToPngFile}
					>
						Сохранить
					</button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ChartPreviewModal;



import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import DeviceAlertType from '../../model/device.alert.type';

export const saveAlertType = async (schema: DeviceAlertType) => {
    console.log('save alert type:', schema)

    const headers = getHeaders()
    const data = JSON.parse(JSON.stringify(schema))

    const result = await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/device/alert_type`,
		headers,
        data,
	})

    return result.data.result
};

import { TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useState } from "react";
import saveDevicesGroup from "../../../api/devices/save.devices.group";
import DevicesGroup from "../../../model/devices.group";

interface EditGroupFormProps {
    groupForEdit: DevicesGroup
    handleClose?: () => void,
    afterSaveSuccess?: () => void
}

type AccessTypeOptions = {
    [key: string]: string
}

export const ACCESS_TYPES: AccessTypeOptions = {
    'all': 'Всем',
    'registered': 'Только зарегистрированным',
    'admin': 'Только администратору'

}


export default function AddUpdateForm({ groupForEdit, handleClose, afterSaveSuccess }: EditGroupFormProps) {

    const [group, setGroup] = useState<DevicesGroup>(Object.assign({}, groupForEdit))
    const [saving, setSaving] = useState<boolean>(false)

    const clickSaveGroup = async () => {
        const result = await saveDevicesGroup(group)
        if (result && afterSaveSuccess) {
            afterSaveSuccess()
        }
    }
    return (
        <>
            <>
                {group.name}
            </>

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="name"
                value={group.name}
                size="small"
                label="Название"
                onChange={(event) => {
                    group.name = event.target.value
                    setGroup(Object.assign({}, group))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="label"
                value={group.description}
                size="small"
                label="Описание"
                onChange={(event) => {
                    group.description = event.target.value
                    setGroup(Object.assign({}, group))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <TextField
                className="mt-5 shadow-none"
                fullWidth
                name="short_label"
                value={group.slug}
                size="small"
                label="Ссылка"
                onChange={(event) => {
                    group.slug = event.target.value
                    setGroup(Object.assign({}, group))
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <FormControl fullWidth className="mt-5">
                <InputLabel id="module_type_id-select-label" className="text-xs">Доступ</InputLabel>

                <Select
                    className="mt-1 shadow-none w-full"
                    size='small'
                    labelId="device_type_id-select-label"
                    name="access_type"
                    value={group.access_type}
                    label="Доступ"
                    onChange={(event) => {
                        group.access_type = event.target.value
                        setGroup(Object.assign({}, group))
                    }}
                >
                    {Object.keys(ACCESS_TYPES).map((type: string, i) => {
                        return (
                            <MenuItem key={`device-type-${i}`} value={`${type}`}>{ACCESS_TYPES[type]}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>


            <hr className="border-dashed my-6" />
            <p className='text-right'>
                <button className="lr-secondary-button" onClick={handleClose}>
                    Закрыть
                </button>
                <button className="ml-5 lr-primary-button" onClick={clickSaveGroup}
                    disabled={saving}

                >
                    {saving ? 'Сохранение...' : 'Сохранить'}
                </button>
            </p>
        </>
    );
}
import { useEffect, useState } from 'react';
import AxisChart from '../components/map/devices/seismic/chart';
import Nav from '../components/nav'
import SeismicEvent from '../model/seismic.event';


export default function SeismicEchart() {
    const [startDate, setStartDate] = useState(new Date(1689135682 * 1000)); // 1689135682
    const [selectedFilter, setSelectedFilter] = useState(1);

    const [seismicEvent, setSeismicEvent] = useState<SeismicEvent>(new SeismicEvent)

    useEffect(() => {
        const sEvent = new SeismicEvent()
        sEvent.mstime = new Date(1689135682 * 1000).toLocaleDateString()
    }, [])

    const updatePointsInfo = () => {

    }

    const updateEventDate = () => {

    }

    return (
        <>
            <Nav />
            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AxisChart
                        date={startDate}
                        filter={selectedFilter}
                        device_id={31}
                        seismic_event={seismicEvent}
                        updatePointsInfo={updatePointsInfo}
                        updateEventDate={updateEventDate}
                    />
                </div>
            </section >

        </>
    );
}
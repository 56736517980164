import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AeroArch from './aero_arch';
import AeroMaterials from './aero_materials';
import { isMobile } from 'react-device-detect';

function Landing(props) {
	const margin = isMobile ? 'mx-7' : '';
	const offset = isMobile ? '' : 'row offset-md-1 col-10';

	return (
		<div className={`${offset} f-text-div f-text ${margin}`}>
				<Tabs
					defaultActiveKey="features"
					className="mb-2 border-0"
					variant="tabs"
					justify="true"
					tabClassName="f-text"
				>
					<Tab eventKey="features" title="Наши возможности">
						<AeroArch
							showRequestForm={props.showRequestForm}
							closeRequestForm={props.closeRequestForm}
						/>
					</Tab>
					<Tab eventKey="materials" title="Материалы аэрофотосъёмки">
						<AeroMaterials
							showRequestForm={props.showRequestForm}
							closeRequestForm={props.closeRequestForm}
						/>
					</Tab>
				</Tabs>
		</div>
	);
}

export default Landing;




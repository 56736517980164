import { useEffect, useState } from "react";
import { getAlertsForDevice, getAlertsForUser } from "../../../api/devices/get.alerts";
import DeviceAlert from "../../../model/device.alert";
import Nav from "../../nav";
import NoItems from "../../NoItems";
import AlertsTable from "../device/AlertsTable";
import AdminHeader from "../header";


export interface AlertsProps {
}

const Alerts = ({ }: AlertsProps) => {
    const [alerts, setAlerts] = useState<DeviceAlert[]>([])

    useEffect(() => {
        const fetchData = async () => {
            const alerts = await getAlertsForUser()
            setAlerts(alerts)
        }
        fetchData()

    }, [])


    return (
        <>
            <Nav />

            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AdminHeader headerText={`Оповещения`} />


                    {(!alerts || alerts.length === 0) &&
                        <NoItems text="Нет оповещений" />
                    }

                    {alerts && alerts.length != 0 &&
                        <>
                            <AlertsTable
                                alerts={alerts}
                            />
                        </>
                    }
                </div>
            </section>
        </>
    );
}

export default Alerts;
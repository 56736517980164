import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import * as consts from '../../../consts';
import axios from "axios";
import getHeaders from '../../../api/headers';

import 'react-toastify/dist/ReactToastify.css';

class RemoveForm extends Component {

	removeResource = () => {
		const headers = getHeaders()

		axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/${this.props.endpoint}`,
			headers,
			params: {
				id: this.props.id
			}
		})
			.then((response) => {
				const data = response.data
				if (data.result === 'ok') {
					toast.success(this.props.successLabel)
					this.props.handleClose()
					setTimeout(() => {
						window.location.reload()
					}, 1000);
				} else if (data.result === 'error') {
					const label = data.description ? data.description : 'Произошла ошибка при удалении.'
					toast.error(label)
				}
			}).catch((error) => {
				if (error.response) {
					console.log(error.response)
				}
			})
	}

	render() {
		return (
			<>
				<ToastContainer autoClose={2000} hideProgressBar={false} />
				<Modal show={this.props.show} onHide={this.props.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Удалить?</Modal.Title>
					</Modal.Header>
					<Modal.Footer>
						<button className='lr-secondary-button' onClick={this.props.handleClose}>
							Закрыть
						</button>
						<button className='lr-primary-button' onClick={this.removeResource}>
							Удалить
						</button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}

export default RemoveForm;


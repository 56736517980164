import * as consts from './consts';
import moment from 'moment';

const BASE_API_URL = '/api/v1';

export const getUrlForResource = (resource, thumbnail) => {
	return `${BASE_API_URL}/get_resource?device_id=${resource.device_id}&type=${resource.type}&filename=${resource.filename}&thumbnail=${thumbnail}`
}


export const getUrlForImageResource = (deviceId, filename) => {
	return `${BASE_API_URL}/get_resource?device_id=${deviceId}&type=image&filename=${filename}`
}

export const getUrlForSeismicDayFigure = (device_id, day, axis, selectedFilter) => {
	return `${BASE_API_URL}/get_seismic_figure?device_id=${device_id}&day=${day}&axis=${axis}&filter=${selectedFilter}`
}

export const getUrlForSeismicSparkline = (device_id) => {
	return `${BASE_API_URL}/data/get_last_seismic_sparkline_image?device_id=${device_id}`
}


export const getUrlForPlantPhenologyImage = (deviceId, filename) => {
	return `${BASE_API_URL}/data/get_plant_phenology_image?device_id=${deviceId}&filename=${filename}`
}


export const getUrlForPlantSpectrImage = (deviceId) => {
	return `${BASE_API_URL}/data/get_plant_last_spectr_image?device_id=${deviceId}`
}


export const getUrlForThumbVideo = (resource) => {
	// Change extension.
	let filename = resource.filename;
	let pos = filename.lastIndexOf(".");
	filename = filename.substr(0, pos < 0 ? filename.length : pos) + ".jpg";
	let res = {
		device_id: resource.device_id,
		type: 'image',
		filename,
	}

	return getUrlForResource(res, 0)
}


// Check is numeric.
export const isNumeric = (str) => {
	return !isNaN(str) && !isNaN(parseFloat(str)) && isFinite(str);
}


export const getDeviceTypeLabel = (type_id) => {
	return consts.DEVICE_INFO[type_id].name
}

export const periodTypes = {
	'minutes_5': '5 минут',
	'minutes_15': '15 минут',
	'minutes_30': '30 минут',
	'hour': 'Час',
	'day': 'Сутки',
	'week': 'Неделя',
	'month': 'Месяц',
	'year': 'Год',
	'custom': 'Свой интервал',
}


export const periodSeismoTypes = {
	'minutes_5': '5 минут',
	'minutes_15': '15 минут',
	'minutes_30': '30 минут',
	'hour': 'Час',
}


export const getDtStartForTypePeriod = (type) => {
	let dtStart;
	switch (type) {
		case 'minutes_5':
			dtStart = moment().subtract(5, "minutes");
			break;
		case 'minutes_15':
			dtStart = moment().subtract(15, "minutes");
			break;
		case 'minutes_30':
			dtStart = moment().subtract(30, "minutes");
			break;
		case 'hour':
			dtStart = moment().subtract(1, "hour");
			break;
		case 'day':
			dtStart = moment().subtract(1, "days");
			break;
		case 'week':
			dtStart = moment().subtract(7, "days");
			break;
		case 'month':
			dtStart = moment().subtract(30, "days");
			break;
		case 'year':
			dtStart = moment().subtract(365, "days");
			break;
		case 'custom':
			const savedStartTime = localStorage.getItem('custom_date_start')
			if (savedStartTime) {
				dtStart = moment(savedStartTime, "DD-MM-YYYY HH:mm")
			} else {
				dtStart = moment().subtract(1, "days");
			}
			break;
		default:
			dtStart = moment().subtract(1, "days");
			break;
	}
	return dtStart;
}

export const getDtEndForTypePeriod = (type) => {
	if (type === 'custom') {
		const savedEndTime = localStorage.getItem('custom_date_end')
		if (savedEndTime) {
			return moment(savedEndTime, "DD-MM-YYYY HH:mm").unix()
		}
	}
	return moment().unix()
}

export const getOnlineIndicatorClass = (info) => {
	if (!info.last_parameter_values || !info.last_parameter_values.mstime) {
		return 'offline'
	}


	const datetime = info.last_parameter_values.mstime

	if (!moment(datetime, "YYYY-MM-DD hh:mm:ss").isValid()) {
		return 'offline'
	}
	const minutes = info.time_not_online ? info.time_not_online : 5 * 60

	const timeoffset = new Date().getTimezoneOffset() / 60
	const m_mstime = moment(info?.last_parameter_values?.mstime, "YYYY-MM-DD HH:mm:SS")
	const mstime = m_mstime.subtract(timeoffset, 'hours').add(info?.tz, 'hours')

	const isDeviceOnline = mstime > moment().subtract(minutes, "minutes");

	return isDeviceOnline ? 'online' : 'offline';
}

import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import DevicesGroup from '../../model/devices.group';


const saveDevicesGroup = async (group : DevicesGroup) => {

    const headers = getHeaders()
    const result = await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/devices/save_devices_group`,
		headers,
        data: {
            group
        },
	})

    return result.data
};


export default saveDevicesGroup
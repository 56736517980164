import { useState } from 'react';
import { Form } from 'react-bootstrap';
import Box from '@mui/material/Box';
import { TextField, Typography } from '@mui/material';
import saveParametersForGroup from '../../../api/devices/save.parameters.group';
import DeviceParameter from '../../../model/device.parameter';

interface ChangedRequestFormProps {
    allParameters: { [id: string]: DeviceParameter[]; },
    checkedParameters: any,
    groupId: number,
    handleClose?: () => void,
    afterSaveSuccess?: () => void
}


export default function ChangedParametersForGroupForm({ allParameters, checkedParameters, groupId, handleClose, afterSaveSuccess }: ChangedRequestFormProps) {

    const [checkedParametersForEdit, setCheckedParametersForEdit] = useState<any>(Object.assign({}, checkedParameters))

    const [searchText, setSearchText] = useState<string>('')

    const [saving, setSaving] = useState<boolean>(false)

    const clickSaveParametersToGroup = async () => {
        setSaving(true)
        const data = await saveParametersForGroup(checkedParametersForEdit, groupId)
        setSaving(false)
        if (data && afterSaveSuccess) {
            afterSaveSuccess()
        }
    }

    const changeGroupParameters = (checked: boolean, parameterId: number) => {
        let _checkedDevices = checkedParametersForEdit
        _checkedDevices[parameterId] = checked
        setCheckedParametersForEdit(Object.assign({}, _checkedDevices))
    }

    const selectAll = () => {
        let _checkedDevices = checkedParametersForEdit
        Object.keys(allParameters).map((label) => {
            allParameters[label].map((parameter) => {
                _checkedDevices[parameter.id] = true
            })

        })
        setCheckedParametersForEdit(Object.assign({}, _checkedDevices))
    }

    const deSelectAll = () => {
        setCheckedParametersForEdit(Object.assign({}, {}))
    }

    const invertAll = () => {
        let _checkedDevices = checkedParametersForEdit
        Object.keys(allParameters).map((label) => {
            allParameters[label].map((parameter) => {
                _checkedDevices[parameter.id] = !_checkedDevices[parameter.id]
            })

        })
        setCheckedParametersForEdit(Object.assign({}, _checkedDevices))
    }

    return (
        <>
            <TextField
                className="my-5 shadow-none"
                fullWidth
                value={searchText}
                size="small"
                placeholder='Текст для поиска'
                onChange={(event) => {
                    setSearchText(event.target.value)
                }}
                inputProps={{ style: { boxShadow: '0 0 #0000' } }}
            />

            <div className="pb-5">
                <Typography component='a' onClick={selectAll} className="text-xs cursor-pointer text-lr-green-primary">
                    Выбрать все
                </Typography> |&nbsp;
                <Typography component='a' onClick={deSelectAll} className="text-xs cursor-pointer text-lr-green-primary">
                    Снять выделение у всех
                </Typography>  |&nbsp;
                <Typography component='a' onClick={invertAll} className="text-xs cursor-pointer text-lr-green-primary">
                    Инвертировать
                </Typography>
            </div>
            <Box
                sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 600,
                    overflow: "hidden",
                    overflowY: "scroll",
                }}
            >
                {
                    Object.keys(allParameters).map((label) => {
                        return (<>
                            <span className="text-lg font-extrabold">{label}:</span>
                            {allParameters[label].map((parameter) => {
                                const label = `${parameter.label} (${parameter.name}, ${parameter.units})`
                                const sText = searchText.toLocaleLowerCase()
                                const isVisible = label.toLocaleLowerCase().includes(sText)

                                if (!isVisible) return null

                                return <div className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        label={label}
                                        checked={checkedParametersForEdit[parameter.id]}
                                        onChange={(evnt) => changeGroupParameters(evnt.target.checked, parameter.id)}

                                    />
                                </div>
                            })}
                        </>
                        )

                    })
                }
            </Box>
            <hr className="border-dashed my-2" />
            <p className='text-right'>
                <button className="lr-secondary-button" onClick={handleClose}>
                    Закрыть
                </button>
                <button className="ml-5 lr-primary-button" onClick={clickSaveParametersToGroup}
                    disabled={saving}

                >
                    {saving ? 'Сохранение...' : 'Сохранить'}
                </button>
            </p>
        </>
    );
}
import axios from 'axios';
import * as consts from '../../consts';
import DeviceTypeInfo from '../../model/device.type.info';
import getHeaders from '../headers';


export const getDeviceTypeInfo = async (device_type_id: string | undefined, module_type_id: string | undefined): Promise<DeviceTypeInfo | null> => {

    try {

        const headers = getHeaders()

        const result = await axios({
            method: "GET",
            url: `${consts.BASE_API_URL}/devices/get_device_type_info`,
            headers,
            params: {
                device_type_id,
                module_type_id,
            },
        })

        return result.data as DeviceTypeInfo
    } catch (error: any) {

        if (error.response.status === 401) {
            return null
        }

    }
    return null

};

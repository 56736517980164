import { BsXCircle } from "react-icons/bs";
import { Component } from 'react';
import * as consts from '../../../consts';
import DevicesListBlock from './devices_list_block';

class DevicesListDiv extends Component {
    constructor(props) {
	super(props);
	this.state = {devices: []}
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
	this.setState({
	    devices: nextProps.devices,
	})
    }
  
    render() {
	return (
	    <div className={`devices-list-container ${this.props.show}`}>
		<BsXCircle onClick={this.props.hideDevicesListPanel} className="close-icon"/>
		<div className="header">
		   Оборудование  
		</div>
		{Object.values(consts.DEVICE_TYPES).map( (type) => {
		    return (
			<DevicesListBlock
			    key={type}
			    device_type_name={consts.DEVICE_INFO[type].name}
			    block_name={consts.DEVICE_INFO[type].plural_name}
			    devices={this.state.devices.filter(device => device.device_type_id == type)}
			    deviceClick={this.props.deviceClick}
			    showDeviceOnMap={(device) => this.props.showDeviceOnMap(device)}
			/>
		    )}
		)}
	    </div>
	);
    }
}

export default DevicesListDiv;




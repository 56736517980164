import { Component } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';

class HeaderNavigation extends Component {
	constructor(props) {
		super(props);

		this.leftHeaders = [
			"", " 01 | Мониторинг 4.0", " 02 | Подключение к сети", " 03 | Оборудование", " 04 | Аэромониторинг"
		]

		this.rightHeaders = [
			"02 | Подключение к сети ", "03 | Оборудование ", "04 | Аэромониторинг ", "05 | Анализ данных ", ""
		]
	}

	prev = () => {
		this.props.changeSlide(-1);
	}

	next = () => {
		this.props.changeSlide(1);
	}

	render() {
		const slider = `/landing/masthead relativeheader/header-slider-page${this.props.currentPage + 1}.png`;
		const navCircles = Array.from({ length: 5 }, (_, i) => this.props.currentPage == i ? '_current' : '')

		return (
			<Grid container className="absolute bottom-16">
				<Grid item xs={1}></Grid>

				<Grid xs={3}>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className='pl-0'>

						<div className="cover-right-text header-next-page">
							{this.props.currentPage > 0 &&
								<ArrowBackIosIcon onClick={this.prev} className="cursor-pointer" />
							}
							<span onClick={this.prev} className="header-slide-link">{this.leftHeaders[this.props.currentPage]}</span>
						</div>
					</Box>

				</Grid>

				<Grid xs={12} md={4}>
					<div className="header-slider">
						{navCircles.map((v, i) => {
							const className = i == 0 ? '' : 'header-slider-image';
							return (
								<img
									src={`/landing/header/navigation${v}_circle.png`}
									className={`${className} inline cursor-pointer`}
									onClick={() => this.props.updateSlide(i)}
								/>
							)
						})}
					</div>
				</Grid>


				<Grid xs={3}>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						<div className="cover-left-text header-next-page text-right w-full pr-0">
							<span onClick={this.next} className="header-slide-link">{this.rightHeaders[this.props.currentPage]}</span>
							{this.props.currentPage < this.leftHeaders.length - 1 &&
								<ArrowForwardIosIcon onClick={this.next} className="cursor-pointer" />
							}
						</div>
					</Box>

				</Grid>
				<Grid item xs={1}></Grid>

			</Grid>
		);
	}
}

export default HeaderNavigation;

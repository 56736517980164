import PersonIcon from '@mui/icons-material/Person';
import { Grid } from '@mui/material';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import GridViewIcon from '@mui/icons-material/GridView';

const menuItems = [
    {
        'url': '/profile',
        'title': 'Профиль',
        'icon': PersonIcon
    },
    {
        'url': '/admin/map',
        'title': 'Панель мониторинга',
        'icon': GridViewIcon
    },
    {
        'url': '/admin/devices',
        'title': 'Устройства',
        'icon': DevicesOtherIcon
    },
    {
        'url': '/admin/seismic',
        'title': 'Сейсмособытия',
        'icon': MonitorHeartIcon
    }
]



export default function UserActions() {

    return (
        <div>
            <Grid container spacing={2}>
                {menuItems.map((item, i) => {
                    const Icon = item.icon
                    return (
                        <Grid item xs={3} key={`grid-item-${i}`}>
                            <Link to={`${item.url}`}>

                                <Card className="flex w-100 h-[100%]">

                                    <Icon
                                        className="p-1 text-8xl"
                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography component="div" variant="h6" className='font-montserrat text-lr-green-primary'>
                                                {item.title}
                                            </Typography>
                                            
                                        </CardContent>

                                    </Box>

                                </Card>
                            </Link>
                        </Grid>

                    )
                })}

            </Grid>
        </div>
    );
}
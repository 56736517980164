export enum DeviceAlertOrigType {
    System = 'system',
    User = 'user',
    Seismic = 'seismic'
}

export enum DeviceAlertArchived {
    NotArchived = 0,
    Archived = 1,
}

class DeviceAlert {
    id: number = NaN;
    device_id: number = NaN;
    device_alert_type_id: number = NaN;
    value: string = '';
    date: string = '';
    name: string = '';
    description: string = '';
    device_name: string = '';
    device_alert_type: DeviceAlertOrigType = DeviceAlertOrigType.System;
    archived: DeviceAlertArchived = DeviceAlertArchived.NotArchived;
    alert_url: string = '';
}

export default DeviceAlert;

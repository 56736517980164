import ReactEcharts from 'echarts-for-react';
import { useEffect, useState, useRef } from "react";


export interface PlantInfoProps {
    waves: number[],
    proc: number[],
    wavesValues: number[],
}

const CrownProcChart = ({ waves, proc, wavesValues }: PlantInfoProps) => {


    const chartRef = useRef();

    const [options, setOptions] = useState<any>()


    useEffect(() => {
        const options = createChartOptins()
        setOptions(options)
    }, [])


    const createChartOptins = () => {

        return {
            title: {
                text: 'Распределение значений каналов',
                textStyle: {
                    fontSize: 12,
                }
            },
            xAxis: {
                type: 'category',
                data: waves,
                name: 'Канал',
                nameLocation: 'center',
                nameGap: 25,
            },
            yAxis: {
                type: 'value',
                name: '%',
                nameLocation: 'middle',
                nameGap: 25,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: (params: any) => {
                    const param = params[0]
                    return (
                        `${wavesValues[param.dataIndex]} μW/cm2 <br /> ${param.value} %`
                    );
                },
            },
            series: [
                {
                    data: proc,
                    type: 'bar',
                    itemStyle: {color: '#508104'},
                }
            ]
        };
    }

    return (
        <>
            {options &&
                <ReactEcharts
                    key={`seismic-echart`}
                    option={options}
                    style={{ height: `300px`, width: '100%' }}
                />
            }
        </>
    );
}

export default CrownProcChart;
import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import { CameraImage, CameraImagesRequest } from '../../model/camera.image';


const loadCameraImages =  async (schema: CameraImagesRequest): Promise<CameraImage[]> => {
    const headers = getHeaders()
    const data = JSON.parse(JSON.stringify(schema))

    const result = await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/data/get_camera_list_images`,
		headers,
        data,
	})

    return result.data.images as CameraImage[]
};


export default loadCameraImages
import checkSeismicData from '../../../../api/devices/check.seismic.data.forday';
import { useEffect, useState } from 'react';
import { getUrlForSeismicDayFigure } from '../../../../utils';
import ImageGallery from 'react-image-gallery';
import ResponsiveDatePickers from './datepicker';
import moment from 'moment';
import { Grid } from '@mui/material';
import FilterChooser from './fitler'
import CircularProgress from '@mui/material/CircularProgress';
import AxisChart from './chart'
import RefreshImages from './RefreshImages';
import { SEISMO_DATA_SOURCES, LOCAL_STORAGE_KEYS } from '../../../../consts';

const directions = {
    0: 'Z',
    1: 'E',
    2: 'N',
}

function SeismicDayFiguresGallery(props) {

    const getSelecteFilter = () => {
        const savedDataSource = localStorage.getItem(LOCAL_STORAGE_KEYS.SEISMO_DATA_SOURCE)
        
        if (savedDataSource === null || savedDataSource == SEISMO_DATA_SOURCES.seismostation) {
            return 3
        }

        return 1
    }



    const [images, setImages] = useState([])
    const [startDate, setStartDate] = useState(new Date());

    const [selectedFilter, setSelectedFilter] = useState(getSelecteFilter());
    const [loading, setLoading] = useState(false)

    const [refreshImages, setRefreshImages] = useState(1);

    const [headerLabel, setHeaderLabel] = useState('Данные сейсмостанции за день')


    useEffect(() => {

        const fetchAsix = async () => {
            setLoading(true)
            const day = moment(startDate).format('YYYYMMDD')
            const axies = await checkSeismicData(day, selectedFilter, props.device_id)
            const images = []
            axies.forEach((axis) => {
                const url = getUrlForSeismicDayFigure(props.device_id, day, axis, selectedFilter)
                const img = {
                    original: url,
                    thumbnail: url,
                    originalHeight: 1800,
                    originalWidth: 1200,
                    description: `${directions[axis]}`
                }

                images.push(img)
            })
            setLoading(false)
            setImages(images)
        }

        fetchAsix()

    }, [startDate, selectedFilter, refreshImages])


    useEffect(() => {
        if (!props.startDate) {
            return
        }
        if (props.startDate.getTime() === startDate.getTime()) {
            return
        }
        setStartDate(props.startDate)
    }, [props.startDate])



    useEffect(() => {
        if (props.headerLabel) {
            setHeaderLabel(props.headerLabel)
        }
    }, [])


    useEffect(() => {
        if (props.seismic_event && props.seismic_event.mstime) {
            const date = new Date(props.seismic_event.mstime)
            setStartDate(date)
        }
    }, [props.seismic_event?.mstime])

    const updateDate = (date) => {
        setStartDate(date)
        if (props.updateEventDate) {
            props.updateEventDate(date)
        }
    }

    const afterRefreshImages = () => {
        setRefreshImages(refreshImages + 1)
    }

    return (
        <div key={`div-seismic-day-figures-${props.device_id}`}>
            
            <p className='text-2xl mb-5'>{headerLabel}</p>
            {props.seismic_event && props.seismic_event.points[props.device_id] && props.seismic_event.points[props.device_id].map((point) => {
                return (
                    <div key={`p-s-${props.device_id}`}>
                        {point.values_s &&
                            <p className="mb-1 ml-3" key={`s-${props.device_id}`}>
                                Значение точки S: {point.values_s[0]} (E), {point.values_s[1]} (N), {point.values_s[2]} (Z)
                            </p>
                        }
                        {point.values_p &&
                            <p className="mb-7 ml-3" key={`p-${props.device_id}`}>
                                Значение точки P: {point.values_p[0]} (E), {point.values_p[1]} (N), {point.values_p[2]} (Z) 
                            </p>
                        }
                    </div>
                )
            })}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ResponsiveDatePickers
                        className='w-full'
                        date={startDate}
                        onChange={updateDate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FilterChooser
                        value={selectedFilter}
                        setSelectedFilter={setSelectedFilter}
                    />
                </Grid>

            </Grid>
            {loading &&
                <div className="flex items-center justify-center mt-5">
                    <CircularProgress />
                </div>
            }

            {!loading && images.length !== 0 &&
                <div>
                    <RefreshImages startDate={startDate} stationId={props.device_id} afterRefreshImages={afterRefreshImages} />
                    <ImageGallery items={images} showPlayButton={false} showNav={true} />
                    <hr className='my-5' />
                    <AxisChart
                        date={startDate}
                        filter={selectedFilter}
                        device_id={props.device_id}
                        seismic_event={props.seismic_event}
                        updatePointsInfo={props.updatePointsInfo}
                        updateEventDate={props.updateEventDate}
                        selectedDataSource={props.selectedDataSource}
                    />

                </div>
            }
            {!loading && images.length === 0 &&
                <p className='mt-5'>На данное число файлы не найдены.</p>
            }
        </div>
    )
}

export default SeismicDayFiguresGallery;

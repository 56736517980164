// import { Vincenty } from "tsgeo/Distance/Vincenty";
// import { Vincenty } from 'tsgeo/Bearing/DirectVincentyBearing'
import LatLonEllipsoidal_Vincenty from 'geodesy/latlon-ellipsoidal-vincenty';


/**
 * @description Get information about the intersection points of a circle.
 * Adapted from: https://stackoverflow.com/a/12221389/5553768.
 * @param {Object} c1 An object describing the first circle.
 * @param {float} c1.x The x coordinate of the circle.
 * @param {float} c1.y The y coordinate of the circle.
 * @param {float} c1.r The radius of the circle.
 * @param {Object} c2 An object describing the second circle.
 * @param {float} c2.x The x coordinate of the circle.
 * @param {float} c2.y The y coordinate of the circle.
 * @param {float} c2.r The radius of the circle.
 * @returns {Object} Data about the intersections of the circles.
 */
export function circleIntersection(c1, c2) {
    c1.x = deg2rad(c1.x)
    c1.y = deg2rad(c1.y)
    c2.x = deg2rad(c2.x)
    c2.y = deg2rad(c2.y)
    c1.r = c1.r / 1000 / 6378.1
    c2.r = c2.r / 1000 / 6378.1
    console.log(c1)
    console.log(c2)
    // Start constructing the response object.
    const result = {
        intersect_count: 0,
        intersect_occurs: true,
        one_is_in_other: false,
        are_equal: false,
        point_1: { x: null, y: null },
        point_2: { x: null, y: null },
    };

    // Get vertical and horizontal distances between circles.
    const dx = c2.x - c1.x;
    const dy = c2.y - c1.y;

    // Calculate the distance between the circle centers as a straight line.
    const dist = Math.hypot(dy, dx);

    // Check if circles intersect.
    if (dist > c1.r + c2.r) {
        result.intersect_occurs = false;
    }

    // Check one circle isn't inside the other.
    if (dist < Math.abs(c1.r - c2.r)) {
        result.intersect_occurs = false;
        result.one_is_in_other = true;
    }

    // Check if circles are the same.
    if (c1.x === c2.x && c1.y === c2.y && c1.r === c2.r) {
        result.are_equal = true;
        result.are_equal = true;
    }

    // Find the intersection points
    if (result.intersect_occurs) {
        // Centroid is the pt where two lines cross. A line between the circle centers
        // and a line between the intersection points.
        const centroid = (c1.r * c1.r - c2.r * c2.r + dist * dist) / (2.0 * dist);

        // Get the coordinates of centroid.
        const x2 = c1.x + (dx * centroid) / dist;
        const y2 = c1.y + (dy * centroid) / dist;

        // Get the distance from centroid to the intersection points.
        const h = Math.sqrt(c1.r * c1.r - centroid * centroid);

        // Get the x and y dist of the intersection points from centroid.
        const rx = -dy * (h / dist);
        const ry = dx * (h / dist);

        // Get the intersection points.
        result.point_1.x = Number((x2 + rx).toFixed(15));
        result.point_1.y = Number((y2 + ry).toFixed(15));

        result.point_2.x = Number((x2 - rx).toFixed(15));
        result.point_2.y = Number((y2 - ry).toFixed(15));

        // Add intersection count to results
        if (result.are_equal) {
            result.intersect_count = null;
        } else if (result.point_1.x === result.point_2.x && result.point_1.y === result.point_2.y) {
            result.intersect_count = 1;
        } else {
            result.intersect_count = 2;
        }

        result.point_1.x = rad2deg(result.point_1.x)
        result.point_1.y = rad2deg(result.point_1.y)

        result.point_2.x = rad2deg(result.point_2.x)
        result.point_2.y = rad2deg(result.point_2.y)
    }
    return result;
}

export function degToRad(degrees) {
    return degrees * (Math.PI) / 180;
}

export function radToDeg(radians) {
    return radians * (180 / Math.PI);
}

export function getIntersectionPoints(circle1, circle2) {
    // Convert WGS84 coordinates to radians
    var lat1Rad = degToRad(circle1.lat);
    var lon1Rad = degToRad(circle1.lon);
    var lat2Rad = degToRad(circle2.lat);
    var lon2Rad = degToRad(circle2.lon);

    // Earth radius in meters
    var earthRadius = 6378137;

    // Convert WGS84 coordinates to Cartesian coordinates
    var x1 = earthRadius * Math.cos(lat1Rad) * Math.cos(lon1Rad);
    var y1 = earthRadius * Math.cos(lat1Rad) * Math.sin(lon1Rad);
    var x2 = earthRadius * Math.cos(lat2Rad) * Math.cos(lon2Rad);
    var y2 = earthRadius * Math.cos(lat2Rad) * Math.sin(lon2Rad);

    // Calculate the distance between the centers of the circles
    var dx = x2 - x1;
    var dy = y2 - y1;
    var distance = Math.sqrt(dx * dx + dy * dy);

    // Check if the circles are overlapping or disjoint
    if (distance > circle1.radius + circle2.radius) {
        // Circles are disjoint, no intersection points
        return [];
    } else if (distance < Math.abs(circle1.radius - circle2.radius)) {
        // One circle is completely inside the other, no intersection points
        return [];
    } else if (distance === 0 && circle1.radius === circle2.radius) {
        // Circles are coincident, infinite intersection points
        return [];
    } else {
        // Calculate the intersection points
        var a = (circle1.radius * circle1.radius - circle2.radius * circle2.radius + distance * distance) / (2 * distance);
        var h = Math.sqrt(circle1.radius * circle1.radius - a * a);
        var cx = x1 + (a * dx) / distance;
        var cy = y1 + (a * dy) / distance;
        var intersection1 = {
            lat: radToDeg(Math.asin(cy / earthRadius)),
            lon: radToDeg(Math.atan2(cx, cy))
        };
        var intersection2 = {
            lat: radToDeg(Math.asin((cy + (h * dx) / distance) / earthRadius)),
            lon: radToDeg(Math.atan2((cx - (h * dy) / distance), (cy + (h * dx) / distance)))
        };

        // Return the intersection points
        return [intersection1, intersection2];
    }
}


function deg2rad(deg) {
    return (deg * Math.PI) / 180;
}

function rad2deg(rad) {
    return (rad * 180) / Math.PI;
}

// export function getIntersectionPointsVincenty(lat1, lon1, r1, lat2, lon2, r2) {
//     const ellipsoid = {
//         a: 6378137,
//         b: 6356752.314245,
//         f: 1 / 298.257223563,
//     };

//     const directVincenty = new Vincenty(ellipsoid);

//     const brg1 = directVincenty.inverse(lat1, lon1, lat2, lon2).initialBearing;
//     const α1 = (brg1 + 180) % 360;
//     const α2 = (α1 - 180 + 360) % 360;

//     const d = directVincenty.inverse(lat1, lon1, lat2, lon2).distance;
//     const d1 = (Math.pow(r1, 2) - Math.pow(r2, 2) + Math.pow(d, 2)) / (2 * d);
//     const d2 = d - d1;

//     if (d > r1 + r2 || d < Math.abs(r1 - r2)) {
//         return false;
//     }

//     const height = Math.sqrt(Math.pow(r1, 2) - Math.pow(d1, 2));
//     const heightFraction = height / d1;

//     const latLng1 = directVincenty.direct(lat1, lon1, α1, d1);
//     const latLng2 = directVincenty.direct(lat2, lon2, α2, d2);

//     const latLngs1 = {
//         lat: latLng1.lat + heightFraction * (latLng2.lat - latLng1.lat),
//         lon: latLng1.lon + heightFraction * (latLng2.lon - latLng1.lon),
//     };

//     const latLngs2 = {
//         lat: latLng1.lat - heightFraction * (latLng2.lat - latLng1.lat),
//         lon: latLng1.lon - heightFraction * (latLng2.lon - latLng1.lon),
//     };

//     return [latLngs1, latLngs2];
// }

export function getIntersectionPointsVincenty(lat1, lon1, r1, lat2, lon2, r2) {
    const p1 = new LatLonEllipsoidal_Vincenty(lat1, lon1);
    const p2 = new LatLonEllipsoidal_Vincenty(lat2, lon2);

    const brg1 = p1.initialBearingTo(p2);
    const α1 = (brg1 + 180) % 360;
    const α2 = (α1 - 180 + 360) % 360;

    const d = p1.distanceTo(p2);
    const d1 = (Math.pow(r1, 2) - Math.pow(r2, 2) + Math.pow(d, 2)) / (2 * d);
    const d2 = d - d1;

    if (d > r1 + r2 || d < Math.abs(r1 - r2)) {
        return false;
    }

    const height = Math.sqrt(Math.pow(r1, 2) - Math.pow(d1, 2));
    const heightFraction = height / d1;

    const latLng1 = p1.destinationPoint(d1, α1);
    const latLng2 = p2.destinationPoint(d2, α2);

    const latLngs1 = {
        lat: latLng1.lat + heightFraction * (latLng2.lat - latLng1.lat),
        lon: latLng1.lon + heightFraction * (latLng2.lon - latLng1.lon),
    };

    const latLngs2 = {
        lat: latLng1.lat - heightFraction * (latLng2.lat - latLng1.lat),
        lon: latLng1.lon - heightFraction * (latLng2.lon - latLng1.lon),
    };

    return [latLngs1, latLngs2];
}


// Function to convert degrees to radians
function toRadians(degrees) {
    return degrees * (Math.PI / 180);
}

// Function to calculate the distance between two points using the Haversine formula
function calculateDistance(lat1, lon1, lat2, lon2) {
    const earthRadius = 6371; // Radius of the Earth in kilometers

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c;
    return distance;
}

// Function to calculate the points of intersection between two circles
export function calculateIntersectionPoints(lat1, lon1, radius1, lat2, lon2, radius2) {
    const distance = calculateDistance(lat1, lon1, lat2, lon2);

    // Check if the circles intersect
    if (distance > radius1 + radius2 || distance < Math.abs(radius1 - radius2)) {
        return null; // No intersection
    }

    const a = (radius1 * radius1 - radius2 * radius2 + distance * distance) / (2 * distance);
    const h = Math.sqrt(radius1 * radius1 - a * a);

    // Calculate the coordinates of the intersection points
    const x0 = lat1 + (a * (lat2 - lat1)) / distance;
    const y0 = lon1 + (a * (lon2 - lon1)) / distance;

    const x1 = x0 + (h * (lon2 - lon1)) / distance;
    const y1 = y0 - (h * (lat2 - lat1)) / distance;

    const x2 = x0 - (h * (lon2 - lon1)) / distance;
    const y2 = y0 + (h * (lat2 - lat1)) / distance;

    return { point1: { lat: x1, lon: y1 }, point2: { lat: x2, lon: y2 } };
}


export function circleIntersectionV1(lat1, lon1, r1, lat2, lon2, r2) {
    var R = 6371; // km
    var dLat = deg2rad(lat2 - lat1);
    var dLon = deg2rad(lon2 - lon1);
    var lat1 = deg2rad(lat1);
    var lat2 = deg2rad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    var AC = c / 2
    var AB = r1 / R
    var A = Math.acos(Math.tan(AC) * Math.atan(AB))

    var y = Math.sin(dLon) * Math.cos(lat2);
    var x = Math.cos(lat1) * Math.sin(lat2) -
        Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    var brng = Math.atan2(y, x);


    var B_bearing = brng - A
    var D_bearing = brng + A


    var latB = Math.asin(Math.sin(lat1) * Math.cos(r1 / R) +
        Math.cos(lat1) * Math.sin(r1 / R) * Math.cos(B_bearing));
    var lonB = deg2rad(lon1) + Math.atan2(Math.sin(B_bearing) * Math.sin(r1 / R) * Math.cos(lat1),
        Math.cos(r1 / R) - Math.sin(lat1) * Math.sin(lat2));

    return [{lat: rad2deg(latB), lon: rad2deg(lonB)}]
}

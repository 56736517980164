import moment from 'moment';
import { Component } from 'react';
import { Table } from 'react-bootstrap';
import LoginForm from './loginform';
import { BsFillTrashFill } from "react-icons/bs";
import EcoModal from '../../eco_modal';
import services from '../../requestform/services.json';
import ChangedItemDeviceListForm from './ChangedRequestDeviceListForm';
import { Snackbar } from '@mui/material';

class RTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoginForm: false,
			showDevicesForm: false,
			request_devices: [],
			is_request_devices_loaded: true,
			request_devices_checked: {},
			actionMessage: '',
			actionNotificationOpen: false

		}
		this.services = this.transformService()
	}

	transformService = () => {
		return services.reduce(function (obj, x) {
			obj[x.value] = x.label;
			return obj;
		}, {});
	}

	handleClose = () => {
		this.setState({ showLoginForm: false })
	}


	handleCloseDevicesForm = () => {
		this.setState({ showDevicesForm: false })
	}

	showLoginForm = (request) => {
		let login = "";
		if (request.account) {
			login = request.account.user_name;
		}
		this.setState({
			showLoginForm: true,
			request_id: request.id,
			login,
		})
	}

	showDevicesForm = (request) => {
		let request_devices_checked = {}
		if (request.devices.length > 0) {
			request.devices.forEach((v) => {
				request_devices_checked[v.id_device] = true;
			})
		}

		this.setState({
			showDevicesForm: true,
			request_id: request.id,
			request_devices: [],
			request_devices_checked
		})
	}

	afterSaveSuccess = () => {
		this.setState({
			showDevicesForm: false,
			actionMessage: 'Сохранение прошло успешно',
			actionNotificationOpen: true
		})

		setTimeout(() => {
            window.location.reload()
        }, 1000)

	}


	render() {
		return (
			<>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>ФИО</th>
							<th>Контакты</th>
							<th>Оборудование</th>
							<th>Доступ</th>
							<th>Дата</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{this.props.requests.map((request, i) => {
							let fio = request.name;
							let login = '--';
							if (request.account) {
								login = request.account.user_name
							}
							return (
								<tr key={request.id}>
									<td>{fio}</td>
									<td>
										EMail: <a href={"mailto:" + request.email}>{request.email} </a><br />
										Тел: <a href={"tel:" + request.phone}>{request.phone} </a> <br />
										Организация: {request.organization} <br />
										Услуга: {this.services[request.service]}
									</td>
									<td>
										{request.devices.length === 0 && "Оборудование не добавлено"}
										<ol>
											{request.devices.map(function (device, i) {
												return <li key={i}>{device.name}</li>
											})}
										</ol>
										<button className="lr-primary-button" size="small" onClick={() => this.showDevicesForm(request)}>Изменить</button>
									</td>
									<td>
										Логин: {login}
										<br />
										<button className="lr-primary-button" size="small" onClick={() => this.showLoginForm(request)}>Изменить</button>
									</td>
									<td>{moment(request.created_at).format('DD-MM-YYYY')}</td>
									<td>
										<BsFillTrashFill onClick={() => this.props.showModalRemoveResource(request.id)} className="remove-resource-icon" />
									</td>
								</tr>
							)
						})}
					</tbody>
				</Table>
				<LoginForm show={this.state.showLoginForm} handleClose={this.handleClose} login={this.state.login} request_id={this.state.request_id} />

				<EcoModal
					key="addupdate-modal"
					open={this.state.showDevicesForm}
					handleClose={this.handleCloseDevicesForm}
					content={ChangedItemDeviceListForm}
					title={`Изменение параметров доступа`}
					allDevices={this.props.devices}
					devicesLoaded={this.state.is_request_devices_loaded}
					checkedDevices={this.state.request_devices_checked}
					id={this.state.request_id}
					afterSaveSuccess={this.afterSaveSuccess}
					url="save_devices_for_request"
				/>


				<Snackbar
					open={this.state.actionNotificationOpen}
					autoHideDuration={2500}
					onClose={() => this.setState({actionNotificationOpen: false})}
					message={this.state.actionMessage}
				/>

			</>
		);
	}
}

export default RTable;





import { Component } from 'react';
import LastData from '../last_data';
import LastDataPanel from './last_data_panel';

const defaultState = {
	data: {},
	parameters: {}
}


class MeteoWifiDevice extends Component {
	constructor(props) {
		super(props);
		this.state = defaultState
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.data) {
			this.handleData(nextProps.data, nextProps.parameters);
		}
	}

	handleData(data, parameters) {
		this.setState({
			data: data.info.last_parameter_values,
			parameters,
		})
	}


	render() {
		return (
			<div>
				<LastDataPanel 
					deviceParameters={this.props.deviceParameters}
					parameters={this.props.parameters}
					data={this.props.data}
				/>
			</div>
		);
	}
}

export default MeteoWifiDevice;

import axios from 'axios';
import * as consts from '../../consts';
import DeviceTypeIcon from '../../model/device.type.icon';
import getHeaders from '../headers';


const saveDeviceTypeIcon = async (icon : DeviceTypeIcon) => {

    const headers = getHeaders()
    const result = await axios({
		method: "POST",
		url: `${consts.BASE_API_URL}/devices/edit_device_type_icon`,
		headers,
        data: {
            icon: icon
        },
	})

    return result.data
};


export default saveDeviceTypeIcon
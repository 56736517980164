import { Component } from 'react';
import RequestForm from './requestform/modal'
import '../landing/css/styles.css';
import Nav from './nav';
import Sec1Tabs from './landing/sec1_tabs';
import Sec2Row from './landing/sec2_row';
import Sec3Tabs from './landing/sec3_tabs';
import Sec4Tabs from './landing/sec4_tabs';
import Sec5Row from './landing/sec5_row';
import Header from './landing/header';
import Questions from './landing/questions';
import Contacts from './landing/contacts';
import SectionHeader from './landing/section/header';
import ResponsiveAppBar from './nav';
import EcoModal from './eco_modal';

class Landing extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showRequestForm: false,
			isOrder: true,
			defaultService: 0,
		}
	}

	showRequestForm = (isOrder, defaultService = 0) => {
		this.setState({ showRequestForm: true, isOrder, defaultService })
	}

	closeRequestForm = () => {
		this.setState({ showRequestForm: false, defaultService: 0 })
	}

	render() {
		return (
			<>
				<ResponsiveAppBar
					showRequestForm={(isOrder) => this.showRequestForm(isOrder)}
					closeRequestForm={this.closeRequestForm}
				/>

				<Header />

				<section id="part01" className="part01">
					<SectionHeader
						number="01"
						header={["Создаем сети и сервисы для мониторинга", "в режиме реального времени (IoT)"]}
						description={["Для работы с данными мы разработали специальную цифровую платформу <span style='font-weight: 700;'>«Мониторинг 4.0»</span>"]}
					/>
					<Sec1Tabs />
				</section>

				<section id="part02" className="part02">
					<SectionHeader
						number="02"
						header={["Подключаем к платформе", "стороннее оборудование"]}
						description={["Мы можем подключить к сети Ваш пункт мониторинга или оборудование, например,",
							"метео- или сейсмостанцию, карбоновый полигон, фотоловушку и многое другое."]}
						secondDescription="Как это работает?"
					/>

					<Sec2Row />
				</section>

				<section>
					<Questions
						showRequestForm={() => this.showRequestForm(true)}
						closeRequestForm={this.closeRequestForm}
					/>
				</section>

				<section id="part03">
					<SectionHeader
						number="03"
						header={["Оборудование", "для мониторинга"]}
						description={["Устройства <span style='font-weight: 700;'>Smart Ecosystems</span> — наша разработка для автономного мониторинга окружающей среды и дикой природы."]}
					/>
					<Sec3Tabs />
				</section>

				<section id="part04">
					<SectionHeader
						number="04"
						header={["Аэромониторинг местности", "с помощью БПЛА"]}
						description={["Выполняем профессиональную аэрофотосъемку леса, ландшафта или городской среды с дальнейшей фотограмметрической обработкой данных."]}
					/>

					<Sec4Tabs
						showRequestForm={() => this.showRequestForm(true, 4)}
						closeRequestForm={this.closeRequestForm}
					/>
				</section>

				<section id="part05">
					<SectionHeader
						number="05"
						header={["Проводим научный анализ данных", "и полевые исследования"]}
						description={["Выявляем зависимости изменений мониторинговых параметров, разрабатываем",
							"математические модели и прогнозируем изменения."]}
					/>

					<Sec5Row
						showRequestForm={() => this.showRequestForm(true, 5)}
						closeRequestForm={this.closeRequestForm}
					/>
				</section>

				<Contacts
					showRequestForm={() => this.showRequestForm(true)}
					closeRequestForm={this.closeRequestForm}
				/>


				<EcoModal
					show={this.state.showRequestForm}
					close={() => this.closeRequestForm(false)}
					handleClose={() => this.closeRequestForm(false)}
					isOrder={this.state.isOrder}
					defaultService={this.state.defaultService}
					open={this.state.showRequestForm}
					content={RequestForm}
					title={``}
				/>

			</>
		);

	}
}

export default Landing;

import CameraDevice from "../camera";
import ReactPlayer from 'react-player'

export interface AIFireDeviceProps {
	device_id: number;
}

const AICameraFireDevice = ({ device_id }: AIFireDeviceProps) => {
	return (
		<div className='pt-5'>
			<CameraDevice device_id={device_id} />
			<div className="">
				<hr className="border-dashed my-6" />
				<div className='h4'>Live:</div>

				<ReactPlayer url='/fire_example.mp4' width={'420px'} controls={true} playing={true} loop={true} />
			</div>
		</div>

	);
}
export default AICameraFireDevice;



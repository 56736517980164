import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControlLabel, Switch, FormGroup } from '@mui/material';
import EcoModal from '../eco_modal';
import LoginForm from '../loginform/main';

interface TermOfUseDialogProps {
    open: boolean;
    handleClose: () => void;
}



export default function TermOfUseDialog({ open, handleClose }: TermOfUseDialogProps) {

    const [isApproved, setIsApproved] = React.useState<boolean>(false)
    const [showLoginForm, setShowLoginForm] = React.useState(false);

    const updateTermOfUse = (checked: boolean) => {
        setIsApproved(checked)
        if (checked) {
            localStorage.setItem('terms_of_use_approved', "true")
        } else {
            localStorage.removeItem('terms_of_use_approved')
        }
    }



    const LoginModalForm = () => {
        return (
            <EcoModal
                close={() => setShowLoginForm(false)}
                key="aisettings-car-modal"
                open={showLoginForm}
                handleClose={() => setShowLoginForm(false)}
                content={LoginForm}
                title={``}
                afterLogin={ () => window.location.reload()}
            />
        )
    }

    const clickOkButton = () => {
        const isAlreadyLogin = localStorage.getItem('token')
        if (!isAlreadyLogin) {
            setShowLoginForm(true)
        }
        handleClose()
    }

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                className="z-[9999999]"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Предупреждение ⚠️"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p className="mt-5">
                            Администрация сайта не ведет деятельность в области гидрометеорологии и смежных с ней областях.
                            Все материалы на сайте являются результатом экспериментальных разработок,
                            возможны отклонения и неточности в данных.
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <FormGroup className="my-3">
                        <FormControlLabel control={
                            <Switch
                                onChange={(event) => updateTermOfUse(event.target.checked)}
                                checked={isApproved}
                            />}
                            label="Больше не показывать"
                        />
                    </FormGroup>

                    <Button onClick={clickOkButton}>Понятно</Button>
                </DialogActions>
            </Dialog>

            <LoginModalForm />
        </div>
    );
}
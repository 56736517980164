import axios from 'axios';
import * as consts from '../../consts';
import SeismicEvent from '../../model/seismic.event';
import getHeaders from '../headers';

export const getSeismicEvents = async (deviceUUID: string = ''): Promise<SeismicEvent[]|null> => {

	try {

		const headers = getHeaders()

		const response = await axios({
			method: "GET",
			url: `${consts.BASE_API_URL}/seismicevents/get_seismic_events`,
			headers,
			params: {
				timeoffset: new Date().getTimezoneOffset() / 60,
				device_uuid: deviceUUID
			},
		})

		return response.data.events as SeismicEvent[]
	} catch (error: any) {
		console.error(error);

		if (error.response.status === 401) {
			return null
		}
		
	}
	return null

};

export default getSeismicEvents;
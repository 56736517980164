import { useEffect, useState } from 'react';

const isServer = () => typeof window === 'undefined';
const isBrowser = () => !isServer();

export const useWindowWidth = () => {
	const [windowWidth, setWindowWidth] = useState<number>(isBrowser() ? window.innerWidth : 0);

	const handleWindowResize = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);
		handleWindowResize();

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	return windowWidth;
};

import * as consts from '../consts';

import Location from './location';

class   Device {
    module_type_id: number = NaN;
    id_device: number = NaN;
    device_type_id: number = NaN;
    is_public: number = NaN;
    is_allow_download: number = NaN;
    is_verified: number = NaN;
    tz: string = "";
    serial_number: string = "";
    location_description: string = "";
    name: string = "";
    time_not_online: number = 60;
    uuid: string = "";
    file_format: string = "";
    device_id: number = NaN;
    location: Location = new Location();
    last_parameter_values: any;
    last_update_datetime: Date | undefined;
    latitude: number = NaN;
    longitude: number = NaN;
    description: string = ""

    isComparable() {
        return !(this.device_type_id === consts.DEVICE_TYPES.CAMERA)
    }

    // Returns name with serial number.
    getFullName() {
        return `${this.name}, s/n ${this.serial_number}`
    }

    // Returns parameters for device
    getParameters() {
        if (this.device_type_id === consts.DEVICE_TYPES.MODULE) {
            return consts.MODULE_INFO[this.module_type_id].parameters;
        }
        return consts.DEVICE_INFO[this.device_type_id].parameters
    }

    static from(json: any) {
        return Object.assign(new Device(), json);
    }

    static fromArray(devices: Device[]) {
        return devices.map(device => Object.assign(new Device(), device))
    }

}

export default Device;
